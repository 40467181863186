import React, { useState } from 'react';
import { firestore } from '../../../firebase/firebase'
import { storage } from '../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import HRForm from '../../components/HumanResources/HRForm';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

const HRNew = (props) => {

    const throwAsyncError = useAsyncError();

    const initialHrForm = {
        name: '',
        description: '',
        dateCreated: '',
        dateUpdated: '',
        pdfURL: '',
        docType: setDocType(props.location.state.pathType),
    }

    const [hrForm, setHrForm] = useState(initialHrForm);
    const [hrPDF, setHrPDF] = useState(null);
    const [submitting, setSubmitting] = useState(0);    // 0 false, 1 true, -1 successful
    const [error, setError] = useState("");

    const title = (() => {
        switch(props.location.state.pathType) {
            case 'policies':
                return 'Policy';
            case 'accessibility':
                return 'Accessibility';
            case 'healthsafety':
                return 'Health & Safety';
            default:
                return 'HR';
        }
    })();


    function setDocType(pathType){
        switch(pathType){
            case 'policies':
                return 'policies';
            case 'accessibility':
                return 'accessibility';
            case 'healthsafety':
                return 'healthsafety';
            default:
                return 'none';
        }
    }

    

    const handleChange = (event) => {
        switch (event.target.name) {
            //Handle value as array 
            default:
                setHrForm({ ...hrForm, [event.target.name]: event.target.value });
                break;
        }
    }

    const handleFileUpload = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setHrPDF(file);
            setHrForm({ ...hrForm, pdfURL: file.name});
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        createMSDS();
    }

    const createMSDS = async () => {
        try{
            setSubmitting(1);
            let newHrForm = hrForm;
            newHrForm.dateCreated = Date.now() / 1000;
            newHrForm.archived = false;

            if(hrPDF !== null){
                console.log('has pdf')
                //Create reference to .pdf
                const storageRef = storage.ref();
                let docId = firestore.collection('humanResources').doc().id;
                const pdfRef = storageRef.child('HR/' + hrPDF.name + '_' + docId);

                pdfRef.put(hrPDF).then((ref) => {
                    pdfRef.getDownloadURL().then((url) => {
                        newHrForm.pdfURL = url;
                        newHrForm.docId = docId;
                        newHrForm.dateUpdated = new Date(newHrForm.dateUpdated);
                        newHrForm.dateUpdated = new Date(newHrForm.dateUpdated.getTime() + (newHrForm.dateUpdated.getTimezoneOffset() * 60000)) / 1000;

                        firestore.collection('humanResources').doc(docId).set(newHrForm)
                            .then(() => {
                                setSubmitting(-1);
                                setTimeout(() => {
                                    setHrPDF();
                                    setHrForm(initialHrForm);
                                    setSubmitting(0);
                                    setError('');
                                }, 2000);
                        
                            })
                    })
                })
            }
            else{
                let docId = firestore.collection('humanResources').doc().id;
                newHrForm.pdfURL = null;
                newHrForm.docId = docId;
                newHrForm.dateUpdated = new Date(newHrForm.dateUpdated);
                newHrForm.dateUpdated = new Date(newHrForm.dateUpdated.getTime() + (newHrForm.dateUpdated.getTimezoneOffset() * 60000)) / 1000;

                firestore.collection('humanResources').doc(docId).set(newHrForm)
                    .then(() => {
                        setSubmitting(-1);
                        setTimeout(() => {
                            setHrPDF();
                            setHrForm(initialHrForm);
                            setSubmitting(0);
                            setError('');
                        }, 2000);
                    })
            }
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        };
    }
        
    //substring text to use ... if too long
    const substringText = (text) => {
        if(text.length > 20){
            return text.substring(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <React.Fragment>
            <HRForm
                hrForm={hrForm} hrPDF={hrPDF} setHrPDF={setHrPDF}
                handleChange={handleChange} handleSubmit={handleSubmit}
                handleFileUpload={handleFileUpload}
                submitting={submitting} error={error} 
                substringText={substringText} title={title}
            />
        </React.Fragment>
    )
}

export default withRouter(HRNew);