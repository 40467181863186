import * as actionTypes from '../actions/actionTypes'
import _ from 'lodash';
import { updateObject } from '../utility'

//Make sure reducers have an initialState 
const initialState = {
    //access this state in your components using the name you 
    // set in index.js (you did set it in combineReducers, right?)
    // products.state.featuredProducts 
    // or products.state.loading

    //all products
    products: [],

    equipment: [],

    //featured products (current design should be limited to 3...)
    featuredProducts: [],

    //currently selected product
    product: {},

    //currently selected variant
    variant: {},

    //loading spinner state
    featuredProductsLoading: true,
    productsLoading: true,
    productLoading: true,
    equipmentLoading: true,

    qty: '1.0',

    price: {},

    shippingMethod: '',

    error: false,
}

function round(x, y) {
    x = x * 100;
    y = y * 100;
    x = x - (x % y);
    return x / 100;
}

function clamp(value, min, max) {

    let retVal = value

    if (value < min)
        retVal = min;
    else if (value > max)
        retVal = max
    return retVal;
}

function setQtyDecimals(value) {
    let numDecimals = 0;

    if (value.toString().includes('.'))
        numDecimals = value.toString().split(".")[1].length || 0;

    return numDecimals;
}

const incrQty = (state, action) => {
    if (_.isEmpty(state.product)) {
        return '0.0';
    }
    var temp = isNaN(parseFloat(state.qty)) ? 0 : parseFloat(state.qty);
    temp = temp + (state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep );
    

    temp = round(temp, (state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep ));
    temp = clamp(temp, state.product.qtyMin, Number.MAX_SAFE_INTEGER)
    return temp.toFixed(setQtyDecimals((state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep )));
}

const decrQty = (state, action) => {
    if (_.isEmpty(state.product)) {
        return '0.0';
    }
    var qty = isNaN(parseFloat(state.qty)) ? 0 : parseFloat(state.qty);
    qty = Math.max((state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep ), qty - (state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep ))
    qty = round(qty, (state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep ));
    qty = clamp(qty, state.product.qtyMin, Number.MAX_SAFE_INTEGER)
    return qty.toFixed(setQtyDecimals((state.price.unit.toLowerCase() === 'per bag' || state.price.unit.toLowerCase() === 'bag' ? 1 : state.product.qtyStep )));
}

const setPrice = (state, action) => {

    let newPrice = { ...action.price }

    return {
        ...state,
        price: newPrice
    }
}

//and it is set as default state i.e. (state = initialState)
const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRODUCT:
            return updateObject(state, { productLoading: true });
        case actionTypes.FETCH_PRODUCTS:
            return updateObject(state, { productsLoading: true });
        case actionTypes.FETCH_FEATURED_PRODUCTS:
            return updateObject(state, { featuredProductsLoading: true });
        case actionTypes.GET_PRODUCT:
            return updateObject(state, { product: action.product, variant: action.variant, productLoading: action.loading, qty: action.product.qtyMin.toFixed(setQtyDecimals(action.product.qtyStep)) });
        case actionTypes.GET_PRODUCTS:
            return updateObject(state, { products: action.products, productsLoading: action.loading });
        case actionTypes.GET_FEATURED_PRODUCTS:
            return updateObject(state, { featuredProducts: state.featuredProducts.concat(action.featuredProducts), featuredProductsLoading: action.loading });
        case actionTypes.SET_CURRENT_VARIANT:
            return updateObject(state, { variant: action.variant });
        case actionTypes.CREATE_PRODUCT:
            return updateObject(state, { products: action.products });
        case actionTypes.EDIT_PRODUCT:
            return updateObject(state, { products: action.products });
        case actionTypes.DELETE_PRODUCT:
            return updateObject(state, { products: action.products });
        case actionTypes.INCR_QTY:
            return updateObject(state, { qty: incrQty(state, action), error: false });
        case actionTypes.DECR_QTY:
            return updateObject(state, { qty: decrQty(state, action), error: false });
        case actionTypes.SET_QTY:
            return updateObject(state, !_.isString(action.qty) ? state : { qty: action.qty });
        case actionTypes.SET_SHIP_METHOD:
            return updateObject(state, !_.isString(action.shippingMethod) ? state : { shippingMethod: action.shippingMethod });
        case actionTypes.SET_ERROR:
            return updateObject(state, !_.isBoolean(action.error) ? state : { error: action.error });
        case actionTypes.SET_PRICE:
            return updateObject(state, setPrice(state, action));
        case actionTypes.FETCH_EQUIPMENT:
            return updateObject(state, { equipmentLoading: true });
        case actionTypes.GET_EQUIPMENT:
            return updateObject(state, { equipment: action.equipment, equipmentLoading: action.equipmentLoading });
        default:
            return state;
    }
};

export default productsReducer

/*

Current calc written in php

function woocommerce_custom_surcharge() {

    $COOKIE = "coords"; //Coordinates Cookie
    $baseShip = 0;
    $duration = 0;
    $distance = 0;
    $destination = "";


    ////////////////
    //CONSTANTS
    ///////////////
    $origin = "49.904521,-96.932222";
    //$apiKey = "AIzaSyBD-FCHZx82HT1yAYMQQQ0iiovrPiCxAyg";
    $apiKey = "AIzaSyBCB0Px7psLZfaPOa8LHvvFKNochbsRETw";
    $BASE = 25.5; //25, +2% 04/21/2020
    $LOW_DISTANCE = 20;
    $HIGH_DISTANCE = 40;
    $LOW_RATE = 1.275;  //1.25, +2% 04/21/2020
    $MID_RATE = 1.479;  //1.45, +2% 04/21/2020
    $HIGH_RATE = 1.683; //1.65, +2% 04/21/2020
    $MINIMUM_CHARGE = 57.50;
    $TAX = 1.05;
    $CARBON_TAX = 0.04;
    $MAX_LOAD = 10;

    $NOSHIP = "Unfortunately, we are unable to ship to this location through online ordering. Please contact us to place your order. Thank you.";

    $NOCOOKIE = "Please select a delivery location on the map";

    $lat = $_COOKIE["lat"];
    $long = $_COOKIE["long"];
    $destination = $lat.",".$long;

    ////////////////
    //GET for Google Distance Matrix API
    ///////////////
    $details = "https://maps.googleapis.com/maps/api/distancematrix/json?origins=".$origin."&destinations=".$destination."&mode=driving"."&key=".$apiKey;

    $json = file_get_contents($details);

    $details = json_decode($json, TRUE);
    $status = $details["rows"][0]['elements'][0]['status'];
    $duration = $details["rows"][0]['elements'][0]['duration']['value'] / 60;
    $distance = $details["rows"][0]['elements'][0]['distance']['value'] / 1000;

    wc_clear_notices();
    if ($status === "ZERO_RESULTS")
    {
        $displayShipping = -1;
        remove_action( 'woocommerce_proceed_to_checkout','woocommerce_button_proceed_to_checkout', 20);
        wc_clear_notices();
        wc_add_notice( __($NOSHIP), 'notice');
    }

    ////////////////
    //Determine Shipping Price
    ///////////////


    if ($distance <= $LOW_DISTANCE) {
        $baseShip = $duration * $LOW_RATE * 2;
    }

    if ($distance > $LOW_DISTANCE && $distance <= $HIGH_DISTANCE) {
        $baseShip = $duration * $MID_RATE * 2;
    }

    if ($distance > $HIGH_DISTANCE) {
        $baseShip = $duration * $HIGH_RATE * 2;
    }

    $baseShip = $baseShip + $BASE;

    if ($baseShip < $MINIMUM_CHARGE) {
        $baseShip = $MINIMUM_CHARGE;
    }


    $quantity = array();
    $beingShipped = 0;
    $totalLoads = 0;
    $totalDiscounts = 0;
    $overload = 0;

    $cartSubtotal = 0;

    $calculatedGST = 0;

    foreach ( WC()->cart->get_cart() as $cart_item ) {
        $product = $cart_item['data'];
        if(!empty($product)){

            $remaining = $cart_item['quantity'];
            $cartSubtotal += ($remaining * $cart_item['data']->get_price());
            $product_id = method_exists( $product, 'get_id' ) ? $product->get_id() : $product->id;

            $myAttribute = array_shift( wc_get_product_terms( $product_id, 'pa_shipping', array( 'fields' => 'names' ) ) );
            if (strcmp($product->get_attribute('pa_shipping'), "Delivery") != 0)
               $remaining = -1;
        }



        if($remaining>0){
            $beingShipped++;

            switch ($product_id){
                case 6543:
                case 6514:
                case 6537:
                case 6540:
                case 6546:
                case 6548:
                    $remaining = round(($remaining / 15) * $MAX_LOAD);
                    break;
            }

            array_push($quantity, $remaining);
        }



    }



    $individual = sizeof($quantity);
    rsort($quantity);

    for($i = 0; $i < $individual; $i++){
        $totalLoads++;
        if ($quantity[$i] >= $MAX_LOAD){
            $loads = floor($quantity[$i] / $MAX_LOAD);
            $extra = $quantity[$i] % $MAX_LOAD;
            for($k = 1; $k < $loads; $k++){
                $overload++;
                $totalLoads++;
            }
            $quantity[$i] = $extra;
            if($quantity[$i] > 0){
                $totalLoads++;
                $overload++;
            }
            continue;
        }
        if ($quantity[$i] == $MAX_LOAD){
            $totalLoads++;
            continue;
        }
        for($j = $i + 1; $j < sizeof($quantity); $j++){

            if($quantity[$i]+$quantity[$j] <= $MAX_LOAD){
                $quantity[$i] = $quantity[$i] + $quantity[$j];
                unset($quantity[$j]);
                $totalDiscounts++;
                break;
            }
        }

    }

    $totalShipping = $baseShip * ($totalLoads - (0.5 * $totalDiscounts) - (0.5 * $overload));
    $totalShipping = floor($totalShipping);
    $totalShipping -= $totalShipping % 5;

    $totalShipping = $totalShipping < $MINIMUM_CHARGE ? $MINIMUM_CHARGE : $totalShipping;

    if($totalShipping > 0 && $beingShipped > 0 && $displayShipping > 0){
        if($lat == "" || $long == ""){
              remove_action( 'woocommerce_proceed_to_checkout','woocommerce_button_proceed_to_checkout', 20);
            wc_clear_notices();
            $message = sprintf( '<a href="%s" class="button wc-forward">%s</a> %s', esc_url( wc_get_page_permalink( 'cart' ) ), esc_html__( 'VIEW MAP', 'woocommerce' ), esc_html( $NOCOOKIE ) );
            wc_add_notice( __($message), 'notice');
        }else{
            $cartSubtotal += $totalShipping;
            WC()->cart->add_fee( __('Shipping', 'woocommerce'), $totalShipping, false, 'GST only' );
        }
    }
    $carbonTax = $cartSubtotal * $CARBON_TAX;
    WC()->cart->add_fee( __('Carbon Fee', 'woocommerce'), $carbonTax, false );
}
*/
