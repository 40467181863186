import { Divider, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const CustomerDrawer = (props) => {

    const classes = useStyles();

    return <List style={{zIndex:'999'}}>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/customers/new'} button>
            <ListItemText primary={'+ Add Customer'} />
        </ListItem>
        <Divider></Divider>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/customers'} button>
            <ListItemText primary={'Customer Manager'} />
        </ListItem>
        {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/customers/rates'} button>
            <ListItemText primary={'Customer Jobs & Rates'} />
        </ListItem> */}
    </List>
}