import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { PDFViewer, Font, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
    },
    buttonRow: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    checkRow: {
        margin: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-evenly',
    },
}));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',
        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 12,
    },
    tableTextBold: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
});

// Create Document Component
const TimesheetTablePDF = (props) => {

    const classes = useStyles();
    
    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
     */
    const Table = (props) => {

        let columnWidth = 100 / props.columns.length + '%';

        return (
            <View style={styles.dTable}>
                <Text style={styles.dTableRow}>{props.title}</Text>
                <View style={styles.dTableRow}>
                    {props.columns.map((column, index) => {
                        return (
                            <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                <Text style={{ ...styles.dTableCell, fontSize: 12 }}>{column.title}</Text>
                            </View>
                        )
                    })}
                </View>

                {props.data.map((data, index) => {
                    return (
                        <View key={'tablerow' + props.title + index}>
                            <View style={styles.dTableRow} wrap={false}>
                                {props.columns.map((column, index) => {

                                    //Use render function if exists, otherwise render data.
                                    let cellData = column.render ? column.render(data) : data[column.field];

                                    return (
                                        <View style={{ ...styles.dTableCol, width: columnWidth }} key={index}>
                                            <Text style={styles.dTableCell}>{cellData}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
                })}
            </View>
        )
    }



    let weekData = props.fullData.timesheetData;
    //find user in users
    let user = props.users.find(user => user.displayName === props.fullData.data[0].uid);

    let firstWeek = [];
    let secondWeek = [];
    let OT= user?.department === 'Office' ? 40 : 50;
    let firstWeekTotal = 0;
    let firstWeekRegular = 0;
    let firstWeekOT = 0;
    let secondWeekTotal = 0;
    let secondWeekRegular = 0;
    let secondWeekOT = 0;
    let firstDayOfFirstWeekString = moment(props.fullData.firstDate).format('MMMM Do, YYYY');
    let lastDayOfSecondWeekString = moment(props.fullData.lastDate).format('MMMM Do, YYYY');
    let firstDayOfSecondWeekString = moment(props.fullData.lastDate).startOf('week').format('MMMM Do, YYYY');
    let lastDayOfFirstWeekString = moment(props.fullData.firstDate).endOf('week').format('MMMM Do, YYYY');

    if (weekData.every(data => data.uid === weekData[0].uid)) {
        
        weekData = weekData.sort((a, b) => (a.date > b.date) ? 1 : -1);
        
        // Separate data into weeks using moment
        let firstWeekEnd = moment(props.fullData.firstDate).endOf('week');
        //add up operatorHours for first week
        firstWeekTotal = weekData.reduce((total, data) => {
            if (moment(data.date).isBefore(firstWeekEnd)) {
                return total + Number(data.operatorHours);
            } else {
                return total;
            }
        }, 0);

        firstWeekRegular = firstWeekTotal > OT ? OT : firstWeekTotal;

        firstWeekOT = firstWeekTotal > OT ? firstWeekTotal - OT : 0;

        secondWeekTotal = weekData.reduce((total, data) => {
            if (moment(data.date).isAfter(firstWeekEnd)) {
                return total + Number(data.operatorHours);
            } else {
                return total;
            }
        }, 0);

        secondWeekRegular = secondWeekTotal > OT ? OT : secondWeekTotal;

        secondWeekOT = secondWeekTotal > OT ? secondWeekTotal - OT : 0;
    

        weekData.forEach((data) => {
            if (moment(data.date).isBefore(firstWeekEnd)) {
                firstWeek.push(data);
            } else {
                secondWeek.push(data);
            }
        });
        
        // Rest of your code here...
    } else {
        weekData = weekData.sort((a, b) => (a.date > b.date) ? 1 : -1);

    }
    


 

    return (
        <div className={classes.root}>
            <div className={classes.content}>

            <div className={classes.buttonRow}>
                <Button variant="contained" startIcon={<ArrowBackIcon />}
                    onClick={() => props.history.goBack()}>Back</Button>
            </div>
                <PDFViewer style={styles.viewer}>
                    <Document>
                        <Page size="A4" style={styles.page} wrap>

                            <View style={{ ...styles.section,border:'0px', fontSize:'12px',  }}>
                                <Text>Timesheets - Total Hours: {props.fullData.totalHours}</Text>
                                <Text>{firstDayOfFirstWeekString} - {lastDayOfSecondWeekString}</Text>
                            </View>

                            {weekData.every(data => data.uid === props.fullData.timesheetData[0].uid) ?
                            <View>
                                <View style={styles.summary} wrap={false}>
                                    <Text>{firstDayOfFirstWeekString+' - '+lastDayOfFirstWeekString}</Text>
                                </View>
                                <Table
                                columns={[
                                    { title: 'Timesheet ID', field: 'timesheetID', defaultSort: 'asc' },
                                    { title: 'Employee', field: 'uid' },
                                    { title: 'Date', field: 'date' },
                                    { title: 'Hours', field: 'operatorHours' },

                                ]}
                                data={firstWeek}
                                title={'Week 1'} />
                                <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table, border:'0px',marginRight: 5, fontSize:'12px' ,width:'50%'}}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}> </Text>
                                            <Text style={styles.tableTextBoldRed}></Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.table, marginLeft: 5, fontSize:'12px',backgroundColor:'#e8e8e8'  }}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Total Hours: </Text>
                                            <Text style={styles.tableTextBoldRed}>{firstWeekTotal.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Regular Hours: </Text>
                                            <Text style={styles.tableTextBoldRed}>{firstWeekRegular.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>{`Overtime >${OT.toFixed(0)} Hours: `} </Text>
                                            <Text style={styles.tableTextBoldRed}>{firstWeekOT.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.summary} wrap={false}>
                                    <Text>{firstDayOfSecondWeekString+' - '+lastDayOfSecondWeekString}</Text>
                                </View>
                                <Table
                                columns={[
                                    { title: 'Timesheet ID', field: 'timesheetID', defaultSort: 'asc' },
                                    { title: 'Employee', field: 'uid' },
                                    { title: 'Date', field: 'date' },
                                    { title: 'Hours', field: 'operatorHours' },

                                ]}
                                data={secondWeek}
                                title={'Week 2'} />
                                <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table, border:'0px',marginRight: 5, fontSize:'12px' ,width:'50%'}}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}> </Text>
                                            <Text style={styles.tableTextBoldRed}></Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.table, marginLeft: 5, fontSize:'12px',backgroundColor:'#e8e8e8'  }}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Total Hours: </Text>
                                            <Text style={styles.tableTextBoldRed}>{secondWeekTotal.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>Regular Hours: </Text>
                                            <Text style={styles.tableTextBoldRed}>{secondWeekRegular.toFixed(2)}</Text>
                                        </View>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}>{`Overtime >${OT.toFixed(0)} Hours: `}  </Text>
                                            <Text style={styles.tableTextBoldRed}>{secondWeekOT.toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.summary} wrap={false}>
                                    <View style={{ ...styles.table, border:'0px',marginRight: 5, fontSize:'12px' ,width:'50%'}}>
                                        <View style={styles.tableRow}>
                                            <Text style={styles.tableText}> </Text>
                                            <Text style={styles.tableTextBoldRed}></Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.table,border:'0px', marginLeft: 5, fontSize:'12px',  }}>
 
                                        <View style={{...styles.tableRow, borderBottom:'1px solid grey'}}>
                                            <Text style={{...styles.tableText}}>Regular Hours: </Text>
                                            <Text style={{...styles.tableText}}>{(firstWeekRegular + secondWeekRegular).toFixed(2)}</Text>
                                        </View>
                                        <View style={{...styles.tableRow, borderBottom:'1px solid black'}}>
                                            <Text style={{...styles.tableText}}>{`Overtime Hours: `}  </Text>
                                            <Text style={{...styles.tableText}}>{(firstWeekOT + secondWeekOT).toFixed(2)}</Text>
                                        </View>
                                        <View style={{...styles.tableRow, borderBottom:'1px solid grey'}}>
                                            <Text style={{...styles.tableText}}>Total Hours: </Text>
                                            <Text style={{...styles.tableTextBold}}>{(firstWeekTotal + secondWeekTotal).toFixed(2)}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            :
                            <Table
                                    columns={[
                                        { title: 'Timesheet ID', field: 'timesheetID', defaultSort: 'asc' },
                                        { title: 'Employee', field: 'uid' },
                                        { title: 'Date', field: 'date' },
                                        { title: 'Hours', field: 'operatorHours' },
                                    ]}
                                    data={weekData}
                                    title={''} />
                                }

                        </Page>
                        
                    </Document>
                </PDFViewer>
            </div>
        </div >
    )
};

export default withRouter(TimesheetTablePDF);