import React, { useState} from 'react'
import { Typography, Grid, TextField, InputAdornment, IconButton, Checkbox, Menu, MenuItem, } from '@material-ui/core';
import _ from 'lodash';

import SearchIcon from '@material-ui/icons/Search';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';

const DataGridTableTitle = (props) => {

    const [columnSelectorOpen, setColumnSelectorOpen] = useState(false)

    const handleOpenColumnSelector = (e) => {
        setColumnSelectorOpen(e.currentTarget);
      };
    
    const handleCloseColumnSelector = () => {
        setColumnSelectorOpen(null);
    };

    let debouncedHandleSearchChange = _.debounce(function (value) {
        props.handleSearchChange(value)
    }, 400);

    const handleChangeColumnsVisible = (index) => {
        let container = _.cloneDeep(props.columns)
        container[index].active =  !_.isUndefined(container[index].active) ? !container[index].active : false
        props.setColumns(container)
    }

    //console.log('rerendered dataGridTableTitle')

    return (
        <>
            <Grid item xs={6}>
                <Typography variant='h6'>{props.title ? props.title : 'Undefined'} 
                    {props.rowsSelected.length > 0 && ` (${props.rowsSelected.length} rows selected)`}
                </Typography>
            </Grid>

            <Grid item xs={6}>
                <Grid container justifyContent={'flex-end'} >
                    <TextField
                        InputProps={{
                            startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                        }}
                        placeholder='Search'
                        defaultValue={props.searchValue}
                        onChange={(e) => { 
                            debouncedHandleSearchChange(e.target.value);  
                            props.page !== 0 && props.setPage(0)
                        }}
                    />
        
                    <IconButton
                        size='small'
                        onClick={(e)=> { props.updateSort() }}
                    >
                        <RefreshIcon/>
                    </IconButton>

                    <IconButton
                        size='small'
                        onClick={(e)=> { handleOpenColumnSelector(e) }}
                    >
                        <ViewColumnIcon/>
                    </IconButton>

                    <Menu
                        anchorEl={columnSelectorOpen}
                        keepMounted
                        open={columnSelectorOpen}
                        onClose={handleCloseColumnSelector}
                    >
                        <Typography noWrap style={{fontSize: '12px', fontWeight: '600', padding: '15px'}}>Add or remove columns</Typography>
                        {props.columns.map((column, index) => {
                            return (
                                <MenuItem 
                                    key={index}
                                    value={index}
                                    onClick={(e) => { handleChangeColumnsVisible(index) }}
                                >
                                    <Checkbox checked={!_.isUndefined(column?.active) ? column.active : true} />
                                    <Typography style={{marginTop: '5px'}}>{column.title}</Typography>    
                                </MenuItem>
                            )  
                        })}
                    </Menu>

                </Grid>
            </Grid>
        </>
    )
}
  export default DataGridTableTitle;