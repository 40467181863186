import React from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Checkbox, Grid, Paper, Typography, TextField, Radio, RadioGroup } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import InlineMediaViewer from '../../UI/MediaViewer/InlineMediaViewer'
import _ from 'lodash'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Img from 'react-cool-img';
import truck from '../../../../img/geeteezoomzoom.gif';
import PrintIcon from '@material-ui/icons/Print';
import trucksad from '../../../../img/geeteesad.png';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        height: '100%',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        padding: theme.spacing(2),
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    img: {
        width: '300px',
        height: '300px',
        border: '1px solid #0000004a',
        borderRadius: '8px',
      },
    row: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: 'anywhere',
    },
    weatherTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '16px',
    },
    end: {
        justifyContent: 'flex-end',
    },
    temp: {
        justifyContent: 'center',
        border: '4px solid white',
        borderRadius: '64px',
    },
    weatherCard: {
        backgroundColor: 'rgb(59, 98, 177)',
        boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.3)',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const ChecklistCard = (props) => {

    const classes = useStyles();
    let checklistCard = null;

    if (!_.isUndefined(props.checklistData)) {

        //let timesheetPath = "/dashboard/reporting/timesheets?docId=" + props.checklistData.timesheetID;
        //let jobPath = "/dashboard/reporting/timesheets/job?docId=" + props.checklistData.jobID;
        let date = props.checklistData.date > 9999999999 ? moment(props.checklistData?.date) : moment.unix(props.checklistData?.date)

        let checklist = null;
        
        if(props.checklistData.checkedItems){
            switch (props.checklistData.serviceType) {
                case 'Cleaning':
                    checklist = (
                        [
                            ['pavement' in props.checklistData.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                            ['curb' in props.checklistData.checkedItems, 'All Curbs are exposed'],
                            ['driveway' in props.checklistData.checkedItems, 'All Driveway approaches are clear'],
                            ['loadingdock' in props.checklistData.checkedItems, 'All loading docks are clear, if applicable'],
                            ['piles' in props.checklistData.checkedItems, 'Snow is piled in a designated area, which doesn’t obstruct a roadway, sidewalk, fire hydrant or entrance or exit from building'],
                            ['damage' in props.checklistData.checkedItems, 'Check this box if damaged occurred during cleaning and take picture',],
                            ['inaccessible' in props.checklistData.checkedItems, 'Check box if there was somewhere that couldn’t be cleared and take picture of reason'],
                        ]
                    )
                    break;
                case 'Sanding':
                    checklist = (
                        [
                            ['approaches' in props.checklistData.checkedItems, 'Approaches sanded'],
                            ['aisles' in props.checklistData.checkedItems, 'Main aisles and walking paths sanded'],
                            ['docks' in props.checklistData.checkedItems, 'Loading docks sanded if applicable'],
                            ['damage' in props.checklistData.checkedItems, 'Check box if any damaged occurred during sanding and take pictures'],
                            ['inaccessible' in props.checklistData.checkedItems, 'Check box if there was somewhere that couldn’t be sanded and take picture of reason'],
                        ]
                    )
                    break;
                case 'Shovelling':
                    checklist = (
                        [
                            ['pavement' in props.checklistData.checkedItems, 'All snow is cleaned to pavement where reasonably possible'],
                            ['leeway' in props.checklistData.checkedItems, 'Snow is cleaned at least 1-2 feet from edge of sidewalk'],
                            ['salted' in props.checklistData.checkedItems, 'If needed sand or other ice melting products applied on icy areas'],
                            ['emergency' in props.checklistData.checkedItems, 'All emergency exits are clear of snow'],
                            ['inaccessible' in props.checklistData.checkedItems, 'Check this box If any area was unable to be  cleaned, take picture of area'],
                        ]
                    )
                    break;
                default: 
                    break;
            }
        }

                
        checklistCard = (
            <Grid className={classes.content}>
                <Grid className={classes.backButton}>
                    <Button variant="contained" startIcon={<ArrowBackIcon />}
                       onClick={() => props.history.goBack()}>Back</Button>
                </Grid>
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid item xs={5} style={{ marginBottom: '32px' }}>
                            <Grid container>
                                <Grid item xs={12} className={classes.row}>
                                    <Typography variant='h4'>{props.checklistData.title ? props.checklistData.title : 'Checklist'}</Typography>
                                </Grid>
                            </Grid>

                            <Typography variant='h6'>{props.userData?.displayName ? props.userData?.displayName : 'N/A'}</Typography>
                            <Typography variant='h6'>{date.format('YYYY-MM-DD h:mm a')}</Typography>
                            <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
                            <Typography variant='h6'>{props.checklistData?.customer ? props.checklistData?.customer : ''}</Typography>
                            <Typography variant='h6'>{props.checklistData?.address ? props.checklistData?.address : ''}</Typography>

                        </Grid>

                        <Grid item xs={7} className={classes.btnGroup}>

                            {props.checklistData.timesheetID ? 
                            <Button 
                                variant='contained' 
                                style={{ marginBottom: '6px' }}
                                onClick={()=> {
                                    props.history.push({
                                        pathname: '/dashboard/reporting/timesheets',
                                        search: "?" + new URLSearchParams({ docId: props.checklistData.timesheetID }).toString(),
                                    }) }
                                }
                            >
                                View Related Timesheet
                            </Button>
                            : null
                            }

                            {props.checklistData.jobID ? 
                            <Button 
                                variant='contained' 
                                style={{ marginBottom: '6px' }}
                                onClick={()=> {
                                    props.history.push({
                                        pathname: '/dashboard/reporting/timesheets/job',
                                        search: "?" + new URLSearchParams({ docId: props.checklistData.jobID }).toString(),
                                    }) }
                                }
                            >
                                View Related Job
                            </Button>
                            : null
                            }
                            <Button 
                                variant='contained'
                                startIcon={<PrintIcon />} 
                                onClick={()=> {
                                    props.history.push({
                                        pathname: '/dashboard/reporting/timesheets/checklist/printing',
                                        search: "?" + new URLSearchParams({ docId: props.checklistData.docId }).toString(),
                                        state: props.checklistData
                                    }) }
                                }
                            >
                                Print Checklist
                            </Button>
                        </Grid>

                        { props.checklistData.serviceReason || props.checklistData.serviceType ?
                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    {props.checklistData.serviceReason ?
                                    <React.Fragment>
                                        <Grid item xs={12} md={6} className={classes.row}>
                                            <Typography variant='body1'>Service Reason:</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={[classes.row, classes.end].join(' ')}>
                                            <Typography variant='body1'>{props.checklistData.serviceReason}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                    : null
                                    }

                                    {props.checklistData.serviceType ?
                                    <React.Fragment>
                                        <Grid item xs={12} md={6} className={classes.row}>
                                            <Typography variant='body1'>Service Type:</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={[classes.row, classes.end].join(' ')}>
                                            <Typography variant='body1'>{props.checklistData.serviceType}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                    : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        : null
                        }
                        
                        { props.checklistData.equipmentType || props.checklistData.sandAmount ?
                        <Grid item xs={6}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    
                                    {props.checklistData.equipmentType ?
                                    <React.Fragment>
                                        <Grid item xs={12} md={6} className={classes.row}>
                                            <Typography variant='body1'>Equipment:</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={[classes.row, classes.end].join(' ')}>
                                            <Typography variant='body1'>{Object.values(props.checklistData.equipmentType).join(", ")}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                    : null
                                    }

                                    {props.checklistData.sandAmount ?
                                    <React.Fragment>
                                        <Grid item xs={12} md={6} className={classes.row}>
                                            <Typography variant='body1'>Sand Amount:</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={[classes.row, classes.end].join(' ')}>
                                            <Typography variant='body1'>{props.checklistData.sandAmount}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                    : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        : null
                        }
                        {props.checklistData.items ? 
                        <React.Fragment>
                            {
                                    Object.entries(props.checklistData.items).map(([key, value], index) => {
                                    return (
                                        <Grid key={key + index} item xs={12}>
                                            <Paper className={classes.card}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} className={classes.row}>
                                                        <Typography variant='h6'>{key}</Typography>
                                                    </Grid>
                                                    {value.type === 'Checkbox' ? value.items.map((checkboxes, index) => {
                                                        return (<React.Fragment key={checkboxes + index}>
                                                            <Grid key={checkboxes + index} item xs={12} className={classes.row}>
                                                                <Checkbox checked={checkboxes.checked} />  
                                                                <Typography variant='body1'>{checkboxes.name}:&nbsp;</Typography>
                                                                
                                                            </Grid> 
                                                                
                                                            <Grid>
                                                                {checkboxes.style === 'userEnteredText' ?
                                                                <TextField variant="outlined" disabled style={{color:'#000', marginLeft:'5px'}} value={checkboxes.enteredText}></TextField> : null}
                                                                {checkboxes.style === 'photoUpload' && checkboxes.checked ?
                                                                <Typography><Img className={classes.img} error={trucksad} placeholder={truck} src={props.checklistData.photoURLs['CHECKBOX_'+checkboxes.name] ? props.checklistData.photoURLs['CHECKBOX_'+checkboxes.name].toString().substring(2, props.checklistData.photoURLs['CHECKBOX_'+checkboxes.name].length-2) : "#"} alt={checkboxes.name + ' Photo'}/></Typography>
                                                                
                                                                : null}
                                                            </Grid></React.Fragment>

                                                        )})
                                                        :<RadioGroup row value={value.selectedValue}>
                                                            {value.items.map((checkboxes, index) => {
                                                        return (<React.Fragment key={checkboxes + index}>
                                                            <Grid key={checkboxes + index} item md={12} className={classes.row}>
                                                                <Radio value={checkboxes.name} />  
                                                                <Typography variant='body1'>{checkboxes.name}:&nbsp;</Typography>
                                                            </Grid>
                                                            <Grid item md={8}>
                                                                
                                                                {checkboxes.style === 'userEnteredText' ?
                                                                <TextField variant="outlined" disabled style={{color:'#000', marginLeft:'5px'}} value={checkboxes.enteredText}></TextField> : null}
                                                                {checkboxes.style === 'photoUpload' && value.selectedValue === checkboxes.name ?
                                                                <Typography><Img className={classes.img} error={trucksad} placeholder={truck} src={props.checklistData.photoURLs['RADIO_'+checkboxes.name] ? props.checklistData.photoURLs['RADIO_'+checkboxes.name].toString().substring(2, props.checklistData.photoURLs['RADIO_'+checkboxes.name].length-2) : "#"} alt={checkboxes.name + ' Photo'}/></Typography>
                                                                
                                                                : null}
                                                            </Grid>
                                                            
                                                            </React.Fragment>
                                                        )})}
                                                        </RadioGroup>
                                                        
                                                    }
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        )
                                    })
                                
                            }
                            
                        </React.Fragment>
                        : null
                        }



                        { props.checklistData.checkedItems ?
                        <Grid item xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} className={classes.row}>
                                        <Typography variant='h6'>Checked Items</Typography>
                                    </Grid>
                                    {checklist.map((item, index) => {
                                        return (
                                            <Grid key={index} item xs={6} className={classes.row}>
                                                <Checkbox checked={item[0]} />
                                                <Typography variant='body1'>{item[1]}</Typography>
                                            </Grid>)
                                    })}
                                </Grid>
                            </Paper>
                        </Grid>
                        : null
                        }

                        {(props.checklistData.current && _.isUndefined(props.checklistData.gathersWeatherData)) || (props.checklistData.current && props.checklistData.gathersWeatherData) ?
                            <Grid item xs={12}>
                                <Paper elevation={1} className={[classes.card, classes.weatherCard].join(' ')}>
                                    <Grid container spacing={3} >
                                        <Grid item xs={4} className={classes.row}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Cloud Coverage: {props.checklistData?.current.cloud}%</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Humidity: {props.checklistData?.current.humidity}%</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Precipitation: {props.checklistData?.current.precip_mm}mm</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4} className={[classes.row, classes.temp].join(' ')}>
                                            <Grid container>
                                                <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                                    <Typography variant='body1' style={{ color: 'white' }}>{props.checklistData?.current.condition.text}</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                                    <img alt="" src={props.checklistData.current ? props.checklistData.current.condition.icon : ""} />
                                                    <Typography variant='h3' style={{ color: 'white' }}> &nbsp;{props.checklistData?.current.temp_c}°C</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={4} className={classes.row}>
                                            <Grid container>
                                                <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Wind: {props.checklistData?.current.wind_kph}km/h {props.checklistData?.current.wind_dir}</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Visibility: {props.checklistData?.current.vis_km}km</Typography>
                                                </Grid>
                                                <Grid item xs={12} className={[classes.row, classes.end].join(' ')}>
                                                    <Typography variant='h6' style={{ color: 'white' }}>Pressure: {props.checklistData?.current.pressure_in}Nm</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} className={classes.row} style={{ justifyContent: 'center' }}>
                                            <Typography variant='h6' style={{ color: 'white' }}>Weather Data Created @{props.checklistData?.current.last_updated}</Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            : null}

                        <Grid item xs={12}>
                            <InlineMediaViewer photoURLs={props.checklistData.photoURLs} />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <div className={classes.content}>
            {checklistCard}
        </div>
    )
}

export default withRouter(ChecklistCard)