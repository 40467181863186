import React, { useState } from 'react'
import { TableIcons } from '../../../../constants/tableIcons';
import { Grid, Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@material-ui/core';
import MaterialTable from 'material-table';
import moment from 'moment';
import ChecklistModal from './Modals/ChecklistModal';
import _ from 'lodash';

const ChecklistTable = (props) => {

    const [checklistModal, setChecklistModal] = useState(false);
    const [checklistModalOpenInEdit, setChecklistModalOpenInEdit] = useState(false);

    const openChecklistModal = (docId, openInEdit) => {
        if(openInEdit){
            setChecklistModalOpenInEdit(true)
            let checklistContainer = _.cloneDeep(props.checklistData.filter(checklist => checklist.docId === docId)[0]);
            props.setCurrentChecklist({...checklistContainer});
        }
        else{
            setChecklistModalOpenInEdit(false)
            props.setCurrentChecklist({});
        }
        setChecklistModal(true);
    }

    const closeChecklistModal = () => {
        setChecklistModal(false)
    }

    return (
        <Grid>
            {/* Checklist Edit/Add Modal */}
            <Dialog 
                fullWidth
                keepMounted
                open={checklistModal}
                onClose={closeChecklistModal}
            >
                <DialogTitle id="alert-dialog-title">{checklistModalOpenInEdit ? "Edit" : "Create"} Checklist</DialogTitle>

                <DialogContent>
                    <ChecklistModal
                        checklistData={props.checklistData}
                        currentChecklist={props.currentChecklist}
                        setCurrentChecklist={props.setCurrentChecklist}
                        jobData={props.jobData}
                        checklistModalOpenInEdit={checklistModalOpenInEdit}
                        checklistModal={checklistModal}
                    />
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            let checklistContainer = {...props.currentChecklist};
                            if(checklistModalOpenInEdit){
                                    props.updateChecklist({...checklistContainer}); 
                                }
                                else{     
                                    props.createChecklist({...checklistContainer});  
                                }
                            closeChecklistModal();
                        }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => { closeChecklistModal() }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <MaterialTable
                columns={[
                    { title: 'Service Type', field: 'serviceType' },
                    { title: 'Date', field: 'date', render: rowData => moment(rowData.date).format('YYYY-MM-DD h:mm a') },
                    { title: 'Customer', field: 'customer' },
                    { title: 'Address', field: 'address' },
                ]}
                icons={TableIcons}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [],
                    toolbar: true,
                    paging: true,
                    actionsColumnIndex: -1,
                    search: false,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    rowStyle: {
                        whiteSpace: 'pre-wrap',
                    },
                }}
                data={props.checklistData}
                isLoading={props.checklistsLoading}
                title={"Checklist Details"}
                actions={[
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'Show Checklist',
                        onClick: (event, rowData) => props.history.push({
                            pathname: '/dashboard/reporting/timesheets/checklist',
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                        })
                    },
                    {
                        icon: TableIcons.Edit,
                        tooltip: 'Edit Checklist',
                        onClick: (event, rowData) => {
                            openChecklistModal(rowData.docId, true)
                        }
                    },
                    {
                        icon: TableIcons.Add,
                        tooltip: 'Add Checklist',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            openChecklistModal(rowData.docId, false)
                        }
                    },
                ]}
                pathname='/dashboard/reporting/timesheets/checklist'
                editable={{
                    onRowDelete: (oldData) => props.deleteChecklist(oldData),
                }}
            />
        </Grid>
    )
}
export default ChecklistTable