import React, { useState, useEffect } from 'react';
import moment from 'moment'
import MaterialTable from '@material-table/core';
import { firestore } from '../../../firebase/firebase';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { TableIcons } from '../../../constants/tableIcons';
//not used, users should be loaded by now from reporting component
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../providers/UserContext';
import { useTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withRouter} from 'react-router-dom';
import { useTimesheetType } from '../../../providers/TimesheetTypeContext';

import CreateNewTimesheetModal from '../Reports/Timesheets/Modals/CreateNewTimesheetModal';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    },
}));

const UserTimesheetTable = (props) => {

    const { getUsers, users, userLoading } = useUser();
    const { timesheetType } = useTimesheetType();

    //can be used to get users from database, should be neccessary. 

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
    }, [smallScreen]);


    const [timesheetData, setTimesheetData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [userData] = useState(props.userData || [])
    const [searchBarValue, setSearchBarValue] = useState(userData.displayName+"SearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"SearchBarValue")) : null)
    const [page, setPage] = useState(userData.displayName+"PageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"PageValue")) : 0)

    const [createNewModal, setCreateNewModal] = useState(false)

    useEffect(() => {
        //only call get users once
        if(users.length <= 0){
           getUsers();
        }
        let unsubscribe = firestore.collection('timesheets').where('uid', '==', userData.uuid).orderBy('date').onSnapshot(querySnapshot => {

            //process & store timesheet data
            let timesheetResponse = querySnapshot.docs.map((doc) => {

                let date = doc.data().date
                let inTime = doc.data().usingGateInTime ? doc.data().gateIn15 : doc.data().inTime
                let outTime = doc.data().usingGateOutTime ? doc.data().gateOut15 : doc.data().outTime
                if (inTime < 9999999999) inTime *= 1000
                if (outTime < 9999999999) outTime *= 1000
                if (date < 9999999999) date *= 1000
                var oHours = outTime - inTime
                oHours -= ((doc.data().skipLunch === "na" || doc.data().skipLunch === 'declined') ? 1800000 : 0)
                oHours = oHours / 3600000;
                oHours -= doc.data().offHours;
                oHours = Math.max(oHours.toFixed(2), 0);
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'date': moment.utc(date).format('YYYY-MM-DD'),
                    'timesheetID': doc.data().timesheetID === 0 ? 'Unsubmitted' : doc.data().timesheetID,
                    'operatorHours': oHours.toFixed(2),
                }
            })
            setTimesheetData(timesheetResponse
                //sort by date newest to oldest
                .sort((a, b) => {
                    return new Date(b.date) - new Date(a.date);
                })
                )
            setLoading(false);
            setPage(userData.displayName+"PageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem(userData.displayName+"PageValue")) : 0)
        })

        return () => { unsubscribe(); }
    }, [getUsers, userData,]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleOnSearchChange = (value) => {
        setSearchBarValue(value);  
        sessionStorage.setItem(userData.displayName+"SearchBarValue", JSON.stringify(value));
    
        setPage(0);  
        sessionStorage.setItem(userData.displayName+"PageValue", JSON.stringify(0));
      }
    
    const handleOnPageChange = (pageNum) => {
        setPage(pageNum);     
        sessionStorage.setItem(userData.displayName+"PageValue", JSON.stringify(pageNum));
        
    }
    const timesheetClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/timesheets',
            search: "?" + new URLSearchParams({ docId: path }).toString(),

        })
    }

    const onCloseCallback = () => {
        setCreateNewModal(false)
    }
    
    return (
        <div className={classes.content}>

            <CreateNewTimesheetModal 
                open={createNewModal}
                onCloseCallback={onCloseCallback}
                defaultUser={props.userData}
                
                //not called within because places should theoretically have these
                timesheetType={timesheetType}
                users={users}
                usersLoading={userLoading}
            />

            <Grid container>
                <Button variant={'contained'} style={{marginBottom: '10px'}} onClick={()=> { setCreateNewModal(true) }}>Create New Timesheet</Button>
            </Grid>

            {!loading && timesheetData.length === 0 ? <div>No Timesheets Found</div> :
            <MaterialTable

                columns={[
                    { title: 'Timesheet ID', field: 'timesheetID', defaultSort: 'desc' },
                    { title: 'Date', field: 'date' },
                    { title: 'Hours', field: 'operatorHours' },
                ]}
                data={timesheetData}
                title={`${userData.displayName.substring(0, userData.displayName.indexOf(" "))}'s Timesheets`}
                isLoading={loading}
                icons={TableIcons}
                actions={[
                    {
                        icon: OpenInNewIcon,
                        tooltip: 'View Timesheet',
                        onClick: (event, rowData) => timesheetClickHandler(rowData['docId']),
                    },
                ]}
                onSearchChange={ (value) => {handleOnSearchChange(value) }}
                onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                options={{
                    
                    toolbar: true,
                    paging: true,
                    initialPage: page,
                    pageSizeOptions: false,
                    pageSize: 12,
                    actionsColumnIndex: -1,
                    search: true,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    searchText: searchBarValue,

                    

                    rowStyle: rowData => {
                        if (rowData.timesheetID === "Unsubmitted") {
                            return { backgroundColor: '#fff2f4' };
                        }

                        return {};
                    }
                }}
            />}

        </div>
    )
}

export default withRouter(UserTimesheetTable)