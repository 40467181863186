import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, useMediaQuery, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { firestore } from '../../../firebase/firebase';
import _ from 'lodash';
import moment from 'moment';
import useAsyncError from '../../components/UI/AsyncError/AsyncError';

import CalanderCard from '../../components/Scheduler/CalanderCard';
import DayViewCard from '../../components/Scheduler/DayViewCard';
import { functions } from '../../../firebase/firebase';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        //width: '75vw',
        margin: 'auto',
    },
}));

const Scheduler = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const isMounted = useRef(false)    
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const throwAsyncError = useAsyncError();
    const sendNotification = functions.httpsCallable('scheduleUpdate');
    const versioningNum = useRef(0);

    //holds all documents
    const [plannedDeliveries, setPlannedDeliveries] = useState([])
    //holds the currently displayed document, used to rerender cached quill table (values, published, not stored here, in plannedDeliveries instead)
    const [currentDoc, setCurrentDoc] = useState({})

    //hold either calander or day view type
    const [viewType, setViewType] = useState(JSON.parse(sessionStorage.getItem('scheduleViewType')) || 'Day')
    //hold either schedule or notes for use in day view
    const [tabSelected, setTabSelected] = useState('schedule');
    //hold currently selected date
    const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD").valueOf())

    //loading bools
    const [pageProcessing, setPageProcessing] = useState(true)
    const [viewProcessing, setViewProcessing] = useState(false)

    //menu state
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {}, [smallScreen]);

    //sets initial planned deliveries, keeps plannedDeliveries up to date
    useEffect(() => {
        let unsubscribe = firestore.collection('plannedDeliveries').onSnapshot((snapshot) => {
            //console.log('snapshot update')
            let deliveriesContainer = snapshot.docs.map((doc) => {
                return {
                ...doc.data(),
                'docId': doc.id,
                'title': doc.data().value?.replace(/<[^>]*>/g, ' ').replace(/&nbsp;/g, '\n')   //converts value to plaintext (for displaying in fullcalendar)
                }
            })

            setPlannedDeliveries(deliveriesContainer)

            if(!isMounted.current){
                isMounted.current = true;
                handleCheckExistingDoc(selectedDate, deliveriesContainer);
                setPageProcessing(false);
            }
        })

        return () => { unsubscribe(); }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //checks if a document exists, based on date, if not it creates it, sets current document
    const handleCheckExistingDoc = async (date, checkAgainstArray) => {
        //console.log('handleCheckExistingDoc')
        try{
            let currentDocContainer = handleFindExistingDocByDate(date, checkAgainstArray)
            if(currentDocContainer === undefined){
            
                let newDoc = {
                    value: '',
                    date: moment(date).format("YYYY-MM-DD"),
                    sandbox: process.env.NODE_ENV === 'development',
                    versions: [],
                }

                await firestore.collection('plannedDeliveries').add(newDoc)
                    .then((querySnapshot) => {
                        newDoc['docId'] = querySnapshot.id
                        setCurrentDoc(newDoc)
                    })
            }
            else{
                setCurrentDoc(currentDocContainer)
            }
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    //returns the document for the specified date, if it does not exist will return undefined. Deletes and throws error if multiple documents found
    const handleFindExistingDocByDate = (date, checkAgainstArray) => {
        let dateFilter = checkAgainstArray.filter(doc => doc.date && moment(doc.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD"))

        //check (and removal) for multiple documents- suuuper edge case, should have set up dates as fb doc ids really
        if(dateFilter.length > 1){ handleDeleteRedundantDocs(dateFilter) }

        return checkAgainstArray.length > 0 ? checkAgainstArray.find(doc => doc.date && moment(doc.date).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) : undefined;
    }

    const handleDeleteRedundantDocs = (intakeArray) => {
        //find the most complete document
        let index = intakeArray.reduce((maxIndex, currentValue, currentIndex, array) => currentValue.value.length > array[maxIndex].value.length ? currentIndex : maxIndex, 0);
        let container = intakeArray.filter((x, i) => i !== index)
        const batch = firestore.batch();

        container.forEach((doc) => {
            let query = firestore.collection('plannedDeliveries').doc(doc.docId)
            batch.delete(query);
        })

        batch.commit()
            .then(() => {
                throwAsyncError(new Error('Multiple documents found, deleted extra documents.'));
            })
            .catch((e) => {
                throwAsyncError(new Error(e.message, e));
            });
    }

    const handleChangeDate = (nextBool) => {
        //console.log('handleChangeDate')
        setViewProcessing(true)
        let container = nextBool ? moment(selectedDate).add(1, "days").format('YYYY-MM-DD').valueOf() : moment(selectedDate).subtract(1, "days").format('YYYY-MM-DD').valueOf()
        setSelectedDate(container)
        handleCheckExistingDoc(container, plannedDeliveries)
        .then(() => {
            setViewProcessing(false)
        })
    }

    const handleDateClick = (date) => {
        //console.log('handleDateClick')
        setViewProcessing(true)
        setSelectedDate(moment(date).format("YYYY-MM-DD").valueOf())
        setViewType('Day')
        sessionStorage.setItem('scheduleViewType', JSON.stringify('Day'))
        handleCheckExistingDoc(moment(date).format("YYYY-MM-DD").valueOf(), plannedDeliveries)
        .then(() => {
            setViewProcessing(false)
        })
    }

    const handleCalanderClick = () => {
        setViewType('Calendar')
        sessionStorage.setItem('scheduleViewType', JSON.stringify('Calendar'))
    }

    const handleTableChange = (event, newValue) => {
        if(newValue !== tabSelected){
            setViewProcessing(true)
            setTimeout(() => { 
                setCurrentDoc(newValue === 'notes' ? handleFindExistingDocByDate('notes', plannedDeliveries) : handleFindExistingDocByDate(selectedDate, plannedDeliveries))
                setTabSelected(newValue);
                setViewProcessing(false)
            }, 100);
        }
    };

    const handleChangePublished = () => {
        try{
            let currentDoc = handleFindExistingDocByDate(selectedDate, plannedDeliveries)
            let published = currentDoc.published ? !currentDoc.published : true

            if(published){
                sendNotification({
                    published: true, 
                    date: currentDoc.date, 
                    value: currentDoc.value,
                    sandbox: process.env.NODE_ENV === 'development',
                })
            }

            firestore.collection('plannedDeliveries').doc(currentDoc.docId).set({'published': published}, { merge: true })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    let debouncedHandleUpdateValue = _.debounce(function (html, currentDoc) {
        try{
            let container = {...currentDoc, value: html}

            versioningNum.current++;
            if(versioningNum.current % 10 === 0){ container = hanldeGenerateDocVersion(container) }

            firestore.collection('plannedDeliveries').doc(container.docId).update({
                value: container.value || '',
                sandbox: process.env.NODE_ENV === 'development',
                title: container.title || '',
                versions: container.versions || [],
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }, 350);

    //callbacks to allow for debouncers / memoization of quill component
    const schedulerUpdateCallback = useCallback((html, currentDoc) => debouncedHandleUpdateValue(html, currentDoc), []);  // eslint-disable-line react-hooks/exhaustive-deps
    const notesUpdateCallback = useCallback((html, currentDoc) => debouncedHandleUpdateValue(html, currentDoc), []);  // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    //takes document and adds a version to the versioning list
    const hanldeGenerateDocVersion = (document) => {
        //console.log('hanldeGenerateDocVersion')
        document.versions = document.versions || [];

        //delete? needed or no
        // if(document.versions.length > 10){
        //     console.log('remove oldest')
        //     let index = document.versions.reduce((smallestDateIndex, currentValue, currentIndex, array) => 
        //         moment(currentValue.date).isSameOrBefore(moment(array[smallestDateIndex].date)) ? currentIndex : smallestDateIndex, 0);
        //     console.log(document.versions[index])
        //     document.versions.splice(index, 1)
        // }

        let newVersion = {time: moment().valueOf(), value: document.value};
        document.versions.push(newVersion);

        return document;
    }

    const handleSwitchVersion = (version) => {
        setViewProcessing(true)
        //get up to date doc
        let container = handleFindExistingDocByDate(selectedDate, plannedDeliveries)
        //create new version based doc as is currently
        let currentValue = {value: container.value, time: moment().valueOf()}
        //add current values to version
        container.versions.push(currentValue)
        //switch value to versions value
        container.value = version.value;
        //update value in db
        firestore.collection('plannedDeliveries').doc(container.docId).update({
            value: container.value || '',
            sandbox: process.env.NODE_ENV === 'development',
            title: container.title || '',
            versions: container.versions || [],
        })

        //change current doc to trigger rerender of quillText
        setTimeout(() => { 
            setAnchorEl(null)
            setCurrentDoc(container)
            setViewProcessing(false)
        }, 100);
    }

    return (
        <Grid style={{padding: '10px'}}>
            {pageProcessing
            ? 
                <Grid container> 
                    <Skeleton variant='rect' width={'95vw'} height={'85vh'}/>
                </Grid>
            : 
            <Grid container className={classes.container} style={smallScreen ? {width: '90vw'} : {width: '75vw'}}>
                <Grid item xs={12} style={{height: '85vh',}}>
                    {viewType === 'Calendar'
                    ?      
                        <CalanderCard 
                            handleDateClick={handleDateClick}
                            height={smallScreen ? '55vh' : '85vh'}
                            smallScreen={smallScreen}
                            viewProcessing={viewProcessing}
                            plannedDeliveries={plannedDeliveries}
                        />
                    :
                        <DayViewCard
                            setViewType={setViewType}
                            selectedDate={selectedDate}
                            handleChangeDate={handleChangeDate}
                            smallScreen={smallScreen}
                            viewProcessing={viewProcessing}

                            currentDoc={currentDoc}

                            handleChangePublished={handleChangePublished}
                            tabSelected={tabSelected}
                            handleTableChange={handleTableChange}

                            plannedDeliveries={plannedDeliveries}
                            handleFindExistingDocByDate={handleFindExistingDocByDate}
                            schedulerUpdateCallback={schedulerUpdateCallback}
                            notesUpdateCallback={notesUpdateCallback}

                            handleCalanderClick={handleCalanderClick}

                            anchorEl={anchorEl}
                            handleOpen={handleOpen}
                            handleClose={handleClose}
                            handleSwitchVersion={handleSwitchVersion}
                        />
                    }
                </Grid>
            </Grid>
            }
        </Grid>
    )
}

export default withRouter(Scheduler)