import React, {useState, useCallback} from 'react';
import { Grid, makeStyles, Paper, TextField, Typography, Snackbar, Button} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { firestore } from '../../../firebase/firebase'
import NoteIcon from '@material-ui/icons/Note';

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        
    },

    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
        
    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const NotesTab = (props) => {
    const classes = useStyles();
    const [userData, setUserData] = useState(props.userData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateUserData = useCallback((e) => {
            setUserData(e)
        },
        [],
    );

    const confirmSave = () => {
        props.setUserData(userData);
        props.setSaveState(userData);
        setSaving(true);
        firestore.collection('users').doc(path)
            .update(userData)
            .then(() => {
                setSaveState(userData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});

    };

    const cancelSave = () => {
        setUserData(saveState);
    };
    return (
        <Grid container spacing={2}>
            <Grid container>
                <Grid container item xs={1} >
                    
                </Grid>
                <Grid container item xs={10} >
                    <Paper className={classes.paper}>
                        <Typography variant="h5">Notes <NoteIcon></NoteIcon></Typography> 
                        <div style={{border: '1px dashed #d3d3d3', padding: '10px'}}>
                            {props.edit ?
                            <TextField
                            label="Notes"
                            multiline
                            rows={10}
                            value={userData.notes ? userData.notes : ""}
                            style={{width: '100%'}}
                            variant="outlined"
                            onChange={(event) => { updateUserData({ ...props.userData, 'notes': event.target.value }) }}
                            /> : 
                            props.userData.notes ? props.userData.notes : "No Notes to Display" }
                        </div>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                            open={!_.isEqual(userData, saveState)}
                            message={saving ? 'Saving...' : 'Save Document?'}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            action={
                                <React.Fragment>
                                    {saving
                                        ? null
                                        : <React.Fragment>
                                            <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                            <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                                        </React.Fragment>}
                                </React.Fragment>
                            } />
        </Grid>
    )
}
export default withRouter(NotesTab)