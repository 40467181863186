import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Tab, Box, Button, useMediaQuery, Paper, IconButton} from '@material-ui/core';
import { withRouter } from 'react-router';
import MaterialTable from '@material-table/core';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';
import { useUser } from '../../../providers/UserContext';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TableIcons } from '../../../constants/tableIcons';
import moment from 'moment';
import { firestore } from '../../../firebase/firebase'
import { useTheme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkOrderOptions from '../../components/WorkOrders/WorkOrderOptions';

const useStyles = makeStyles((theme) => ({

    card: {
        padding: theme.spacing(2),
        width: '100%',
        height: '100%',
    },

    content: {
        padding: '32px',
        paddingRight: '230px',
        overflow: 'auto'
    },

    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        minHeight: '100vh',
        zIndex: '1000',
    },
    expand: {
        position: 'fixed',
        right: 0,
        top: '444px',
        transform: 'rotate(90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        position: 'fixed',
        right: 253,
        top: '444px',
        transform: 'rotate(270deg)',
    },

    grid: {
        marginTop: theme.spacing(2),
        maxWidth: '1000px',
    },

    table: {
        display: 'flex',
        justifyContent: 'center',
    },

    tile: {
        width: '300px',
        height: '300px',
    },

    tileContent: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: theme.spacing(4),
    },

    tileIcon: {
        color: 'rgba(0, 0, 0, 0.54)',
        width: '100px',
        height: '100px',
    },

    tileText: {
        fontSize: '1.10rem',
    },
    btn: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const WorkOrdersHome = (props) => {
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const tableRef = React.useRef();
    const { users, error, getUsers } = useUser();

    const [startDate, setStartDate] = useState("startDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState("endDate" in sessionStorage ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [tabSelected, setTabSelected] = useState("workOrderTabValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("workOrderTabValue")) : 'unassigned');
    const [workData, setWorkData] = useState("userDataWorkOrdersHome" in sessionStorage ? JSON.parse(sessionStorage.getItem("userDataWorkOrdersHome")) : []);
    const [pageLoading, setPageLoading] = useState(true)
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(0);
    const [loadingText, setLoadingText] = useState("");
    const [errorMessage, setErrorMessage] = useState("")
    const [workOrders, setWorkOrders] = useState("workOrderData" in sessionStorage ? JSON.parse(sessionStorage.getItem("workOrderData")) : []);
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("reportExpanded")) || true);
    const [completedCheckbox, setCompletedCheckbox] = useState("workOrdersCompleteCheckbox" in sessionStorage ? JSON.parse(sessionStorage.getItem("workOrdersCompleteCheckbox")) : false)

    useEffect(() => {
    }, [smallScreen]);

    //grabs users when users set is empty
    useEffect(() => {
        if(!users.length > 0){
            getUsers()
        }
        generateHandler()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setPageLoading(true) 
        if(tabSelected === 'incomplete'){
            let container = workOrders.filter((order) => (order?.dateCompleted === ''))
            sessionStorage.setItem("userDataWorkOrdersHome", JSON.stringify(container));
            setWorkData(container)
        } else if(tabSelected === 'awaitingReview'){
            let container = workOrders.filter((order) => (!order?.completed && order?.dateCompleted !== ''))
            sessionStorage.setItem("userDataWorkOrdersHome", JSON.stringify(container));
            setWorkData(container)
        } else if(tabSelected === 'completed'){
            let container = workOrders.filter((order) => (order?.completed))
            sessionStorage.setItem("userDataWorkOrdersHome", JSON.stringify(container));
            setWorkData(container)
        }else{
            let container = workOrders.filter((order) => ( order.dateCompleted !== '' ))
            sessionStorage.setItem("userDataWorkOrdersHome", JSON.stringify(container));
            setWorkData(container)
        }
        setPageLoading(false) 
    }, [workOrders, tabSelected])

    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("reportExpanded", JSON.stringify(expanded))
    };

    const getUserDisplayName = (uuid) => {
        let user = _.find(users, {uuid: uuid});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return uuid;
        }
    }

    const handleOnSearchChange = (value) => { 
        sessionStorage.setItem("workOrderSearchBarValue", JSON.stringify(value));
    }

    const handleOnPageChange = (pageNum) => {
        sessionStorage.setItem("workOrderPageValue", JSON.stringify(pageNum));
    }

    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem("startDate", JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem("endDate", JSON.stringify(date))
    }

    const tileClickHandler = (path) => {
        props.history.push({
        pathname: 'workOrders/' + path,
        })
    };

    const handleTableChange = (event, newValue) => {
        setTabSelected(newValue);
        sessionStorage.setItem("workOrderTabValue", JSON.stringify(newValue));
        tableRef.current.onPageChange({}, 0);
        sessionStorage.removeItem("workOrderPageValue");
    };

    async function dateCompat() {
        let query = firestore.collection('workOrders')

        query.orderBy('dateCreated')

        const oldDate = query.where("dateCreated", ">=", startDate.startOf('day').unix()).where("dateCreated", "<=", endDate.endOf('day').unix()).get()
        const newDate = query.where("dateCreated", ">=", startDate.startOf('day').utc().valueOf()).where("dateCreated", "<=", endDate.endOf('day').utc().valueOf()).get()

        const [oldDateSnapshot, newDateSnapshot] = await Promise.all([newDate, oldDate]).catch((err) => console.log(err))

        const oldDateArray = oldDateSnapshot.docs
        const newDateArray = newDateSnapshot.docs
        return _.concat(oldDateArray, newDateArray)
    }

    const generateHandler = () => {
        sessionStorage.removeItem("workOrderData");
        setProgress(1)
        setLoadingText("Preparing Cache")
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            setProgress(0)
            setLoadingText("")
            return
        }

        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            setProgress(0)
            setLoadingText("")
            return
        }

        setLoading(true);
        setProgress(10)
        setLoadingText("Grabbing Work Orders...")
        dateCompat().then(querySnapshot => {
            let dataResponse = querySnapshot.map((doc) => {

                return {
                    ...doc.data(),
                    'docId': doc.id,

                }
            })
            setProgress(15)
            setProgress(25)
            setWorkOrders(dataResponse)

            sessionStorage.setItem("workOrderData", JSON.stringify(dataResponse))
        })
        .then(() => {
            setProgress(99)
            //should have required data
            setLoading(false);
            setLoadingText("Complete.")
            setTimeout(() => {
                setProgress(100)
            }, 3000);
        }).catch(e => {

            setWorkOrders([]);
            sessionStorage.removeItem("workOrderData");
            setLoading(false);
            setProgress(0);

            switch(e.name){
                case 'QuotaExceededError':
                    setErrorMessage("Query size too big for browser storage.");
                    console.log(e.message);
                    break;
                  default:
                    setErrorMessage("Unable to process.");
                    console.log(e.message);
            }
        });
    }

  return (
    <div className={classes.content}>
      {!pageLoading && !error ?
        <div className={classes.content}
                    style={expanded ? {paddingRight: '230px' } : {paddingRight: smallScreen ? '12px' : '230px' }} >

          <Grid>

            <Grid item xs={12} className={classes.btn}>
              <Button variant='contained' onClick={() => tileClickHandler('new')} startIcon={<AddBoxIcon/>} 
                      >
              Create New Work Order
              </Button>
            </Grid>

                <Grid item sm={12} xs={12} className={classes.table}>
                  <TabContext value={tabSelected}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
                      <TabList onChange={handleTableChange}>
                        
                        <Tab style={{position:'relative',backgroundColor:'#ffbf00', borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} label={'Incomplete ('+workOrders.filter((order) => (order?.dateCompleted === '')).length+')'} value="incomplete" />
                        <Tab style={{backgroundColor:'#ffbf00', borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} label={'Awaiting Review ('+workOrders.filter((order) => (!order?.completed && order?.dateCompleted !== '')).length+')'} value="awaitingReview" />
                        <Tab style={{backgroundColor:'#ffbf00', borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}} label={'Completed ('+ workOrders.filter((order) => (order?.completed)).length+')'} value="completed" />

                      </TabList>
                    </Box>

                  </TabContext>
                </Grid>

                  
                    <MaterialTable
                      tableRef={tableRef}
                      columns={[
                        { title: 'Unit #', width:'10%', field: 'vehicle.unitNumber' },
                        { title: 'Customer', field: 'customer.companyName' },
                        { title: 'Contact', field: 'customer.contactName' },
                        
                        { title: 'Date Created', field: 'dateCreated', type: 'date', render: rowData => {
                            return moment(rowData.dateCreated).format('MM/DD/YYYY')
                        }},
                        { title: 'Tech', field: 'assignedUser', render: rowData => {
                          return rowData.assignedUser === '' ? 'Unassigned' : getUserDisplayName(rowData.assignedUser)
                        }},
                        { title: 'Repairs Needed', width:'1%', type:'numeric', render: rowData => {
                          return <b>{rowData.repairsNeeded.length}</b>
                        }},
                        { title: 'Repairs Completed', width:'1%', type:'numeric', render: rowData => {
                            return <b>{rowData.repairsNeeded.filter(x=> x.completed).length}</b>
                          }},
                      ]}
                      data={workData}                  
                      title= {"Work Orders"}
                      onSearchChange={ (value) => {handleOnSearchChange(value) }}
                      onPageChange={ (pageNum) => {handleOnPageChange(pageNum) }}
                      isLoading={pageLoading}
                      actions={[
                        rowData => ({
                          icon: TableIcons.OpenInNew,
                          tooltip: 'Show Work Order',
                          onClick: (event, rowData) => {
                            props.history.push({
                              pathname: '/dashboard/workOrders/form',
                              search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                            })
                          }
                        }),
                      ]}
                      options={{
                        toolbar: true,
                        paging: true,
                        initialPage: "workOrderPageValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("workOrderPageValue")) : 0,
                        //pageSizeOptions: false,
                        pageSize: 10,
                        actionsColumnIndex: -1,
                        search: true,
                        emptyRowsWhenPaging: false,
                        thirdSortClick: false,
                        searchText: "workOrderSearchBarValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("workOrderSearchBarValue")) : null,
                        rowStyle: rowData => {
                            if ((rowData.completed)) {
                                return { backgroundColor: 'lightGreen', whiteSpace: 'pre-wrap', };
                            }
                            return {whiteSpace: 'pre-wrap', };
                        }
                      }}
                    />
                
              </Grid>
            
          </div>
        :
        <Grid >
          <Skeleton variant='rect' width={'45vw'} height={'85vh'} style={{marginTop: '85px'}}/>
        </Grid>
      }
                {smallScreen ?
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" >
                        <Paper className={classes.sidebar}>
                            <WorkOrderOptions
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                workData={workData}
                                error={error}
                                completedCheckbox={completedCheckbox}
                                setCompletedCheckbox={setCompletedCheckbox}
                            />
                        </Paper>
                    </Collapse>
                </div>
                :
                <div>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                        <ExpandMoreIcon style={{fontSize: '50px'}} />
                    </IconButton>
                    <Collapse orientation="horizontal" in={expanded} timeout="auto" >
                        <Paper className={classes.sidebar}>
                            <WorkOrderOptions
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                error={error}
                                progress={progress} loadingText={loadingText}
                                completedCheckbox={completedCheckbox}
                                setCompletedCheckbox={setCompletedCheckbox}
                            />
                        </Paper>
                    </Collapse>
                </div>
}
    </div>
  )
}

export default withRouter(WorkOrdersHome);