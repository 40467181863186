import { List, ListItem, ListItemText, makeStyles, Divider } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const HRDrawer = (props) => {

    const classes = useStyles();

    return <List>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/hr/policies'} button>
            <ListItemText primary={'Policies'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/hr/accessibility'} button>
            <ListItemText primary={'Accessibility'} />
        </ListItem>
        <Divider />
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/hr/all'} button>
            <ListItemText primary={'All'} />
        </ListItem>
    </List>
}