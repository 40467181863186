import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Snackbar, Button, Divider,
 TextField, Paper, IconButton, Typography} from '@material-ui/core';
import { withRouter } from 'react-router';
import { Skeleton, Alert } from '@material-ui/lab';
import _ from 'lodash';
import Slide from '@material-ui/core/Slide';
import { useUser } from '../../../../providers/UserContext';
import { functions, uploadFile } from '../../../../firebase/firebase'
import PaperTitle from '../../../components/Orders/OrderPage/PaperTitle';
import { v4 as uuidv4 } from 'uuid'
import { firestore, } from '../../../../firebase/firebase'
import ClearIcon from '@material-ui/icons/Clear';
import MemoDrafts from './MemoDrafts';
import Dropzone from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import SaveIcon from '@material-ui/icons/Save';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import SendIcon from '@material-ui/icons/Send';

function SlideTransition(props) {
    return <Slide {...props} direction="down" />;
  }
const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    },
  grid: {
    marginTop: theme.spacing(2),
    padding:'10px',
    maxWidth: '1000px',
  },
  checkbox: {
    lineHeight: 2,
    padding:'0px',
    marginTop: '4px',
    marginBottom: '4px',
},
fileInput: {
    '&:hover': {
        cursor: 'pointer',
      },
    alignItems: 'center',
    border: '1px solid #bdbdbd',
    borderRadius:'4px',
    margin:'8px',
    marginTop:'16px',
},
}));

const EmployeeMemo = (props) => {
  const [userData, setUserData] = useState("userDataUserHome" in sessionStorage ? JSON.parse(sessionStorage.getItem("userDataUserHome")) : []);

  const [pageLoading, setPageLoading] = useState(true)

  const classes = useStyles();
  const { users, error, getUsers } = useUser();
  const [sendingEmail, setSendingEmail] = useState(false);

  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [docId, setDocId] = useState("");

  const [draftData, setDraftData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [alertUp, setAlertUp] = useState(false);
  const [alert, setAlert] = useState("");

  const darkMode = JSON.parse(window.localStorage.getItem('colorMode')) === 'dark' ? true : false;
  //grabs users when users set is empty
  useEffect(() => {
    if(!users.length > 0){
      getUsers()
    }
    listDrafts()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageLoading(true) 
    if(users.length > 0){
        let container = users.filter((user) => ( _.isUndefined(user.employment) || user.employment.active === true ))
        sessionStorage.setItem("userDataUserHome", JSON.stringify(container));
        setUserData(container)  
        setPageLoading(false) 
    }
  }, [users, emailList])


  const sendMemoEmail = (email) => {
    setSendingEmail(true);
    const sendEmail = functions.httpsCallable('sendOutMemo');
    const emails = emailList.map((email) => email).join(', ')
    sendEmail({
        email: emails,
        html: content,
        subject: subject,
        attachments: attachments,
        sandbox: process.env.NODE_ENV === 'development'
    })
      .then(() => {
        setSendingEmail(false);
        console.log('Email sent to '+ emails)
        // ..
      })
      .catch((error) => {
        console.log(error)
        // ..
      });
  }

    const newDraft = () => {
        setSubject('');
        setContent('');
        setAttachments([]);
        setEmailList([]);
        setDocId('');
    }

  //save draft of email to firestore collection called emailDrafts
    const saveDraft = () => {
        setSaving(true);
        let newDocId = docId === '' ? uuidv4() : docId;
        firestore.collection('emailDrafts').doc(newDocId).set({
            docId: newDocId,
            subject: subject,
            content: content,
            attachments: attachments,
            emailList: emailList,
            dateUpdated: new Date(),
        })
        .then(() => {
            setSaving(false);
            setAlertUp(true);
            setDocId(newDocId);
            setAlert('Draft saved successfully');
            listDrafts()
        })
    }

    const listDrafts = () => {
        firestore.collection('emailDrafts').get()
        .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                  return {
                    ...doc.data(),
                    'docId': doc.id,
                  }
                })
            setDraftData(response);
        })
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setAlertUp(false);
      }; 

    const handleFileUpload = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
          //update attachments 
            
            const imageUrl = await uploadFile('TempGiftCards/'+file.name, file);
            console.log(file)
            console.log(imageUrl)
            let newFile = {
                filename: file.name,
                //content: imageFile,
                path: imageUrl.url,
                contentType: file.type,
            }

            let updatedAttachments = [...attachments, newFile]
            setAttachments(updatedAttachments)
            
        }
      };
  
      //check if emailList has all the office employees are included in the emailList
      const check4Office = () => {
            return userData.filter(user => user.department === 'Office').map((user) => user.email).every((email) => emailList.includes(email))
        };

        const check4Driver = () => {
            return userData.filter(user => user.department === 'Driver/Trucker').map((user) => user.email).every((email) => emailList.includes(email))
        }

        const check4Shop = () => {
            return userData.filter(user => user.department === 'Shop').map((user) => user.email).every((email) => emailList.includes(email))
        }

        const check4Construction = () => {
            return userData.filter(user => user.department === 'Construction').map((user) => user.email).every((email) => emailList.includes(email))
        }

        const check4Grain = () => {
            return userData.filter(user => user.department === 'Grain').map((user) => user.email).every((email) => emailList.includes(email))
        }

  return (
    <div className={classes.content}>

      {!pageLoading && !error ?
      <Grid container style={{marginBottom:'20px'}}>
      <Grid item className={classes.content} spacing={2} xs={7}>
        {alertUp ?
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={true} autoHideDuration={4500} onClose={handleClose} TransitionComponent={SlideTransition}>
                <Alert onClose={handleClose} style={{backgroundColor:'green', color:'white'}} severity="success" sx={{ width: '100%' }}>
                    {alert}
                </Alert>
            </Snackbar>
        :null}
        <Paper className={classes.grid} >
            <PaperTitle title="Send Mass Employee Email" />
            <Divider style={{margin:'10px'}}/>
            <Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.table}>
                        <TextField
                            id="subject"
                            label="Subject"
                            size="small"
                            fullWidth
                            variant="outlined"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.table}>
                        <ReactQuill 
                            //ref={quill}
                            style={{ width: '100%', height: '300px', marginBottom:'50px'}}
                            theme="snow" 
                            defaultValue={content} 
                            value={content}
                            preserveWhitespace={false}
                            // set up this way as quill does some wonky stuff making onChange call at unexpected times
                            onChange={(html, delta, source) => {
                                if (source === 'user') {
                                    setContent(html)
                                }
                            }}
                            //modules={modules}
                            //formats={formats}
                        />
                        {/*
                        <TextField
                            id="content"
                            label="Content"
                            size="small"
                            fullWidth
                            multiline
                            rows={15}
                            variant="outlined"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />*/}
                    </Grid>
                    
                    <Grid container item xs={12}>
                        <Grid container item justifyContent='flex-start'>
                            <Grid item xs={8}>
                                <Typography variant='h6'>Attachments:</Typography>
                            </Grid>
                        </Grid>
                        <Grid container style={{border:'1px solid',
                                                    maxHeight: '400px',
                                                    overflowY: 'auto',
                                                    padding: '5px'
                        }}>
                            <Dropzone style={{width:'100%', height:'100%'}}  className={classes.fileInput}
                            accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf', '.PDF']}} onDrop={handleFileUpload}>
                                    {({ getRootProps, getInputProps }) => (
                                        <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                            <input {...getInputProps()} style={{ display: 'none' }} />
                    
                                            <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                            <Typography variant='h6'>
                                                {"Drag & Drop or Click to Upload File"}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Dropzone>
                            <Grid item  xs={12}>
                            <Divider style={{margin:'5px'}}/>  
                            </Grid>
                            
                            {attachments.map((attachment, index) => (
                                <Grid item key={index} xs={12} style={{display:'flex', alignItems:'center'}}>
                                    <Typography variant='body2' style={{marginRight:'10px'}}>{attachment.filename}</Typography>
                                    <IconButton onClick={() => {
                                        let updatedAttachments = attachments.filter((att, i) => i !== index)
                                        setAttachments(updatedAttachments)
                                    }}>
                                        <ClearIcon />
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.table}>
                        <Button style={{marginRight:'4px'}}
                            variant="contained" color="primary" disabled={saving || docId === ''}
                            onClick={() => newDraft()} startIcon={<FiberNewIcon/>}
                        >
                            New Draft
                        </Button>
                        <Button style={{marginRight:'4px'}}
                            variant="contained" color="primary" disabled={saving || subject === '' || content === ''}
                            onClick={() => saveDraft()} startIcon={<SaveIcon/>}
                        >
                            {saving? 'Saving...' : 'Save Draft'}
                        </Button>
                        

                    </Grid>
                </Grid>

            </Grid>
            <Divider style={{margin:'10px'}}/>
            <Grid item xs={12}>
                    <Button 
                        fullWidth style={{marginTop:'10px',}}
                        variant="contained" color="primary" disabled={sendingEmail || subject === '' || content === '' || emailList.length === 0 || saving}
                        onClick={() => sendMemoEmail(emailList)} startIcon={<SendIcon/>}
                    > {sendingEmail ? 'Sending...' : 'Send Mass Email'}</Button>
                </Grid>
        </Paper>
        </Grid>
        <Grid item spacing={2} xs={4}>
            <MemoDrafts draftData={draftData} setSubject={setSubject}
            setContent={setContent} setAttachments={setAttachments}
            setEmailList={setEmailList} getDrafts={listDrafts}
            setDocId={setDocId}/>
        </Grid>
        <Grid className={classes.content} item spacing={2} xs={7}>
            <Paper className={classes.grid} >
                <PaperTitle title={emailList.length > 0 ? "Recipients (" + emailList.length + ")" : "Recipients "} />
                <Grid item xs={12} style={{padding:'10px'}}>
                    <Button variant={emailList.length !== userData.length?"contained":"outlined"} size="small" onClick={
                        () =>emailList.length !== userData.length? setEmailList(userData.map((user) => user.email)) : setEmailList([])
                    }>All</Button>

                    <Button
                    variant={check4Office() ? "outlined" : "contained"}
                    size="small"
                    style={{marginLeft: '2px'}}
                    onClick={() => {
                        const officeEmails = userData
                        .filter(user => user.department === 'Office')
                        .map(user => user.email);
                        if (!check4Office()) {
                        // Add all office emails to the emailList
                            setEmailList([...emailList, ...officeEmails]);
                        } else {
                        // Remove office emails from the emailList
                            setEmailList(emailList.filter(email => !officeEmails.includes(email)));
                        }
                    }}
                    >
                    Office
                    </Button>
                    <Button
                    variant={check4Shop() ? "outlined" : "contained"}
                    size="small"
                    style={{marginLeft: '2px'}}
                    onClick={() => {
                        const officeEmails = userData
                        .filter(user => user.department === 'Shop')
                        .map(user => user.email);
                        if (!check4Shop()) {
                        // Add all office emails to the emailList
                            setEmailList([...emailList, ...officeEmails]);
                        } else {
                        // Remove office emails from the emailList
                            setEmailList(emailList.filter(email => !officeEmails.includes(email)));
                        }
                    }}
                    >
                    Shop
                    </Button>
                    <Button
                    variant={check4Construction() ? "outlined" : "contained"}
                    size="small"
                    style={{marginLeft: '2px'}}
                    onClick={() => {
                        const officeEmails = userData
                        .filter(user => user.department === 'Construction')
                        .map(user => user.email);
                        if (!check4Construction()) {
                        // Add all office emails to the emailList
                            setEmailList([...emailList, ...officeEmails]);
                        } else {
                        // Remove office emails from the emailList
                            setEmailList(emailList.filter(email => !officeEmails.includes(email)));
                        }
                    }}
                    >
                    Construction
                    </Button>
                    <Button
                    variant={check4Driver() ? "outlined" : "contained"}
                    size="small"
                    style={{marginLeft: '2px'}}
                    onClick={() => {
                        const officeEmails = userData
                        .filter(user => user.department === 'Driver/Trucker')
                        .map(user => user.email);
                        if (!check4Driver()) {
                        // Add all office emails to the emailList
                            setEmailList([...emailList, ...officeEmails]);
                        } else {
                        // Remove office emails from the emailList
                            setEmailList(emailList.filter(email => !officeEmails.includes(email)));
                        }
                    }}
                    >
                    Driver/Trucker
                    </Button>
                    <Button
                    variant={check4Grain() ? "outlined" : "contained"}
                    size="small"
                    style={{marginLeft: '2px'}}
                    onClick={() => {
                        const officeEmails = userData
                        .filter(user => user.department === 'Grain')
                        .map(user => user.email);
                        if (!check4Grain()) {
                        // Add all office emails to the emailList
                            setEmailList([...emailList, ...officeEmails]);
                        } else {
                        // Remove office emails from the emailList
                            setEmailList(emailList.filter(email => !officeEmails.includes(email)));
                        }
                    }}
                    >
                    Grain
                    </Button>
                </Grid>
                <Divider style={{margin:'10px'}}/>
                <Grid>
                    <Grid container style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        padding: '10px'}}>
                        {userData.sort ((a, b) => a.displayName.localeCompare(b.displayName))
                        .map((user) => (
                            <Grid item xs={2} key={user.id}>
                                <Typography onClick={() => emailList.includes(user.email)? setEmailList(emailList.filter((email) => email !== user.email)) 
                                :setEmailList([...emailList, user.email])}
                                 variant="body2" style={emailList.includes(user.email)?{marginBottom: '10px', color: darkMode?'#000000':'#000000',
                                    backgroundColor:'#FFBF00', cursor:'pointer', fontWeight:'bold', borderRadius:'5px'}
                                 :{marginBottom: '10px', cursor:'pointer'}}>{user.displayName}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                    <Divider/>
                </Grid>
            </Paper>
        </Grid>
 
        </Grid>
        :
        <Grid >
          <Skeleton variant='rect' width={'45vw'} height={'85vh'} style={{marginTop: '85px'}}/>
        </Grid>
      }
    </div>
  )
}

export default withRouter(EmployeeMemo);