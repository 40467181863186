import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const ShopSettingsDrawer = (props) => {

    const classes = useStyles();

    return <List>
        <ListItem className={classes.listItem} onClick={props.onClick}  component={Link} to={'/dashboard/store/businessSettings'} button>
            <ListItemText primary={'Business Settings'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/store/shopSettings'} button>
            <ListItemText primary={'Shop Settings'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/store/zoneSettings'} button>
            <ListItemText primary={'Zone Settings'} />
        </ListItem>
    </List>
}