import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment'
import { Button, Grid, IconButton, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { firestore, removeFile } from '../../../firebase/firebase';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '792px',
        margin: 'auto',
    },
    paper: {
        maxWidth: '792px',
        width: '100%',
        //backgroundColor: 'rgb(252, 252, 249)',
        padding: theme.spacing(2),
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const MSDSCard = (props) => {

    const classes = useStyles();

    const [edit, setEdit] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const file = {url: props.msdsData.pdfURL};
    let msdsCard = null;

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEdit = () => {
        if (edit)
            props.cancelSave();

        setEdit(!edit);
    }
    const removePDF = async (pdfURL) => {

        let query = await removeFile(pdfURL);

        return query.fileDeleted;
    }
    const handleDeleteClick = async () => {
        await removePDF(props.msdsData.pdfURL);
        firestore.collection('msds').doc(props.msdsData.docId).delete().then(() => {
            console.log("Material Safety Data Sheet successfully deleted!");
            props.history.replace({pathname: '/dashboard/msds'})
        }).catch((error) => {
            console.error("Error removing Safety Material Safety Data Sheet: ", error);
        });
    }

    if (!_.isUndefined(props.msdsData)) {
        msdsCard = (
            <Grid className={classes.content}>
                    <Grid className={classes.backButton}>
                        <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                    </Grid>
                <Paper className={classes.paper}>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Delete MSDS?"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete this Material Safety Data Sheet?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="contained" color="secondary">
                                No
                            </Button>
                            <Button onClick={handleDeleteClick} variant="contained" style={{ backgroundColor: 'red', color: 'white' }} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            {edit ?
                                <React.Fragment>
                                    <TextField
                                        label='Product Name'
                                        value={props.msdsData?.name}
                                        variant='outlined'
                                        onChange={(event) => { props.updateMsdsData({ ...props.msdsData, 'name': event.target.value }) }}
                                        inputProps={{ style: { fontSize: '2.125rem' } }} />

                                    {props.msdsData?.aliases.map((alias, index) => {
                                        return (
                                            <div className={classes.alias}>
                                                <TextField
                                                    label={'Alias ' + (index + 1)}
                                                    fullWidth
                                                    value={alias}
                                                    size='small'
                                                    variant='outlined'
                                                    onChange={(event) => {
                                                        let newAlias = [...props.msdsData.aliases];
                                                        newAlias.splice(index, 1, event.target.value);
                                                        props.updateMsdsData({ ...props.msdsData, 'aliases': newAlias })
                                                    }} />
                                                <Button
                                                    onClick={() => {
                                                        let newAlias = [...props.msdsData.aliases];
                                                        index === 0 ? newAlias.push('') : newAlias.splice(index, 1);
                                                        props.updateMsdsData({ ...props.msdsData, 'aliases': newAlias });
                                                    }}
                                                    variant={index === 0 ? 'contained' : 'outlined'}
                                                    style={{ marginLeft: '8px' }} >
                                                    {index === 0 ? '+' : '-'}
                                                </Button>
                                            </div>
                                        )
                                    })}

                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Typography align='left' variant='h4'>{props.msdsData?.name}</Typography>
                                    <Typography align='left' variant='h6'><i>{props.msdsData?.aliases.join(', ')}</i></Typography>
                                </React.Fragment>
                            }

                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <div className={classes.btnGroup} style={{ marginBottom: '32px' }}>
                                <IconButton href={props.msdsData.manufacturerPage} title="View Manufacturer Page"><LanguageIcon /></IconButton>
                                <IconButton onClick={handleEdit} style={edit ? { color: 'black' } : {}} title="Edit MSDS"><EditIcon /></IconButton>
                                <IconButton onClick={() => { window.open(props.msdsData.pdfURL, "_blank")}} title="View MSDS in Browser"><FindInPageIcon /></IconButton>
                                <IconButton onClick={() => {handleClickOpen()}} title="Delete MSDS"><DeleteIcon /></IconButton>
                            </div>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item sm={5} className={classes.row}>
                                        <Typography variant='body1'>Last Updated</Typography>
                                    </Grid>
                                    <Grid item sm={7} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <KeyboardDatePicker
                                                format='YYYY/MM/DD'
                                                value={moment.unix(props.msdsData.dateUpdated)}
                                                onChange={(date) => { props.updateMsdsData({ ...props.msdsData, 'dateUpdated': date.unix() }) }}
                                                fullWidth />
                                            : <Typography>{moment(props.msdsData.dateUpdated * 1000).format('YYYY/MM/DD')}</Typography>
                                        }
                                    </Grid>
                                    <Grid item sm={5} className={classes.row}>
                                        <Typography variant='body1'>Form Created</Typography>
                                    </Grid>
                                    <Grid item sm={7} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <KeyboardDatePicker
                                                format='YYYY/MM/DD'
                                                value={moment.unix(props.msdsData.dateCreated)}
                                                onChange={(date) => { props.updateMsdsData({ ...props.msdsData, 'dateCreated': date.unix() }) }}
                                                fullWidth />
                                            : <Typography>{moment(props.msdsData.dateCreated * 1000).format('YYYY/MM/DD')}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={6} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    <Grid item sm={7} className={classes.row}>
                                        <PhoneIcon />
                                        <Typography variant='body1'>&nbsp; Emergency</Typography>
                                    </Grid>
                                    <Grid item sm={5} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <TextField
                                                fullWidth
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                value={props.msdsData.emergencyPhone}
                                                onChange={(event) => { props.updateMsdsData({ ...props.msdsData, 'emergencyPhone': event.target.value }) }} />
                                            : <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.msdsData.emergencyPhone}</Typography>
                                        }
                                    </Grid>
                                    <Grid item sm={7} className={classes.row}>
                                        <PhoneIcon />
                                        <Typography variant='body1'>&nbsp; Safety</Typography>
                                    </Grid>
                                    <Grid item sm={5} className={classes.row} style={{ justifyContent: 'flex-end' }}>
                                        {edit
                                            ? <TextField
                                                fullWidth
                                                inputProps={{ style: { textAlign: 'center' } }}
                                                value={props.msdsData.safetyPhone}
                                                onChange={(event) => { props.updateMsdsData({ ...props.msdsData, 'safetyPhone': event.target.value }) }} />
                                            : <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.msdsData.safetyPhone}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item sm={12} xs={12}>
                            <Paper className={classes.card}>
                                <Grid container spacing={2}>
                                    {!edit
                                        ? <Grid item sm={12}>
                                            <Typography variant='h6'>Description</Typography>
                                        </Grid>
                                        : null}

                                    <Grid item sm={12}>
                                        {edit
                                            ? <TextField variant='outlined'
                                                label='Description'
                                                multiline rows={5}
                                                fullWidth
                                                value={props.msdsData.description}
                                                onChange={(event) => { props.updateMsdsData({ ...props.msdsData, 'description': event.target.value }) }} />
                                            : <Typography style={{ whiteSpace: 'pre-wrap' }}>{props.msdsData.description}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop:'50px'}}>
                                <iframe
                                    src={props.msdsData.pdfURL}
                                    title="PDF Viewer"
                                    width="1000px"
                                    height="800px"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <div className={classes.content}>
                {msdsCard}
            </div>
            <Snackbar
                open={!_.isEqual(props.msdsData, props.saveState)}
                message={props.saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {props.saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={props.cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={props.saveMsdsData}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </React.Fragment>
    )
}

export default withRouter(MSDSCard)