import { Divider, List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const EmployeeDrawer = (props) => {

    const classes = useStyles();

    return <List style={{zIndex:'999'}}>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/users/new'} button>
            <ListItemText primary={'+ Add Employee'} />
        </ListItem>
        <Divider/>
        <Divider/>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/users'} button>
            <ListItemText primary={'Employee Manager'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/users/roles'} button>
            <ListItemText primary={'Account Roles'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/users/rates'} button>
            <ListItemText primary={'User Rates'} />
        </ListItem>
        <Divider/>
        <Divider/>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/users/memos'} button>
            <ListItemText primary={'Send Mass Email'} />
        </ListItem>
    </List>
}