import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, Paper, Modal, Typography, ListItem, ListItemText, List} from '@material-ui/core';
import { withRouter } from 'react-router';
import { firestore, } from '../../../firebase/firebase';
import Skeleton from '@material-ui/lab/Skeleton';
import { useCustomer } from '../../../providers/CustomerContext';
import { useUser } from '../../../providers/UserContext';
import { useUnitDescription } from '../../../providers/UnitDescriptionContext'
import _ from 'lodash';
import moment from 'moment';

import TicketCard from '../../components/Ticket/TicketCard';
import TicketFilter from '../../components/Ticket/TicketFilter';

const Ticket = (props) => {
    const { customers, loading, getCustomers } = useCustomer();
    const { getUsers, users } = useUser();
    const { units, getUnits } = useUnitDescription();

    //const getTicketNumber = functions.httpsCallable('createTicket');

    const isMounted = useRef(false)

    const defaultTicket = {
      //ticketId: 'NA', //remove references to?
      ticket: "Unnumbered",
      date: moment().format("YYYY-MM-DD").valueOf(),
      customerId: null,
      //customer: null,
      //job: null,
      rate: null,
      //salesRate: null,
      notes: null, 
      uid: null,
      units: [],
      //equipment: null,
      //trailer1: null, // 
      //trailer2: null, //
      cartageSaleUnits: 0,
      cartageSaleRate: 0.00,
      cartageSalesPer: null,
      cartageSalesTotal: 0.000,
      payrollRate: 0.00,
      payrollUnits: null,
      payrollPer: null,
      payroll: 0.00,
      payrollAdjustedBase: 1.00,   
      payrollMultiplier: 100.00,
      linkOperatorToSalesRate: false,
      material: null,
      materialSalesRate: 0.00,
      quantity: null,
      materialSalesTotal: 0.00,
      operatorPayDate: null,
      invoicePayDate: null,
      dataEntryDate: moment().valueOf(),
      //orderId: "Unnumbered",
      percentageFuelSurcharge: 0.00,
      dollarFuelSurcharge: 0.00,
      timeIn: null,
      timeOut: null,
      nextDay: false,
      payrollVerified: false,
      invoiceVerified: false,    
    }

    //holds current ticket
    const [currentTicket, setCurrentTicket] = useState({})
    //holds all tickets
    const [ticketEntryData, setTicketEntryData] = useState([]);

    //const [orderData, setOrderDate] = useState([]);
    const [ratesData, setRatesData] = useState([]);

    //should no longer be in use
    // const [customerJobRateData, setCustomerJobRateData] = useState([])
    // const [customerJobs, setCustomerJobs] = useState([])
    // const [jobRates, setJobRates] = useState([])
    // const [salesRates, setSalesRates] = useState([])

    const [per, setPer] = useState([])
    const [materials, setMaterials] = useState([])

    const [internalLoading, setInternalLoading] = useState(true);
    //0 false, 1 true, 2 confirmation
    const [saving, setSaving] = useState(0)

    const [quickRateModal, setQuickRateModal] = useState({
      open: false,
      selected: null,
    })

    // const [currentRadio, setCurrentRadio] = useState("ticketRadioFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketRadioFilter")) : 'all')
    // const [currentFindBy, setCurrentFindBy] = useState("ticketFindByFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByFilter")) :'ticket')
    // const [contactAutocompleteValue, setContactAutocompleteValue] = useState("ticketFindByCustomerFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByCustomerFilter")) : null)
    // const [ticketAutocompleteValue, setTicketAutocompleteValue] = useState("ticketFindByTicketFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByTicketFilter")) : null)
    // const [orderAutocompleteValue, setOrderAutocompleteValue] = useState("ticketFindByOrderFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByOrderFilter")) : null)

    const [filterValues, setFilterValues] = useState({
      contactAutocompleteValue: "ticketFindByCustomerFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByCustomerFilter")) : null,
      ticketAutocompleteValue: "ticketFindByTicketFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByTicketFilter")) : null,
      orderAutocompleteValue: "ticketFindByOrderFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByOrderFilter")) : null,
      dateValue: "ticketFilterDate" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFilterDate")) : moment().format('YYYY').valueOf(),
      currentFindBy: "ticketFindByFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFindByFilter")) :'ticket',
      currentRadio: "ticketRadioFilter" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketRadioFilter")) : 'all',
      dateRange: "ticketFilterDateRange" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFilterDateRange")) : 
                  {start: moment().valueOf(), end: moment().valueOf()},
    })

    //change to ticketCard autocompletes
    const [autoCompletes, setAutoCompletes] = useState({
        uid: { open: false, value: {}},
        units: { open: false, value: {}},
        trailer1: { open: false, value: {}},
        trailer2: { open: false, value: {}},
        cartageSalesPer: { open: false, value: {}},
        customerId: { open: false, value: {}},
        job: { open: false, value: {}},
        rate: { open: false, value: {}},
        payrollPer: { open: false, value: null},
        material: { open: false, value: null},
    })

    const quickRates = {
      farmPickup: {
        notes: 'Farm Pickup',
        payrollRate: 12.00,
        payrollUnits: 1,
        payrollPer: 'Hour',
        cartageSaleRate: 0.00,
        cartageSaleUnits: 2,
        cartageSalesPer: 'Hour',
        invoiceSuffix: 'P',
        payrollAdjustedBase: 1,
      },
      waitingTime: {
        notes: 'Waiting Time',
        payrollRate: 10.00,
        payrollUnits: 1,
        payrollPer: 'Hour',
        cartageSaleRate: 50.00,
        cartageSaleUnits: 2,
        cartageSalesPer: 'Hour',
        invoiceSuffix: 'W',
        payrollAdjustedBase: 1,
      }
    }

    //gets the inital data
    useEffect(() => {
      if(users.length === 0){ getUsers() }
      if(customers.length === 0){ getCustomers() }
      if(units.length === 0){ getUnits() }

      
      firestore.collection('rates').get()
      .then(querySnapshot => {
          const ratesResponse = querySnapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                'docId': doc.id,     
            }
          })
          setRatesData(ratesResponse);

          firestore.collection('deliveries').where("date", ">=", moment().subtract(4, 'month').format('YYYY').valueOf()).get()
          .then(querySnapshot => {
              const ticketentryResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,     
                }
              })
              setTicketEntryData(ticketentryResponse);
              setPer(retrieveField(ticketentryResponse, ['payrollPer', 'cartageSalesPer']))
              setMaterials(retrieveField(ticketentryResponse, ['material']))
              setInternalLoading(false);    
          })
      })
      .catch(e => {
          console.log(e.message);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //gets ticket and order data based on date
    // useEffect(() => {
    //   console.log('filter based on date', filterValues.dateValue)

    //   firestore.collection('deliveries').where("date", ">=", filterValues.dateValue).get()
    //   .then(querySnapshot => {
    //       const ticketentryResponse = querySnapshot.docs.map((doc) => {
    //         return {
    //             ...doc.data(),
    //             'docId': doc.id,     
    //         }
    //       })

    //       setTicketEntryData(ticketentryResponse);
    //       setPer(retrieveField(ticketentryResponse, ['payrollPer', 'cartageSalesPer']))
    //       setMaterials(retrieveField(ticketentryResponse, ['material']))

    //       // firestore.collection('orders').where("payment.created_at", ">=", moment(filterValues.dateValue).format('MM/DD/YYYY h:mm:ss a')).get()
    //       // .then(querySnapshot => {
    //       //     const ordersResponse = querySnapshot.docs.map((doc) => {
    //       //       return {
    //       //           ...doc.data(),
    //       //           'docId': doc.id,     
    //       //       }
    //       //     })

    //       //     setOrderDate(ordersResponse.filter(x=> !_.isUndefined(x.bol)))
    //           //setCustomerJobRateData(customerJobRateResponse)
    //           setInternalLoading(false);    
    //         //})
    //   })
    //   .catch(e => {
    //       setInternalLoading(false);
    //       console.log(e.message);
    //   })
        
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [filterValues.dateValue]);

    //handles session storage for tickets
    useEffect(() => {
      if(isMounted.current){
        let currentTicketDocId = "ticketFilterValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("ticketFilterValue")) : null
        let currentTicketValue = ticketEntryData.find(x=> x.docId === currentTicketDocId)
        !_.isUndefined(currentTicketValue) ? handleChangeCurrentTicket(currentTicketValue) : handleChangeCurrentTicket(defaultTicket);
      } else {
        isMounted.current = true;
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ internalLoading === false]) 

    // //sets customerJobs based on customer changes
    // useEffect(() => {
    //   let customerJobsContainer = customerJobRateData.find(x=> x.customerId === autoCompletes.customer.value?.docId)?.jobs
    //   setCustomerJobs(!_.isEmpty(customerJobsContainer) ? customerJobsContainer : [])
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [autoCompletes.customer])

    // useEffect(() => {
    //   let jobRatesContainer = autoCompletes.job?.value?.jobRates
    //   setJobRates(!_.isEmpty(jobRatesContainer) ? jobRatesContainer : [])
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [autoCompletes.job])

    // //sets sales rates based on rate changes
    // useEffect(() => {
    //   let salesRatesContainer = autoCompletes.rate?.value?.salesRates
    //   setSalesRates(!_.isEmpty(salesRatesContainer) ? salesRatesContainer : [])
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [autoCompletes.rate])


    const handleInstructionsRateChanges = (previousTicketValue, newTicketValue) => {
      // let jobRateChanges = previousTicketValue?.rate !== newTicketValue.rate
      // let salesRateChanges = previousTicketValue?.salesRate !== newTicketValue?.salesRate
      // let newInstructionsContainer = _.cloneDeep(previousTicketValue.notes)

      // let oldCustomerJobObj = customerJobRateData?.find(x=> x.customerId === previousTicketValue['customer'])?.jobs
      // let oldJobRateObj = oldCustomerJobObj?.find(x=> x.jobId === previousTicketValue['job'])?.jobRates
      // let oldSaleRateObj = oldJobRateObj?.find(x=> x.jobRateId === previousTicketValue['rate'])?.salesRates

      // let newCustomerJobObj = customerJobRateData?.find(x=> x.customerId === newTicketValue['customer'])?.jobs
      // let newJobRateObj = newCustomerJobObj?.find(x=> x.jobId === newTicketValue['job'])?.jobRates
      // let newSaleRateObj = newJobRateObj?.find(x=> x.jobRateId === newTicketValue['rate'])?.salesRates

      // //if either rates have been changed
      // if(jobRateChanges || salesRateChanges){
      //   if(jobRateChanges){
      //     let oldJobRateValue = oldJobRateObj?.find(x=> x.jobRateId === previousTicketValue.rate)?.description
      //     let newJobRateValue = newJobRateObj?.find(x=> x.jobRateId === newTicketValue.rate)?.description

      //     if(!_.isUndefined(oldJobRateValue) && _.isUndefined(newJobRateValue)){ newInstructionsContainer = newInstructionsContainer.replace(` - ${oldJobRateValue}`, ""); }
      //     else if(_.isUndefined(oldJobRateValue) && !_.isUndefined(newJobRateValue)){ newInstructionsContainer = newInstructionsContainer.concat(` - ${newJobRateValue}`) }
      //     else{ newInstructionsContainer = newInstructionsContainer.replace(` - ${oldJobRateValue}`, ` - ${newJobRateValue}`); }
      //   }

      //   if(salesRateChanges){
      //     let oldSalesRatesValue = oldSaleRateObj?.find(x=> x.salesRateId === previousTicketValue.salesRate)?.description
      //     let newSalesRatesValue = newSaleRateObj?.find(x=> x.salesRateId === newTicketValue.salesRate)?.description

      //     if(!_.isUndefined(oldSalesRatesValue) && _.isUndefined(newSalesRatesValue)){ newInstructionsContainer = newInstructionsContainer.replace(` - ${oldSalesRatesValue}`, ""); }
      //     else if(_.isUndefined(oldSalesRatesValue) && !_.isUndefined(newSalesRatesValue)){ newInstructionsContainer = newInstructionsContainer.concat(` - ${newSalesRatesValue}`)}
      //     else{ newInstructionsContainer = newInstructionsContainer.replace(` - ${oldSalesRatesValue}`, ` - ${newSalesRatesValue}`);}
      //   }
      // }
      // return newInstructionsContainer;
    }

    const retrieveUnitData = async (unitDocIdArray) => {
      let unitsContainer = []

      for (const docId of unitDocIdArray) {
        if(docId === null){
          unitsContainer.push(undefined)
        }
        else{
          let findUnit = units.find(x=> x.docId === docId)
          if(!_.isUndefined(findUnit)){  
            unitsContainer.push(findUnit); 
          }
          else{
            await firestore.collection('units').where("docId", "==", docId).get()
            .then(querySnapshot => {
              const unitResponse = querySnapshot.docs.map((doc) => {
                return {
                  ...doc.data(),
                  'docId': doc.id,
                };
              });

              //get the parent document, if no ref to that document exists add an error field
              let parentDoc = units.find(x=> x.docId === unitResponse[0].parentUnitDocId)
              if( _.isUndefined(parentDoc)){ unitsContainer.push({...unitResponse[0]});  }
              else{ unitsContainer.push(parentDoc);  }
            })
          }
        }
      }
      return unitsContainer;
    }

    const retrieveField = (data, fieldSet) => {
        let returnContainer = []
        fieldSet.forEach(field => {
            data.forEach((doc) => {  (!_.isEmpty(doc[field]) && !_.isUndefined([field])) && returnContainer.push(doc[field])   }) 
        })
        return ([...new Set(returnContainer)])
    }

    //ensures filter autocomplete value is kept up to with current state
    const ensureFilterValueIsCurrent = (value) => {
        //set current selected filter autocomplete value
        let container = _.cloneDeep(filterValues)

        switch(container.currentFindBy) {
          case 'ticket':
            container.ticketAutocompleteValue = value;
            //setTicketAutocompleteValue(value)
            sessionStorage.setItem("ticketFindByTicketFilter", JSON.stringify(value));
            setFilterValues(container);
            break;
          case 'order':
            //setOrderAutocompleteValue(value)
            container.orderAutocompleteValue = value;
            sessionStorage.setItem("ticketFindByOrderFilter", JSON.stringify(value)) 
            setFilterValues(container);
            break;
          case 'contact':
            // doesnt need to change
            break;
          default:
            console.warn('No valid ticket filter was provided.')
        } 
    }

    const handleCopyTicket = async () => {
      setInternalLoading(true)
      let container = _.cloneDeep(currentTicket)
      container.ticket = 'Unnumbered';
      container.operatorPayDate = null;
      container.invoicePayDate = null;
      //container.ticketId = await getTicketNumber().then((res) => {return res.data})

      firestore.collection('deliveries').add(container)
      .then((docRef) => {
        container.docId = docRef.id;

        handleChangeCurrentTicket(container)
        ensureFilterValueIsCurrent(container)

        setTicketEntryData([...ticketEntryData, container])
        setInternalLoading(false)
      })
      .catch(function (error) {
          console.warn(error);
          setInternalLoading(false)
      }); 
    }

    //changes all autocompletes to reflect new ticket values
    const handleChangeCurrentTicket = async (newValue) => {
      let ticketContainer = _.cloneDeep(newValue)
      let container = _.cloneDeep(autoCompletes)
      !_.isUndefined(newValue.docId) ? sessionStorage.setItem('ticketFilterValue', JSON.stringify(newValue.docId)) : sessionStorage.removeItem("ticketFilterValue");

      //ensure tickets have all values
      // for (const [key, value] of Object.entries(defaultTicket)) {
      //   if(!ticketContainer.hasOwnProperty(key)){ 
      //     console.log(key, value)
      //     ticketContainer[key] = value; 
      //   }
      // }
      
      //set values for autocompletes
      for (const [key, value] of Object.entries(ticketContainer)) {
        if(container.hasOwnProperty(key)){
          switch(key) {
            case 'customerId':
              let customerObj = customers.find(x=> x.docId === ticketContainer[key])
              container[key].value = !_.isUndefined(customerObj) ? customerObj : value;
              break;
            case 'job':
              // let customerJobObj = customerJobRateData?.find(x=> x.customerId === ticketContainer['customer'])?.jobs
              // let jobObj = customerJobObj?.find(x=> x.jobId === ticketContainer[key])
              // container[key].value = !_.isUndefined(jobObj) ? jobObj : value;
              //setJobRates(!_.isEmpty(jobObj) ? jobObj.jobRates : []);
              break;
            case 'uid':
              let usersObj = users.find(x=> x.uuid === ticketContainer[key])
              container[key].value = !_.isUndefined(usersObj) ? usersObj : value;
              break;
            case 'units':
              //returns the parent docs of the units documents that contain the parent doc field
              await retrieveUnitData(ticketContainer.units).then((unitsValue) => {
                let unit0Obj = units.find(x=> x.docId === unitsValue[0]?.docId)
                container['units'].value = !_.isUndefined(unit0Obj) ? unit0Obj : !_.isUndefined(unitsValue[0]) ? {unitNumber: unitsValue[0].unitNumber} : null;
                let unit1Obj = units.find(x=> x.docId === unitsValue[1]?.docId)
                container['trailer1'].value = !_.isUndefined(unit1Obj) ? unit1Obj : !_.isUndefined(unitsValue[1]) ? {unitNumber: unitsValue[1].unitNumber}  : null;
                let unit2Obj = units.find(x=> x.docId === unitsValue[2]?.docId)
                container['trailer2'].value = !_.isUndefined(unit2Obj) ? unit2Obj : !_.isUndefined(unitsValue[2]) ? {unitNumber: unitsValue[2].unitNumber}  : null;   
              })
              break;
            case 'rate':
              let rateObj = ratesData.find(x=> x.docId === ticketContainer[key])
              container[key].value = !_.isUndefined(rateObj) ? rateObj : value;
              break;
            case 'material':
              container[key].value = value;
              break;
            case 'cartageSalesPer':
              container[key].value = value;
              break;
            case 'payrollPer':
              container[key].value = value;
              break;
            default:
              console.log(key)
              throw new Error('key was not part of collection', key)
          } 
        }
      }
      setAutoCompletes(container)
      setCurrentTicket(ticketContainer)
    }

    const confirmSave = () => {
      setSaving(1);
      let docId = currentTicket.docId;

      if(_.isUndefined(docId)){
        //create query to add docId to document before update
        let query = firestore.collection('deliveries').doc()
        currentTicket.docId = query.id;

        query.set(currentTicket)
          .then(() => {
            //setSaveState(currentTicket);
            ensureFilterValueIsCurrent(currentTicket)
            //setAutoCompleteSaveState(autoCompletes)
            let container = _.cloneDeep(ticketEntryData)
            container.push(currentTicket)
            //set to currently selected ticket
            sessionStorage.setItem('ticketFilterValue', JSON.stringify(currentTicket.docId))
            setTicketEntryData(container)
            setSaving(2)
            setTimeout(() => { setSaving(0)}, 1000);

          })
          .catch((e) => { console.warn(e); });
      }
      else{
        firestore.collection('deliveries').doc(currentTicket.docId).update(currentTicket)
          .then(() => {
            //setSaveState(currentTicket);
            ensureFilterValueIsCurrent(currentTicket)
            //setAutoCompleteSaveState(autoCompletes)
            let container = _.cloneDeep(ticketEntryData)
            container.splice(container.findIndex(x=> x.docId === currentTicket.docId), 1, currentTicket)
            setTicketEntryData(container)
            setSaving(2)
            setTimeout(() => { setSaving(0)}, 1000);

          })
          .catch((e) => { console.warn(e); });
      }
    };

    // const cancelSave = () => {
    //     setCurrentTicket({...saveState});
    //     setAutoCompletes({...autoCompleteSaveState})
    // };

    const handleSettingQuickRate = (rateChosen) => {
      let container = _.cloneDeep(currentTicket)
      let autocompleteContainer = _.cloneDeep(autoCompletes)

      Object.entries(rateChosen).forEach(([key, value]) => {
          if(container.hasOwnProperty(key)){
            container[key] = value;

            //settting autocompletes
            if(key === 'cartageSalesPer' || key === 'payrollPer'){
              autocompleteContainer[key].value = value
            }
          }
          else if(key === 'invoiceSuffix'){  //should only be suffix
            container['ticket'] = container['ticket'].concat(` - ${value}`)
          }
          else{
            throw new Error('no key of that type was found.')
          }

          //additional math, to get totals from various fields
          let payroll = container.payroll || 0.00;
          let cartageSalesTotal = container.cartageSalesTotal || 0.00;
          let dollarFuelSurcharge = container.dollarFuelSurcharge || 0.00;
  
          if(!_.isUndefined(container.linkOperatorToSalesRate) && container.linkOperatorToSalesRate){
            payroll = Number((Number((container.payrollAdjustedBase || 0.00)) * Number(container.payrollUnits || 0)) * (Number((container.payrollRate || 0)))).toFixed(2)
          }
          else{
            payroll = Number((Number((container.payrollAdjustedBase || 0.00)) * Number(container.payrollUnits || 0)) * (Number((container.payrollRate || 0)))).toFixed(2)
          }
          cartageSalesTotal = Number(Number(container.cartageSaleUnits) * Number((container.cartageSaleRate|| 0.00))).toFixed(2)
          dollarFuelSurcharge = Number(Number((container.percentageFuelSurcharge || 0.000) / 100)  * Number(Number((container.cartageSaleRate || 0.00)) * Number((container.cartageSaleUnits || 0)))).toFixed(2)

          container.payroll = payroll;
          container.cartageSalesTotal = cartageSalesTotal;
          container.dollarFuelSurcharge = dollarFuelSurcharge;

          setAutoCompletes({...autocompleteContainer})
          setCurrentTicket({...container})
      })
    }

    const handleListItemClick = (event, index) => {
      setQuickRateModal({...quickRateModal, selected: index});
    };

    const handleNewTicket = () => {
      handleChangeCurrentTicket(defaultTicket);
      ensureFilterValueIsCurrent(null);
    }

  return (
    <Grid>
        {internalLoading 
        ?
            <Skeleton variant='rect' width={'91vw'} height={'70vh'} style={{margin: 'auto', marginTop: '75px'}} />
        :      
            <Grid>
              <Grid container>
                {/* quick rate modal */}
                <Modal
                    open={quickRateModal.open}
                    onClose={() => { setQuickRateModal({...quickRateModal, open: false})}}
                    style={{display:'flex', alignItems:'center', justifyContent:'center'}}
                >
                    <Paper style={{padding:'10px', margin:'20px',}}>
                      <Grid container justifyContent='center'>
                        <Typography variant='h6'>Quick Rates:</Typography>
                      </Grid>

                      <Grid container>
                        <Grid container justifyContent='center'>
                          <List component="nav" style={{backgroundColor: 'lightGrey', border: '1px solid black'}}>
                            {Object.entries(quickRates).map(([key, value], index) => {
                              return (
                                  <ListItem
                                    key={key}
                                    selected={quickRateModal.selected === key}
                                    onClick={(event) => {
                                      handleListItemClick(event, key)
                                    }}
                                  >
                                    <ListItemText primary={`${value.notes} - Payroll Rate: $${Number(value.payrollRate).toFixed(2)}, Payroll Units: ${value.payrollUnits}, Payroll Per: ${value.payrollPer}, 
                                                  Cartage Rate: $${Number(value.cartageSaleRate).toFixed(2)},  Cartage Units: ${value.cartageSaleUnits},  Cartage Per: ${value.cartageSalesPer}, 
                                                  Invoice Suffix: ${value.invoiceSuffix},`} />
                                  </ListItem>
                              )
                            })}
                          </List>
                        </Grid>

                        <Grid container justifyContent='flex-end'>
                            <Button
                              style={{margin: '10px'}}
                              variant='contained'
                              onClick={(e)=> { 
                                handleSettingQuickRate(quickRates[quickRateModal.selected])
                                setQuickRateModal({selected: null, open: false})
                              }}
                            >
                              Confirm
                            </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                </Modal>
              </Grid>


              <Grid container>
                  <TicketCard 
                      units={units}
                      users={users}
                      customers={customers}
                      per={per}
                      materials={materials}

                      internalLoading={internalLoading}

                      currentTicket={currentTicket}
                      setCurrentTicket={setCurrentTicket}

                      autoCompletes={autoCompletes}
                      setAutoCompletes={setAutoCompletes}

                      handleCopyTicket={handleCopyTicket}

                      quickRateModal={quickRateModal}
                      setQuickRateModal={setQuickRateModal}

                      ratesData={ratesData}

                      //customerJobRateData={customerJobRateData}
                      //customerJobs={customerJobs}
                      //jobRates={jobRates}
                      //salesRates={salesRates}

                      handleInstructionsRateChanges={handleInstructionsRateChanges}
                      confirmSave={confirmSave}
                      saving={saving}

                      // handleAddUnit={handleAddUnit}
                      // handleRemoveUnit={handleRemoveUnit}

                      handleNewTicket={handleNewTicket}
                  />
                </Grid>
                
                <Grid container>
                  <TicketFilter 
                      units={units}
                      users={users}
                      customers={customers}
                      per={per}
                      loading={loading}
                      internalLoading={internalLoading}
                      currentTicket={currentTicket}
                      ticketEntryData={ticketEntryData}
                      handleChangeCurrentTicket={handleChangeCurrentTicket}

                      //orderData={orderData}

                      filterValues={filterValues}
                      setFilterValues={setFilterValues}
                      //currentRadio={currentRadio}
                      //setCurrentRadio={setCurrentRadio}
                      //currentFindBy={currentFindBy}
                      //setCurrentFindBy={setCurrentFindBy}
                      //contactAutocompleteValue={contactAutocompleteValue}
                      //setContactAutocompleteValue={setContactAutocompleteValue}
                      //ticketAutocompleteValue={ticketAutocompleteValue}
                      //setTicketAutocompleteValue={setTicketAutocompleteValue}
                      //orderAutocompleteValue={orderAutocompleteValue}
                      //setOrderAutocompleteValue={setOrderAutocompleteValue}
                  />  
                </Grid>

                {/* <Snackbar
                    open={!_.isEqual(currentTicket, saveState)}
                    message={saving ? 'Saving...' : 'Save Document?'}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    action={
                        <React.Fragment>
                            {props.saving
                                ? null
                                : <React.Fragment>
                                    <Button variant='text' color='primary' disabled={saving}  onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                    <Button variant='contained' disabled={saving} onClick={confirmSave}>Confirm</Button>
                                </React.Fragment>}
                        </React.Fragment>
                    }
                  /> */}
            </Grid>
        }

    </Grid>
  )
}

export default withRouter(Ticket);