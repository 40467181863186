import React from 'react';
//import { makeStyles } from '@material-ui/core';
import { Font, Text, View, StyleSheet } from '@react-pdf/renderer';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         justifyContent: 'center',
//         width: '100%',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         maxWidth: '1200px',
//         margin: 'auto',
//     },
//     buttonRow: {
//         marginBottom: '20px',
//         marginTop: '20px',
//     },
//     checkRow: {
//         margin: theme.spacing(1),
//         display: 'flex',
//         justifyContent: 'space-evenly',
//     },
// }));

Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        fontFamily: 'Helvetica',
        padding: 20,
    },
    section: {
        marginBottom: 10,
    },
    dTable: {
        display: "table",
        width: "auto",
        border: '1 solid black',

        marginBottom: 10,
    },
    dTableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    dTableCol: {
        flexGrow: 1,
    },
    dTableCell: {
        margin: "auto",
        marginTop: 5,
        marginBottom: 5,
        fontSize: 10,
    },
    table: {
        border: '1 solid black',
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subTable: {
        marginBottom: 10,
        flexGrow: 1,
        fontSize: 14,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 2,
        marginBottom: 2,
    },
    tableText: {
        fontSize: 10,
        marginBottom: '1px',
    },
    tableTextBold: {
        fontSize: 10,
        marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        marginTop: '5px'
    },
    tableTextSmall: {
        fontSize: 10,
    },
    tableTextBoldUnderline: {
        fontSize: 12,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        marginBottom: '10px',
    },
    summary: {
        flexDirection: 'row',
    },
    viewer: {
        width: '100%',
        maxWidth: '1200px',
        height: '85vh',
    },
    checkbox: {
        width: '10px',
        height: '10px',
        background: 'white',
        border: '1 solid black',
    },
    radio: {
        width: '10px',
        height: '10px',
        background: 'white',
        borderRadius: '50%',
        border: '1 solid black',
    },
    userEntered: {
        width: '200px',
        height: '20px',
        background: 'white',
        border: '1 solid black',
    },
    
    
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '4px',
        borderBottom: '1px solid black',
        marginBottom: '30px',
    },
    portraitTableCell: {
        width: '14.25%',
        minWidth: '14.25%',
        alignItems: 'center',
    },
    landscapeTableCell: {
        width: '9%',
        minWidth: '9%',
        alignItems: 'center',
    },
    portraitTableCellNth2: {
        width: '14.25%',
        minWidth: '14.25%',
        alignItems: 'center',
        backgroundColor: 'grey',
    },
    landscapeTableCellNth2: {
        width: '9%',
        minWidth: '9%',
        alignItems: 'center',
        backgroundColor: 'grey',
    },
    instructionHeader: {
        paddingBottom: '5px',
        fontSize: 12,
        color: 'blue',
    },

    summaryCombinedPortraitTableCell:{
        width: '71.5%', 
        minWidth: '71.5%', 
        alignItems: 'center',
    },
    summaryNotCombinedLandscapeTableCell: {
        width: '20.5%',
        minWidth: '20.5%', 
        alignItems: 'center',
    },

});

const PDFSummary = (props) => {
    //const classes = useStyles();

    /**
     * @param title - Table Header
     * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
     * @param data - The table data passed (as an array of objects)
     * @param components - React components passed as special rendering for additional rows 
    */

    //calc totals
    const subTotal = props.ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + (Number(currentValue.materialSalesTotal || 0) + Number(currentValue.cartageSalesTotal || 0))).toFixed(2), 0,)
    const cartageOnlyFeeValue = Number(subTotal) * Number(props.invoiceDetails.cartageOnlyFee  / 100 || 0);
    const cartageAndMaterialFeeValue = Number(subTotal) *  Number(props.invoiceDetails.cartageAndMaterialFee / 100 || 0);
    const preTaxSubTotal = Number(subTotal) +  Number(cartageOnlyFeeValue) +  Number(cartageAndMaterialFeeValue);
    const gst = props.invoiceDetails.taxOptions === 'calculate' ? Number(preTaxSubTotal) *  Number(props.invoiceDetails.gstRate / 100 || 0) : Number(props.invoiceDetails.gstRate);
    const rst = props.invoiceDetails.taxOptions === 'calculate' ? Number(preTaxSubTotal) *  Number(props.invoiceDetails.rstRate / 100 || 0) : Number(props.invoiceDetails.rstRate);
    const totalDue = Number(preTaxSubTotal) +  Number(gst) +  Number(rst);

    return (
        <>            
            <View style={{backgroundColor: 'grey'}}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', padding: '10x'}}>
                    <View style={{flexDirection: 'column'}}>
                        <Text style={{...styles.tableTextBold}}>{`Terms: ${props.invoiceDetails.invoiceTerms}`}</Text>
                        <Text style={{...styles.tableText}}>uid</Text>
                    </View>

                    <View style={{flexDirection: 'column', minWidth: '50%'}}>
                        <View>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                <Text style={{...styles.tableTextBold}}>Sub-Total:</Text>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                <Text style={{...styles.tableTextBold}}>{`$${Number(subTotal).toFixed(2)}`}</Text>
                            </View>
                        </View>

                        {(Number(props.invoiceDetails.cartageOnlyFee) > 0) &&
                            <View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`${props.invoiceDetails.cartageOnlyFeeType}: ${props.invoiceDetails.cartageOnlyFee} %`}</Text>
                                </View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`$${Number(cartageOnlyFeeValue).toFixed(2)}`}</Text>
                                </View>
                            </View>
                        }

                        {(Number(props.invoiceDetails.cartageAndMaterialFee) > 0) &&
                            <View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`${props.invoiceDetails.cartageAndMaterialFeeType}: ${props.invoiceDetails.cartageAndMaterialFee} %`}</Text>
                                </View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`$${Number(cartageAndMaterialFeeValue).toFixed(2)}`}</Text>
                                </View>
                            </View>
                        }

                        {(((Number(props.invoiceDetails.cartageOnlyFee) > 0)) || ( Number(props.invoiceDetails.cartageAndMaterialFee) > 0)) &&
                            <View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`Pre-Tax Sub Total:`}</Text>
                                </View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`$${Number(preTaxSubTotal).toFixed(2)}`}</Text>
                                </View>
                            </View>
                        }

                        <View>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                <Text style={{...styles.tableTextBold}}>{`Goods and Service Tax:`}</Text>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                <Text style={{...styles.tableTextBold}}>{`$${Number(gst).toFixed(2)}`}</Text>
                            </View>
                        </View>

                        {(Number(rst) > 0) &&
                            <View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`Retail Sales Tax:`}</Text>
                                </View>
                                <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                    <Text style={{...styles.tableTextBold}}>{`$${Number(rst).toFixed(2)}`}</Text>
                                </View>
                            </View>
                        }

                        <View>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', width: '50%'}}>
                                <Text style={{...styles.tableTextBold}}>{`Total Due:`}</Text>
                            </View>
                            <View style={{flexDirection: 'row', justifyContent: 'flex-end', position: 'absolute', right: 0, width: '50%'}}>
                                <Text style={{...styles.tableTextBold}}>{`$${Number(totalDue).toFixed(2)}`}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
         </>

  
    )
};

export default PDFSummary;