import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const ProductDrawer = (props) => {

    const classes = useStyles();

    return <List>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/products'} button>
            <ListItemText primary={'Product Manager'} />
        </ListItem>
        {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Price Books'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Inventory'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Categories'} />
        </ListItem> */}
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/products/categories'} button>
            <ListItemText primary={'Category Manager'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick}  component={Link} to={'/dashboard/coupons'} button>
            <ListItemText primary={'Coupon Manager'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick}  component={Link} to={'/dashboard/giftCards'} button>
            <ListItemText primary={'Gift Card Manager'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/store/equipment'} button>
            <ListItemText primary={'Used Equipment'} />
        </ListItem>
    </List>
}