import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {auth, firestore, storage} from '../../../../firebase/firebase';
//import Backdrop from '@material-ui/core/Backdrop'
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import HomeIcon from '@material-ui/icons/Home';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ShopIcon from '@material-ui/icons/Shop';
import PeopleIcon from '@material-ui/icons/People';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom'
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import {ListSubheader, Grid, Box, Tooltip, TextField} from '@material-ui/core';
//import MenuBookIcon from '@material-ui/icons/MenuBook';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { ProductDrawer } from './SubDrawers/ProductDrawer';
import { EmployeeDrawer } from './SubDrawers/EmployeeDrawer';
import { FormsDrawer } from './SubDrawers/FormsDrawer';
import { OrderDrawer } from './SubDrawers/OrderDrawer';
import { ShopSettingsDrawer } from './SubDrawers/ShopSettingsDrawer';
import { CustomerDrawer } from './SubDrawers/CustomersDrawer';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
//import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
//import { TicketDrawer } from './SubDrawers/TicketDrawer';
import { useAuth } from '../../../../providers/AuthContext';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import { Badge, useMediaQuery, FormControlLabel, Switch, Modal, Fade, Button, Backdrop, Paper, Chip } from '@material-ui/core';
//import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { useTheme } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import _ from 'lodash';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import BuildIcon from '@material-ui/icons/Build';
import BugReportIcon from '@material-ui/icons/BugReport';
import GrainIcon from '@material-ui/icons/Grain';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import GavelIcon from '@material-ui/icons/Gavel';
import { GrainReportDrawer } from './SubDrawers/GrainReportDrawer';
import { HRDrawer } from './SubDrawers/HRDrawer';
import HealingIcon from '@material-ui/icons/Healing';

const drawerWidth = 220;
const drawerWidthClosed = 72;

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 999,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        //width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
    drawerOpen: {
        width: drawerWidth,
        paddingTop: '64px',
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        paddingTop: '64px',
        whiteSpace: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
        overflow: "auto",
        scrollbarWidth: "none", // Hide the scrollbar for firefox
        '&::-webkit-scrollbar': {
            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
        },
        '&-ms-overflow-style:': {
            display: 'none', // Hide the scrollbar for IE
        },
        alignItems: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidthClosed,
        },
    },
    expand: {
        transform: 'rotate(-90deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    subDrawerOpen: {
        zIndex: 999,
        //backgroundColor: 'whitesmoke',
        left: drawerWidth,
        width: drawerWidth,
        paddingTop: '64px',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    subDrawerClose: {
        zIndex: 999,
        //backgroundColor: 'whitesmoke',
        left: 0,
        width: 0,
        paddingTop: '64px',
        transition: theme.transitions.create(['left', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        width: '100%',
    },
    listItem: {
        paddingLeft: 24,
    },
    container: {
        display: 'flex',
        width: '100%',
        paddingTop: '64px',
    },
    previewDrawerOpen: {
        width: drawerWidth,
        whiteSpace: 'nowrap',
        position: 'relative',
    },
    previewDrawerClose: {
        whiteSpace: 'nowrap',
        position: 'relative',
        width: 0,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: drawerWidthClosed,
        },
    },
    fileInput: {
        '&:hover': {
            cursor: 'pointer',
          },
        display:'flex',
        alignItems: 'center',
        border: '1px solid #bdbdbd',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        //backgroundColor: '#f2f2f2',
    },
    fileInputAccept: {
        display:'flex',
        alignItems: 'center',
        border: '0px',
        borderRadius:'4px',
        margin:'8px',
        marginTop:'16px',
        backgroundColor: '#56a84b',
        color:'white',
    },
}));
const SideDrawer = (props) => {
    const classes = useStyles();
    const { loading, userInfo, roles, currentUser } = useAuth();
    const theme = useTheme();

    const [alwaysOpen, setAlwaysOpen] = React.useState(JSON.parse(window.localStorage.getItem("sidebarAlwaysOpen")));
    const [open, setOpen] = React.useState(alwaysOpen);
    const [openSubDrawer, setOpenSubDrawer] = React.useState(false);
    const [subDrawer, setSubDrawer] = React.useState("");
    const [resumeBadge, setResumeBadge] = React.useState(0);
    const [unapprovedBadge, setUnapprovedBadge] = React.useState(0);
    const [unreadBadge, setUnreadBadge] = React.useState(0);
    const [shopExpanded, setShopExpanded] = useState("shopExpanded" in localStorage ? JSON.parse(window.localStorage.getItem("shopExpanded")) : true);
    const [demoExpanded, setDemoExpanded] = useState("demoExpanded" in localStorage ? JSON.parse(window.localStorage.getItem("demoExpanded")) : true);
    const [generalExpanded, setGeneralExpanded] = useState("generalExpanded" in localStorage ? JSON.parse(window.localStorage.getItem("generalExpanded")) : true);
    const [adminExpanded, setAdminExpanded] = useState("adminExpanded" in localStorage ? JSON.parse(window.localStorage.getItem("adminExpanded")) : true);
    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

    const locale = 'en';
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const day = today.toLocaleDateString(locale, { weekday: 'long' });
    const date = ` ${day}, ${today.toLocaleDateString(locale, { month: 'long' })} ${today.getDate()}\n\n`;
    const hour = today.getHours();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}${!loading ? userInfo?.firstName ? ' ' + userInfo?.firstName : '' : ''}! `;
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });

    React.useEffect(() => {
        if(props.preview){setOpen(true)}
    }, [loading, roles, alwaysOpen, smallScreen])// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if(!loading)
            unapprovedBadgeHandler();
    }, [loading, roles]);// eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        const unsubscribe = firestore.collection('resumes').where('viewed', '==', false)
        .onSnapshot(querySnapshot => {
            setResumeBadge(querySnapshot.docs.length)

        });

        return () => { unsubscribe(); }
    }, []);

    React.useEffect(() => {
        const unsubscribe = firestore.collection('messages').where('read', '==', false).where('directionType', '==', 'MO')
        .onSnapshot(querySnapshot => {
            setUnreadBadge(querySnapshot.docs.length)
        });

        return () => { unsubscribe(); }
    }, []);

    React.useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
        // This will trigger a rerender every component that uses the useDate hook.
        setDate(new Date());
        }, 10 * 1000);
        return () => {
        clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    const logoutHandler = () => {
        auth.signOut().then(() => {
            props.history.push('/dashboard')
        });
    };

    const defaultClickHandler = () => {
        setOpen(alwaysOpen);
        setOpenSubDrawer(false);
        setSubDrawer("");
    }

    const subDrawerClickHandler = (component) => {
        if(_.isEqual(component, subDrawer)){
            setOpenSubDrawer(false);
            setSubDrawer("");
        }
        else{
            setOpen(true);
            setOpenSubDrawer(true);
            setSubDrawer(component);
        }
    }

    const unapprovedBadgeHandler = () => {
        //grab all docs where viewed == false
        let amount = 0

        if(roles.includes('Approve Office Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Office').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Shop Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Shop').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Gravel Timesheets')) {

            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Gravel').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Construction Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Construction').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Grain Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Grain').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)

            })
        }
        if(roles.includes('Approve Snow Timesheets')) {
            firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Snow').get()
            .then(querySnapshot => {
                const response = querySnapshot.docs.map((doc) => {
                    return {
                        ...doc.data(),
                        'docId': doc.id,
                    }
                })
                amount += response.length
                setUnapprovedBadge(amount)
            })
        }
        setUnapprovedBadge(amount)
    }

    const handleMode = (colorMode) => {
        if(props.colorMode === 'light'){
            props.setColorMode('dark')
            window.localStorage.setItem("colorMode", JSON.stringify('dark'))
        } else {
            props.setColorMode('light')
            window.localStorage.setItem("colorMode", JSON.stringify('light'))
        }
    }

    const handleSwitch = () => {
        if(!alwaysOpen){
            setOpen(open)
            window.localStorage.setItem("sidebarAlwaysOpen", JSON.stringify(true))
            setAlwaysOpen(true)
        } else {
            setOpen(false)
            setOpenSubDrawer(false)
            window.localStorage.setItem("sidebarAlwaysOpen", JSON.stringify(false))
            setAlwaysOpen(false)
        }
    };

    const handleExpandClick = (hook) => {
        switch(hook) {
            case "admin":
                if(adminExpanded){
                    setAdminExpanded(false);
                    localStorage.setItem("adminExpanded", JSON.stringify(false))
                }
                else{
                    setAdminExpanded(true);
                    localStorage.setItem("adminExpanded", JSON.stringify(true))
                }
              break;
            case "general":
                if(generalExpanded){
                    setGeneralExpanded(false);
                    localStorage.setItem("generalExpanded", JSON.stringify(false))
                }
                else{
                    setGeneralExpanded(true);
                    localStorage.setItem("generalExpanded", JSON.stringify(true))
                }
              break;
            case "shop":
                if(shopExpanded){
                    setShopExpanded(false);
                    localStorage.setItem("shopExpanded", JSON.stringify(false))
                }
                else{
                    setShopExpanded(true);
                    localStorage.setItem("shopExpanded", JSON.stringify(true))
                }
                break;
            case "demo":
                if(demoExpanded){
                    setDemoExpanded(false);
                    localStorage.setItem("demoExpanded", JSON.stringify(false))
                }
                else{
                    setDemoExpanded(true);
                    localStorage.setItem("demoExpanded", JSON.stringify(true))
                }
                break;
            default:
              console.warn("Expand Click Error")
          }
    }

    const defaultBugReportData = {
        open: false,
        text: null,
        files: [],
        saving: false,
        confirmation: false,
    }

    const [bugReportData, setBugReportData] = useState(defaultBugReportData)

    const handleFileUpload = (acceptedFiles) => {
        setBugReportData({...bugReportData, files: [...bugReportData.files, ...acceptedFiles]})
    }

    const handleSendBugReport = async () => {
        setBugReportData({...bugReportData, saving: true})

        const storageRef = storage.ref();
        let urls = [];
        let docRef = firestore.collection("bugReports").doc()

        for (const file of bugReportData.files) {
            const path = storageRef.child(`BugReports/${docRef.id}/${file.name}`);
            await path.put(file)
            const url = await path.getDownloadURL()
            urls.push(url)
        }

        let bugReportObj = {
            date: moment().valueOf(),
            description: bugReportData.text,
            docId: docRef.id,
            os: navigator.userAgent,
            photoUrls: urls,
            sandbox: process.env.NODE_ENV === 'development',
            user: currentUser.uid,
            version: 'N/A'
        }

        docRef.set(bugReportObj)
        .then(() => {
            setBugReportData({...bugReportData, confirmation: true, saving: true})
            setTimeout(() => {
                setBugReportData(defaultBugReportData);
            }, 1500)
        });
    }

    return (
        <React.Fragment>
            {/* bug report modal */}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={bugReportData.open}
                onClose={() => { setBugReportData({...bugReportData, open: false}) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={bugReportData.open}>
                    <Paper style={{ padding: '16px', minWidth: '400px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='center'>
                                    Please describe the problem you are having:
                                </Typography>
                            </Grid>

                            <Grid item>
                                <TextField
                                    fullWidth
                                    placeholder='Report Description'
                                    multiline
                                    value={bugReportData.text}
                                    onChange={(e)=> {setBugReportData({...bugReportData, text: e.target.value})}}
                                />

                            </Grid>

                            <Grid container  style={{marginBottom: '20px'}}>
                                <Grid item sm={12} className={props.msdsPDF?.name ? classes.fileInputAccept : classes.fileInput}>

                                     <Dropzone onDrop={handleFileUpload}>
                                        {({ getRootProps, getInputProps }) => (
                                            <Grid container item xs={12}justifyContent='center' {...getRootProps()} >
                                                <input {...getInputProps()} style={{ display: 'none' }} />
                        
                                                <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                                <Typography variant='h6'>
                                                    {"Upload File(s)"}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Dropzone>
                                </Grid>
                                
                                {bugReportData.files.map((file, index) => {
                                        return (
                                            <Grid container justifyContent='center' key={index} style={{marginBottom: '5px'}}>
                                                <Chip
                                                    label={file.name}
                                                    style={{backgroundColor: '#39E75F'}}
                                                    onDelete={() => { 
                                                        let container = _.cloneDeep(bugReportData)
                                                        container.files.splice(index, 1)
                                                        setBugReportData(container)
                                                     }}
                                                />
                                            </Grid>
                                        )
                                    })}
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        variant='contained'
                                        disabled={bugReportData.saving || bugReportData.confirmation}
                                        style={bugReportData.saving ? {marginRight: '10px'} : {color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        onClick={(e) => { setBugReportData(defaultBugReportData); }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        disabled={bugReportData.saving || bugReportData.confirmation}
                                        style={bugReportData.confirmation ? {backgroundColor: 'green'} : null}
                                        onClick={(e) => {
                                            handleSendBugReport();
                                        }}
                                    >
                                        {bugReportData.confirmation ? "Sent!"  : "Send"}
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <div style={{ width: '100%', maxWidth: '73px' }} />

            <CssBaseline />

            {!props.preview ?
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => { setOpen(!open); setOpenSubDrawer(false) }}
                            edge="start"
                            className={classes.menuButton}

                        >
                            {open ? <ChevronLeftIcon /> : <MenuIcon />}
                        </IconButton>

                        {!smallScreen ?
                        <Grid container spacing={4}>
                            <Grid container item spacing={2}>
                                <Grid container item spacing={3}>
                                    <Typography noWrap variant="h6"  component="div" sx={{ flexGrow: 2 }} style={{flexGrow: '2'}}>
                                        Gee Tee Dashboard {process.env.NODE_ENV === 'development' && "(DEV MODE)"}
                                    </Typography>

                                </Grid>
                                <Grid container item spacing={3}>
                                    <Typography noWrap style={{paddingRight:'15px'}}>{time}</Typography>
                                    <Typography>{date}</Typography>
                                </Grid>
                                <Grid container item spacing={3}>
                                    <Typography>{wish}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={2}>
                            <Grid container item spacing={2}>
                                <Grid container item spacing={3}>
                                    <Typography noWrap  component="div" sx={{ flexGrow: 2 }} style={{flexGrow: '2'}}>
                                        Gee Tee {process.env.NODE_ENV === 'development' && "(DEV)"}
                                    </Typography>

                                </Grid>
                                <Grid container item spacing={3}>
                                    <Typography noWrap style={{paddingRight:'10px'}}><small>{time}</small></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        }

                        <Grid container justifyContent='flex-end' spacing={2}>
                        {!smallScreen ?  <Grid item>
                                <Grid container>
                                    <Tooltip title={"Report a problem"}>
                                        <IconButton sx={{ ml: 1 }} onClick={() => setBugReportData({...bugReportData, open: true})} color="secondary">
                                            {<BugReportIcon />}
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>:null}

                            <Grid item>
                                <Tooltip title={props.colorMode === 'dark' ? "Enable Light Mode" : "Enable Dark Mode"}>
                                    <IconButton sx={{ ml: 1 }} onClick={() => handleMode(props.colorMode)} color="secondary">
                                        {props.colorMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                {!smallScreen ?
                                    <FormControlLabel 
                                        control={
                                            <Switch color='secondary'
                                            checked={alwaysOpen}
                                            onChange={() => handleSwitch()}
                                            />
                                        } 
                                        label={<Typography style={{fontSize: '14px'}}>{alwaysOpen === true ? 'Sidebar Always Open' : 'Sidebar Always Closed'}</Typography>} 
                                        style={{marginTop: '5px'}}
                                    />
                                :
                                    null
                                }
                            </Grid>

                        </Grid>


                    </Toolbar>
                </AppBar>
            </Box>
            : null}

            <div className={classes.container}>
                <Drawer
                    open
                    variant="permanent"
                    style={{zIndex: '1000'}}
                    className={open ?
                        props.preview ? classes.previewDrawerOpen : classes.drawerOpen
                        : props.preview ? classes.previewDrawerClose : classes.drawerClose }

                    classes={{ paper: open ?
                        props.preview ? classes.previewDrawerOpen : classes.drawerOpen
                        : props.preview ? classes.previewDrawerClose : classes.drawerClose }}
                >
                {(props.preview 
                ?
                ( props.roles.includes("Approve Office Timesheets") || props.roles.includes("Approve Shop Timesheets") || props.roles.includes("Approve Snow Timesheets")
                || props.roles.includes("Approve Construction Timesheets") || props.roles.includes("Approve Gravel Timesheets") || props.roles.includes("Approve Grain Timesheets" ))
                :
                ( roles.includes("Approve Office Timesheets") || roles.includes("Approve Shop Timesheets") || roles.includes("Approve Snow Timesheets")
                || roles.includes("Approve Construction Timesheets") || roles.includes("Approve Gravel Timesheets") || roles.includes("Approve Grain Timesheets" )))
                ?
                <React.Fragment>
                    <List
                    subheader={
                    <ListSubheader style={{cursor: 'pointer'}}
                        onClick={() => { handleExpandClick("admin") }}
                    >
                        Admin
                        <IconButton
                            className={clsx(classes.expand, { [classes.expandOpen]: adminExpanded, })}
                            onClick={ () => {handleExpandClick("admin")}}
                            aria-expanded={adminExpanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        </ListSubheader>}
                    >
                    <Collapse in={adminExpanded} timeout="auto">

                        {props.preview ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Approval'>
                                <AssignmentTurnedInIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Time Approval'} />
                        </ListItem>
                        :
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/approval/timesheets'}
                            button
                        >
                            <ListItemIcon title='Approval'>
                                <Badge badgeContent={unapprovedBadge} color={unapprovedBadge > 0 ? "error" : "primary"} max={999} showZero>
                                    <AssignmentTurnedInIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary={'Time Approval'} />
                        </ListItem>
                        }

                        {props.preview ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Truck Schedule'> <CalendarTodayIcon /></ListItemIcon>
                            <ListItemText primary={'Truck Schedule'} />
                        </ListItem>
                        :
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/Scheduler/view'}
                            button
                        >
                            <ListItemIcon title='Truck Schedule'> <CalendarTodayIcon /></ListItemIcon>
                            <ListItemText primary={'Truck Schedule'} />
                        </ListItem>
                        }

                    {props.preview ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Bug Reports'> <BugReportIcon /></ListItemIcon>
                            <ListItemText primary={'Bug Reports'} />
                        </ListItem>
                        :
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/tech/bugreports'}
                            button
                        >
                            <ListItemIcon title='Bug Reports'> <BugReportIcon /></ListItemIcon>
                            <ListItemText primary={'Bug Reports'} />
                        </ListItem>
                        }
                    </Collapse>

                    </List>

                    <Divider /></React.Fragment>
                    : null}

                    <List
                        subheader={
                        <ListSubheader
                            style={{cursor: 'pointer'}}
                            onClick={() => { handleExpandClick("general")}}
                        >
                            General
                            <IconButton
                                className={clsx(classes.expand, { [classes.expandOpen]: generalExpanded, })}
                                onClick={ () => {handleExpandClick("general")}}
                                aria-expanded={generalExpanded}
                                aria-label="show more"
                            >
                            <ExpandMoreIcon />
                            </IconButton>
                        </ListSubheader>}
                    >
                    <Collapse in={generalExpanded} timeout="auto">

                        {props.preview ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Home'> <HomeIcon /></ListItemIcon>
                            <ListItemText primary={'Home'} />
                        </ListItem>
                        :
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard'}
                            button
                        >
                            <ListItemIcon title='Home'> <HomeIcon /></ListItemIcon>
                            <ListItemText primary={'Home'} />
                        </ListItem>
                        }

                        {(props.preview && props.roles.includes("Access Reports Section")) 
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Reports'> <AssessmentIcon /></ListItemIcon>
                            <ListItemText primary={'Reports'} />
                        </ListItem>
                        :
                        (!props.preview && roles.includes("Access Reports Section")) ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/reporting'}
                            button
                        >
                            <ListItemIcon title='Reports'> <AssessmentIcon /></ListItemIcon>
                            <ListItemText primary={'Reports'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Employees"))
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Employees'> <PeopleIcon /></ListItemIcon>
                            <ListItemText primary={'Employees'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Create|Update|Delete Employees")) 
                        ?
                        <ListItem
                            onClick={() => subDrawerClickHandler(<EmployeeDrawer />)}
                            className={classes.listItem}
                            button
                        >
                            <ListItemIcon title='Employees'> <PeopleIcon /></ListItemIcon>
                            <ListItemText primary={'Employees'} />
                        </ListItem>
                        : null}

                        {(props.preview)
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Human Resources'> <GavelIcon /></ListItemIcon>
                            <ListItemText primary={'Human Resources'} />
                        </ListItem>
                        : 
                        (!props.preview) 
                        ?
                        <ListItem
                            onClick={() => subDrawerClickHandler(<HRDrawer />)}
                            className={classes.listItem}
                            button
                        >
                            <ListItemIcon> <GavelIcon /></ListItemIcon>
                            <ListItemText primary={'Human Resources'} />
                        </ListItem>
                        : null}

                        {(props.preview) ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Health & Safety'> <HealingIcon /></ListItemIcon>
                            <ListItemText primary={'Health & Safety'} />
                        </ListItem>
                        : 
                        (!props.preview) 
                        ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/hr/healthsafety'}
                            button
                        >
                            <ListItemIcon title='Health & Safety'> <HealingIcon /></ListItemIcon>
                            <ListItemText primary={'Health & Safety'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Equipment"))  ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Equipment'> <LocalShippingIcon /></ListItemIcon>
                            <ListItemText primary={'Equipment'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Create|Update|Delete Equipment")) 
                        ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/equipment'}
                            button
                        >
                            <ListItemIcon title='Equipment'> <LocalShippingIcon /></ListItemIcon>
                            <ListItemText primary={'Equipment'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Work Orders"))
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Work Orders'> <BuildIcon /></ListItemIcon>
                            <ListItemText primary={'Work Orders'} />
                        </ListItem>
                        :
                        (!props.preview && roles.includes("Create|Update|Delete Work Orders"))
                        ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/workorders'}
                            button
                        >
                            <ListItemIcon title='Work Orders'> <BuildIcon /></ListItemIcon>
                            <ListItemText primary={'Work Orders'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Customers")) 
                        ?
                        <ListItem
                             className={classes.listItem}
                        >
                            <ListItemIcon title='Customers'> <PermContactCalendarIcon /></ListItemIcon>
                            <ListItemText primary={'Customers'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Create|Update|Delete Customers")) 
                        ?
                        <ListItem
                            onClick={() => subDrawerClickHandler(<CustomerDrawer />)}
                            className={classes.listItem}
                            button
                        >
                            <ListItemIcon title='Customers'> <PermContactCalendarIcon /></ListItemIcon>
                            <ListItemText primary={'Customers'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Forms"))
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon> <ListAltIcon /></ListItemIcon>
                            <ListItemText primary={'Forms & More'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Create|Update|Delete Forms")) 
                        ?
                        <ListItem
                            onClick={() => subDrawerClickHandler(<FormsDrawer />)}
                            className={classes.listItem}
                            button
                        >
                            <ListItemIcon> <ListAltIcon /></ListItemIcon>
                            <ListItemText primary={'Forms & More'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Manage Message Center"))
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Message Center'>
                                <PhoneAndroidIcon />
                            </ListItemIcon>
                                <ListItemText primary={'Message Center'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Manage Message Center")) 
                        ?
                        <ListItem
                            className={classes.listItem}
                            onClick={() => defaultClickHandler()}
                            component={Link}
                            to={'/dashboard/store/conversations'}
                            button
                        >
                            <ListItemIcon> <Badge badgeContent={unreadBadge} color={"primary"} max={99}><PhoneAndroidIcon /></Badge></ListItemIcon>
                                <ListItemText primary={'Message Center'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Permits")) 
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon> <AssignmentTurnedInIcon /></ListItemIcon>
                            <ListItemText primary={'Permits'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Create|Update|Delete Permits")) 
                        ?
                        <ListItem
                            className={classes.listItem}
                            onClick={() => defaultClickHandler()}
                            component={Link}
                            to={'/dashboard/permits'}
                            button
                        >
                            <ListItemIcon> <AssignmentTurnedInIcon /></ListItemIcon>
                            <ListItemText primary={'Permits'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Manage Resumes")) 
                        ?
                        <ListItem className={classes.listItem}>
                            <ListItemIcon title='Resumes'>
                                    <ContactMailIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Resumes'} />
                        </ListItem>
                        :  
                        (!props.preview && roles.includes("Manage Resumes")) 
                        ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/resumes'}
                            button
                        >
                        <ListItemIcon title='Resumes'>
                            <Badge badgeContent = {resumeBadge} color ="error" max={99} >
                                <ContactMailIcon />
                            </Badge>
                        </ListItemIcon>
                            <ListItemText primary={'Resumes'} />
                        </ListItem>
                        : null}

                    </Collapse>
                    </List>

                    <Divider />

                    {(props.preview 
                    ?
                    (props.roles.includes("Create|Update|Delete Orders") || props.roles.includes("Create|Update|Delete Products") || props.roles.includes("Manage Shop Settings"))
                    :
                    (roles.includes("Create|Update|Delete Orders") || roles.includes("Create|Update|Delete Products") || roles.includes("Manage Shop Settings")))
                    ?
                    <List
                        subheader={
                            <ListSubheader
                                style={{cursor: 'pointer'}}
                                onClick={() => { handleExpandClick("shop")}}
                            >
                                Shop
                                {open ?
                                <IconButton
                                    className={clsx(classes.expand, {[classes.expandOpen]: shopExpanded, })}
                                    onClick={ () => {handleExpandClick("shop")}}
                                    aria-expanded={shopExpanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                                : null}
                            </ListSubheader>
                        }
                    >

                    <Collapse in={shopExpanded} timeout="auto">
                        {(props.preview && props.roles.includes("Create|Update|Delete Orders"))
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon> <LocalAtmIcon /></ListItemIcon>
                            <ListItemText primary={'Orders'} />
                        </ListItem>
                        : 
                        (!props.preview && roles.includes("Create|Update|Delete Orders")) 
                        ?
                        <ListItem
                            onClick={() => subDrawerClickHandler(<OrderDrawer />)}
                            className={classes.listItem}
                            button
                        >
                            <ListItemIcon> <LocalAtmIcon /></ListItemIcon>
                            <ListItemText primary={'Orders'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Products")) 
                        ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon> <ShopIcon /></ListItemIcon>
                            <ListItemText primary={'Products'} />
                        </ListItem>
                        :  
                        (!props.preview && roles.includes("Create|Update|Delete Products")) 
                        ?
                        <ListItem
                            onClick={() => subDrawerClickHandler(<ProductDrawer />)}
                            className={classes.listItem}
                            button
                        >
                            <ListItemIcon> <ShopIcon /></ListItemIcon>
                            <ListItemText primary={'Products'} />
                        </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Create|Update|Delete Products")) 
                        ?
                            <ListItem
                                className={classes.listItem}
                            >
                            <ListItemIcon> <GrainIcon /></ListItemIcon>
                            <ListItemText primary={'Grain Report'} />
                        </ListItem>
                        :  
                        (!props.preview && roles.includes("Create|Update|Delete Products")) 
                        ?
                            <ListItem
                                onClick={() => subDrawerClickHandler(<GrainReportDrawer />)}
                                className={classes.listItem}
                                button
                            >
                                <ListItemIcon> <GrainIcon /></ListItemIcon>
                                <ListItemText primary={'Grain Report'} />
                            </ListItem>
                        : null}

                        {(props.preview && props.roles.includes("Manage Shop Settings"))
                        ?
                            <ListItem
                                className={classes.listItem}
                            >
                                <ListItemIcon title='Settings'> <SettingsApplicationsIcon /></ListItemIcon>
                                <ListItemText primary={'Settings'} />
                            </ListItem>
                        :
                        (!props.preview && roles.includes("Manage Shop Settings"))
                        ?
                            <ListItem
                                onClick={() => subDrawerClickHandler(<ShopSettingsDrawer />)}
                                className={classes.listItem}
                                button
                            >
                                <ListItemIcon title='Settings'> <SettingsApplicationsIcon /></ListItemIcon>
                                <ListItemText primary={'Settings'} />
                            </ListItem>

                        : null}
                        </Collapse>
                    </List>
                    : null }

                    {/* <Divider />
                    { (props.preview 
                    ? 
                    props.roles.includes("Access Demo Section") 
                    : 
                    roles.includes("Access Demo Section")) 
                    ?
                        <List
                            subheader={
                                <ListSubheader
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleExpandClick("demo")}
                                >
                                    Demo
                                <IconButton
                                    className={clsx(classes.expand, { [classes.expandOpen]: demoExpanded, })}
                                    onClick={ () => {handleExpandClick("demo")}}
                                    aria-expanded={demoExpanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </ListSubheader>}
                        >

                        <Collapse in={demoExpanded} timeout="auto">
                                {props.preview ?
                                <ListItem
                                    className={classes.listItem}
                                >
                                    <ListItemIcon title='Ticket Entries'><ConfirmationNumberIcon/></ListItemIcon>
                                    <ListItemText primary={'Tickets'} />
                                </ListItem>
                                :
                                <ListItem
                                    onClick={() => subDrawerClickHandler(<TicketDrawer />)}
                                    className={classes.listItem}
                                    button
                                >
                                    <ListItemIcon title='Ticket Entries'><ConfirmationNumberIcon/></ListItemIcon>
                                    <ListItemText primary={'Tickets'} />
                                </ListItem>
                                }
                                </Collapse>
                            </List>
                    : null} */}

                    <Divider />

                    <List>{currentUser.email === "derekmilleryoung@gmail.com" || currentUser.email === "landryjrdn@gmail.com" ?
                        <ListItem
                            onClick={() => defaultClickHandler()}
                            className={classes.listItem}
                            component={Link}
                            to={'/dashboard/Tech'}
                            button
                        >
                            <ListItemIcon title='Tech'> <ImportantDevicesIcon /></ListItemIcon>
                            <ListItemText primary={'Tech'} />
                        </ListItem> : null}
                    </List>

                    <List>
                        {props.preview ?
                        <ListItem
                            className={classes.listItem}
                        >
                            <ListItemIcon title='Logout'> <ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary={'Logout'} />
                        </ListItem>
                        :
                        <ListItem
                            className={classes.listItem}
                            onClick={logoutHandler}
                            button
                        >
                            <ListItemIcon title='Log Out'> <ExitToAppIcon /></ListItemIcon>
                            <ListItemText primary={'Log Out'} />
                        </ListItem>
                        }
                    </List>
                </Drawer>

                <Drawer
                    open
                    variant="permanent"
                    classes={{ paper: openSubDrawer ? classes.subDrawerOpen : classes.subDrawerClose }}>
                    {subDrawer !== "" && React.cloneElement(subDrawer, { onClick: defaultClickHandler })}
                </Drawer>
                <main className={classes.content}>
                    {props.children}
                </main>
            </div>

        </React.Fragment >
    );
}
export default withRouter(SideDrawer);