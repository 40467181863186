import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Grid, Typography } from '@material-ui/core/';
import styles from './InlineMediaViewer.module.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { clamp } from '../../../../store/utility';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  mediaSelected: {
    padding: '4px',
    width: '20%',
    height: '100%',
    backgroundColor: '#000',
    opacity: .40,
    border: '1px solid white'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'rgba(1,1,1,0.9)',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '100%',

  },
  scroll: {
    overflowX: 'auto',
  }

}));

/**
 * Displays photos as a gallery within the div
 * @see {@link test}
 * @param {Array} photoURLs Array of photoURLs, 
 */
const InlineMediaViewer = (props) => {
  const classes = useStyles();
  const [index, setIndex] = React.useState(0);
  const [selected, setSelected] = React.useState(0)
  const [imgFront, setImgFront] = React.useState(0);
  const [imgBack, setImgBack] = React.useState(0);
  const [swap, setSwap] = React.useState(true);
  const [opacity, setOpacity] = React.useState(false);

  let photos = [];
  let captions = [];

  let photoFormat = _.isArray(props.photoURLs) ? "Array" : "JSON";


  switch (photoFormat) {
    case "Array":
      photos = props.photoURLs;
      break;
    case "JSON":
      if (!_.isEmpty(props.photoURLs)) {
        photos = JSON.parse(Object.values(props.photoURLs));
        captions = Object.keys(props.photoURLs);
      }
      break;
    default:
      photos = [];
      break;
  }

  photos.forEach((photo, index) => {
    if (photo === "")
      photos.splice(index, 1);
  });

  const handleSelect = (newIndex) => {
    if (newIndex !== selected) {
      setOpacity(!opacity);
      setSwap(!swap);
      setSelected(newIndex);

      swap ? setImgFront(newIndex) : setImgBack(newIndex);
    }
  }

  //Generates the [index, index+5] preview images to show in the preview row.
  const generateRow = (index) => {
    let row = [];
    const imagesPerRow = photos.length > 5 ? 5 : photos.length;

    for (let i = index; i < (index + imagesPerRow) && i < photos.length; i++) {
      row.push(<img alt="" key={i} className={(!swap && i === imgFront) || (swap && i === imgBack) ? classes.mediaSelected : styles.media} src={photos[i]} onClick={() => handleSelect(i)} />)
    }

    return row;
  }

  return (
    photos && photos.length > 0 ?
      <Grid container className={styles.mediaContainer}>
        <Grid item xs={12} className={styles.content}>
          {captions.map((caption, index) => {
            return (
              <Typography variant='body1' className={styles.caption} key={index}>
                {caption.charAt(0).toUpperCase() + caption.slice(1)}
              </Typography>)
          })
          }
          <img alt="" className={[styles.mediaMainPrev, opacity ? styles.mediaOpacity1 : styles.mediaOpacity0].join(' ')} src={photos[imgFront]} />
          <img alt="" className={[styles.mediaMain, opacity ? styles.mediaOpacity0 : styles.mediaOpacity1].join(' ')} src={photos[imgBack]} />
        </Grid>
        <Grid item xs={12} className={styles.scroll}>
          {photos.length > 5 ?
            <div className={styles.fabLeft}>
              <Fab color="primary" onClick={() => setIndex(clamp(index - 5, 0, photos.length - 5))}>
                <ChevronLeftIcon />
              </Fab>
            </div>
            : null}
          {generateRow(index)}
          {photos.length > 5 ?
            <div className={styles.fabRight}>
              <Fab color="primary" onClick={() => setIndex(clamp(index + 5, 0, photos.length - 5))}>
                <ChevronRightIcon />
              </Fab>
            </div>
            : null}
          <div className={styles.pagination}>
            <Typography>Photos {index + 1}-{photos.length < 5 ? photos.length : index + 5} of {photos.length}</Typography>
          </div>
        </Grid>
      </Grid>
      : null
  )
}

export default InlineMediaViewer;