import React, { useState, useEffect } from 'react';
import { firestore, functions } from '../../../../firebase/firebase'
import { withRouter, useHistory } from 'react-router';
import { Paper, Grid, Chip, Button, Fade, Typography, Snackbar, TextField, 
    InputAdornment, Modal, Backdrop, CircularProgress, Divider,  ButtonGroup} from '@material-ui/core';
import PaperTitle from '../../../components/Orders/OrderPage/PaperTitle'
import CustomerCard from '../../../components/Orders/OrderPage/CustomerCard';
import CheckoutCard from '../../../components/Orders/OrderPage/CheckoutCard';
import PalletCard from '../../../components/Orders/OrderPage/PalletCard';
import PaymentDetails from '../../../components/Orders/OrderPage/PaymentDetails';
import ProductTable from '../../../components/Orders/OrderPage/ProductTable';
import DeliveryDetails from '../../../components/Orders/OrderPage/DeliveryDetails';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import 'date-fns';
import { Autocomplete, Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import * as actionCreators from '../../../../store/actions/index';
import { useUser } from '../../../../providers/UserContext'
import { useUnitDescription } from '../../../../providers/UnitDescriptionContext'
import PalletModal from './Modals/PalletModal'
import _  from 'lodash';
//import ShippingTable from '../../../components/Orders/OrderPage/ShippingTable';
import { useProduct } from '../../../../providers/ProductContext';
import AreYouSure from '../../../components/UI/AreYouSure.js/AreYouSure';
import GiftCardTable from '../../../components/Orders/OrderPage/GiftCardTable';
import PrintIcon from '@material-ui/icons/Print';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import EmailIcon from '@material-ui/icons/Email';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CachedIcon from '@material-ui/icons/Cached';
import BlockIcon from '@material-ui/icons/Block';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import EditIcon from '@material-ui/icons/Edit';

const Order = (props) => {

    const history = useHistory();
    const { calcShipping, setSubtotal, getTax, getSales, addToCart, resetCart, setDistance, removeFromCart} = props
    const { users, userLoading, getUsers } = useUser();
    const { units, unitLoading, getUnits } = useUnitDescription();
    const { products } = useProduct();

    const refundPayment = functions.httpsCallable('convergeRefundPayment');
    const squareRefund = functions.httpsCallable('squareRefund');

    const [orderData, setOrderData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [palletError, setPalletError] = useState("");
    const [loading, setLoading] = useState(true)
    const [dispatching, setDispatching] = useState(false)
    const [completing, setCompleting] = useState(false)
    const [reverting, setReverting] = useState(false)
    const [returning, setReturning] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [refundAmount, setRefundAmount] = useState(0);
    const [palletRefundAmount, setPalletRefundAmount] = useState(0)
    const [qtyRefund, setQtyRefund] = useState([])
    const [taxRefund, setTaxRefund] = useState({GST: 0, PST: 0})

    const [dispatchModalOpen, setDispatchModalOpen] = React.useState(false);
    const [cancelDispatchModalOpen, setCancelDispatchModalOpen] = React.useState(false);
    const [palletModalOpen, setPalletModalOpen] = useState(false);
    const [completeModalOpen, setCompleteModalOpen] = React.useState(false);
    const [revertModalOpen, setRevertModalOpen] = React.useState(false);
    const [refundModalOpen, setRefundModalOpen] = React.useState(false);
    const [resendModalOpen, setResendModalOpen] = React.useState(false);
    const [cancelModalOpen, setCancelModalOpen] = React.useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [revertCancelModalOpen, setRevertCancelModalOpen] = React.useState(false);

    const [fullRefunding, setFullRefunding] = useState(false);
    const [partialRefunding, setPartialRefunding] = useState(false);
    const [palletRefunding, setPalletRefunding] = useState(false);

    const [debitCreditRefundAmount, setDebitCreditRefundAmount] = useState(0)
    const [giftCardRefundAmount, setGiftCardRefundAmount] = useState(0)

    const [edit, setEdit] = useState(false);

    const [numPallets, setNumPallets] = useState(0);

    //Save and Editing product management
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState({});
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    //Search Autocomplete Values
    const [typedValue, setTypedValue] = useState('')
    const [typedValue2, setTypedValue2] = useState('')
    
    const [resendingEmail, setResendingEmail] = useState(false)
    const [refundingOrder, setRefundingOrder] = useState(false)

    const [newOrder, setNewOrder] = useState({})
    const [areYouSureOpen, setAreYouSureOpen] = useState(false);

    const deliverables = orderData !== null && orderData?.order?.products?.filter(product => (product.shippingMethod === 'Delivery'))
    const pickupables = orderData !== null && orderData?.order?.products?.filter(product => (product.shippingMethod === 'Pickup'))

    useEffect(() => {
        getTax()
        getUsers()
        getUnits()
        return () => {
            resetCart()
        }
      // eslint-disable-next-line
    }, []) 

    //move getSales to then after grab, to get all sales which were applied to particular order
    useEffect(() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
  
        //TODO:this will require some filtering eventually.
        firestore.collection('orders').doc(path).get()
            .then(doc => {
                let orderResponse = { ...doc.data(), 'docId': doc.id }
                setOrderData(orderResponse)
                setSaveState(orderResponse)

                //sales into redux
                if(!_.isEmpty(doc.data().customer.destination.location)){ getSales(doc.data().order.fees.salesApplicable , true) }
                else{ getSales() }

                //location into redux
                if(!_.isEmpty(doc.data().customer.destination.location)){ 
                    setDistance(doc.data().customer.destination.coordinates, "INPUT_SEARCHBOX", doc.data().customer.destination.location) 
                }

                //products into redux (doesnt need to happen, will happen on item change)
            })
            .catch(error => setErrorMessage(error.message))
            .finally(setLoading(false))
      // eslint-disable-next-line
    }, [props.location.search])

    //updates cart fees once cart has been updated
    useEffect(() => {
        if (props.cart.length > 0){
            calcShipping()
            setSubtotal()
        }
    }, [props.cart]) // eslint-disable-line react-hooks/exhaustive-deps

    //sets orderData once cartfees have updated
    useEffect(() => {
        if (props.cart.length > 0){
            setOrderData({...newOrder, order: {...newOrder.order, fees: props.cartFees}})
            //resetCart(); 
        }
    }, [props.cartFees]) // eslint-disable-line react-hooks/exhaustive-deps

    //sets refund amounts to 0 whenever changes have been made to total
    useEffect(() => {
        if(refundModalOpen){
            setDebitCreditRefundAmount(Number(0.00).toFixed(2))
            setGiftCardRefundAmount(Number(0.00).toFixed(2))
        }
    }, [orderData, refundAmount, taxRefund]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
    },[edit,orderData])

    const handleCancelDispatchModalOpen = () => {
        setCancelDispatchModalOpen(true);
    };

    const handleAreYouSureOpen = () => {
        setAreYouSureOpen(true);
    };

    const handleCancelDispatchModalClose = () => {
        setCancelDispatchModalOpen(false);
    };
    const handleDispatchModalOpen = () => {
        setDispatchModalOpen(true);
    };

    const handleDispatchModalClose = () => {
        setDispatchModalOpen(false);
    };

    const handleCompleteModalOpen = () => {
        setCompleteModalOpen(true);
    };

    const handleCompleteModalClose = () => {
        setCompleteModalOpen(false);
    };

    const handleCancelModalOpen = () => {
        setCancelModalOpen(true);
    };

    const handleCancelModalClose = () => {
        setCancelModalOpen(false);
    };

    const handleDeleteModalOpen = () => {
        setDeleteModalOpen(true);
    };

    const handleDeleteModalClose = () => {
        setDeleteModalOpen(false);
    };

    const handlePalletModalClose = () => {
        setPalletModalOpen(false);
    }

    const handleRevertModalOpen = () => {
        setRevertModalOpen(true);
    };

    const handleRevertModalClose = () => {
        setRevertModalOpen(false);
    };

    const handleRefundModalOpen = () => {
        setPartialRefunding(false);
        setFullRefunding(false);
        setRefundAmount(0.00);
        setRefundModalOpen(true);
        setQtyRefund(handleGetQtyRefund())
        setDebitCreditRefundAmount(0.00)
        setGiftCardRefundAmount(0.00)
        setTaxRefund({GST: 0, PST: 0})
    };

    const handleRefundModalClose = () => {
        setPartialRefunding(false);
        setFullRefunding(false);
        setRefundAmount(0.00);
        setRefundModalOpen(false);
    };

    const handleResendModalOpen = () => {
        setResendModalOpen(true);
    };

    const handleResendModalClose = () => {
        setResendModalOpen(false);
    };

    const handleRevertCancelModalOpen = () => {
        setRevertCancelModalOpen(true);
    };

    const handleRevertCancelModalClose = () => {
        setRevertCancelModalOpen(false);
    };

    const handleGetQtyRefund = () => {
        let container = {}
        orderData.order.products.forEach((product) => {
            container[product.productName] = Number(product.qty)
        })
        return container;        
    }

    const calcNewTotal = () => {
        let container = 0;
        orderData.order.products.forEach((product) => {
            container += (Number(product.price.price) * Number(qtyRefund[product.productName]).toFixed(2))
        })
        return container;
    }

    const calcTaxRefund = (qtyRefundContainer) => { 
        let productList = _.clone(orderData.order.products)

        productList.forEach((p, i) => {
            productList[i] = { ...p, taxClass: products[products.findIndex(x=> x.docId === p.product)].taxClass }
        })

        let tempTaxes = { GST: 0, PST: 0, }

        let taxList = {
            GST: orderData?.order?.fees?.rates?.GST ? orderData.order.fees.rates.GST : 0.05,
            PST: orderData?.order?.fees?.rates?.PST ? orderData.order.fees.rates.PST : 0.07,
            carbonFee: orderData?.order?.fees?.rates?.carbonFee ? orderData.order.fees.rates.carbonFee : 0.06,
        }

        productList.forEach((product) => {
            product.taxClass.forEach((tax) => {
                tempTaxes[tax] += taxList[tax] * (product.qty - qtyRefundContainer[product.productName]) * product.price.price;
                tempTaxes[tax] += Math.round(taxList[tax] * taxList['carbonFee'] * (product.qty - qtyRefundContainer[product.productName]) * product.price.price * 100) / 100;
                tempTaxes[tax] = Math.round(tempTaxes[tax] * 100) / 100
            });
        });

        setTaxRefund(tempTaxes)
    }

    const updateOrder = (newOrder) => {
        if(checkProductChanges(newOrder)){
            setNewOrder({...newOrder})

            //remove products from cart, ** this way so we dont need to find differences in two different orders **
            if(props.cart.length > 0){  props.cart.forEach((item, index) => { removeFromCart(index)}) }

            //add new products back to cart
            for(let i = 0; i < _.size(newOrder.order?.products[i]); i++){
                let productIndex = products.findIndex(x=> x.docId === newOrder.order?.products[i].product)
                let variantContainer;
    
                if(newOrder.order?.products[i].variant.inStock === false){
                    variantContainer = newOrder.order?.products[i].variant
                    variantContainer = {...variantContainer, inStock: true}
                }
    
                addToCart(products[productIndex], newOrder.order?.products[i].variant.inStock === false ? variantContainer : newOrder.order?.products[i].variant, 
                        newOrder.order?.products[i].qty, newOrder.order?.products[i].shippingMethod, newOrder.order?.products[i].price)
            }
        }
        else(
            setOrderData({...newOrder})
        )
    }

    //returns true if products (from newOrder and saveState) are different
    const checkProductChanges = (newOrder) => {
        if(_.size(newOrder.order?.products) !== _.size(saveState.order?.products)){
            return true;
        }
        else if(_.size(newOrder) === _.size(saveState)){
            for(let i = 0; i < _.size(newOrder?.order?.products); i++){
                if(!_.isEqual(newOrder.order?.products[i], saveState.order?.products[i])){
                return true;
                }
            }
        }
        return false;
    }

    const cancelUpdate = () => {
        setOrderData({ ...saveState })
    }

    const saveOrder = (event) => {
        event.preventDefault();
        setSaving(true);
        console.log('saving')
        firestore.collection('orders').doc(orderData.docId).update(orderData)
            .then(setSaveState({ ...orderData }))
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setSaving(false);
                setSaveState({ ...orderData })
            })
    }

    const handleDispatch = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setDispatching(true);
        orderData.order.status = 'DISPATCHED'
        firestore.collection('orders').doc(path).update(orderData)
            .then()
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setDispatchModalOpen(false)
                setDispatching(false);
            })
    })

    const handleCancelDispatch = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setDispatching(true);
        orderData.order.status = 'PROCESSING'
        firestore.collection('orders').doc(path).update(orderData)
            .then()
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setCancelDispatchModalOpen(false)
                setDispatching(false);
            })
    })
    
    const handleRefund = async (type, pallet) => {
        if(!refundingOrder){
            setRefundingOrder(true);
            let path = new URLSearchParams(props.location.search).get("orderId");
            let orderDataContainer = _.cloneDeep(orderData)
            let giftCardContainer = null;
            let debitRefundAmountContainer = null;
            let giftCardRefundAmountContainer = null;
            let totalRefundAmount = null;
            let error = null;

            //gets values for debitRefundAmountContainer, giftCardRefundAmountContainer, and totalRefundAmount
            if(pallet){
                debitRefundAmountContainer = palletRefundAmount

            } else if(orderDataContainer.order.fees.giftCards?.length > 0 && orderDataContainer.order.fees.giftCardApplied > 0){
                if(type === 'full'){
                    debitRefundAmountContainer = orderDataContainer.payment.approved_money.amount
                    giftCardRefundAmountContainer = orderData.order.fees.giftCardApplied
                }
                else{
                    if(orderDataContainer.payment.approved_money.amount === 0){
                        debitRefundAmountContainer = Number(0.00).toFixed(2)
                        giftCardRefundAmountContainer = Number(((orderData.order.fees.subTotal)) - (calcNewTotal()).toFixed(2) + Number(taxRefund.GST) + Number(taxRefund.PST) + Number(refundAmount)).toFixed(2)
                    } 
                    else{
                        //assert that Number(((orderData.order.fees.subTotal)) - (calcNewTotal()).toFixed(2) + Number(refundAmount) + Number(taxRefund.GST) + Number(taxRefund.PST) - Number(giftCardRefundAmount)).toFixed(2) and debitCreditRefundAmount are teh same
                        debitRefundAmountContainer =  Number(debitCreditRefundAmount).toFixed(2)
                        giftCardRefundAmountContainer = Number(giftCardRefundAmount).toFixed(2) 
                    }
                }
                totalRefundAmount = Number(debitRefundAmountContainer) + Number(giftCardRefundAmountContainer);
            } else{
                debitRefundAmountContainer = type === 'full' ? orderDataContainer.payment.approved_money.amount : Number(((orderData.order.fees.subTotal)) - (calcNewTotal()).toFixed(2) + Number(taxRefund.GST) + Number(taxRefund.PST) + Number(refundAmount)).toFixed(2)
                totalRefundAmount = debitRefundAmountContainer;
            }

            //if gift cards were used in purchase pulls data
            if(orderDataContainer.order.fees.giftCards?.length > 0 && orderDataContainer.order.fees.giftCardApplied > 0){
                 await firestore.collection('giftCards').get()
                 .then(querySnapshot => {
                    const response = querySnapshot.docs.map((doc) => {
                      return { ...doc.data(), }
                    })
                    giftCardContainer = response.filter(gc => orderDataContainer.order.fees.giftCards.map((x) => {return x.code}).includes(gc.code))
                  })
                  .catch(e => {
                    error = e
                    console.log(e.message);
                  })
            }


            if(Number(debitRefundAmountContainer) > Number(0.00)){
                orderData.payment.paymentHandler === 'CONVERGE' 
                ?
                    await refundPayment({
                        sandbox: process.env.NODE_ENV === 'development',
                        amount: type === 'full' ? null : debitRefundAmountContainer,
                        order: orderData,
                        email: orderData.customer.email,
                        transactionID: orderData.payment.id,
                    }).then((res) => {
                        console.log(res)
                    }).catch((err) => {
                        console.log(err)
                        error = err;
                    })
                :
                   // console.log('square refund', type === 'full' ? null : debitRefundAmountContainer)

                    await squareRefund({
                        sandbox: process.env.NODE_ENV === 'development',
                        amount: type === 'full' ? null : debitRefundAmountContainer,
                        order: orderData,
                        email: orderData.customer.email,
                        transactionID: orderData.payment.id,
                    }).then((res) => {
                        console.log(res)
                    }).catch((err) => {
                        console.log(err)
                        error = err;
                    })
            }

            if(error === null){
                if(pallet){
                    //orderData.order.refund = type === 'full' ? 'FULL' : 'PARTIAL'
                    if (!('refunds' in orderData.payment)){ orderDataContainer.payment.refunds = []; }
                    orderDataContainer.order.fees.palletFee.remainingPallets -= numPallets;
                    orderDataContainer.order.fees.palletFee.palletStatus = orderData.order.fees.palletFee.remainingPallets > 0 ? 'PARTIAL REFUND' : 'REFUNDED';
                    orderDataContainer.order.fees.palletFee.remainingPalletFee -= palletRefundAmount;
                }
                else if(type === 'full'){
                    orderDataContainer.order.refund = 'FULL';
                    orderDataContainer.order.refundAmount = totalRefundAmount/100;
                }
                else{
                    //order changed 
                    if (type !== 'full' && !_.isEqual(handleGetQtyRefund(), qtyRefund) ){
                        let productsContainer = _.cloneDeep(orderData.order.products)
                        let feesContainer = _.cloneDeep(orderData.order.fees)

                        Object.keys(qtyRefund).forEach((key) => {
                            let index = productsContainer.findIndex(x=> x.productName === key)
                            let productDataContainer = productsContainer[index]
                            productDataContainer.qty = qtyRefund[key]
                            productsContainer.splice(index, 1, productDataContainer)
                        })     
                        
                        feesContainer.discSubTotal = Number((calcNewTotal()).toFixed(2) - ((orderData.order.fees.coupons).toFixed(2)));
                        feesContainer.subTotal = Number(calcNewTotal().toFixed(2));
                        feesContainer.total = Number(orderData.order.fees.total) - Number(debitRefundAmountContainer);
                        feesContainer.cartTaxes.GST = Number(orderData.order.fees.cartTaxes.GST) - Number(taxRefund['GST'])
                        feesContainer.cartTaxes.PST = Number(orderData.order.fees.cartTaxes.PST) - Number(taxRefund['PST'])
                        orderDataContainer.refundedOrder = {...orderData.order, refundAmount: totalRefundAmount, refund: 'PARTIAL'}
                        orderDataContainer.order = {...orderDataContainer.order, products: productsContainer, fees: feesContainer, refund: 'PARTIAL', refundAmount: refundAmount}
                    }
                    //no order changes
                    else{
                        orderDataContainer.order.refund = 'PARTIAL';
                        orderDataContainer.order.refundAmount =  !orderDataContainer.order?.refundAmount ? totalRefundAmount : (Number(orderDataContainer.order.refundAmount) + Number(totalRefundAmount)).toFixed(2);
                        if(Number(orderDataContainer.order.refundAmount) === Number(orderDataContainer.order.fees.total)){
                            orderDataContainer.order.refund = 'FULL'
                        }
                    }
                }

                //add gift card value back
                if(giftCardRefundAmountContainer !== null && giftCardRefundAmountContainer > 0){ 
                    let batch = firestore.batch();
                    let refundContainer = _.cloneDeep(giftCardRefundAmountContainer)

                    for(let i = 0; i < orderDataContainer.order.fees.giftCards.length; i++){
                        //current value was amount used on this transaction
                        refundContainer -= orderDataContainer.order.fees.giftCards[i].currentValue

                        //up to date value of gc
                        let cardValue = Number(giftCardContainer[giftCardContainer.findIndex(gc=> gc.code === orderDataContainer.order.fees.giftCards[i].code)].currentValue.toFixed(2)) 

                        //update giftCardApplied
                        orderDataContainer.order.fees.giftCardApplied -= Number(cardValue.toFixed(2))

                        if(refundContainer > 0){
                            batch.update(
                                firestore.collection('giftCards').doc(orderDataContainer.order.fees.giftCards[i].code), 
                                {currentValue: Number(Number(orderDataContainer.order.fees.giftCards[i].currentValue.toFixed(2)) + Number(cardValue.toFixed(2)))} 
                            );
                        }
                        else{
                            batch.update(
                                firestore.collection('giftCards').doc(orderDataContainer.order.fees.giftCards[i].code), 
                                {currentValue: (Number(orderDataContainer.order.fees.giftCards[i].currentValue.toFixed(2)) - Number(Math.abs(refundContainer.toFixed(2))) + Number(cardValue.toFixed(2))) } 
                            );
                            break;
                        }
                    }
                    batch.commit().catch((e) => { console.log(e); });
                }
                firestore.collection('orders').doc(path).update(orderDataContainer)
                    .then()
                    .catch(e => {
                         console.error("Error updating order: ", e)
                         error = e
                     })
                    .finally(() => {
                        setOrderData(orderDataContainer)
                        setSaveState(orderDataContainer)
                        setNumPallets(0)
                        setRefundingOrder(false);
                        setRefundAmount(0)
                        setGiftCardRefundAmount(Number(0.00).toFixed(2))
                        setDebitCreditRefundAmount(Number(0.00).toFixed(2))
                        setRefundModalOpen(false)
                    })
            }
            else{
                console.warn('unable to refund order: ', error)
            }
        }
    }

    const handlePallets = ((event, returnedPallets) => {

        event.preventDefault();

        let path = new URLSearchParams(props.location.search).get("orderId");
        let newOrder = { ...orderData };

        setReturning(true);

        if (returnedPallets > 0 && newOrder.order.fees.palletFee.remainingPallets - returnedPallets >= 0) {
            let refund;

            if (newOrder.order.fees.palletFee.remainingPallets - returnedPallets === 0)
                refund = newOrder.order.fees.palletFee.remainingPalletFee;
            else
                refund = newOrder.order.fees.palletFee.totalFee * (returnedPallets / newOrder.order.fees.palletFee.totalPallets);

            const sendPayment = functions.httpsCallable('squarePalletRefund');

            sendPayment({
                order: newOrder,
                refund: parseInt(refund * 100),
                sandbox: process.env.NODE_ENV === 'development',
            })
                .then(result => {
                    if (!('refunds' in newOrder.payment))
                        newOrder.payment.refunds = [];

                    newOrder.payment.refunds.push(result.data.message.refund);

                    newOrder.order.fees.palletFee.remainingPallets -= returnedPallets;
                    newOrder.order.fees.palletFee.palletStatus = newOrder.order.fees.palletFee.remainingPallets > 0 ? 'PARTIAL REFUND' : 'REFUNDED';
                    newOrder.order.fees.palletFee.remainingPalletFee -= refund;

                    firestore.collection('orders').doc(path).update(newOrder)
                        .then(setPalletError(""))
                        .catch(error => {
                            console.error("Error updating document: ", error)
                            setPalletError("A refund was issued, but the order was not updated. Please contact dev@geeteeholdings.com about the error.");
                        })
                        .finally(() => {
                            handlePalletModalClose();
                            setReturning(false);
                        })
                })
                .catch(error => {
                    console.log(error);
                    setPalletError("Unable to issue refund, please try again.");
                    setReturning(false);
                });
        }
        else {
            setPalletError("Error: Invalid number of pallets.")
            setReturning(false)
        }
    })

    const handleCancel = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setCompleting(true);
        orderData.order.status = 'CANCELLED'
        firestore.collection('orders').doc(path).update(orderData)
            .then()
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setCancelModalOpen(false)
                setCompleting(false);
            })
    })

    const handleComplete = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setCompleting(true);
        orderData.order.status = 'COMPLETED'
        firestore.collection('orders').doc(path).update(orderData)
            .then()
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setCompleteModalOpen(false)
                setCompleting(false);
            })
    })

    const handleResendEmail = (() => {
        handleResendModalClose();
        setResendingEmail(true);
        let path = new URLSearchParams(props.location.search).get("orderId");
        const sendEmail = functions.httpsCallable('resendCustomerEmail');
        sendEmail({
            payment: orderData.payment,
            customer: orderData.customer,
            order: orderData.order,
        })
        if(!orderData.customer.confirmEmailSent) {
            orderData.customer.confirmEmailSent = true
            firestore.collection('orders').doc(path).update(orderData)
                .then(() => {
                    setTimeout(() => { setResendingEmail(false) }, 3000);
                })
                .catch(error => console.error("Error updating document: ", error))
        } else {
            setTimeout(() => { setResendingEmail(false) }, 3000);
        }      
    })

    const handleRevert = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setReverting(true);
        orderData.order.status = 'PROCESSING'
        firestore.collection('orders').doc(path).update(orderData)
            .then()
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setRevertModalOpen(false)
                setReverting(false);
            })
    })

    const handleUncancel = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setReverting(true);
        orderData.order.status = 'PROCESSING'
        firestore.collection('orders').doc(path).update(orderData)
            .then()
            .catch(error => console.error("Error updating document: ", error))
            .finally(() => {
                setRevertCancelModalOpen(false)
                setReverting(false);
            })
    })

    const handleDelete = (() => {
        let path = new URLSearchParams(props.location.search).get("orderId");
        setDeleting(true);
        firestore.collection('orders').doc(path).delete()
            .then()
            .catch(error => console.error("Error deleting document: ", error))
            .finally(() => {
                setDeleteModalOpen(false)
                setDeleting(false);
                props.history.push('/dashboard/store/orders');
            })
    })

    const handleCapRefundValues = (value, type) => {
        let totalRefund = Number(((orderData.order.fees.subTotal)) - (calcNewTotal()).toFixed(2) + Number(refundAmount) + Number(taxRefund.GST) + Number(taxRefund.PST)).toFixed(2)
        let max = type === 'giftCard' ? Number(orderData.order.fees.giftCardApplied).toFixed(2) : Number(orderData.payment.approved_money.amount).toFixed(2);
        //let secondaryMax =  type === 'giftCard' ? Number(orderData.payment.approved_money.amount) : Number(orderData.order.fees.giftCardApplied)

        if((Number(value) > Number(totalRefund)) && (Number(value) > Number(max))){
            let lowerValue = Number(totalRefund) > Number(max) ? Number(max).toFixed(2) : Number(totalRefund).toFixed(2);
            type === 'giftCard' ? setGiftCardRefundAmount(Number(lowerValue).toFixed(2)) : setDebitCreditRefundAmount(Number(lowerValue).toFixed(2))

            // if((Number(lowerValue) - Number(totalRefund)) > Number(secondaryMax)){
            //     //set state to totalRefund - secondaryMax
            //     type === 'giftCard' ? setGiftCardRefundAmount(Number(Number(secondaryMax) - Number(totalRefund)).toFixed(2)) : setDebitCreditRefundAmount(Number(Number(secondaryMax) - Number(totalRefund)).toFixed(2))
            //     //set secondary state to secondaryMax
            //     type === 'giftCard' ? setDebitCreditRefundAmount(Number(secondaryMax).toFixed(2)) : setGiftCardRefundAmount(Number(secondaryMax).toFixed(2))
            // }
            // else{ 
            //     //set state to lower value
            //     type === 'giftCard' ? setGiftCardRefundAmount(Number(lowerValue).toFixed(2)) : setDebitCreditRefundAmount(Number(lowerValue).toFixed(2))
            //     //set secondary state to lowerValue - totalRefund
            //     type === 'giftCard' ? setDebitCreditRefundAmount(Number(Number(totalRefund) - Number(lowerValue)).toFixed(2)) : setGiftCardRefundAmount(Number(Number(totalRefund) - Number(lowerValue)).toFixed(2))
            // }
        }

        else if((Number(value) > Number(totalRefund)) && !(Number(value) > Number(max))){
            type === 'giftCard' ? setGiftCardRefundAmount(Number(totalRefund).toFixed(2)) : setDebitCreditRefundAmount(Number(totalRefund).toFixed(2))

            // //set state to totalRefund
            // type === 'giftCard' ? setGiftCardRefundAmount(Number(totalRefund).toFixed(2)) : setDebitCreditRefundAmount(Number(totalRefund).toFixed(2))
            // //set secondary state to 0
            // type === 'giftCard' ? setDebitCreditRefundAmount(0.00) : setGiftCardRefundAmount(0.00)
        }

        else if(!(Number(value) > Number(totalRefund)) && (Number(value) > Number(max))){
            type === 'giftCard' ? setGiftCardRefundAmount(Number(max).toFixed(2)) : setDebitCreditRefundAmount(Number(max).toFixed(2))

            // if((Number(max) - Number(totalRefund)) >= Number(secondaryMax)){ //(should theoretically never happen, unless maxing out partial refund to full)
            //     //set value to max
            //     type === 'giftCard' ? setGiftCardRefundAmount(Number(max).toFixed(2)) : setDebitCreditRefundAmount(Number(max).toFixed(2))
            //     //set secondary state to secondaryMax
            //     type === 'giftCard' ? setDebitCreditRefundAmount(Number(secondaryMax).toFixed(2)) : setGiftCardRefundAmount(Number(secondaryMax).toFixed(2))
            // }
            // else{
            //     //set state to max
            //     type === 'giftCard' ? setGiftCardRefundAmount(Number(max).toFixed(2)) : setDebitCreditRefundAmount(Number(max).toFixed(2))
            //     //set secondary state to max - totalRefund
            //     type === 'giftCard' ? setDebitCreditRefundAmount(Number(Number(totalRefund) - Number(max)).toFixed(2)) : setGiftCardRefundAmount(Number(Number(totalRefund) - Number(max)).toFixed(2))
            // }
        }

        else if(!(Number(value) > Number(totalRefund)) && !(Number(value) > Number(max))){
            type === 'giftCard' ? setGiftCardRefundAmount(Number(value).toFixed(2)) : setDebitCreditRefundAmount(Number(value).toFixed(2))

            // if((Number(totalRefund) - Number(value)) > Number(secondaryMax)){
            //     //set value to totalRefund - secondaryMax
            //     type === 'giftCard' ? setGiftCardRefundAmount(Number(Number(totalRefund) - Number(secondaryMax)).toFixed(2)) : setDebitCreditRefundAmount(Number(Number(totalRefund) - Number(secondaryMax)).toFixed(2))
            //     //set secondary state to secondaryMax
            //     type === 'giftCard' ? setDebitCreditRefundAmount(Number(secondaryMax).toFixed(2)) : setGiftCardRefundAmount(Number(secondaryMax).toFixed(2))
            // }
            // else{
            //     //set state to value
            //     type === 'giftCard' ? setGiftCardRefundAmount(Number(value).toFixed(2)) : setDebitCreditRefundAmount(Number(value).toFixed(2))
            //     //set secondary state to value - totalRefund
            //     type === 'giftCard' ? setDebitCreditRefundAmount(Number(Number(totalRefund) - Number(value)).toFixed(2)) : setGiftCardRefundAmount(Number(Number(totalRefund) - Number(value)).toFixed(2))
            // }
        }
    }


    return orderData !== null &&
        <form onSubmit={ (event) => {saveOrder(event)}}>
        <React.Fragment>
            <Grid container style={{ justifyContent: 'center' }}>
                <Grid item xl={6} lg={12} xs={12}>
                    <Button style={{ marginLeft: '15px', marginTop: '15px' }} variant="contained" startIcon={<KeyboardBackspaceIcon />} onClick={() => props.history.goBack()}>
                        Back</Button>
                    <Paper style={{ padding: '16px', margin: '16px', color: '#707070' }}>
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <PaperTitle loading={loading || !orderData} title={`Order #${orderData?.order.orderID} - $${(orderData?.payment?.paymentHandler ? parseFloat(orderData?.order?.fees?.total) : orderData?.order?.fees?.total / 100).toFixed(2)}`} width='100px' />
                            </Grid>

                            <Grid item xs={6}>
                                <Grid container spacing={2} justifyContent='flex-end' >

                                    <Typography>Order Actions</Typography>
                                </Grid>
                                <Divider style={{margin:'10px'}}/>
                                
                                <Grid container spacing={2} justifyContent='flex-end'>
                                    <ButtonGroup color="primary" style={{margin:'5px'}}>
                                        <Button startIcon={<EditIcon/>} variant="contained" size="small"
                                            onClick={()=>{setEdit(!edit)}}>
                                            {edit?'Cancel':'Edit'}
                                        </Button>
                                        <Button size="small"
                                            disabled={edit}
                                            variant='contained' startIcon={<PrintIcon />}
                                            onClick={(e) => history.push("/dashboard/reporting/orders/printing/?docId=" + orderData?.docId)}>
                                            Print
                                        </Button>
                                        {deliverables?.length > 0 &&
                                        <Button size="small"
                                            variant="contained" startIcon={<AssignmentReturnIcon />}
                                            disabled={dispatching || edit}
                                            onClick={() => orderData?.order.status === 'DISPATCHED' ? handleCancelDispatchModalOpen(true) : handleDispatchModalOpen(true)}>
                                            {orderData?.order.status === 'DISPATCHED' ? 'Cancel Dispatch' : 'Dispatch'}
                                        </Button>}
                                        {orderData?.order.status !== 'COMPLETED' && orderData?.order.status !== 'CANCELLED' && _.isUndefined(orderData.customer.contactMethod) ?
                                        <Button size="small"
                                            variant="contained" startIcon={<EmailIcon />}
                                            disabled={resendingEmail || edit}
                                            onClick={() => handleResendModalOpen()}>
                                            {resendingEmail ? 'Sending...' : 'Resend Email'}
                                        </Button>: null}
                                    </ButtonGroup>
                                    <ButtonGroup style={{margin:'5px'}}>
                                        {orderData?.order.status !== 'CANCELLED' ?
                                        <Button size="small"
                                            color={orderData?.order.status === 'COMPLETED' ? "secondary" : "primary"}
                                            variant="contained" startIcon={orderData?.order.status === 'COMPLETED' ? <CheckCircleIcon />:<CachedIcon/>}
                                            disabled={completing || reverting || edit}
                                            onClick={
                                                orderData?.order.status !== 'COMPLETED' ? () => handleCompleteModalOpen(true)
                                                    :  () => handleRevertModalOpen(true)
                                            }>

                                            {orderData?.order.status === 'COMPLETED' ? 'Reprocess' : 'Complete'}
                                        </Button>:null}
                                        <Button size="small"
                                            color={orderData?.order.status === 'CANCELLED' ? "secondary" : "secondary"}
                                            variant="contained"  startIcon={orderData?.order.status !== 'CANCELLED' ? <BlockIcon />:<DeleteForeverIcon/>}
                                            disabled={completing || reverting || orderData?.order?.status === 'COMPLETED' || edit}
                                            onClick={
                                                orderData?.order.status !== 'CANCELLED' ? () => handleCancelModalOpen(true)
                                                    : () => handleDeleteModalOpen(true)
                                            }>

                                            {orderData?.order.status === 'CANCELLED' ? 'Delete' : 'Cancel'}
                                        </Button>
                                        {orderData?.order.status === 'CANCELLED' ?
                                        <Button size="small"
                                            color={"primary"}  startIcon={<CachedIcon />}
                                            variant="contained"
                                            disabled={reverting || edit}
                                            onClick={
                                                () => handleRevertCancelModalOpen(true)
                                                
                                            }>

                                            Uncancel Order
                                        </Button>: null}
                                        {!orderData?.order?.refund || (orderData.order?.refund === 'PARTIAL' && Number(orderData.order?.refundAmount) < Number(orderData.order.fees.total)) ?
                                            <Button style={{color:edit?'':'white', backgroundColor:edit?'':'blue'}}
                                                variant="contained"  size="small" startIcon={<MoneyOffIcon />}
                                                disabled={refundingOrder || edit}
                                                onClick={() => handleRefundModalOpen()}>
                                                {refundingOrder ? 'Processing...' : 'Refund'}
                                            </Button>: null}
                                    </ButtonGroup>

                                </Grid>
                            </Grid>


                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '8px' }}>BOL &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                                <TextField
                                    style={{ maxWidth: '300px', width: '100%' }}
                                    variant='outlined'
                                    label="BOL"
                                    size='small'
                                    value={orderData?.bol ?? ''}
                                    onChange={(event) => updateOrder({ ...orderData, 'bol': event.target.value })} />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '8px' }}>Operator</Typography>
                                <Autocomplete
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={() => {
                                        setOpen(false);
                                    }}
                                    loading={userLoading}
                                    label="Search"
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    style={{ maxWidth: '300px', width: '100%' }}
                                    inputValue={typedValue}
                                    onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                    value={orderData?.operator ?? ''}
                                    onChange={(event, newValue) => { 
                                        let localContainer = { ...orderData, 'operator': newValue }

                                        //check if equipment is not present
                                        if(( !orderData.truckNumber)){
                                            let unitContainer = users.find(x=> x.displayName === newValue)?.commonEquipment?.fullUnit

                                            if(!_.isUndefined(unitContainer)){  
                                                localContainer.truckNumber = unitContainer 
                                            }
                                        }

                                        updateOrder(localContainer) 
                                    }}
                                    options={users.map((user) => user.displayName ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                    getOptionSelected={(option, value) => option?.displayName === value?.displayName}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ maxWidth: '300px', width: '100%' }}
                                            label="Operator"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ marginRight: '8px' }}>Truck #&nbsp;&nbsp;</Typography>
                                <Autocomplete
                                    open={open2}
                                    onOpen={() => {
                                        setOpen2(true);
                                    }}
                                    onClose={() => {
                                        setOpen2(false);
                                    }}
                                    loading={unitLoading}
                                    label="Search"
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    style={{ maxWidth: '300px', width: '100%' }}
                                    inputValue={typedValue2}
                                    onInputChange={(event, newValue) => { setTypedValue2(newValue) }}
                                    value={orderData?.truckNumber || null}
                                    onChange={(event, newValue) => { 
                                        let localContainer = { ...orderData, 'truckNumber': newValue }

                                        if(( !orderData.operator)){
                                            let userContainer = users.filter(x=> x.commonEquipment?.fullUnit?.docId === newValue.docId).pop()?.displayName
                                            if(!_.isUndefined(userContainer)){  
                                                localContainer.operator = userContainer 
                                            }
                                        }

                                        updateOrder({...localContainer}) 
                                    }}
                                    options={units.map((unit) => unit).sort((a, b) => a > b)}
                                    getOptionLabel={(option) => "Unit #" + option.unitNumber + " - " + option.description}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ maxWidth: '300px', width: '100%' }}
                                            label="Truck"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            {!orderData.customer?.confirmEmailSent && _.isUndefined(orderData.customer.contactMethod) ? 
                            <Grid item xs={12}>
                                <Alert severity='warning' variant='outlined'>Customer never received their confirmation email!</Alert>
                            </Grid> : null}
                            {orderData.order?.refund ? 
                            <Grid item xs={12}>
                                <Alert severity='info' variant='outlined'>This order has had a {orderData.order.refund} refund. 
                                {orderData.order.refund === 'PARTIAL'? `\nRefunded amount: $${orderData.order.refundAmount}  Remaining: $${(Number(orderData.order.fees.total)-Number(orderData.order.refundAmount)).toFixed(2)}` : null}
                                </Alert>
                            </Grid> : null}
                            <Grid item xs={12}>
                                <Chip
                                    color='secondary'
                                    label={orderData?.order.status}
                                    variant='outlined' />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <CustomerCard order={orderData} loading={loading} edit={edit} updateOrder={updateOrder} />
                            </Grid>

                            <Grid item md={6} xs={12}>
                                <PaymentDetails order={orderData} loading={loading} />
                            </Grid>

                            {(orderData.customer.destination.location !== "" && !_.isUndefined(orderData.customer.destination.location)) && <Grid item xs={12}>
                                <DeliveryDetails order={orderData} loading={loading} updateOrder={updateOrder} edit={edit}/>
                            </Grid>}

                            {/* {'shippingInformation' in orderData.order.fees && orderData.order.fees.shippingInformation.trucks.length > 0 &&
                                <Grid item xs={12}>
                                    <ShippingTable shipping={orderData.order.fees.shippingInformation} title='Shipping Information' loading={loading} />
                                </Grid>} */}

                            {deliverables?.length > 0 &&
                                <Grid item xs={12}>
                                    <ProductTable order={orderData} products={deliverables} title='Delivery' loading={loading} updateOrder={updateOrder}/>
                                </Grid>}

                            {pickupables?.length > 0 &&
                                <Grid item xs={12}>
                                    <ProductTable order={orderData} products={pickupables} title='Pickup' loading={loading} updateOrder={updateOrder}/>
                                </Grid>}

                            {orderData?.giftCardList && orderData?.giftCardList?.length > 0 &&
                                <Grid item xs={12}>
                                    <GiftCardTable order={orderData} title='Gift Cards Created' loading={loading}/>
                                </Grid>}

                            <Grid item md={6} xs={12}>
                                <CheckoutCard order={orderData} loading={loading} />
                            </Grid>
                           {"palletFee" in orderData.order.fees ? <Grid item md={6} xs={12}>
                                <PalletCard order={orderData} loading={loading} />
                            </Grid>:null}
                            
                        </Grid>
                        
                    </Paper>
                </Grid>
            </Grid>
            
            <AreYouSure open={areYouSureOpen} setOpen={setAreYouSureOpen} passedFunction={() => handleRefund('full',false)}/>
            {partialRefunding ? <AreYouSure open={areYouSureOpen} setOpen={setAreYouSureOpen} passedFunction={() => handleRefund((Number(calcNewTotal()) + Number(refundAmount)).toFixed(2), false)}/> : null}
            {palletRefunding ? <AreYouSure open={areYouSureOpen} setOpen={setAreYouSureOpen} passedFunction={() => handleRefund(palletRefundAmount, true)}/> : null}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={dispatchModalOpen}
                onClose={handleDispatchModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={dispatchModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you certain you wish to Dispatch this order? The customer will be notified via email that their order is out for delivery.</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={dispatching || orderData?.order.status !== 'PROCESSING'} variant='contained' onClick={() => handleDispatch()}> Dispatch It! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={deleteModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to Delete this order?</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={deleting || orderData?.order.status !== 'CANCELLED'} variant='contained' onClick={() => handleDelete()}> DELETE ORDER! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={cancelDispatchModalOpen}
                onClose={handleCancelDispatchModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={cancelDispatchModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to revert this order back to Processing?</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={dispatching} variant='contained' onClick={() => handleCancelDispatch()}> CANCEL DISPATCH! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={cancelModalOpen}
                onClose={handleCancelModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={cancelModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to Cancel this order?</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={completing || orderData?.order.status === 'CANCELLED'} variant='contained' onClick={() => handleCancel()}> CANCEL ORDER! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={completeModalOpen}
                onClose={handleCompleteModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={completeModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to Complete this order? The customer will be notified via email that their order has been completed.</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={completing || orderData?.order.status === 'COMPLETED'} variant='contained' onClick={() => handleComplete()}> Complete It! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={revertCancelModalOpen}
                onClose={handleRevertCancelModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={revertCancelModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to revert this order back to processing? The customer will NOT be notified about this change.</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={reverting} variant='contained' onClick={() => handleUncancel()}> Revert It! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            {/* refund modal */}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                    
                }}
                open={refundModalOpen}
                onClose={handleRefundModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={refundModalOpen}>
                    <Paper style={{ padding: '16px', width:'40vw', }}>
                        <Grid container direction='column' spacing={2}>
                        {refundingOrder ?
                            <Grid container>
                                <Grid item md={12}>
                                    <Typography align='center' variant='h3' style={{marginBottom:'20px'}}>PROCESSING...</Typography>
                                </Grid>
                                <Grid item md={5}></Grid>
                                <Grid item md={6}>
                                    <CircularProgress align='center' color='secondary' size={128} style={{marginLeft:'40px'}}/>
                                </Grid>
                            </Grid>
                        :
                            <Grid item>
                                <Typography align='center' variant='h5'>Order Refunds</Typography>
                                <Divider style={{marginTop:'8px', marginBottom:'12px'}}/>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button 
                                            variant={fullRefunding ? "outlined":"contained"}
                                            disabled={orderData.order.refund === 'PARTIAL'} 
                                            onClick={() => {
                                                setFullRefunding(true); 
                                                setPartialRefunding(false); 
                                                setPalletRefunding(false);
                                            }}
                                        >
                                            Full Refund
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button 
                                            variant={partialRefunding ? "outlined":"contained"} 
                                            onClick={() => {
                                                setFullRefunding(false); 
                                                setPartialRefunding(true); 
                                                setPalletRefunding(false);
                                            }}
                                        >
                                            Partial Refund
                                        </Button>
                                    </Grid>
                                    {"palletFee" in orderData.order.fees ?
                                    <Grid item>
                                        <Button disabled={ orderData.order.fees.palletFee.palletStatus === "REFUNDED"}
                                             variant={palletRefunding ? "outlined":"contained"} 
                                             onClick={() => {setFullRefunding(false); setPartialRefunding(false); setPalletRefunding(true);}}
                                        >
                                            Pallet Refund
                                        </Button>
                                    </Grid>
                                    :null}
                                </Grid>


                                {fullRefunding && 
                                <Grid container direction='column' spacing={2}>
                                    <Grid item>
                                        <Typography>Are you sure you want to refund this order? 
                                            This will refund the ENTIRE amount to the customer. <br></br>
                                            The customer will be notified via email that their order has been refunded.
                                        </Typography>
                                    </Grid>
                                </Grid>
                                }

                                {partialRefunding && 
                                <React.Fragment>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item>
                                            <Typography>Are you sure you want to partially refund some of this order? 
                                                This will refund the filled out amount to the customer. <br></br>
                                                The customer will be notified via email that their order has been partially refunded.
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Divider style={{marginBottom: '20px', marginTop: '20px'}}/>
                                    {orderData.order?.refund === 'PARTIAL' && 
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item>
                                            <Typography>This order has already had a partial refund, so more advanced options are unavailable
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    }
                                    {orderData.order?.refund !== 'PARTIAL' &&
                                    <Grid container >
                                        <Grid container >
                                            <Grid item xs={5}>
                                                <Typography style={{fontWeight: 600}}>
                                                    Product
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{fontWeight: 600}}>
                                                    Unit
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography style={{fontWeight: 600}}>
                                                    Quantity
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Typography style={{fontWeight: 600}}>
                                                    Total
                                                </Typography>
                                            </Grid>

                                            {/* map thorugh all products */}
                                            {orderData.order.products.map((product, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Grid container>
                                                            <Grid item xs={5}>
                                                                <Typography style={{lineHeight: 2}}>
                                                                    {`${product.productName} (${Object.values(product.variant.variantTypes).sort().join(' ')})`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3} >
                                                                <Typography style={{lineHeight: 2}}>{`${product.price.unit}`}</Typography>
                                                            </Grid>
                                                            <Grid xs={3}>
                                                                <Grid container>
                                                                    <TextField
                                                                        style={{width: '100px', lineHeight: 2}}
                                                                        type='number'
                                                                        value={qtyRefund[product.productName]}
                                                                        onChange={(e)=> { 
                                                                            if(!isNaN(e.target.value) && Number(e.target.value) <= product.qty && Number(e.target.value) > 0){
                                                                                setQtyRefund({...qtyRefund, [product.productName]: Number(e.target.value)}) 
                                                                                calcTaxRefund({...qtyRefund, [product.productName]: e.target.value})
                                                                            }
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <Typography style={{lineHeight: 2}}>
                                                                    {`$${(calcNewTotal()).toFixed(2)}`}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>}

                                    <Divider style={{marginBottom: '20px', marginTop: '20px'}}/>

                                    {/* refunded from product */}
                                    {orderData.order?.refund !== 'PARTIAL' &&
                                    <Grid container xs={12}>
                                        <Grid item xs={6}>
                                            <Typography style={{lineHeight: 2, marginLeft: '10px'}}>Product Refund:</Typography>
                                        </Grid>

                                        <Grid container justifyContent='flex-end' xs={6}>
                                            <Typography style={{lineHeight: 2, marginRight: '10px'}}>
                                                {`$${Number((orderData.order.fees.subTotal) - (calcNewTotal()).toFixed(2)).toFixed(2)}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>}
                                                
                                    {/* tax refund*/}
                                    {orderData.order?.refund !== 'PARTIAL' &&
                                    <Grid container xs={12}>
                                        <Grid item xs={6}>
                                            <Typography style={{lineHeight: 2, marginLeft: '10px'}}>GST Refund:</Typography>
                                        </Grid>

                                        <Grid container justifyContent='flex-end' xs={6}>
                                            <Typography style={{lineHeight: 2, marginRight: '10px'}}>
                                                {`$${(taxRefund.GST).toFixed(2)}`}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <Typography style={{lineHeight: 2, marginLeft: '10px'}}>PST Refund:</Typography>
                                        </Grid>

                                        <Grid container justifyContent='flex-end' xs={6}>
                                            <Typography style={{lineHeight: 2, marginRight: '10px'}}>
                                                {`$${(taxRefund.PST).toFixed(2)}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>}

                                    {/* custom refund */}
                                    <Grid container xs={12}>
                                        <Grid item xs={6}>
                                            <Typography style={{lineHeight: 2, marginTop: '15px', marginLeft: '10px'}}>Custom Refund:</Typography>
                                        </Grid>

                                        <Grid container justifyContent='flex-end' xs={6}>
                                            <TextField
                                                label="Refund Amount"
                                                type="number"
                                                value={refundAmount}
                                                onChange={(e) => setRefundAmount(Number(e.target.value))}
                                                onClick={(e) => { 
                                                    if(Number(e.target.value) < Number(0.00)){ 
                                                        setDebitCreditRefundAmount(Number(0.00).toFixed(2)) 
                                                    } else if(Number(e.target.value) !== Number(0.00)){
                                                        let total = !orderData.order.refund ? Number(orderData.payment.approved_money.amount) + Number(orderData.order.fees.giftCardApplied)
                                                        : (Number(orderData.order.total) + Number(orderData.order.fees.giftCardApplied)) - Number(orderData.order.refundAmount)

                                                        if(Number(e.target.value) > Number(total)){ setRefundAmount(Number(total).toFixed(2)) }
                                                        else{ setRefundAmount(Number(e.target.value).toFixed(2)) }
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    let total = Number(orderData.payment.approved_money.amount) + Number(orderData.order.fees.giftCardApplied)

                                                    if(Number(e.target.value) > Number(total)){ setRefundAmount(Number(total).toFixed(2)) }
                                                    else{ setRefundAmount(Number(e.target.value).toFixed(2)) }
                                                }}
                                                variant='outlined'
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Divider style={{marginBottom: '20px', marginTop: '20px'}}/>

                                    {/* total refund */}
                                    <Grid container xs={12}>
                                        {
                                            orderData.order.refund === 'PARTIAL' &&
                                            <React.Fragment>
                                            <Grid item xs={6}>
                                                <Typography style={{lineHeight: 2, fontWeight: 'bold',  marginTop: '10px', marginLeft: '10px'}}>Already Refunded:</Typography>
                                            </Grid>

                                            <Grid container justifyContent='flex-end' xs={6}>
                                                <Typography style={{lineHeight: 2, fontWeight: 'bold',  marginTop: '10px', marginRight: '10px'}}>
                                                    ${orderData.order.refundAmount}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography style={{lineHeight: 2, fontWeight: 'bold',  marginTop: '10px', marginLeft: '10px'}}>Remaining Refundable Amount:</Typography>
                                            </Grid>

                                            <Grid container justifyContent='flex-end' xs={6}>
                                                <Typography style={{lineHeight: 2, fontWeight: 'bold',  marginTop: '10px', marginRight: '10px'}}>
                                                    ${(Number(orderData.order.fees.total)-Number(orderData.order.refundAmount)).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            </React.Fragment>
                                        }

                                        <Grid item xs={6}>
                                            <Typography style={{lineHeight: 2, fontWeight: 'bold',  marginTop: '10px', marginLeft: '10px'}}>Total Refund:</Typography>
                                        </Grid>

                                        <Grid container justifyContent='flex-end' xs={6}>
                                            <Typography style={{lineHeight: 2, fontWeight: 'bold',  marginTop: '10px', marginRight: '10px'}}>
                                                ${Number(((orderData.order.fees.subTotal)) - (calcNewTotal()).toFixed(2) + Number(refundAmount) + Number(taxRefund.GST) + Number(taxRefund.PST)).toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    {(Number(orderData.payment.approved_money.amount) > 0) && (Number(orderData.order.fees.giftCardApplied) > 0)
                                    ?
                                    <React.Fragment>
                                        <Divider style={{margin:'10px'}}/>

                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography style={{marginBottom: '20px'}}>** This order was partially paid for with a gift card. Where would you like to send the refund? **</Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Debit/Credit"
                                                    type="number"
                                                    value={debitCreditRefundAmount}
                                                    onChange={(e) => { setDebitCreditRefundAmount(Number(e.target.value)) }}
                                                    onBlur={(e) => { handleCapRefundValues(e.target.value, 'debitCredit') }}
                                                    onClick={(e) => { 
                                                        if(Number(e.target.value) < Number(0.00)){ 
                                                            setDebitCreditRefundAmount(Number(0.00).toFixed(2)) 
                                                        } else if(Number(e.target.value) !== Number(0.00)){
                                                            handleCapRefundValues(e.target.value, 'debitCredit')
                                                        }
                                                    }}
                                                    variant='outlined'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                                    }}
                                                />
                                            </Grid>

                                            <Grid container justifyContent='flex-end' xs={6}>
                                                <TextField
                                                    label="Gift Card"
                                                    type="number"
                                                    value={giftCardRefundAmount}
                                                    onChange={(e) => setGiftCardRefundAmount(Number(e.target.value))}
                                                    onClick={(e) => { 
                                                        if(Number(e.target.value) < Number(0.00)){ 
                                                            setGiftCardRefundAmount(Number(0.00).toFixed(2)) 
                                                        } else if(Number(e.target.value) !== Number(0.00)){
                                                            handleCapRefundValues(e.target.value, 'giftCard')
                                                        }
                                                    }}
                                                    onBlur={(e) => {  if(Number(e.target.value) !== Number(0.00)){ handleCapRefundValues(e.target.value, 'giftCard') } }}
                                                    variant='outlined'
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </React.Fragment>

                                    : null }
                                </React.Fragment>
                                }

                                {palletRefunding && 
                                <React.Fragment>
                                    <Grid container direction='column' spacing={2}>
                                        <Grid item></Grid>
                                    </Grid>

                                    <Grid container direction='column' spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant='h6'>Refund Pallet Deposit</Typography>
                                            <Typography>Enter the number of pallets returned by the client for a full or partial refund.</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Total Pallet Fees: <b>${orderData.order.fees.palletFee.totalFee.toFixed(2)}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Total Pallets on Order: <b>{orderData.order.fees.palletFee.totalPallets}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Fee Refunded: <b>${(orderData.order.fees.palletFee.totalFee - orderData.order.fees.palletFee.remainingPalletFee).toFixed(2)}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Returned Pallets: <b>{orderData.order.fees.palletFee.totalPallets - orderData.order.fees.palletFee.remainingPallets}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Outstanding Fee: <b>${orderData.order.fees.palletFee.remainingPalletFee.toFixed(2)}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>Remaining Pallets: <b>{orderData.order.fees.palletFee.remainingPallets}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                label="Returned Pallets"
                                                size='small'
                                                required
                                                type='number'
                                                value={numPallets}
                                                onChange={(event) => {setNumPallets(event.target.value);
                                                    setPalletRefundAmount(
                                                        (orderData.order.fees.palletFee.totalFee * (event.target.value / orderData.order.fees.palletFee.totalPallets)).toFixed(2)
                                                    );
                                                }}
                                                fullWidth>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                                }
                            </Grid>
                        }
                            <Divider style={{margin:'10px'}}/>

                            <Grid container justifyContent='flex-end'>
                                <Button 
                                    disabled={refundingOrder || (!fullRefunding && !palletRefunding && !partialRefunding) ||

                                        (palletRefunding && (numPallets <= 0 || numPallets > Number(orderData.order.fees.palletFee.remainingPallets))) ||
                                        (partialRefunding && (Number(((orderData.order.fees.subTotal)) - calcNewTotal() + Number(refundAmount)).toFixed(2) <= 0.00 || 

                                        (!orderData.order.fees.giftCardApplied > 0 && (refundAmount > Number(orderData.payment.approved_money.amount))) ||
                                        (orderData.order.fees.giftCardApplied > 0 && (refundAmount > (Number(orderData.payment.approved_money.amount) + Number(orderData.order.fees.giftCardApplied)))) || 
                                        ((orderData.order.fees.giftCardApplied > 0 && Number(orderData.payment.approved_money.amount) > 0) && Number(Number(giftCardRefundAmount) + Number(debitCreditRefundAmount)).toFixed(2) !== Number(((orderData.order.fees.subTotal)) - (calcNewTotal()).toFixed(2) + Number(refundAmount) + Number(taxRefund.GST) + Number(taxRefund.PST)).toFixed(2))
                                        )) ||
                                        (partialRefunding && (refundAmount > (Number(orderData.order.fees.total) - Number(orderData.order.refundAmount))) ) ||
                                        (partialRefunding && orderData.order.fees.giftCardApplied > 0 && (Number(giftCardRefundAmount) > Number(orderData.order.fees.giftCardApplied)))                                  
                                    } 
                                    variant='contained' 
                                    style={{marginRight: '5px'}}
                                    onClick={handleAreYouSureOpen}
                                > 
                                    Refund It! 
                                </Button>

                                <Button 
                                    variant="contained" 
                                    disabled={refundingOrder} 
                                    style={{color:'white', backgroundColor:'red'}} 
                                    onClick={handleRefundModalClose}
                                >
                                    Cancel
                                </Button>
                            </Grid>
       
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={revertModalOpen}
                onClose={handleRevertModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={revertModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to revert this order back to processing? The customer will NOT be notified about this change.</Typography>
                            </Grid>

                            <Grid item>
                                <Button disabled={reverting || orderData?.order.status === 'PROCESSING'} variant='contained' onClick={() => handleRevert()}> Revert It! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>
            
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={resendModalOpen}
                onClose={handleResendModalClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={resendModalOpen}>
                    <Paper style={{ padding: '16px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography>Are you sure you want to resend the customer their order confirmation email?</Typography>
                            </Grid>

                            <Grid item>
                                <Button variant='contained' onClick={() => handleResendEmail()}> Resend It! </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            {
                'palletFee' in orderData.order.fees &&
                <PalletModal
                    error={palletError}
                    errorMessage={errorMessage}
                    loading={returning}
                    orderData={orderData.order}
                    open={palletModalOpen}
                    onClose={handlePalletModalClose}
                    onConfirm={(event, returnedPallets) => handlePallets(event, returnedPallets)} />
            }

            <Snackbar
                open={!_.isEqual(orderData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={ () => {cancelUpdate()}} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' type='submit'>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                }
            />
        </React.Fragment>
        </form >
}

//Map slice of global state to props
const mapStateToProps = (state) => {
    return {
        // state refers to global state/ redux state (acts somewhat like singleton) 
        // "products" is the name given in index.js to the state of our reducer
        // the props: featuredProducts and loading come from the reducer productsReducer.js
        cart: state.cart.cart, 
        cartFees: state.cart.cartFees,
        checkoutForm: state.checkout.checkoutForm, 
        coupons: state.cart.coupons, 
        couponError: state.cart.couponError, 
        customerAddress: state.cart.customerAddress,
        customerLocation: state.cart.customerLocation,
        searchboxCoordinates: state.cart.searchboxCoordinates,
        distance: state.cart.distance,
        validLocation: state.cart.validLocation,
        validAddress: state.cart.validAddress, 
        validDeliveryDate: state.cart.validDeliveryDate,
        alertActive: state.cart.alertActive,
        alertMsg: state.cart.alertMsg,
        alertSev: state.cart.alertSev, 
        taxesLoading: state.cart.taxesLoading, 
        error: state.cart.error,
        errorMsg: state.cart.errorMsg,
    };
}

//map our dispatch functions to props
const mapDispatchToProps = dispatch => {
    return {
        setSubtotal: () => dispatch(actionCreators.setSubtotal()),
        calcShipping: () => dispatch(actionCreators.calcShipping()),
        getTax: () => dispatch(actionCreators.getTax()),
        getSales: (saleList, include) => dispatch(actionCreators.getSales(saleList, include)),
        setDistance: (coordinates, inputType, address) => dispatch(actionCreators.setDistance(coordinates, inputType, address)),
        addToCart: (product, currentVariant, qty, shippingMethod, price) => dispatch(actionCreators.addToCart(product, currentVariant, qty, shippingMethod, price)),
        resetCart: () => dispatch(actionCreators.resetCart()),
        removeFromCart: (index) => dispatch(actionCreators.removeFromCart(index)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));