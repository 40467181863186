import { Typography, Paper, Grid, Select, MenuItem, TextField } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useAuth } from '../../../../providers/AuthContext';
import _ from 'lodash';

const libraries = ["places"];

const DeliveryDetails = (props) => {

    //Google Maps API setup
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });
    const { roles } = useAuth();

    const mapContainerStyle = {
        width: '100%',
        height: '400px',
    };

    //Error checking on googlemaps api
    if (loadError) return "Error";
    if (!isLoaded) return "";

    //const deliverables = (!props.loading && props.order) ? props.order.order.products.filter(product => product.shippingMethod === 'Delivery').length > 0 : false
    //to account for custom orders which may not have products listed but still be a delivery
    const deliverables = props.order.customer.destination.location !== "" && !_.isUndefined(props.order.customer.destination.location)

    return (
        props.loading ? <Skeleton></Skeleton> :

            (props.order && <Paper elevation={2} style={{ padding: "16px", margin: "8px", color: '#707070' }}>
                <Grid container spacing={2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography style={{ lineHeight: 2 }} noWrap variant='h6'>Delivery Details</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Requested Date:</Typography>
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <DatePicker
                                disabled={props.order.order.status === "COMPLETED" || !roles.includes('Create|Update|Delete Orders')}
                                style={{ maxWidth: '100px' }}
                                value={moment(props.order.order.deliverDate).format('MMM D YYYY')}
                                onChange={(event) => props.updateOrder({ ...props.order, order: { ...props.order.order, deliverDate: new Date(event).toUTCString() } })} />
                            <Select
                                disabled={props.order.order.status === "COMPLETED" || !roles.includes('Create|Update|Delete Orders')}
                                value={props.order.order.deliverTime}
                                onChange={(event) => props.updateOrder({ ...props.order, order: { ...props.order.order, deliverTime: event.target.value } })}>
                                <MenuItem value=''></MenuItem>
                                <MenuItem value='AM'>AM</MenuItem>
                                <MenuItem value='PM'>PM</MenuItem>
                            </Select>
                        </Grid>
                        {deliverables && <Grid item xs={6}>
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Delivery Location:</Typography>
                        </Grid>}
                        <Grid item xs={6}>
                            {(props.order.customer.destination.location && <Typography style={{ lineHeight: 2 }} variant='body1' align='right'>{props.order.customer.destination.location}</Typography>)}
                        </Grid>
                        {deliverables && <Grid style={{ textAlign: 'right' }} item xs={12}>
                            <a target="_blank" rel="noopener noreferrer" href={"https://www.google.ca/maps?q=" + props.order.customer.destination?.coordinates.lat + ',' + props.order.customer.destination?.coordinates.lng}>View on Google Maps</a>
                        </Grid>}
                        {(props.order.order.notes && <Grid item xs={6} >
                            <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Notes:</Typography>
                        </Grid>)}
                        <Grid item xs={6}>
                            {!props.edit?<Typography style={{ lineHeight: 2 }} variant='body1' align='right'>{props.order.order.notes}</Typography>:
                            <TextField
                                fullWidth
                                multiline
                                variant='outlined'
                                label="Notes"
                                minRows={3}
                                style={{marginBottom:'10px'}}
                                value={props.order.order.notes}
                                onChange={(event) => props.updateOrder({ ...props.order, order: { ...props.order.order, notes: event.target.value } })} />}

                        </Grid>
                        {props.order.customer.destination.location.length > 0 &&
                        <Grid item xs={12}>
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                mapTypeId='satellite'
                                center={props.order.customer.destination?.coordinates}
                                zoom={16}
                            >
                                <Marker position={props.order.customer.destination?.coordinates} />
                            </GoogleMap>
                        </Grid> }
                    </Grid>
                </Grid>
            </Paper>)
    )
}
export default DeliveryDetails