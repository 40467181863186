import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { firestore, functions } from '../../../firebase/firebase';
import _ from 'lodash';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab'

import InvoiceConfirmationModal from './InvoiceConfirmationModal';

// import AggregatesPDF from '../../components/Invoice/PDFs/AggregatesPDF';
// import CartageMaterialPDF from '../../components/Invoice/PDFs/CartageMaterialPDF';
// import DailyWSubtotalsPDF from '../../components/Invoice/PDFs/DailyWSubtotalsPDF';
// import EquipmentWSubtotalsPDF from '../../components/Invoice/PDFs/EquipmentWSubtotalsPDF';
// import GeeTeePDF from '../../components/Invoice/PDFs/GeeTeePDF';
// import GeneralPDF from '../../components/Invoice/PDFs/GeneralPDF';
// import HourlyPDF from '../../components/Invoice/PDFs/HourlyPDF';
// import JobWSubtotalsPDF from '../../components/Invoice/PDFs/JobWSubtotalsPDF';
// import MaterialWSubtotalsPDF from '../../components/Invoice/PDFs/MaterialWSubtotalsPDF';
// import SnowContractPDF from '../../components/Invoice/PDFs/SnowContractPDF';

//general pdfs
import SortByRatePDF from '../../components/Invoice/PDFs/GeneralPDFS/SortByRatePDF';
import SortByEquipmentPDF from '../../components/Invoice/PDFs/GeneralPDFS/SortByEquipmentPDF';
import SortByDatePDF from '../../components/Invoice/PDFs/GeneralPDFS/SortByDatePDF';
import NoSortPDF from '../../components/Invoice/PDFs/GeneralPDFS/NoSortPDF';
import SortByDestinationPDF from '../../components/Invoice/PDFs/GeneralPDFS/SortByDestinationPDF';

const useStyles = makeStyles((theme) => ({
    tableText: {
        fontSize: 14,
        //marginBottom: '1px',
        //padding: '20px',
        paddingRight: '15px',
        paddingLeft: '15px',
    },
    tableTextBold: {
        fontSize: 10,
        //marginBottom: '1px',
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        //marginTop: '5px'
    },
    tableTextBoldUnderline: {
        fontSize: 24,
        fontFamily: 'Helvetica-Bold',
        fontWeight: 'bold',
        textDecoration: 'underline',
        paddingRight: '15px',
        paddingLeft: '15px',
        //padding: '15px',
    },
}));

function InvoicePrint(props) {

    const classes = useStyles();

    const [ticketData] = useState(JSON.parse(props.location.state.ticketData))
    const [invoiceDetails] = useState(JSON.parse(props.location.state.invoiceDetails))
    const [invoiceFilter] = useState(JSON.parse(props.location.state.invoiceFilter))
    const [invoiceType] = useState(JSON.parse(props.location.state.invoiceType))
    const [equipment] = useState(JSON.parse(props.location.state.equipment))
    const [customers] = useState(JSON.parse(props.location.state.customers))
    //const [customerJobs] = useState(JSON.parse(props.location.state.customerJobs))
    const [rates] = useState(JSON.parse(props.location.state.rates))

    const handleChangeTicketsInvoiced = () => {
        let batch = firestore.batch();

        ticketData.forEach((ticket) => {
            if(_.isEmpty(ticket.invoicePayDate)){
                const docRef = firestore.collection('deliveries').doc(ticket.docId);
                batch.update(docRef, { invoicePayDate: invoiceFilter.reportScope !== 'postedOnly' ? moment().valueOf() : null });
            }
        })

        batch.commit()
        .then(() => { console.log('updated tickets') })
        .catch((e) => { console.warn(e) })
    }

    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
          reader.onloadend = () => {
            resolve(reader.result.substr(reader.result.indexOf(',') + 1));
          };
        });
    };

    //get invoice and data needed for email header and body
    const handleEmailInvoice = async (blobPDF, dates, customerName, customerEmail) => {
        await blobToBase64(blobPDF.blob).then((res) => {
            const sendInvoiceEmail = functions.httpsCallable('sendCustomerInvoiceEmail');
            sendInvoiceEmail({
                invoice: res,
                customerName: customerName,
                customerEmail: customerEmail,
                dates: dates,
                sandbox: process.env.NODE_ENV === 'development',
            })
              .then(() => {
                console.log('sent customer invoice')
              })
              .catch((error) => {
                console.warn(error)
              });
        })

    }

    function renderSwitch(param) {
        switch(param) {
        // case 'geeTeeHoldings':
        // case 'jobWithSubtotals':
        // case 'aggregates':
        // case 'materialWithSubtotals':
        // case 'snowContract':
        // case 'hourly':
        case 'rate':
            return <SortByRatePDF 
                ticketData={ticketData}
                invoiceDetails={invoiceDetails}
                invoiceFilter={invoiceFilter}
                invoiceType={invoiceType}
                equipment={equipment}
                customers={customers}
                //customerJobs={customerJobs}
                rates={rates}
                columns={columns}
                handleEmailInvoice={handleEmailInvoice}
            />
        // case 'dailyWithSubtotals':
        case 'date':
            return <SortByDatePDF 
                ticketData={ticketData}
                invoiceDetails={invoiceDetails}
                invoiceFilter={invoiceFilter}
                invoiceType={invoiceType}
                equipment={equipment}
                customers={customers}
                //customerJobs={customerJobs}
                rates={rates}
                columns={columns}
                handleEmailInvoice={handleEmailInvoice}
            />
        // case 'equipmentWIthSubtotals':
        case 'unit':
            return <SortByEquipmentPDF 
                ticketData={ticketData}
                invoiceDetails={invoiceDetails}
                invoiceFilter={invoiceFilter}
                invoiceType={invoiceType}
                equipment={equipment}
                customers={customers}
                //customerJobs={customerJobs}
                rates={rates}
                columns={columns}
                handleEmailInvoice={handleEmailInvoice}
            />
        // case 'cartageAndMaterial': 
        // case 'general'
        case 'destination':
        return <SortByDestinationPDF 
            ticketData={ticketData}
            invoiceDetails={invoiceDetails}
            invoiceFilter={invoiceFilter}
            invoiceType={invoiceType}
            equipment={equipment}
            customers={customers}
            //customerJobs={customerJobs}
            rates={rates}
            columns={columns}
            handleEmailInvoice={handleEmailInvoice}
        />
        case 'none':
            return <NoSortPDF 
                ticketData={ticketData}
                invoiceDetails={invoiceDetails}
                invoiceFilter={invoiceFilter}
                invoiceType={invoiceType}
                equipment={equipment}
                customers={customers}
                //customerJobs={customerJobs}
                rates={rates}
                columns={columns}
                handleEmailInvoice={handleEmailInvoice}
            />
        default:
            console.warn('Was not able to load proper PDF file.')    
        }
    } 

    const getColumns = (invoiceType, combinedMaterialCartageSales) => {
        switch(invoiceType) {
            // case 'geeTeeHoldings':
            // case 'jobWithSubtotals':
            // case 'aggregates':
            // case 'materialWithSubtotals':
            // case 'snowContract':
            // case 'hourly':
            case 'rate':
                return combinedMaterialCartageSales 
                    ? [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit #', width: 0, field: 'unitNumber'}, 
                        {header: 'Ticket #', width: 0, field: 'ticket'}, {header: 'Material', width: 0, field: 'material'}, 
                        {header: 'Shipped', width: 0, field: 'cartageSaleUnitsWPer'}, {header: 'Rate', width: 0, field: 'rateTotal'}, 
                        {header: 'Sales', width: 0, field: 'cartageSalesTotal'}, ] 

                    : [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit', width: 0, field: 'unitNumber'}, {header: 'Ticket #', width: 0, field: 'ticket'}, 
                        {header: 'Material', width: 0, field: 'material'}, {header: 'Units', width: 0, field: 'materialUnits'}, 
                        {header: 'Rate', width: 0, field: 'materialSalesRate'}, {header: 'Material', width: 0, field: 'materialSalesTotal'}, 
                        {header: 'Units', width: 0, field: 'cartageSaleUnits'}, {header: 'Rate', width: 0, field: 'cartageSaleRate'}, 
                        {header: 'Cartage', width: 0, field: 'cartageSalesTotal'}, {header: 'Total', width: 0, field: 'total'},] 
                // case 'dailyWithSubtotals':
                case 'date':
                return combinedMaterialCartageSales 
                    ? [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit #', width: 0, field: 'unitNumber'}, 
                        {header: 'Ticket #', width: 0, field: 'ticket'}, {header: 'Material', width: 0, field: 'material'}, 
                        {header: 'Shipped', width: 0, field: 'cartageSaleUnitsWPer'}, {header: 'Rate', width: 0, field: 'rateTotal'}, 
                        {header: 'Sales', width: 0, field: 'total'}, ] 

                    : [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit', width: 0, field: 'unitNumber'}, {header: 'Ticket #', width: 0, field: 'ticket'}, 
                        {header: 'Material', width: 0, field: 'material'}, {header: 'Units', width: 0, field: 'materialUnits'}, 
                        {header: 'Rate', width: 0, field: 'materialSalesRate'}, {header: 'Material', width: 0, field: 'materialSalesTotal'}, 
                        {header: 'Units', width: 0, field: 'cartageSaleUnits'}, {header: 'Rate', width: 0, field: 'cartageSaleRate'}, 
                        {header: 'Cartage', width: 0, field: 'cartageSalesTotal'}, {header: 'Total', width: 0, field: 'total'},] 
            //case 'equipmentWIthSubtotals':
            case 'unit':
                return combinedMaterialCartageSales 
                    ? [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit #', width: 0, field: 'unitNumber'}, 
                        {header: 'Ticket #', width: 0, field: 'ticket'}, {header: 'Material', width: 0, field: 'material'}, 
                        {header: 'Shipped', width: 0, field: 'cartageSaleUnitsWPer'}, {header: 'Rate', width: 0, field: 'rateTotal'}, 
                        {header: 'Sales', width: 0, field: 'total'}, ] 

                    : [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit', width: 0, field: 'unitNumber'}, {header: 'Ticket #', width: 0, field: 'ticket'}, 
                        {header: 'Material', width: 0, field: 'material'}, {header: 'Units', width: 0, field: 'materialUnits'}, 
                        {header: 'Rate', width: 0, field: 'materialSalesRate'}, {header: 'Material', width: 0, field: 'materialSalesTotal'}, 
                        {header: 'Units', width: 0, field: 'cartageSaleUnits'}, {header: 'Rate', width: 0, field: 'cartageSaleRate'}, 
                        {header: 'Cartage', width: 0, field: 'cartageSalesTotal'}, {header: 'Total', width: 0, field: 'total'},] 
            // case 'cartageAndMaterial':
            // case 'general':
            case 'none':
                return combinedMaterialCartageSales 
                    ? [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit #', width: 0, field: 'unitNumber'}, 
                        {header: 'Ticket #', width: 0, field: 'ticket'}, {header: 'Material', width: 0, field: 'material'}, 
                        {header: 'Shipped', width: 0, field: 'cartageSaleUnitsWPer'}, {header: 'Rate', width: 0, field: 'rateTotal'}, 
                        {header: 'Sales', width: 0, field: 'total'}, ] 

                    : [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit', width: 0, field: 'unitNumber'}, {header: 'Ticket #', width: 0, field: 'ticket'}, 
                        {header: 'Material', width: 0, field: 'material'}, {header: 'Units', width: 0, field: 'materialUnits'}, 
                        {header: 'Rate', width: 0, field: 'materialSalesRate'}, {header: 'Material', width: 0, field: 'materialSalesTotal'}, 
                        {header: 'Units', width: 0, field: 'cartageSaleUnits'}, {header: 'Rate', width: 0, field: 'cartageSaleRate'}, 
                        {header: 'Cartage', width: 0, field: 'cartageSalesTotal'}, {header: 'Total', width: 0, field: 'total'},] 
                case 'destination':
                return combinedMaterialCartageSales 
                    ? [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit #', width: 0, field: 'unitNumber'}, 
                        {header: 'Ticket #', width: 0, field: 'ticket'}, {header: 'Material', width: 0, field: 'material'}, 
                        {header: 'Shipped', width: 0, field: 'cartageSaleUnitsWPer'}, {header: 'Rate', width: 0, field: 'rateTotal'}, 
                        {header: 'Sales', width: 0, field: 'total'}, ] 

                    : [ {header: 'Date', width: 0, field: 'date'}, {header: 'Unit', width: 0, field: 'unitNumber'}, {header: 'Ticket #', width: 0, field: 'ticket'}, 
                        {header: 'Material', width: 0, field: 'material'}, {header: 'Units', width: 0, field: 'materialUnits'}, 
                        {header: 'Rate', width: 0, field: 'materialSalesRate'}, {header: 'Material', width: 0, field: 'materialSalesTotal'}, 
                        {header: 'Units', width: 0, field: 'cartageSaleUnits'}, {header: 'Rate', width: 0, field: 'cartageSaleRate'}, 
                        {header: 'Cartage', width: 0, field: 'cartageSalesTotal'}, {header: 'Total', width: 0, field: 'total'},] 
            default:
                console.warn('Was not able to load proper PDF file.')    
                return null;
            }
    }

    const [loading, setLoading] = useState(true)
    const [columns, setColumns] = useState(getColumns(invoiceType, invoiceDetails.combinedMaterialCartageSales))


    const invisTableCallback = useCallback((el) => {
        if (el !== null) {
            let columnsContainer = _.cloneDeep(columns)
            let columnArray = [...el?.tHead?.children[0]?.children];
            let totalContainer = columnArray.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.offsetWidth)), 0,)

            for (const [index, property] of columnArray.entries()) {
                if(columnsContainer.length !== columnArray.length){ throw new Error('columns length does not match.') }
                columnsContainer[index].width = Number((property.offsetWidth / totalContainer) * 100)
            }

            setColumns(columnsContainer)
            setLoading(false)
            el.style.display = 'none';
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            {loading 
            ?
                <Skeleton></Skeleton>
            :
            <>
                <InvoiceConfirmationModal 
                    confirmFunction={handleChangeTicketsInvoiced}
                    reportScope={invoiceFilter.reportScope}
                    invoiceDetails={invoiceDetails}
                />

                {renderSwitch(invoiceType)}
            </>
            }

            {/* invisible table used for css structure  */}
            <table style={{width: '1000px', margin: 'auto', visibility: 'hidden'}} ref={invisTableCallback} >
                <thead>
                    <tr>
                        {columns.map((column, columnIndex) => (
                            <td key={columnIndex} className={classes.tableTextBoldUnderline}>
                                {column.header}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {ticketData.map((ticket, ticketIndex) => (
                    <tr key={ticketIndex}>
                    {columns.map((column, columnIndex) => {
                            switch(column.field) {
                                //ticket fields
                                case 'date':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {moment(ticket.date).format('DD-MMM-YYYY')} </td>
                                case 'unitNumber':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.units.map((unit) => { return ( equipment?.find(x=> x?.docId === unit)?.unitNumber ) })} </td>                        
                                case 'ticket':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.ticket} </td>
                                case 'material':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {ticket.material} </td>
                                case 'materialUnits':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {Number(ticket.materialUnits).toFixed(3)} </td>                                    
                                case 'materialSalesRate':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.materialSalesRate).toFixed(2)}`} </td>
                                case 'materialSalesTotal':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.materialSalesTotal).toFixed(2)}`} </td>                                    
                                case 'cartageSaleUnitsWPer':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`${Number(ticket.cartageSaleUnits).toFixed(3)} ${ticket.cartageSalesPer || ''}`} </td>
                                case 'cartageSaleUnits':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {Number(ticket.cartageSaleUnits).toFixed(3)} </td>
                                case 'cartageSaleRate':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.cartageSaleRate).toFixed(2)}`} </td>
                                case 'cartageSalesTotal':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(ticket.cartageSalesTotal).toFixed(2)}`} </td>
                                case 'rateTotal':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex} > {`$${Number(Number(ticket.cartageSaleRate || 0) + Number(ticket.materialSalesRate || 0)).toFixed(2)}`} </td>   
                                case 'total':
                                    return <td className={classes.tableText} key={ticketIndex + columnIndex}> {`$${Number(Number(ticket.materialSalesTotal || 0) + Number(ticket.cartageSalesTotal || 0)).toFixed(2)}`} </td>       
                                default:
                                    console.warn('Field not available within render switch.')  
                                    return null;
                            }
                    })}
                    </tr>
                ))}
                {/* totals */}
                <tr>
                    {columns.map((column, columnIndex) => {
                        switch(column.field) {
                            case 'date':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'unitNumber':
                                return <td className={classes.tableText} key={columnIndex} />       
                            case 'ticket':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'material':
                                return <td className={classes.tableText} key={columnIndex} />
                            case 'materialUnits':
                                return <td className={classes.tableTextBold} key={columnIndex}> {ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialUnits)).toFixed(3), 0,)} </td>                                    
                            case 'materialSalesRate':
                                return <td className={classes.tableTextBold} key={columnIndex} />
                            case 'materialSalesTotal':
                                return <td className={classes.tableTextBold} key={columnIndex}> {`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.materialSalesTotal)).toFixed(2), 0,)}`} </td>                                    
                            case 'cartageSaleUnitsWPer':
                                return <td className={classes.tableTextBold} key={columnIndex}> {ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits)).toFixed(3), 0,)} </td>
                            case 'cartageSaleUnits':
                                return <td className={classes.tableTextBold} key={columnIndex}> {ticketData.reduce((accumulator, currentValue) => Number(Number(accumulator) + Number(currentValue.cartageSaleUnits)).toFixed(3), 0,)} </td>
                            case 'cartageSaleRate':
                                return <td className={classes.tableTextBold} key={columnIndex} />
                            case 'cartageSalesTotal':
                                return <td className={classes.tableTextBold} key={columnIndex}>{`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + Number(currentValue.cartageSalesTotal)).toFixed(2), 0,)}`} </td>
                            case 'rateTotal':
                                return <td className={classes.tableTextBold} key={columnIndex} />
                            case 'total':
                                return <td className={classes.tableTextBold} key={columnIndex}>{`$${ticketData.reduce((accumulator, currentValue) => (Number(accumulator) + (Number(currentValue.materialSalesTotal || 0) + Number(currentValue.cartageSalesTotal || 0))).toFixed(2), 0,)}`}</td>     
                            default:
                                console.warn('Field not available within render switch.')  
                                return null;
                        }
                    })}
                </tr>
                </tbody>
            </table>
        </>
    )
}

export default withRouter(InvoicePrint)