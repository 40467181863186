import React, { useState, useEffect } from 'react';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import { firestore } from '../../../firebase/firebase';
import { TableIcons } from '../../../constants/tableIcons';
import { makeStyles } from '@material-ui/core';
import { useUser } from '../../../providers/UserContext';
import { useTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Paper, AppBar, Tabs, Tab, useMediaQuery, Box, Grid } from '@material-ui/core';
//import _ from 'lodash'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AssignmentIcon from '@material-ui/icons/Assignment';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%'
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        backgroundColor: '#FAF9F6'
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
        
    },
}));

const UserDocumentTable = (props) => {
    const { getUsers, users } = useUser();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(JSON.parse(sessionStorage.getItem('userInfoDocumentsTab'))|| 1);
    const [documentData, setDocumentData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [userData] = useState(props.userData || [])

    useEffect(() => {
    }, [smallScreen]);


    useEffect(() => {
        //only call get users once
        if(users.length <= 0){
        getUsers();
        }
        let unsubscribe = firestore.collection('documents').where('uid', '==', userData.uuid).where('appearsInEmployeePage', '==', true).orderBy('date').onSnapshot(querySnapshot => {

            //process & store timesheet data
            let documentResponse = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    'docId': doc.id,
                    'dateSubmit' : doc.data().date.toDate().toDateString(),
                    'timeSubmit': doc.data().date.toDate().toLocaleTimeString(),
                    'fullTime' : moment(doc.data().date.toDate().toDateString() +' '+ doc.data().date.toDate().toLocaleTimeString()).format('MMM Do YYYY h:mm a'),
                    
                }
            })
            setDocumentData(documentResponse)
            setLoading(false);
        })

        return () => { unsubscribe();}
    }, [getUsers, userData]) // eslint-disable-line react-hooks/exhaustive-deps

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
    
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box style={{margin:'20px'}}>
                {children}
              </Box>
            )}
          </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem("userInfoDocumentsTab", JSON.stringify(newValue))
    };

    const documentClickHandler = (path) => {
        props.history.push({
            pathname: '/dashboard/reporting/documents',
            search: "?" + new URLSearchParams({ docId: path }).toString(),

        })
    }

    const abstractTable = (
        <Grid>
             <MaterialTable
                columns={[
                    { title: 'Document', field: 'name' },
                    { title: 'Date Submit', field: 'fullTime', orderBy:'desc' },
                ]}
                data={documentData.filter(doc=> doc.name === 'Employee Driver Abstract')}
                title={`${userData.displayName.substring(0, userData.displayName.indexOf(" "))}'s Employee Driver Abstracts`}
                isLoading={loading}
                icons={TableIcons}
                actions={[
                    {
                        icon: OpenInNewIcon,
                        tooltip: 'View Document',
                        onClick: (event, rowData) => documentClickHandler(rowData['docId']),
                    },
                ]}
                options={{
                    pageSizeOptions: [5, 15, 20, 50, 100],
                    doubleHorizontalScroll: true,
                    pageSize: 5,
                    toolbar: true,
                    paging: true,
                    sorting: true,
                    thirdSortClick: false,
                    rowStyle: rowData => {
                        if (rowData.timesheetID === "Unsubmitted") {
                            return { backgroundColor: '#fff2f4' };
                        }

                        return {};
                    }
                }}
            />
        </Grid>
    )

    const miscTable = (
        <Grid>
             <MaterialTable
                columns={[
                    { title: 'Document', field: 'name' },
                    { title: 'Date Submit', field: 'fullTime', orderBy:'desc' },
                ]}
                data={documentData.filter(doc=> doc.name !== 'Employee Driver Abstract')}
                title={`${userData.displayName.substring(0, userData.displayName.indexOf(" "))}'s Documents`}
                isLoading={loading}
                icons={TableIcons}
                actions={[
                    {
                        icon: OpenInNewIcon,
                        tooltip: 'View Document',
                        onClick: (event, rowData) => documentClickHandler(rowData['docId']),
                    },
                ]}
                options={{
                    pageSizeOptions: [5, 15, 20, 50, 100],
                    doubleHorizontalScroll: true,
                    pageSize: 5,
                    toolbar: true,
                    paging: true,
                    sorting: true,
                    thirdSortClick: false,
                    rowStyle: rowData => {
                        if (rowData.timesheetID === "Unsubmitted") {
                            return { backgroundColor: '#fff2f4' };
                        }
                        return {};
                    }
                }}
            />
        </Grid>
    )

    return (
        <div className={classes.content}>
                <Paper className={classes.paper}>
                    <div className={classes.content}>
                        <AppBar className={classes.tabBar} position="static" elevation={0} >
                            <Tabs value={value} onChange={handleChange} 
                                centered={true} variant={smallScreen ? "scrollable" : "scrollable"} 
                                scrollButtons={"on"} 
                                style={{margin:'0px'}}
                            >
                                <Tab label={<div><AssignmentIcon style={{verticalAlign: 'middle'}} /> Abstract </div>} {...a11yProps(0)} />
                                <Tab label={<div><MoreHorizIcon style={{verticalAlign: 'middle'}} /> Misc </div>} {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                    </div>

                    <TabPanel value={value} index={0}>
                        {abstractTable}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {miscTable}
                    </TabPanel>
                </Paper>
        </div>
    )
}

export default withRouter(UserDocumentTable)