import React, {  useEffect, useState } from 'react';
import { Grid, makeStyles, Box, Dialog, DialogActions, DialogContent, DialogTitle, Button, DialogContentText,
    AppBar, Tabs, Tab, useMediaQuery} from '@material-ui/core';
import { withRouter } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import OfficeSheets from '../../components/Approval/OfficeSheets';
import ShopSheets from '../../components/Approval/ShopSheets';
import ConstructionSheets from '../../components/Approval/ConstructionSheets';
import TruckDriverSheets from '../../components/Approval/TruckDriverSheets';
import GrainSheets from '../../components/Approval/GrainSheets';
import SnowSheets from '../../components/Approval/SnowSheets';
import PropTypes from 'prop-types';
import { useAuth } from '../../../providers/AuthContext';
import {firestore} from '../../../firebase/firebase';

const useStyles = makeStyles((theme) => ({

    card: {
      padding: theme.spacing(2),
      width: '100%',
      height: '100%',
    },
  
    content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      maxWidth: '1000px',
      margin: 'auto',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        zIndex: '998'
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
    },
    grid: {
      marginTop: theme.spacing(2),
      maxWidth: '1000px',
    },
  
    table: {
      width: '100%',
      maxWidth: '1000px',
      display: 'flex',
      marginTop: '20px',
    },
  
    tile: {
      width: '300px',
      height: '300px',
    },
  
    tileContent: {
      display: 'flex',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: theme.spacing(4),
    },
  
    tileIcon: {
      color: 'rgba(0, 0, 0, 0.54)',
      width: '100px',
      height: '100px',
    },
  
    tileText: {
      fontSize: '1.10rem',
    },
    btn: {
      marginBottom: '20px',
      marginTop: '20px',
  },
  }));

const ApprovalHome = (props) => {
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
    
        return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box style={{margin:'20px'}}>
                {children}
            </Box>
            )}
        </div>
        );
    }
    
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };
    
    function a11yProps(index) {
        return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    const [value, setValue] = useState(JSON.parse(sessionStorage.getItem("approvalPageValue")) || 0);
    const { roles } = useAuth();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [officeAmount, setOfficeAmount] = React.useState(0);
    const [shopAmount, setShopAmount] = React.useState(0);
    const [gravelAmount, setGravelAmount] = React.useState(0);
    const [constructionAmount, setConstructionAmount] = React.useState(0);
    const [grainAmount, setGrainAmount] = React.useState(0);
    const [snowAmount, setSnowAmount] = React.useState(0);
    const [approveAllOpen, setApproveAllOpen] = React.useState(false);

    const [checkedSheets, setCheckedSheets] = React.useState("approvalCheckedSheets" in sessionStorage ? JSON.parse(sessionStorage.getItem("approvalCheckedSheets")) : []);

    const [reload, setReload] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem('approvalPageValue', newValue)
    }

    const handleApproveAllOpen = () => {
        setApproveAllOpen(true);
    }

    const handleApproveAllClose = () => {
        setApproveAllOpen(false);
    }

  useEffect(() => {
  }, [smallScreen]);

  useEffect(() => {
    let unsubArray = []

    if(roles.includes('Approve Office Timesheets')){
      let officeUnsubscibe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Office').onSnapshot((querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => {

          console.log('updated from listener on approval.js office')
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        unsubArray.push(officeUnsubscibe)
        setOfficeAmount(response.length)
      })
    }
  
    if(roles.includes('Approve Shop Timesheets')){
      let shopUnsubscribe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Shop').onSnapshot((querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => {
          console.log('updated from listener on approval.js shop')

          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        unsubArray.push(shopUnsubscribe)
        setShopAmount(response.length)
      })
    }

    if(roles.includes('Approve Gravel Timesheets')){
      console.log('updated from listener on approval.js Gravel')
      
      let gravelUnsubscribe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Gravel').onSnapshot((querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        unsubArray.push(gravelUnsubscribe)
        setGravelAmount(response.length)
      })
    }

    if(roles.includes('Approve Construction Timesheets')){
      let constructionUnsubscribe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Construction').onSnapshot((querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => {
          console.log('updated from listener on approval.js Construction')

          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        unsubArray.push(constructionUnsubscribe)
        setConstructionAmount(response.length)
      })
    }

    if(roles.includes('Approve Grain Timesheets')){
      console.log('updated from listener on approval.js Grain')

      let grainUnsubscribe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Grain').onSnapshot((querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        unsubArray.push(grainUnsubscribe)
        setGrainAmount(response.length)
      })
    }

    if(roles.includes('Approve Snow Timesheets')){
      console.log('updated from listener on approval.js Snow')

      let snowUnsubscribe = firestore.collection('timesheets').where('approved', '==', false).where('timesheetID', '!=', 0).where('timesheetType', '==', 'Snow').onSnapshot((querySnapshot) => {
        const response = querySnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            'docId': doc.id,
          }
        })
        unsubArray.push(snowUnsubscribe)
        setSnowAmount(response.length)
      })
    }

    const unsubAll = () => {
      unsubArray.forEach((array) => {
        array();
      })
    }

    return () => { unsubAll(); }
  }, [roles]);

  const handleApproveAllClick = () => {
    setApproveAllOpen(false);
    const batch = firestore.batch();
    //get session storage data for checked sheets to make sure no rendering issues occur

    
    JSON.parse(sessionStorage.getItem("approvalCheckedSheets")).forEach((docId) => {
      let timesheetData = JSON.parse(sessionStorage.getItem("approveTimesheetData"))
      timesheetData[timesheetData.findIndex((obj => obj.docId === docId))].approved = true
      const docRef = firestore.collection('timesheets').doc(docId);
      batch.update(docRef, { approved: true });
    });
    
    batch.commit()
      .then(() => {
       setReload(true);
       setCheckedSheets([]);
        sessionStorage.setItem('approvalCheckedSheets', JSON.stringify([]));
      })
      .catch((e) => {
        console.log(e);
      });
}

  return (
    <div className={classes.content}>
                      <Dialog 
                        open={approveAllOpen}
                        onClose={handleApproveAllClose}
                    >
                        <DialogTitle>{'Bulk Approval'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to approve all {checkedSheets.length} timesheet(s)?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleApproveAllClick()} variant="contained" color="primary" autoFocus>
                                {'Approve All'}
                            </Button>
                            <Button onClick={handleApproveAllClose} variant="contained" color="secondary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
      <Grid>
        <Grid container className={classes.table}>
            <AppBar className={classes.tabBar} position="static" elevation={0} >
                <Tabs value={value} onChange={handleChange} 
                centered={false} variant={smallScreen ? "scrollable" : "standard"} 
                scrollButtons={"on"} 
                style={{margin:'0px'}}>
                
                {roles.includes('Approve Office Timesheets') ?          <Tab label={<div> Office ({officeAmount}) </div>} {...a11yProps(0)} />                : null}      
                {roles.includes('Approve Shop Timesheets') ?            <Tab label={<div> Shop ({shopAmount})</div>} {...a11yProps(1)} />                     : null}
                {roles.includes('Approve Gravel Timesheets') ?          <Tab label={<div> Gravel ({gravelAmount})</div>}  {...a11yProps(2)} />                : null}
                {roles.includes('Approve Construction Timesheets') ?    <Tab label={<div> Construction ({constructionAmount})</div>} {...a11yProps(3)} />     : null}
                {roles.includes('Approve Grain Timesheets') ?           <Tab label={<div> Grain ({grainAmount})</div>} {...a11yProps(4)} />                   : null}
                {roles.includes('Approve Snow Timesheets') ?            <Tab label={<div> Snow ({snowAmount})</div>} {...a11yProps(5)} />                     : null}

                </Tabs>
                <Grid container>
                  <Grid container item justifyContent='center'>
                    <Button variant='contained' disabled={checkedSheets.length <= 0} onClick={() => handleApproveAllOpen()} style={checkedSheets.length > 0?{width:'50%', color:'white',backgroundColor:'green'}:{width:'50%'}}>Approve Bulk</Button>
                  </Grid>
                </Grid>
                <TabPanel value={value} index={0}>
                   
                        <OfficeSheets reload={reload} setReload={setReload} checkedSheets={checkedSheets} setCheckedSheets={setCheckedSheets}/>
                    
                </TabPanel>
                <TabPanel value={value} index={1}>
                   
                        <ShopSheets reload={reload} setReload={setReload} checkedSheets={checkedSheets} setCheckedSheets={setCheckedSheets}/>
                      
                </TabPanel>
                <TabPanel value={value} index={2}>
                    
                        <TruckDriverSheets reload={reload} setReload={setReload} checkedSheets={checkedSheets} setCheckedSheets={setCheckedSheets}/>
                        
                </TabPanel>
                <TabPanel value={value} index={3}>
                   
                        <ConstructionSheets reload={reload} setReload={setReload} checkedSheets={checkedSheets} setCheckedSheets={setCheckedSheets}/>
                     
                </TabPanel>
                <TabPanel value={value} index={4}>
                 
                        <GrainSheets reload={reload} setReload={setReload} checkedSheets={checkedSheets} setCheckedSheets={setCheckedSheets}/>
                      
                </TabPanel>
                <TabPanel value={value} index={5}>
                   
                        <SnowSheets reload={reload} setReload={setReload} checkedSheets={checkedSheets} setCheckedSheets={setCheckedSheets}/>
                       
                </TabPanel>
            </AppBar>
            
        </Grid>
      </Grid>
    </div>
  )
}

export default withRouter(ApprovalHome);