import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid, Card, Typography, Box, Tab, Button, Modal, Fade, Backdrop, Paper, Snackbar } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
//import { useAuth } from '../../../providers/AuthContext'
import { firestore, functions } from '../../../firebase/firebase';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import useAsyncError from '../UI/AsyncError/AsyncError';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DataGridTable from '../UI/DataGridTable/DataGridTable';
import GrainButtonList from './GrainButtonList';
import GrainOrdersCard from './GrainOrdersCard';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        marginTop: '20px',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        width: '100%',
        maxWidth: '90vw',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
    createButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
}));

const GrainOrdersReport = (props) => {
    const classes = useStyles();
    const throwAsyncError = useAsyncError();

    const [tabSelected, setTabSelected] = useState("grainOrdersReportTabValue" in sessionStorage ? JSON.parse(sessionStorage.getItem("grainOrdersReportTabValue")) : 'all');

    const [tableExpanded, setTableExpanded] = useState("grainTableExpanded" in sessionStorage ? JSON.parse(sessionStorage.getItem("grainTableExpanded")) : true)

    const [loading, setLoading] = useState(false)

    const [rowsSelected, setRowsSelected] = useState([])
    const rowsSelectedRef = useRef([])

    //FOR TESTING PURPOSES
    // useEffect(() => {
    //     console.log('rowsSelected', rowsSelected)
    // }, [rowsSelected])

    //holds docId's of documents that have been altered  
    const [alteredDocsData, setAlteredDocsData] = useState([])
    const alteredDocsDataRef = useRef([])
    const [saving, setSaving] = useState(false)

    //modal data
    const [deleteData, setDeleteData] = useState({pending: [], confirmed: [], current: 0, open: false})
    const [modalOpen, setModalOpen] = useState(null)

    //states that handle table alterations (when not pulling new data)
    const [deletedRows, setDeletedRows] = useState([])
    const [addedRows, setAddedRows] = useState({})
    const pageRef = useRef()

    //used to pass data down to child ? set with below state, then this value is passed down via use effect ----------- USED FOR AUTOFOCUS
    const addedValueRef = useRef({})
    const [addedValue, setAddedValue] = useState({})

    const [globalGrainOrders, setGlobalGrainOrders] = useState(_.cloneDeep(props.globalGrainOrdersRef.current))
    const saveStateRef = useRef(_.cloneDeep(props.globalGrainOrdersRef.current))
    const [filteredGrainOrders, setFilteredGrainOrders] = useState(_.cloneDeep( props.pathType === 'all' ? props.globalGrainOrdersRef.current : props.globalGrainOrdersRef.current.filter(x=> x.orderType === props.pathType)))
    const [currentGrainDocId, setCurrentGrainDocId] = useState(null)

    const newGrainOrder = functions.httpsCallable('createGrainOrder');
    //const getTicketNumber = functions.httpsCallable('createTicket');

    //shoiuld be in home
    const defaultColumnData = useMemo(() => (
        [
            {   title: 'GeeTee Order#', 
                field: 'geeTeeOrderNumber', 
                columnType: 'typography',
                uid: true,
                width: 'small',
            },
            {   title: 'Order#', 
                field: 'orderNumber',
                columnType: 'textfield',
                uid: true,
            },
            {   title: 'Status', 
                field: 'orderStatus', 
                columnType: 'autocomplete',
                errorable: true,
                options: {data: props.statuses, displayFields: ['status'], field: 'orderStatus'},
            },
            {   title: 'Ticket Date', 
                field: 'ticketDate', 
                columnType: 'date',
                secondarySort: 'asc',
            },
            {   title: 'Equipment', 
                field: 'equipment', 
                columnType: 'select',
                orderByColumn: 'asc',
                errorable: true,
                options: {data: props.units.sort((a, b) => a.unitNumber?.toLowerCase() > b.unitNumber?.toLowerCase()), displayFields: ['unitNumber', 'description'], field: 'equipment'},
                route: '/dashboard/equipment',
                width: 'small',
            },
            {   title: 'Order Priority',
                field: 'orderPriority',
                columnType: 'autocomplete',
                tertiarySort: 'asc',
                errorable: true,
                options: {data: props.priorities, displayFields: ['priority'], field: 'orderPriority'},
                width: 'small',
            },
            {   title: 'Operator', 
                field: 'operator', 
                columnType: 'select',
                errorable: true,
                options: {data: props.users.filter(x=> x.employment?.active).sort((a, b) => a.displayName?.toLowerCase() > b.displayName?.toLowerCase()), displayFields: ['displayName', 'department'], field: 'operator'},
                route: '/dashboard/users',
            },
            {   title: 'Customer', 
                field: 'customer', 
                columnType: 'select',
                errorable: true,
                options: {data: props.customers.sort((a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()), displayFields: ['name'], field: 'customer'},
                route: '/dashboard/customers',
            },
            {   title: 'Origin', 
                field: 'origin',
                columnType: 'autocomplete',
                errorable: false,
                options: {data: props.placesRef.current, displayFields: ['origin'], field: 'origin'},
            },
            {   title: 'Destination', 
                field: 'destination',
                columnType: 'autocomplete',
                errorable: false,
                options: {data: props.placesRef.current, displayFields: ['destination'], field: 'destination'},
            },
            {   title: 'Material',
                field: 'material',
                columnType: 'autocomplete',
                errorable: false,
                options: {data: props.materialsRef.current, displayFields: ['material'], field: 'material'},
            },
            {   title: 'Instructions', 
                field: 'instructions',
                columnType: 'textfield',
            },
            {   title: 'Order Type',
                field: 'orderType',
                columnType: 'autocomplete',
                errorable: true,
                options: {data: props.orderTypes, displayFields: ['orderType'], field: 'orderType'},
                width: 'small',
            },
        ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), []);

    //console.log('grain orders rerendered')

    const [columns] = useState("grainOrdersColumns" in localStorage ? JSON.parse(localStorage.getItem("grainOrdersColumns")) : defaultColumnData)

    //rerender of table on change of addedRows then passes down docId of added doc for focus
    useEffect(() => { setAddedValue(addedValueRef.current) }, [addedRows])

    //filters based on tab
    useEffect(() => {  
        updateSort(); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabSelected])

    const updateSort = () => {
        setAddedRows({})
        //setDeletedRows([])

        if(tabSelected === 'all'){
            let container = globalGrainOrders.filter((order) => (props.pathType !== 'all' ? order.orderType === props.pathType : order))
            setFilteredGrainOrders([...container])
        } else if(tabSelected === 'active'){
            let container = globalGrainOrders?.filter((order) => ( order.orderStatus === 'active'))
                .filter((order) => (props.pathType !== 'all' ? order.orderType === props.pathType : order))
            setFilteredGrainOrders([...container])
        } else if(tabSelected === 'complete'){
            let container = globalGrainOrders?.filter((order) => ( order.orderStatus === 'complete'))
                .filter((order) => (props.pathType !== 'all' ? order.orderType === props.pathType : order))
            setFilteredGrainOrders([...container])
        }else{  // tabSelected === cancelled
            let container = globalGrainOrders?.filter((order) => ( order.orderStatus === 'cancelled' ))
                .filter((order) => (props.pathType !== 'all' ? order.orderType === props.pathType : order))
            setFilteredGrainOrders([...container])
        }
    }

    const confirmSave = async () => {
        setSaving(true)
        let batch = firestore.batch();
        let globalContainer = globalGrainOrders;

        //creates db doc refs and updates state var
        alteredDocsData.forEach((docId) => {
            const docRef = firestore.collection('grainOrders').doc(docId);
            let container = globalGrainOrders.find(x=> x.docId === docId)
            container = props.checkForAssignedEquipmentOperator(container)
            globalContainer.splice(globalGrainOrders.findIndex(x=> x.docId === docId), 1, container)
            batch.set(docRef, container);
        })

        batch.commit()
        .then(async () => {
            //sets to state after pushing to db
            setGlobalGrainOrders([...globalContainer])
            saveStateRef.current = _.cloneDeep([...globalContainer])

            //checks for new rows to be added
            let addedRowsContainer = [];
            for (const value of Object.values(addedRows)) { 
                value.filter(x=> x.geeTeeOrderNumber === 'New').forEach((doc) => { 
                    addedRowsContainer.push(globalGrainOrders.find(x=> x.docId === doc.docId)) 
                })
            }

            //if there are docs that needs geeTeeOrderNumbers
            if(addedRowsContainer.length > 0){
                let addedRowsMapContainer = _.cloneDeep(addedRows)

                //gets proper grainNumbers for each new order
                await newGrainOrder({
                    sandbox: process.env.NODE_ENV === 'development',
                    dataArray: addedRowsContainer,
                }).then((res) => {
                    res.data.forEach((data) => {
                        //allGrainOrders
                        globalContainer.splice(globalContainer.findIndex(x=> x.docId === data.docId), 1, data)

                        //addedRows
                        for (const [key, value] of Object.entries(addedRows)) {
                            if(value.find(x=> x.docId === data.docId) !== undefined){
                                addedRowsMapContainer[key].splice(addedRowsMapContainer[key].findIndex(x=> x.docId === data.docId), 1, data)
                            }
                        }
                    })
                    setAddedRows(addedRowsMapContainer)
                    setGlobalGrainOrders([...globalContainer])
                    saveStateRef.current = _.cloneDeep([...globalContainer])
                })
            }

            setAlteredDocsData([])
            alteredDocsDataRef.current = []
            setSaving(false)
        })
        .catch((e) => {
          throwAsyncError(new Error(e.message, e));
          setSaving(false)
        });
    };

    const cancelSave = () => {
        let addedRowsRemoveContainer = [];
        let addedRowsMapContainer = _.cloneDeep(addedRows)
        let rowsSelectedContainer = _.cloneDeep(rowsSelectedRef.current)

        for (const value of Object.values(addedRows)) { addedRowsRemoveContainer = addedRowsRemoveContainer.concat(value.filter(x=> x.geeTeeOrderNumber === 'New')) }

        addedRowsRemoveContainer.forEach((data) => { 
            if(rowsSelectedContainer.includes(data.docId)){
                rowsSelectedContainer.splice(rowsSelectedContainer.findIndex(x=> x === data.docId), 1)
            }
        })

        addedRowsRemoveContainer.forEach((data) => {
            for (const [key, value] of Object.entries(addedRows)) {
                if(value.find(x=> x.docId === data.docId) !== undefined){
                    addedRowsMapContainer[key].splice(addedRowsMapContainer[key].findIndex(x=> x.docId === data.docId), 1)
                }
            }
        })

        if(!_.isEqual(rowsSelectedContainer, rowsSelectedRef.current)){
            setRowsSelected(rowsSelectedContainer)
            rowsSelectedRef.current = rowsSelectedContainer
        }
        setGlobalGrainOrders(_.cloneDeep(saveStateRef.current))
        if(!_.isEqual(addedRowsMapContainer, addedRows)){ setAddedRows(addedRowsMapContainer) }
        setAlteredDocsData([])
        alteredDocsDataRef.current = []
    }

    const handleTableChange = (event, newValue) => {
        setTabSelected(newValue);
        sessionStorage.setItem("grainOrdersReportTabValue", JSON.stringify(newValue));
    };

    //callback to be used within memoized component
    const updateAlteredDocs = useCallback((docIdArray, newState) => {
        //console.log('updateAlteredDocs', docIdArray, newState)

        let container = _.cloneDeep(alteredDocsDataRef.current);
        
        docIdArray.forEach((docId) => {
            if(!container.includes(docId) && 
                !_.isEqual(newState.find(x=> x.docId === docId), saveStateRef.current.find(x=> x.docId === docId))){

                container.push(docId)
            }
            else if(container.includes(docId) && 
                    _.isEqual(newState.find(x=> x.docId === docId), saveStateRef.current.find(x=> x.docId === docId))){

                container.splice(container.findIndex(x=> x === docId), 1)
            }
        })

        if(!_.isEqual(container, alteredDocsDataRef.current)){
            setAlteredDocsData(container)
            alteredDocsDataRef.current = container;
        }
    }, []);

    //can be set as a callback and passed to child / REMOVED
    const onCellChange = (value, field, docId) => {
        try{
            setGlobalGrainOrders((previousValues) => { 
                let container = _.cloneDeep(previousValues)
                container[container.findIndex(x=> x.docId === docId)][field] = value;
                //inside set to have access to latest globalGrainOrder data
                updateAlteredDocs([docId], [...container])
                return container
            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    //state within globalGrainOrders will not have up to date values THIS AND ONCELLCHANGE CAN BE THE SAME FUNCTION
    const handleSetCurrentGrainOrderCallback = useCallback((changesObj, docId) => {
        setGlobalGrainOrders((previousValues) => { 
            let container = _.cloneDeep(previousValues)
            for (const [key, value] of Object.entries(changesObj)) { container[container.findIndex(x=> x.docId === docId)][key] = value }
            //inside set to have access to latest globalGrainOrder data
            updateAlteredDocs([docId], container)
            return container;
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[], );

    const onPageChange = (page) => {
        pageRef.current = page;
    }

    const onNewRow = (rowData, copied) => {
        //console.log('rowData', rowData)
        try{
            setLoading(true)
            let allGrainContainer = _.cloneDeep(globalGrainOrders)
            let addedRowsContainer = _.cloneDeep(addedRows)

            rowData.forEach((row) => {
                //add any additional values here
                let query = firestore.collection('grainOrders').doc()
                row.orderStatus = 'active';
                if(props.pathType !== 'all'){ row.orderType = props.pathType; }
                row.docId = query.id
                row.geeTeeOrderNumber = 'New';
                if(copied){ row.orderNumber = '' }

                //check if the field is a related one, and that the opposite value is not present
                if((row.equipment || row.operator) && ((row.equipment && !row.operator) || (!row.equipment && row.operator))){
                    let currentValue = (row.equipment && !row.operator) ? 'equipment' : 'operator'
                    let oppositeValue = (row.equipment && !row.operator) ? 'operator' : 'equipment'

                    let newValue = currentValue === 'equipment'
                    ? {operator: props.users.filter(x=> x.commonEquipment?.fullUnit?.docId === row[currentValue]).pop()?.docId }
                    : {equipment: props.users.find(x=> x.docId === row[currentValue])?.commonEquipment?.fullUnit?.docId } 

                    if(newValue[oppositeValue]){ row[oppositeValue] = newValue[oppositeValue] }
                }

                for(let [key, value] of Object.entries(props.initialgrainOrder)){
                    if(_.isUndefined(row[key]) && key !== 'ticketDate'){  row[key] = value;  }
                }
            })

            addedValueRef.current = rowData.map((row) => { return row.docId });
            setGlobalGrainOrders([...allGrainContainer, ...rowData])
            addedRowsContainer[pageRef.current] = [...addedRowsContainer[pageRef.current] || [], ...rowData]
            setAddedRows(addedRowsContainer)
            updateAlteredDocs(rowData.map((row) => {return row.docId}), [...allGrainContainer, ...rowData])
            setLoading(false)
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    const handleDeleteOrder = (documents) => {
        //console.log('handleDeleteOrder', documents)
        try{
            let batch = firestore.batch();
            let container = _.cloneDeep(globalGrainOrders)
            let alteredDocsContainer = _.cloneDeep(alteredDocsDataRef.current)
            let addedRowContainer= _.cloneDeep(addedRows)
            let rowsSelectedRefContainer= _.cloneDeep(rowsSelectedRef.current)

            documents.forEach((data) => {
                let query = firestore.collection('grainOrders').doc(data.docId)
                batch.delete(query);
                container.splice(container.findIndex(x=> x.docId === data.docId), 1)
                
                if(currentGrainDocId === data.docId){ setCurrentGrainDocId(null) }

                if(alteredDocsContainer.includes(data.docId)){ alteredDocsContainer.splice(alteredDocsContainer.findIndex(x=> x === data.docId), 1) }

                if(addedRowContainer[pageRef.current]?.map((obj) => ( obj.docId )).includes( data.docId )){ 
                    addedRowContainer[pageRef.current].splice(addedRowContainer[pageRef.current].findIndex(x=> x.docId === data.docId), 1) 
                }

                if(rowsSelectedRefContainer.includes( data.docId )){ 
                    rowsSelectedRefContainer.splice(rowsSelectedRefContainer.findIndex(x=> x === data.docId), 1) 
                }
            })

            batch.commit()
            .then(() => { 
                //needs to happen before other states are set
                setDeletedRows([...deletedRows || [], ...documents.map((doc => (doc.docId)) )])

                setGlobalGrainOrders([...container])

                //removes any added data docs that may be present
                if(!_.isEqual(addedRowContainer, addedRows)){
                    setAddedRows(addedRowContainer)
                }

                //removes any alteredData docs that may be present
                if(!_.isEqual(alteredDocsContainer, alteredDocsDataRef.current)){
                    setAlteredDocsData(alteredDocsContainer)
                    alteredDocsDataRef.current = alteredDocsContainer
                }
                
                //alters rowsSelected if there are differences
                if(!_.isEqual(rowsSelectedRefContainer, rowsSelectedRef.current)){
                    setRowsSelected(rowsSelectedRefContainer)
                    rowsSelectedRef.current = rowsSelectedRefContainer
                    //grainButtonsRef.current.handleRowsSelected(rowsSelectedRefContainer)
                }

            })
        }
        catch(e){
            throwAsyncError(new Error(e.message, e));
        }
    }

    const handleDblClick = (column, docId) => {
        let router = columns.find(x=> x.field === column).route
        let docIdDefined = false

        //check if docId exists within particular collection
        switch(column) {
            case 'equipment':
                if(props.units.map((unit) => { return unit.docId}).includes(docId)){ docIdDefined = true; }
                break;
            case 'users':
                if(props.users.map((user) => { return user.docId}).includes(docId)){ docIdDefined = true; }
                break;
            case 'customers':
                if(props.customers.map((customer) => { return customer.docId}).includes(docId)){ docIdDefined = true; }
                break;
            default:
                console.log('column not incuded.')
        } 

        try{
            //if router is not undefined
            if(!_.isUndefined(router)){
                if(docId && docIdDefined){
                    props.history.push({
                        pathname: `${router}/form`,
                        search: "?" + new URLSearchParams({ 'docId': docId }).toString(),
                    })
                }
                else{
                    props.history.push({
                        pathname: router,
                    })
                }
            }
        }
        catch(e){
            console.warn(e)
        }
    }

    const onRowSelectionChange = (rowData) => {
        setCurrentGrainDocId(globalGrainOrders.find(x=> x.docId === rowData[0])?.docId)
        setRowsSelected(rowData)
        rowsSelectedRef.current = rowData;
    }

    const onColumnChange = (columns) => {
        localStorage.setItem('grainOrdersColumns', JSON.stringify(columns));
    }

    return (
        <Grid className={classes.content}>
            {/* DELETE MODAL */}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={deleteData.open}
                onClose={() => { setDeleteData({pending: [], confirmed: [], current: 0, open: false}) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={deleteData.open}>
                    <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>

                            {deleteData.pending?.length > 1 &&
                                <Grid item>
                                    <Typography align='left' style={{fontSize: '12px'}}>
                                        {`${deleteData.current + 1} of ${deleteData.pending?.length}`}
                                    </Typography>
                                </Grid>
                            }

                            <Grid item>
                                <Typography align='center'>
                                    {`This will delete document number ${deleteData.pending[deleteData.current]?.geeTeeOrderNumber} , are you sure?`}
                                </Typography>
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { 
                                            setDeleteData({pending: [], confirmed: [], current: 0, open: false})
                                        }}
                                    >
                                        Cancel All
                                    </Button>

                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { 
                                            if(deleteData.current + 1 < deleteData.pending.length){
                                                setDeleteData((previousData) => {
                                                    //{pending: [], confirmed: [], current: 1, open: false})
                                                    return {...previousData, current: previousData.current + 1};
                                                })
                                            }
                                            else{
                                                deleteData.confirmed.length > 0 && handleDeleteOrder([...deleteData.confirmed])
                                                setDeleteData({pending: [], confirmed: [], current: 1, open: false})
                                            }
                                        }}
                                    >
                                        Skip
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        onClick={(e) => {
                                                if(deleteData.current + 1 < deleteData.pending.length){
                                                    setDeleteData((previousData) => {
                                                        return {...previousData, 
                                                                confirmed: [...previousData.confirmed, previousData.pending[previousData.current]], 
                                                                current: previousData.current + 1
                                                            };
                                                    })
                                                }
                                                else{
                                                    handleDeleteOrder([...deleteData.confirmed, deleteData.pending[deleteData.pending.length -1]])
                                                    setDeleteData({pending: [], confirmed: [], current: 0, open: false})
                                                }
                                            }
                                        }
                                    >
                                        Confirm
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>


            {/* TICKET AND EMAIL MODAL */}
            <Modal
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    outline: 0,
                }}
                open={modalOpen !== null}
                onClose={() => { setModalOpen(null) }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={modalOpen !== null}>
                    <Paper style={{ padding: '16px', maxWidth:'700px' }}>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Typography align='center'>
                                    {modalOpen === 'ticket' 
                                    ?
                                        'This will send these entries to TICKET ENTRY and mark them as COMPLETE, are you sure?'
                                    :
                                    modalOpen === 'email'
                                    ?
                                        `This will send order instructions to these recipients:
                                        ${rowsSelectedRef.current.map((docId) => { return globalGrainOrders.find(x=> x.docId === docId)}).map((row) => { return _.find(props.users, {docId: row.operator})?.email})}
                                        Are you sure?`
                                        
                                    : 
                                    //else whatever else
                                        null
                                    }
                                </Typography>
                            </Grid>

                            <Grid container justifyContent='flex-end'>
                                <Grid item>
                                    <Button
                                        startIcon={<CloseIcon/>} 
                                        style={{color:'white',backgroundColor:'red', marginRight: '10px'}}
                                        variant='contained'
                                        onClick={(e) => { setModalOpen(null) }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        startIcon={<CheckIcon/>}
                                        variant='contained'
                                        onClick={(e) => {
                                            modalOpen === 'ticket' 
                                            ?
                                                props.handleSendToTickets(rowsSelectedRef.current, globalGrainOrders).then((res) => { setGlobalGrainOrders(res) })
                                            :
                                            modalOpen === 'email'
                                            ?
                                                props.handleSendOperatorInstructionsEmail(rowsSelectedRef.current)
                                            : //else whatever else
                                                console.log('null')
                                            
                                            setModalOpen(null)
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Paper>
                </Fade>
            </Modal>

            <Grid container>
                <Grid item md={2} xs={12}>
                    <Button 
                        className={classes.createButton}
                        variant='contained'
                        startIcon={<AddBoxIcon/>}
                        onClick={(e) => {  props.history.push({ pathname: 'new',}) }}
                    >
                        Create New Grain Order
                    </Button>
                </Grid>

                <Grid item md={10} xs={12}>                     
                    <GrainButtonList 
                        rowsSelected={rowsSelected}
                        globalGrainOrders={globalGrainOrders}
                        handlePrintOrder={props.handlePrintOrder}
                        setModalOpen={setModalOpen}
                        setDeleteData={setDeleteData}
                        onNewRow={onNewRow}
                    />
                </Grid>
            </Grid>

            <Grid container >
                <Grid item sm={12} xs={12}>
                    <Card className={classes.paper}>
                        <Grid item sm={12} xs={12} className={classes.table}>
                            <TabContext value={tabSelected}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTableChange}>
                                        <Tab label={"All"} value="all" />
                                        <Tab label={"Active"} value="active" />
                                        <Tab label={"Complete"} value="complete" />
                                        <Tab label={"Cancelled"} value="cancelled" />
                                    </TabList>
                                </Box>
                                <TabPanel value="all"></TabPanel>
                                <TabPanel value="active"></TabPanel>
                                <TabPanel value="complete"></TabPanel>
                                <TabPanel value="cancelled"></TabPanel>
                            </TabContext>
                        </Grid>

                        <Grid >
                            <DataGridTable
                                globalGrainOrders={globalGrainOrders}
                                sortedGrainOrders={filteredGrainOrders}
                                title={'Grain Orders'}
                                columns={columns}
                                loading={loading}
                                orderTypes={props.orderTypes}
                                pathType={props.pathType}
                                onActionClick={(rowData) => {  props.tileClickHandler('form', rowData.docId, {grainOrderData: filteredGrainOrders}) }}
                                handleDblClick={handleDblClick}
                                onRowSelectionChange={(rowData) => { onRowSelectionChange(rowData) }}
                                onColumnChange={(columns) => { onColumnChange(columns) }}
                                onCellChange={onCellChange}
                                onNewRow={onNewRow}
                                updateSort={updateSort}
                                deletedRows={deletedRows}
                                addedRows={addedRows}
                                pageRef={pageRef}
                                onPageChange={onPageChange}
                                addedValue={addedValue}
                                style={tableExpanded ? {height: '55vh'} : {height: '40vh'}}
                            />

                        </Grid>
                        
                        <Grid container style={{marginTop: '10px', marginBottom: '10px'}}>
                            <Button 
                                fullWidth 
                                variant='outlined' 
                                onClick={() => { 
                                    sessionStorage.setItem("grainTableExpanded", JSON.stringify(!tableExpanded));
                                    setTableExpanded(!tableExpanded) }}>{tableExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                }
                            </Button>
                        </Grid>

                        {!tableExpanded &&
                            <GrainOrdersCard 
                                globalGrainOrders={globalGrainOrders}
                                currentGrainOrder={globalGrainOrders.find(x=> x.docId === currentGrainDocId) || null} 
                                handleSetCurrentGrainOrderCallback={handleSetCurrentGrainOrderCallback}
                                path={currentGrainDocId?.docId}
                                loading={props.loading}
                                // props from highest level no rerender
                                handlePrintOrder={props.handlePrintOrder}
                                handleSendOperatorInstructionsEmail={props.handleSendOperatorInstructionsEmail}
                                priorities={props.priorities}
                                statuses={props.statuses}
                                users={props.users}
                                customers={props.customers}
                                units={props.units}
                                usersLoading={props.usersLoading}
                                unitsLoading={props.unitsLoading}
                                per={props.perRef.current}
                                places={props.placesRef.current}
                                materials={props.materialsRef.current}
                                orderTypes={props.orderTypes}
                            />
                        }
                    </Card>
                </Grid>
            </Grid>
        
            <Snackbar
                open={alteredDocsData.length > 0}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave} >Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                }
            />
        </Grid>

    )
}

export default withRouter(GrainOrdersReport)