import { List, ListItem, ListItemText, makeStyles, Divider } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const OrderDrawer = (props) => {

    const classes = useStyles();

    return <List>
        
       {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/store/orders/new'} button>
            <ListItemText primary={'+ Create New Order'} />
        </ListItem>
         CONVERGE */}

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/store/orders/newSquare'} button>
            <ListItemText primary={'+ Create New Order'} />
        </ListItem>
        {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/grains/new'} button>
            <ListItemText primary={'+ Create New Grain Order'} />
        </ListItem> */}
        <Divider></Divider>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/store/orders'} button>
            <ListItemText primary={'Order Reports'} />
        </ListItem>
        {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/grains'} button>
            <ListItemText primary={'Grain Reports'} />
        </ListItem> */}
        
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/quotes'} button>
            <ListItemText primary={'Order Quotes'} />
        </ListItem>

        <Divider></Divider>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/rates'} button>
            <ListItemText primary={'Rates'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/tickets'} button>
            <ListItemText primary={'Ticket Entry'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/invoicing'} button>
            <ListItemText primary={'Invoicing'} />
        </ListItem>

        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/payroll'} button>
            <ListItemText primary={'Payroll'} />
        </ListItem>

        {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Price Books'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Inventory'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Categories'} />
        </ListItem> */}
        {/*  <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/products/metatags'} button>
            <ListItemText primary={'Search Optimization'} />
        </ListItem>
        <Divider /> 
        <ListItem className={classes.listItem} onClick={props.onClick}  component={Link} to={'/dashboard/coupons'} button>
            <ListItemText primary={'Coupon Manager'} />
        </ListItem>*/}
    </List>
}