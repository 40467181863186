import React, { useContext, useState } from "react"
import { firestore } from "../firebase/firebase"

const FormContext = React.createContext()

export function useForm() {
  return useContext(FormContext);
}

export function FormProvider({ children }) {

  const [forms, setForms] = useState([]);
  const [formsLoading, setFormsLoading] = useState(true);
  const [error, setError] = useState('');

  function getForms() {
    //Check if customers exist on local storage

      //Query customer collection for all docs
      firestore.collection("pdfforms").get()
        .then(querySnapshot => {
          const formResponse = querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              'docId': doc.id,
            }
          })

          let formTitles = formResponse.map(form => form.name);

          // get documents with in clause finding all pdf form names
          firestore.collection("documents").where("name", "not-in", formTitles).get()
          .then(querySnapshot => {
            const documentRes = querySnapshot.docs.map((doc) => {
              return {
                name: doc.data().name,
              }
            })
 
            documentRes.forEach(doc => {
              if (!formTitles.includes(doc.name)) {
                formResponse.push(doc)
                formTitles.push(doc.name)
              }
            })

            setFormsLoading(false);
            setForms(formResponse);
          })
        },
          error => {
            setFormsLoading(false);
            setError(error.message);
            console.log(error.message);
          }
        )
  }

  function getForm(name) {
    return forms?.filter(form => form.name === name)
  }

  const value = {
    forms,
    formsLoading,
    error,
    getForms,
    getForm,
  }

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  )
}