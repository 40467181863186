import React, {useEffect, useState, useCallback} from 'react';
import { makeStyles } from '@material-ui/core';
import { Checkbox, CircularProgress, FormControlLabel, Grid, Paper, TextField, Typography, InputAdornment,
 Select, FormControl, Snackbar, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { useCustomer } from '../../../providers/CustomerContext';
import moment from 'moment';
import _ from 'lodash'
import { firestore } from '../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        
    },

    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
        
    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        //backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const InformationTab = (props) => {
    
    const classes = useStyles();
    const equipmentClasses = [
        'Tractor Truck', 'Tandem Truck', 'Grain Bulker', 'Truck Plow', 'Side Dump Trailer', 'End Dump Truck', 'Sanding Truck',
        'Tandem Trailer', 'Labour', 'Lowbed', 'Shop Labour', 'Bobcat', 'Geotec', 'Concrete Products', 'Subcontractor - Truck Plow', 'Subcontractor - Loader',
        'Subcontractor - Trucking', 'Belly Dump Trailer', 'Mechanic', 'Dispatch', 'Office Staff', 'Lawn/Shop Equipment',
        'Subcontractor - Bobcat', 'Equipment Moves', 'Triple End Dump', 'Tandem End Dump', 'Trailer - Bobcat',
        'Loader', 'Mobile Fueling Unit', 'Compaction Equipment', 'Quik-Grip Granite', 'Single Axle Dump', 'Field Equipment',
        'Grader','Tailer','Excavator','Loader Backhoe','Dozer','Compaction Equipment Self Propelled', 'Water Truck',
    ];
    const { customers, loading, getCustomers } = useCustomer();
    const [open, setOpen] = useState(false);
    const [typedValue, setTypedValue] = useState('')
    useEffect(() => {
        getCustomers()
    })

    const [equipmentData, setEquipmentData] = useState(props.equipmentData);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const [saveState, setSaveState] = useState(props.saveState);

    let path = new URLSearchParams(props.location.search).get("docId");

    const updateEquipmentData = useCallback((e) => {
            setEquipmentData(e)
        },
        [],
    );

    const confirmSave = () => {
        props.setEquipmentData(equipmentData);
        props.setSaveState(equipmentData);
        setSaving(true);
        firestore.collection('unitDescriptions').doc(path)
            .update(equipmentData)
            .then(() => {
                setSaveState(equipmentData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };

    const cancelSave = () => {
        setEquipmentData(saveState);
    };

    return (
        <Grid container spacing={2}>
            <Grid container>
                <Grid container item xs={12} >
                {!props.edit ?
                <Typography variant="h4" style={{marginLeft:'10px'}}>{equipmentData.description}</Typography>:
                <TextField
                    label="Description"
                    variant="outlined"
                    value={equipmentData.description}
                    onChange={(e) => updateEquipmentData({...equipmentData, description: e.target.value})}
                    style={{width:'100%'}}
                    inputProps={{ style: { fontSize: '2rem' } }} 
                />

                }
                </Grid>
                <Grid container item xs={6} >
                    <Paper className={classes.paper} >
                        <Grid container spacing={2}>
                            <Typography variant="h5">Unit Info</Typography>
                        </Grid>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid container>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Unit Number:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.unitNumber ? props.equipmentData.unitNumber : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.unitNumber}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'unitNumber': event.target.value });
                                    
                                }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Odometer Units:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.odoUnits ? props.equipmentData.odoUnits : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.odoUnits}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'odoUnits': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Year:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{equipmentData.year ? equipmentData.year : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.year}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'year': event.target.value });
                                     }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Model:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.model ? props.equipmentData.model : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.model}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'model': event.target.value });
                                      }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Make:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.make ? props.equipmentData.make : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.make}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'make': event.target.value });
                                      }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Equipment Type:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.equipmentType.charAt(0).toUpperCase() + props.equipmentData.equipmentType.slice(1)}</Typography>
                                    :
                                    <FormControl className={classes.formControl}>
                                                    
                                        <Select
                                            native
                                            value={equipmentData.equipmentType}
                                            onChange={(event) => { updateEquipmentData({ ...equipmentData, 'equipmentType':  event.target.value }) }}
                                        >
                                        <option value={"truck"}>Truck</option>
                                        <option value={"tractor"}>Tractor</option>
                                        <option value={"trailer"}>Trailer</option>
                                        <option value={"passenger"}>Passenger</option>
                                        <option value={"loader"}>Loader</option>
                                        </Select>
                                    </FormControl>}
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Equipment Features:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.features ? props.equipmentData.features : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.features}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'features': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> 
                <Grid container item xs={6} >
                    <Paper className={classes.paper} >
                        <Grid container spacing={2}>
                            <Typography variant="h5">Unit Info</Typography>
                        </Grid>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid container>
                            <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>License Plate:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.licensePlate ? props.equipmentData.licensePlate : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.licensePlate}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'licensePlate': event.target.value });
                                      }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>MNP Number:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.mnpNum ? props.equipmentData.mnpNum : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.mnpNum}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'mnpNum': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Serial #:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.serialNum ? props.equipmentData.serialNum : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.serialNum}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'serialNum': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Alias Unit Number:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.aliasUnitNum ? props.equipmentData.aliasUnitNum : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.aliasUnitNum}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'aliasUnitNum': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Tare:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.tare ? props.equipmentData.tare : '0'} kgs</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.tare}
                                    size="small"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">kgs</InputAdornment>,
                                      }}
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'tare': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} > <br></br></Grid>
                                <Grid item xs={6} > <br></br></Grid>
                               

                                <Grid item xs={12} >
                                    <FormControlLabel
                                        control={<Checkbox disabled={!props.edit} checked={equipmentData.isActive} name="active" 
                                        onChange={(event) => { updateEquipmentData({ ...equipmentData, 'isActive': event.target.checked  }) }}/>}
                                        label="Equipment is Active"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <FormControlLabel
                                        control={<Checkbox disabled={!props.edit} checked={equipmentData.isPremiumTrailer} name="isPremiumTrailer" 
                                        onChange={(event) => { updateEquipmentData({ ...equipmentData, 'isPremiumTrailer': event.target.checked  }) }}/>}
                                        label="Premium Trailer"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> 
                <Grid container item xs={12} >
                    <Paper className={classes.paper} >
                        <Grid container spacing={2}>
                            <Grid container>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Equipment Classification:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.class ? props.equipmentData.class : ''}</Typography>
                                    :
                                    <FormControl className={classes.formControl}>
                                            
                                        <Select
                                            native
                                            value={equipmentData.class}
                                            onChange={(event) => { updateEquipmentData({ ...equipmentData, 'class':  event.target.value }) }}
                                        >

                                        {equipmentClasses.sort().map((equipment, index) => {
                                            return (
                                                <option key={index} value={equipment}>{equipment}</option>
                                            )
                                        })}
                                        </Select>
                                    </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                <FormControlLabel
                                            
                                            control={<Checkbox disabled={!props.edit} checked={equipmentData.isOwned} name="owned" 
                                            onChange={(event) => { updateEquipmentData({ ...equipmentData, 'isOwned': event.target.checked  }) }}/>}
                                            label="Equipment is Owned"
                                        />
                                </Grid>
                                <Grid item xs={6}>
                                    {equipmentData?.isOwned ? null :                                    
                                    <Autocomplete
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        loading={loading}
                                        label="Search"
                                        id="autocomplete"
                                        disabled={!props.edit}
                                        autoComplete
                                        autoHighlight
                                        style={{ maxWidth: '300px', width: '100%' }}
                                        inputValue={typedValue}
                                        onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                        value={equipmentData?.owner}
                                        onChange={(event, newValue) => { updateEquipmentData({ ...equipmentData, 'owner': newValue }) }}
                                        options={customers.map((customer) => customer.name ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                        getOptionSelected={(option, value) => option?.name === value?.name}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ maxWidth: '300px', width: '100%' }}
                                                label="Owner"
                                                //variant="outlined"
                                                size='small'
                                                
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    />}
                                   
                                    
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Market Value:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.equipmentData.marketValue ? props.equipmentData.marketValue : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.marketValue}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'marketValue': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Purchase Price:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.equipmentData.purchasePrice? props.equipmentData.purchasePrice : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.purchasePrice}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'purchasePrice': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Insured Value:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.equipmentData.insuredValue ? props.equipmentData.insuredValue : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.insuredValue}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'insuredValue': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Purchased From:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.purchasedFrom ? props.equipmentData.purchasedFrom : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.purchasedFrom}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'purchasedFrom': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Purchase Date:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.purchaseDate !== "1990/01/01" ? moment(props.equipmentData.purchaseDate).format('MMM D YYYY') : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.purchaseDate ? moment(equipmentData.purchaseDate).format('YYYY-MM-DD') : '1990/01/01'}
                                    size="small"
                                    type="date"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'purchaseDate': event.target.value }) }}
                                    />
                                    }
                                </Grid>

                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Leased/Financed From:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>{props.equipmentData.leasedFinancedFrom ? props.equipmentData.leasedFinancedFrom : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.leasedFinancedFrom}
                                    size="small"
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'leasedFinancedFrom': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Typography style={{ lineHeight: 2 }} noWrap variant='body1'>Normal Charge Out Rate per Hour:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {!props.edit ?
                                    <Typography style={{ lineHeight: 2 }} align='right' variant='body1'>${props.equipmentData.chargeRate ? props.equipmentData.chargeRate : ''}</Typography>
                                    :
                                    <TextField
                                    style={{maxWidth:'150px'}}
                                    value={equipmentData.chargeRate}
                                    size="small"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                    onChange={(event) => { updateEquipmentData({ ...equipmentData, 'chargeRate': event.target.value }) }}
                                    />
                                    }
                                </Grid>
                               
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid> 
            </Grid>
            <Snackbar
                //disableAutoFocus={true}
                //disableEnforceFocus={true}
                open={!_.isEqual(equipmentData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>
                        }
                    </React.Fragment>
                } />
        </Grid>
    )
}
export default withRouter(InformationTab)