import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    listItem: {
        paddingLeft: 24,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        overFlowX: 'auto'
    },
}));

export const FormsDrawer = (props) => {

    const classes = useStyles();

    return <List>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/safety'} button>
            <ListItemText primary={'Safety Sheets'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/msds'} button>
            <ListItemText primary={'MSDS\'s'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/checklists'} button>
            <ListItemText primary={'Checklists'} />
        </ListItem>
        {/* <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Price Books'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Inventory'} />
        </ListItem>
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard'} button>
            <ListItemText primary={'Categories'} />
        </ListItem> */}
        {/*  <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/products/metatags'} button>
            <ListItemText primary={'Search Optimization'} />
        </ListItem>
        <Divider /> 
        <ListItem className={classes.listItem} onClick={props.onClick}  component={Link} to={'/dashboard/coupons'} button>
            <ListItemText primary={'Coupon Manager'} />
        </ListItem>*/}
        <ListItem className={classes.listItem} onClick={props.onClick} component={Link} to={'/dashboard/pdfforms'} button>
            <ListItemText primary={'PDF Forms'} />
        </ListItem>
    </List>
}