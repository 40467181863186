import React from 'react';
import { Grid, makeStyles, Paper, TableCell, TableBody, TableContainer, Table, TableHead, TableRow,
Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Typography} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { firestore } from '../../../firebase/firebase'

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        
    },

    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
        
    },

    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },

    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },

    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        backgroundColor: 'rgb(252, 252, 249)',
    },

    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const ContactsTab = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);

    const [contactInfo, setContactInfo] = React.useState({})
    const [oldContact, setOldContact] = React.useState({})

    const handleClickOpen = () => {
        setEditing(false);
        setContactInfo({name: '', phone: '', relationship: '', altPhone: ''})
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    const handleEditOpen = (contact) => {
        setContactInfo(contact)
        setOldContact(contact)
        setOpen(true);
        setEditing(true);

    };
  

    const handleDeleteOpen = (contact) => {
        setDeleteOpen(true);
        setContactInfo(contact)
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleSubmit = (event) => {
        const oldContacts = props.userData.contacts
        let newContacts = oldContacts
        setContactInfo({ ...contactInfo, 'id': oldContacts.length })
        newContacts.push(contactInfo)
        props.updateUserData({ ...props.userData, contacts: newContacts })
        firestore.collection('users').doc(props.userData.docId)
            .update(props.userData)
        setContactInfo({name: '', phone: '', relationship: '', altPhone: ''})
    }

    const handleDelete = (event) => {
        const oldContacts = props.userData.contacts
        const index = oldContacts.indexOf(contactInfo)
        if (index > -1) {
            oldContacts.splice(index, 1);
          }
        let newContacts = oldContacts
        props.updateUserData({ ...props.userData, contacts: newContacts })
        firestore.collection('users').doc(props.userData.docId)
            .update(props.userData)
        setContactInfo({name: '', phone: '', relationship: '', altPhone: ''})
    }

    const handleEdit = (event) => {
        const oldContacts = props.userData.contacts
        const index = oldContacts.indexOf(oldContact)
        if (index > -1) {
            oldContacts.splice(index, 1);
          }
        let newContacts = oldContacts
        newContacts.push(contactInfo)
        props.updateUserData({ ...props.userData, contacts: newContacts })
        firestore.collection('users').doc(props.userData.docId)
            .update(props.userData)
        setContactInfo({name: '', phone: '', relationship: '', altPhone: ''})
    }
    
    return (
        <Grid container spacing={2}>
            <Dialog open={deleteOpen} onClose={handleClose}>
                <DialogTitle>Delete Contact</DialogTitle>
                <DialogContent>
                <Typography>Are you sure you want to delete contact: {contactInfo.name}?</Typography>
                    <DialogContentText>
                        This is NOT reversable!
                    </DialogContentText>
                    
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="small" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={handleDelete}>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{!editing ? "Add" : "Edit"} Contact</DialogTitle>
                <DialogContent>
                <DialogContentText>
                {!editing ? "Please enter the details of the new contact." : "Edit the current contact."}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={contactInfo.name}
                    error={contactInfo.name === ''}
                    helperText={contactInfo.name === '' ? "Contact requires a name": ''}
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'name': event.target.value }) }}
                />
                <Grid container spacing={2}> 
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phone"
                            label="Phone #"
                            value={contactInfo.phone}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'phone': event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phonedesc"
                            label="Relationship"
                            value={contactInfo.relationship}
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'relationship': event.target.value }) }}
                        />
                    </Grid>       
                </Grid>
                <TextField
                    margin="dense"
                    id="altPhone"
                    label="Alternate Phone #"
                    type="tel"
                    value={contactInfo.altPhone}
                    fullWidth
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'altPhone': event.target.value }) }}
                />
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={contactInfo.name === ''} variant="contained" color="primary" size="small" onClick={!editing ? handleSubmit : handleEdit}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
            <Grid container>
                <Grid container item xs={12} >
                    <Grid container item xs={12}>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>Contact</TableCell>
                                    <TableCell align="right">Phone Number</TableCell>
                                    <TableCell align="right">Relationship</TableCell>
                                    <TableCell align="right">Alternate Phone Number</TableCell>
                                    {props.edit ? <React.Fragment><TableCell align="center">Delete</TableCell><TableCell align="center">Edit</TableCell></React.Fragment>
                                    : null}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {props.userData.contacts.map((contact, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {contact.name}
                                        </TableCell>
                                        <TableCell align="right">{contact.phone}</TableCell>
                                        <TableCell align="right">{contact.relationship}</TableCell>
                                        <TableCell align="right">{contact.altPhone}</TableCell>
                                        {props.edit ?<React.Fragment>
                                        <TableCell align="center"><IconButton onClick={() => handleDeleteOpen(contact)} color="secondary"><DeleteForeverIcon/></IconButton></TableCell>
                                        <TableCell align="center"><IconButton onClick={() => handleEditOpen(contact)} color="secondary"><EditIcon/></IconButton></TableCell></React.Fragment>  : null}
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {props.edit ?
                    <Grid item xs={6} style={{margin:'16px'}}>
                        <Button disabled={!props.edit} variant="contained" onClick={handleClickOpen} startIcon={<AddIcon/>}>Add Contact</Button>
                    </Grid> : null}
                        
                </Grid>
            </Grid>
        </Grid>      
    )
}

export default withRouter(ContactsTab)