import React from 'react'
import MaterialTable from 'material-table';
import { TableIcons } from '../../../../constants/tableIcons';
import {  Grid} from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const WorkOrderTable = (props) => {
    return (
    <Grid>
        <MaterialTable
            columns={[
                { title: 'Customer', render: rowData => rowData.customer.companyName},
                { title: 'Unit', render: rowData => rowData.vehicle.unitNumber},
                { title: 'Repairs Needed', render: rowData => rowData.repairsNeeded.length},
                { title: 'Repairs Completed', render: rowData => rowData.repairsNeeded.filter(x=> x.completed).length},
            ]}
            data={props.workOrderData}
            isLoading={props.workOrderLoading}
            icons={TableIcons}
            //pathname='/dashboard/reporting/timesheets/workOrder'
            title={"Work Order Details"}
            options={
                {
                    pageSize: 10,
                    pageSizeOptions: [],
                    toolbar: true,
                    paging: true,
                    actionsColumnIndex: -1,
                    search: false,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    rowStyle: {
                        whiteSpace: 'pre-wrap',
                    },
                }}
            actions={[
                {
                    icon: TableIcons.OpenInNew,
                    tooltip: 'Show Work Order',
                    onClick: (event, rowData) => props.history.push({
                        pathname: '/dashboard/workOrders/form',
                        search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                    })
                },
                // {
                //     icon: TableIcons.Edit,
                //     tooltip: 'Edit Unit',
                //     onClick: (event, rowData) => {
                //         openModal(rowData.docId, true)
                //     }
                // },
                // {
                //     icon: TableIcons.Add,
                //     tooltip: 'Add Unit',
                //     isFreeAction: true,
                //     onClick: (event, rowData) => {
                //         openModal(rowData.docId, false)
                //     }
                // },

            ]}
        />
    </Grid>
    )
}
export default withRouter(WorkOrderTable)
