import React from 'react';
// import { Font, Image, StyleSheet } from '@react-pdf/renderer';
import { Image } from '@react-pdf/renderer';
//import { makeStyles } from '@material-ui/core';
import header from '../../../../../img/header.png'

// const useStyles = makeStyles((theme) => ({
//     root: {
//         display: 'flex',
//         justifyContent: 'center',
//         width: '100%',
//     },
//     content: {
//         display: 'flex',
//         flexDirection: 'column',
//         width: '100%',
//         maxWidth: '1200px',
//         margin: 'auto',
//     },
//     buttonRow: {
//         marginBottom: '20px',
//         marginTop: '20px',
//     },
//     checkRow: {
//         margin: theme.spacing(1),
//         display: 'flex',
//         justifyContent: 'space-evenly',
//     },
// }));

// Font.register({
//     family: 'Oswald',
//     src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
// });

// // Create styles
// const styles = StyleSheet.create({
//     header: {
//         width: '100%',
//         height: '100%',
//     },
// });

const PDFHeader = (props) => {

   // const classes = useStyles();

    // /**
    //  * @param title - Table Header
    //  * @param columns - Generates table column header and its internal data. Can pass either an objects key to iterate or a function.
    //  * @param data - The table data passed (as an array of objects)
    //  * @param components - React components passed as special rendering for additional rows 
    // */

    return (
        <div style={{marginBottom: '10px'}}>         
            <Image src={header} key={'header'} cache={false} />

            {/* {props.invoiceDetails.useSystemLogo &&
                <View style={{...styles.header}}>
                    <View style={{flexDirection: 'column'}}>
                        <Text style={{...styles.tableText, fontSize: '26px'}}>Gee Tee Holdings Inc.</Text>
                        <Text style={{...styles.tableText, fontSize: '10px'}}>Gravel, Grain, Snow Removal, Landscape Materials and Equipment Services</Text>
                    </View>

                    <View style={{flexDirection: 'column'}}>
                        <Text style={{...styles.tableText, fontSize: '8px'}}>geeteeholdings.com</Text>
                        <Text style={{...styles.tableText, fontSize: '8px'}}>Box 400 Oakbank, Manitoba ROE 1J0</Text>
                        <Text style={{...styles.tableText, fontSize: '8px'}}>Phone (204) 444-3069</Text>
                        <Text style={{...styles.tableText, fontSize: '8px'}}>admin@geetee.ca</Text>
                    </View>
                </View>
            } */}
         </div>

  
    )
};

export default PDFHeader;