import React, {useState, useEffect} from 'react';
import { firestore } from '../../../../firebase/firebase'
import { withRouter } from 'react-router-dom';
import DeliveryCard from '../../../components/Reports/Deliveries/DeliveryCard'
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        padding: '32px',
        justifyContent: 'center',
        marginTop: '40px',
    },
}));

const Delivery = (props) => {
    const [deliveryData, setDeliveryData] = useState({});
    const [userData, setUserData] = useState({});
    const [unitData, setUnitData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(false);

    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
    
        let path = new URLSearchParams(props.location.search).get("docId");
    
        // Create a reference to the "deliveries" collection
        const deliveriesRef = firestore.collection('deliveries');
    
        // Set up the listener for changes in the "deliveries" collection
        const unsubscribe = deliveriesRef.where('__name__', "==", path).onSnapshot(snapshot => {
            if (snapshot.empty) {
                setErrorMessage("No document found");
                setLoading(false);
                return;
            }
    
            const deliveryResponse = snapshot.docs.map(doc => ({
                ...doc.data(),
                docId: doc.id,
            }));
    
            setDeliveryData(deliveryResponse[0]);
    
            const userDataPromises = deliveryResponse.map(delivery => {
                return firestore.collection('users').where("uuid", "==", delivery.uid).get();
            });
    
            Promise.all(userDataPromises)
                .then(userSnapshots => {
                    const userResponse = userSnapshots.map(snapshot => {
                        if (!snapshot.empty) {
                            const userData = snapshot.docs.map(doc => ({
                                ...doc.data(),
                                docId: doc.id,
                            }));
                            return userData[0];
                        }
                        return null;
                    });
                    setUserData(userResponse[0]);
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                });
    
            const unitDataPromises = deliveryResponse.map(delivery => {
                return firestore.collection('units').where("timesheetID", "==", delivery.timesheetID).get();
            });
    
            Promise.all(unitDataPromises)
                .then(unitSnapshots => {
                    const unitResponse = unitSnapshots.map(snapshot => {
                        return snapshot.docs.map(doc => ({
                            ...doc.data(),
                            docId: doc.id,
                        }));
                    });
                    setUnitData(unitResponse.flat());
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Error fetching unit data:", error);
                    setLoading(false);
                });
        }, error => {
            console.error("Error fetching delivery data:", error);
            setErrorMessage("Unable to Process");
            setLoading(false);
        });
    
        // Clean up function to unsubscribe from the listener when the component unmounts or when the dependency array changes
        return () => unsubscribe();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        loading 
        ? 
        <div className={classes.content}>
            <Skeleton variant='rect' width={'41vw'} height={'95vh'}/>
        </div>
        : 
        <DeliveryCard deliveryData={deliveryData} userData={userData} unitData={unitData} errorMSG={errorMessage} />
    )
}

export default withRouter(Delivery)