import React, { useState } from 'react';
import {Grid, makeStyles, Button, Typography, Paper, TextField, CircularProgress, Divider, InputAdornment,
        useMediaQuery, useTheme, Badge, Box } from '@material-ui/core';
import { withRouter } from 'react-router';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import MediaViewer from '../UI/MediaViewer/MediaViewer';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
    },
    loading: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '90vw',
        margin: 'auto',
        userSelect: 'none',
        pointerEvents: 'none',
        opacity: 0.5,
    },
    columnn1: {
      padding: theme.spacing(1),
    },
    backButton: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    paper: {
        //borderRadius: '0px',
        margin: '0px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1200px',
        padding: '16px',
       // backgroundColor: 'rgb(252, 252, 249)',
    },
    container: {
        marginBottom: '10px',
        padding: '15px'
    },
    textRow: {
        //lineHeight: 2,
        //marginBottom: '8px',
    },
    textRowTypography: {
        marginTop: '4px',
        lineHeight: 2,
        marginBottom: '8px',
    },
    selectRow: {
        paddingRight: '5px',
        paddingLeft: '5px',
    },
    table: {
        display: 'flex',
        justifyContent: 'center',
    },
  }));
  
const TicketCard = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [photoViewerOpen, setPhotoViewerOpen] = useState(false)

    const handleAutoCompleteOpenClose = (key, bool) => {
        props.setAutoCompletes((previousValues) => { return ({...previousValues, [key]: {...previousValues[key], open: bool} }) })
    }

    const handleCalcPayrollHours = (timeIn, timeOut, nDay) => {
        let returnValue = 0.00;
        let start = moment(timeIn || props.currentTicket.timeIn)
        let end = moment(timeOut || props.currentTicket.timeOut)
        let nextDay = !_.isUndefined(nDay) ? nDay : props.currentTicket.nextDay

        if((start !== null || start !== undefined) && (end !== null || end !== undefined)){
            if(nextDay){
                let duration = moment.duration(end.diff(start));
                duration = duration.add(24, 'hours');
                returnValue = duration.asHours().toFixed(2);
            }
            else{
                let duration = moment.duration(end.diff(start));
                returnValue = duration.asHours().toFixed(2);
            }
        }
        return returnValue;
    }

    return (
      <Grid className={classes.content}> 
        {props.currentTicket.photoURLs?.length > 0 ? 
            <MediaViewer open={photoViewerOpen} handleClose={() => setPhotoViewerOpen(false)} photoURLs={props.currentTicket.photoURLs} /> 
        : null}

        <Grid container className={classes.backButton}>
            <Grid item xs={6}>
                <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
            </Grid>
        </Grid>

        <Paper className={((props.currentTicket.invoicePayDate === null || props.currentTicket.invoicePayDate === undefined) 
                        && (props.currentTicket.operatorPayDate === null || props.currentTicket.operatorPayDate === undefined))
                        ? null : classes.loading}>
            <Grid container className={classes.columnn1} >
                <Grid container style={{padding: '10px'}}>
                    <Grid item xs={6}>
                        <Typography variant={'h5'} className={classes.textRowTypography}>
                            {/* {!_.isEmpty(props.currentTicket) ? `Ticket Id: ${props.currentTicket.ticketId}` : `Ticket Id: Choose a Ticket to begin`} */}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent='flex-end'>
                            <Grid container justifyContent='flex-end'>
                                <Typography><small>{`Data Entry Date: ${moment(props.currentTicket.dataEntryDate).format('YYYY-MM-DD h:mm a')}`}</small></Typography>
                            </Grid>   
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={12} md={4} style={{paddingBottom: '10px'}}>
                    <Grid container>
                        <Grid item xs={3} style={{paddingRight: '5px'}}>
                            <Grid container justifyContent='flex-end'>
                                <Typography className={classes.textRowTypography}>
                                    Ticket Number:
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField 
                                InputProps={{ inputProps: { tabIndex: 1 } }}
                                variant='outlined' 
                                className={classes.selectRow} 
                                fullWidth 
                                size='small' 
                                value={props.currentTicket.ticket || ''}
                                onChange={(e)=> {props.setCurrentTicket({...props.currentTicket, ticket: e.target.value}) }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            {/* {props.currentTicket.ticket === 'Unnumbered' && 
                                <Tooltip title={"Ticket Number already in use"}>
                                    <ErrorIcon fontSize="small" style={{color: 'red'}}/>
                                </Tooltip>
                            } */}
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={3}  style={{ paddingRight: '5px'}}>
                            <Grid container justifyContent='flex-end'>
                                <Typography className={classes.textRowTypography}>
                                    Contact:
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                open={props.autoCompletes['customerId'].open}
                                onOpen={() => { handleAutoCompleteOpenClose('customerId', true); }}
                                onClose={() => { handleAutoCompleteOpenClose('customerId', false); }}
                                loading={props.internalLoading}
                                //label="Customer"
                                size='small'
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                //freeSolo={true}
                                className={classes.selectRow}
                                value={props.autoCompletes['customerId'].value || ''}
                                onChange={(event, newValue) => { 
                                    //sets autocomplete and ticket, removes job and rate as customer has changed
                                    props.setCurrentTicket({ ...props.currentTicket, customerId: newValue?.docId || null})  
                                    props.setAutoCompletes({...props.autoCompletes, customerId: {...props.autoCompletes.customerId, value: newValue}, 
                                                            job: {open: false, value: {}}, rate: {open: false, value: {}}}) 
                                }}
                                options={props.customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                                getOptionLabel={(option) =>{ 
                                    let string = option.name ? option.name : '';
                                    if((!_.isUndefined(option.streetNumber) || !_.isUndefined(option.streetName))){
                                        string = string.concat(" - " + (option.streetNumber || '') + ' ' + (option.streetName || '') )
                                    }
                                    return string;
                                }}
                                getOptionSelected={(option, value) =>  option.name === value.name || {} }
                                renderOption={(option) =>( 
                                    props.customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                        return(
                                            <Grid key={option.docId} >
                                                <Typography>{customerObj.name}</Typography>
                                                <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                            </Grid>
                                        )
                                    })
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Customer"
                                        variant="outlined"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            inputProps: { ...params.inputProps, tabIndex: 3 },
                                            endAdornment: (
                                                <React.Fragment>
                                                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {/* <Grid item xs={1}>
                            {(props.currentTicket.customerId === null || props.currentTicket.customerId === undefined || props.currentTicket.customerId === '') && !_.isUndefined(props.currentTicket.customer) && 
                                <Tooltip title={`Customer info present (${props.currentTicket.customer}) but no CustomerId found. Will not show in invoices/payroll`}>
                                    <ErrorIcon fontSize="small" style={{color: 'red'}}/>
                                </Tooltip>
                            }
                        </Grid> */}
                    </Grid>

                    {(props.currentTicket.customerId === null || props.currentTicket.customerId === undefined || props.currentTicket.customerId === '') && !_.isUndefined(props.currentTicket.customer) && 
                        <Grid container>
                            <Grid item xs={3}/>
                            <Grid item xs={8}>
                                <Typography style={{color: 'red', fontSize: '12px'}} component='div'>
                                    ** Driver entered <Box fontWeight='600' display='inline'>{props.currentTicket.customer}</Box> as text **
                                </Typography>
                            </Grid>
                        </Grid>
                    }

                    <Grid container>
                        <Grid item xs={3}  style={{ paddingRight: '5px'}}>
                            <Grid container justifyContent='flex-end'>
                                <Typography className={classes.textRowTypography}>
                                    Rate:
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Autocomplete
                                open={props.autoCompletes['rate'].open}
                                onOpen={() => { handleAutoCompleteOpenClose('rate', true); }}
                                onClose={() => { handleAutoCompleteOpenClose('rate', false); }}
                                loading={props.internalLoading}
                                //label="Customer"
                                size='small'
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                className={classes.selectRow}
                                value={props.autoCompletes['rate'].value || ''}
                                onChange={(event, newValue) => { 
                                    let container = _.cloneDeep(props.currentTicket)
                                    container.rate = newValue?.docId || null

                                    //if cartage rate not set, set with rate
                                    if(Number(container.cartageSaleRate) === 0.00 || container.cartageSaleRate === undefined || container.cartageSaleRate === null){ 
                                        container.cartageSaleRate = newValue.rate;
                                    }

                                    props.setCurrentTicket(container)  
                                    props.setAutoCompletes({...props.autoCompletes, rate: {...props.autoCompletes.rate, value: newValue}, }) 
                                }}
                                options={props.ratesData.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                                getOptionLabel={(option) => option.name ? option.name + ' - ' + Number(option.rate).toFixed(2) : ''  }
                                getOptionSelected={(option, value) => option.name === value.name || {} }
                                renderOption={(option) =>( 
                                    props.ratesData.filter(rate => rate.docId === option.docId).map((rate) => {
                                        return(
                                            <Grid key={option.docId} >
                                                <Typography>{`${rate.name} - ${Number(option.rate).toFixed(2)}`}</Typography>
                                            </Grid>
                                        )
                                    })
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Customer"
                                        variant="outlined"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            inputProps: { ...params.inputProps, tabIndex: 4 },
                                            endAdornment: (
                                                <React.Fragment>
                                                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>

                    {/* <Grid container>
                        <Grid item xs={3} style={{ paddingRight: '5px'}}>
                            <Grid container justifyContent='flex-end'>
                            <Typography className={classes.textRowTypography}>
                                Job:
                            </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                open={props.autoCompletes['job'].open}
                                onOpen={() => { handleAutoCompleteOpenClose('job', true); }}
                                onClose={() => { handleAutoCompleteOpenClose('job', false); }}
                                //loading={props.loading}
                                //label="Job"
                                size='small'
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                className={classes.selectRow}
                                fullWidth
                                value={props.autoCompletes['job'].value || ''}
                                onChange={(event, newValue) => { 
                                    //sets autocomplete and ticket, removes rate as job has changed
                                    props.setCurrentTicket({ ...props.currentTicket, job: newValue?.jobId || null, rate: null, salesRate: null })  
                                    props.setAutoCompletes({...props.autoCompletes, job: {...props.autoCompletes.job, value: newValue}, rate: {open: false, value: {}}})
                                }}
                                options={props.customerJobs?.sort((a, b) => a.jobNumber.localeCompare(b.jobNumber)) || {}}
                                getOptionLabel={(option) => option.jobNumber ? option.jobNumber : ''  }
                                getOptionSelected={(option, value) =>  option.jobNumber === value.jobNumber || {} }
                                renderOption={(option) =>( 
                                    props.customerJobs.filter(x => x.jobId === option.jobId).map((obj) => {
                                        return(
                                            <Grid key={option.jobId} >
                                                <Typography>{obj.jobNumber}</Typography>
                                            </Grid>
                                        )
                                    })
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Job"
                                        variant="outlined"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            inputProps: { ...params.inputProps, tabIndex: 4 },
                                            endAdornment: (
                                                <React.Fragment>
                                                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={3} style={{ paddingRight: '5px'}}>
                            <Grid container justifyContent='flex-end'>
                                <Typography className={classes.textRowTypography}>
                                    Rate:
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Autocomplete
                                open={props.autoCompletes['rate'].open}
                                onOpen={() => { handleAutoCompleteOpenClose('rate', true); }}
                                onClose={() => { handleAutoCompleteOpenClose('rate', false); }}
                                //loading={props.loading}
                                //label="Rate"
                                size='small'
                                id="autocomplete"
                                autoComplete
                                autoHighlight
                                className={classes.selectRow}
                                fullWidth
                                value={props.autoCompletes['rate'].value || ''}
                                onChange={(event, newValue) => { 
                                    let newTicketContainer = { ...props.currentTicket, rate: newValue?.jobRateId || null, salesRate: null }
                                    newTicketContainer.notes = props.handleInstructionsRateChanges(props.currentTicket, newTicketContainer)
                                
                                    props.setCurrentTicket(newTicketContainer)
                                    props.setAutoCompletes({...props.autoCompletes, rate: {...props.autoCompletes.rate, value: newValue}})
                                }}
                                options={props.jobRates.sort((a, b) => a.description.localeCompare(b.description)) || {}}
                                getOptionLabel={(option) => option.description ? option.description : ''  }
                                getOptionSelected={(option, value) =>  option.description === value.description || {} }
                                renderOption={(option) =>( 
                                    props.jobRates.filter(customer => customer.jobRateId === option.jobRateId).map((obj) => {
                                        return(
                                            <Grid key={option.jobRateId} >
                                                <Typography>{obj.description}</Typography>
                                            </Grid>
                                        )
                                    })
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        //label="Rate"
                                        variant="outlined"
                                        size='small'
                                        InputProps={{
                                            ...params.InputProps,
                                            inputProps: { ...params.inputProps, tabIndex: 5 },
                                            endAdornment: (
                                                <React.Fragment>
                                                    {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid> */}

                </Grid>

                <Grid item xs={12} md={5} style={{paddingBottom: '10px'}}>
                    <Grid container>
                        <Grid item xs={11}  >
                            <KeyboardDatePicker
                                InputProps={{ inputProps: { tabIndex: 2 } }}
                                KeyboardButtonProps={{tabIndex: -1}}
                                label="Date"
                                value={props.currentTicket.date}
                                onChange={(value) => { props.setCurrentTicket({ ...props.currentTicket, date: moment(value).format("YYYY-MM-DD").valueOf() }) }}
                                fullWidth
                                className={classes.textRowTypography}
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={11} >
                            <TextField
                                InputProps={{ inputProps: { tabIndex: 23 } }}
                                multiline
                                minRows={4}
                                variant='outlined'
                                fullWidth
                                placeholder='Operator Notes'
                                value={props.currentTicket.notes || ''}
                                onChange={(e) => { props.setCurrentTicket({ ...props.currentTicket, notes: e.target.value }) }}
                            >
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} md={3} style={{paddingBottom: '10px'}}>
                    <Grid container justifyContent='center' style={{padding: '10px'}}>
                        <Typography>Fuel Surcharge:</Typography>
                    </Grid>

                    <Grid container>
                        <Grid item xs={6}>
                            <TextField
                                variant='outlined'
                                style={{marginRight: '5px'}}
                                value={props.currentTicket.percentageFuelSurcharge || ''}
                                onChange={(e) => { props.setCurrentTicket({ ...props.currentTicket, percentageFuelSurcharge: e.target.value }) }}
                                onBlur={(event) => {
                                    let dollarSurchargeValue = Number(Number(event.target.value / 100)  * Number(props.currentTicket.cartageSalesTotal || 0.00)).toFixed(2)

                                    props.setCurrentTicket({ ...props.currentTicket, percentageFuelSurcharge: Number(event.target.value).toFixed(2), 
                                        dollarFuelSurcharge: dollarSurchargeValue})
                                }}
                                InputProps={{
                                    inputProps: { tabIndex: 17 },
                                    startAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                variant='outlined'
                                value={props.currentTicket.dollarFuelSurcharge || ''}
                                onChange={(e) => { props.setCurrentTicket({ ...props.currentTicket, dollarFuelSurcharge: e.target.value }) }}
                                onBlur={(event) => {
                                    let percentSurchargeValue = Number(Number(event.target.value) / Number(props.currentTicket.cartageSalesTotal || 0.00)  * 100).toFixed(2)

                                    props.setCurrentTicket({ ...props.currentTicket, dollarFuelSurcharge: Number(event.target.value).toFixed(2), 
                                        percentageFuelSurcharge: percentSurchargeValue})
                                }}
                                InputProps={{
                                    inputProps: { tabIndex: 18 },
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </Grid>
                
                    </Grid>
                </Grid>

                <Grid container style={{padding: '10px'}}>
                    <Grid item xs={6} md={3}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Autocomplete
                                    open={props.autoCompletes['uid'].open}
                                    onOpen={() => { handleAutoCompleteOpenClose('uid', true); }}
                                    onClose={() => { handleAutoCompleteOpenClose('uid', false); }}
                                    loading={props.userLoading}
                                    label="Operator"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    className={classes.selectRow}
                                    fullWidth
                                    value={props.autoCompletes['uid'].value || ''}
                                    onChange={(event, newValue) => {
                                        let autocompleteContainer = {...props.autoCompletes, uid: {...props.autoCompletes.uid, value: newValue}}
                                        let currentTicketContainer = { ...props.currentTicket, uid: newValue?.uuid || null }

                                        if(( !props.currentTicket.units[0] && newValue !== null)){
                                            let unitContainer = props.users.find(x=> x.docId === newValue?.uuid)?.commonEquipment?.fullUnit
                                            //console.log('unitContainer', unitContainer)
                                            if(!_.isUndefined(unitContainer)){  
                                                currentTicketContainer.units[0] = unitContainer.docId; 
                                                autocompleteContainer.equipment.value = unitContainer;
                                            }
                                        }

                                        //holds doc id (or null to allow for clearable)
                                        props.setCurrentTicket(currentTicketContainer) 
                                        //holds object (and is value)
                                        props.setAutoCompletes(autocompleteContainer)
                                    }}
                                    options={props.users.filter(x=> x.employment?.active).sort((a, b) => a.displayName.localeCompare(b.displayName)) || {}}
                                    getOptionSelected={(option, value) => option?.uuid === value?.uuid || {}}
                                    getOptionLabel={(option) => option.displayName || '' }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Operator"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: { ...params.inputProps, tabIndex: 6 },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Autocomplete
                                    open={props.autoCompletes['units'].open}
                                    onOpen={() => { handleAutoCompleteOpenClose('units', true); }}
                                    onClose={() => { handleAutoCompleteOpenClose('units', false); }}
                                    loading={props.unitLoading}
                                    label="Equipment"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    className={classes.selectRow}
                                    fullWidth
                                    value={props.autoCompletes['units'].value || ''}
                                    onChange={(event, newValue) => { 
                                        let autoCompleteContainer = {...props.autoCompletes, units: {...props.autoCompletes.units, value: newValue}}
                                        //let currentTicketContainer = { ...props.currentTicket, units: [ ...props.currentTicket.units, newValue?.docId || null] }
                                        let currentTicketContainer = _.cloneDeep(props.currentTicket)
                                        currentTicketContainer.units[0] = newValue?.docId || null;
                                        
                                        if((!props.currentTicket.uid && newValue !== null)){
                                            let userContainer = props.users.filter(x=> x.commonEquipment?.fullUnit?.docId === newValue?.docId).pop();
                                            //console.log('userContainer', userContainer)
                                            if(!_.isUndefined(userContainer)){  
                                                currentTicketContainer.uid = userContainer.docId;
                                                autoCompleteContainer.uid.value = userContainer;
                                            }
                                        }

                                        //holds doc id (or null to allow for clearable)
                                        props.setCurrentTicket(currentTicketContainer) 
                                        //holds object (and is value)
                                        props.setAutoCompletes(autoCompleteContainer)
                                    }}
                                    options={props.units || {}}
                                    getOptionSelected={(option, value) => option.unitNumber === value.unitNumber || {}}
                                    getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : ''}
                                    renderOption={(unit) =>(
                                        <Grid >
                                            <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Equipment"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: { ...params.inputProps, tabIndex: 7 },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {/* error icon here */}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <Grid item xs={4} md={6}>
                        <Grid container justifyContent='center'>
                            <Typography style={{borderBottom: "1px solid black"}}>Equipment List:</Typography>
                                {props.currentUnits.map((unit, index) => {
                                    return (
                                        <Grid container key={index}>
                                            <Grid item xs={8}>
                                                <Grid container justifyContent='center'>
                                                    <Typography style={{marginTop: '5px'}}>{'#' + unit?.unitNumber + ' ' + (unit?.description || '')}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Tooltip title={"Remove Equipment"}>
                                                    <IconButton size='small' onClick={(e) => { props.handleRemoveUnit(index) }}><DeleteIcon/></IconButton> 
                                                </Tooltip>
                                                
                                                {unit.noParentRefError && 
                                                    <Tooltip title={"No ParentUnitDocId, will not show in invoices/payroll"}>
                                                        <ErrorIcon fontSize="small" style={{color: 'red'}}/>
                                                    </Tooltip>
                                                }
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                        </Grid>             
                    </Grid> */}

                    <Grid item xs={6} md={3}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Autocomplete
                                    open={props.autoCompletes['trailer1'].open}
                                    onOpen={() => { handleAutoCompleteOpenClose('trailer1', true); }}
                                    onClose={() => { handleAutoCompleteOpenClose('trailer1', false); }}
                                    loading={props.unitLoading}
                                    label="Trailer 1"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    className={classes.selectRow}
                                    fullWidth
                                    value={props.autoCompletes['trailer1'].value || ''}
                                    disabled={props.currentTicket.units?.length < 1}
                                    onChange={(event, newValue) => { 
                                        //holds doc id (or null to allow for clearable)
                                        //props.setCurrentTicket({ ...props.currentTicket, trailer1: newValue?.docId || null  }) 

                                        let currentTicketContainer = _.cloneDeep(props.currentTicket)
                                        currentTicketContainer.units[1] = newValue?.docId || null;
                                        props.setCurrentTicket(currentTicketContainer)

                                        //holds object (and is value)
                                        props.setAutoCompletes({...props.autoCompletes, trailer1: {...props.autoCompletes.trailer1, value: newValue}})
                                    }}
                                    options={props.units || {}}
                                    getOptionSelected={(option, value) => option?.unitNumber === value?.unitNumber || {}}
                                    getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : '' }
                                    renderOption={(unit) =>(
                                        <Grid >
                                            <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Trailer 1"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: { ...params.inputProps, tabIndex: 8 },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Autocomplete
                                    open={props.autoCompletes['trailer2'].open}
                                    onOpen={() => { handleAutoCompleteOpenClose('trailer2', true); }}
                                    onClose={() => { handleAutoCompleteOpenClose('trailer2', false); }}
                                    loading={props.unitLoading}
                                    label="Trailer 2"
                                    size='small'
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    className={classes.selectRow}
                                    fullWidth
                                    value={props.autoCompletes['trailer2'].value || ''}
                                    disabled={props.currentTicket.units?.length < 2}
                                    onChange={(event, newValue) => { 
                                        //holds doc id (or null to allow for clearable)
                                        //props.setCurrentTicket({ ...props.currentTicket, trailer2: newValue?.docId || null  })

                                        let currentTicketContainer = _.cloneDeep(props.currentTicket)
                                        currentTicketContainer.units[2] = newValue?.docId || null;
                                        props.setCurrentTicket(currentTicketContainer)

                                        //holds object (and is value)
                                        props.setAutoCompletes({...props.autoCompletes, trailer2: {...props.autoCompletes.trailer2, value: newValue}})
                                    }}
                                    options={props.units || {}}
                                    getOptionSelected={(option, value) => option.unitNumber === value.unitNumber || {}}
                                    getOptionLabel={(option) => option.unitNumber ? '#' + option.unitNumber + ' ' + option.description : '' }
                                    renderOption={(unit) =>(
                                        <Grid >
                                            <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                        </Grid>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Trailer 2"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: { ...params.inputProps, tabIndex: 9 },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


                <Grid container style={{padding: '10px',}}>
                    <Grid item xs={12} md={3} style={{padding: '10px', position: 'relative'}}>
                        <Grid container justifyContent='center' > 
                            <Typography style={{fontWeight: 600}} className={classes.textRow}>Material</Typography>
                        </Grid>
                        <Divider className={classes.textRow} style={{marginBottom: '8px'}}/>
                        
                        <Grid container>
                            <Grid item xs={6} style={{paddingRight: '5px'}}>
                                <Grid container justifyContent='flex-end' >    
                                    <Typography className={classes.textRowTypography}>Material:</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    open={props.autoCompletes['material'].open}
                                    onOpen={() => { handleAutoCompleteOpenClose('material', true); }}
                                    onClose={() => { handleAutoCompleteOpenClose('material', false); }}
                                    id="materialAutocomplete"
                                    //className={classes.selectRow}
                                    autoComplete
                                    autoHighlight
                                    fullWidth
                                    freeSolo={true}
                                    size='small'
                                    value={props.autoCompletes['material'].value || ''}
                                    onInputChange={(event, newValue)=> {    //onInput changes any null value to empty strings
                                        //props.setCurrentTicket({ ...props.currentTicket, material: newValue === "" ? null : newValue }) 
                                        props.setCurrentTicket((previousValues) => { return ({...previousValues, material: newValue === "" ? null : newValue  }) })
                                        //props.setAutoCompletes({...props.autoCompletes, material: {...props.autoCompletes.material, value: newValue === "" ? null : newValue}})
                                        props.setAutoCompletes((previousValues) => { return ({...previousValues, material: {...previousValues.material, value: newValue === "" ? null : newValue}  }) })
                                    }}
                                    onChange={(event, newValue) => {
                                        props.setCurrentTicket({ ...props.currentTicket, material: newValue })
                                        //holds object (and is value)
                                        props.setAutoCompletes({...props.autoCompletes, material: {...props.autoCompletes.material, value: newValue}})
                                    }}
                                    options={props.materials.map((material) => material ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                    getOptionSelected={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            //variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: { ...params.inputProps, tabIndex: 12 },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={6} style={{paddingRight: '5px'}}>
                                <Grid container justifyContent='flex-end'>
                                    <Typography className={classes.textRowTypography}>Material Sales Rate :</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} >
                                <TextField 
                                    fullWidth
                                    className={classes.textRow}
                                    value={props.currentTicket.materialSalesRate || ''}
                                    InputProps={{
                                        inputProps: { tabIndex: 28 },
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    onChange={(e) => {
                                        props.setCurrentTicket({ ...props.currentTicket, materialSalesRate: e.target.value }) 
                                    }}
                                    onBlur={(e) => {
                                        props.setCurrentTicket({ ...props.currentTicket, materialSalesRate: Number(e.target.value).toFixed(2), materialSalesTotal: Number((props.currentTicket.quantity || 0) * Number(e.target.value) || 0).toFixed(2)}) 
                                    }}
                                />
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}style={{paddingRight: '5px'}}>
                                    <Grid container justifyContent='flex-end' >
                                        <Typography className={classes.textRowTypography}>Material Units :</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField 
                                        fullWidth
                                        InputProps={{ inputProps: { tabIndex: 13 } }}
                                        className={classes.textRow}
                                        value={props.currentTicket.quantity || ''}
                                        onChange={(e) => {
                                            props.setCurrentTicket({ ...props.currentTicket, quantity: e.target.value }) 
                                        }}
                                        onBlur={(e) => {
                                            props.setCurrentTicket({ ...props.currentTicket, quantity: Number(e.target.value), materialSalesTotal: Number((e.target.value) * Number(props.currentTicket.materialSalesRate) || 0).toFixed(2)}) 
                                        }}
                                    />
                                </Grid>
                            </Grid>
                    

                            <Grid container justifyContent='center' style={smallScreen ? null : {position: 'absolute', bottom: 0,}}>
                                <Typography>
                                    {`Material Sales:   $${props.currentTicket.materialSalesTotal || Number(0.00).toFixed(2)}`}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={3} style={{padding: '10px', position: 'relative'}}>
                        <Grid container justifyContent='center'> 
                            <Typography style={{fontWeight: 600}} className={classes.textRow}>Cartage</Typography>
                        </Grid>
                        <Divider className={classes.textRow} style={{marginBottom: '8px'}}/>

                       {/* <Grid container>
                             <Grid item xs={6} style={{paddingRight: '5px'}}>
                                <Grid container justifyContent='flex-end'>
                                    <Typography className={classes.textRowTypography}>Sale Rate:</Typography>
                                </Grid>
                            </Grid>

                             <Grid item xs={6}>
                                <Select
                                    value={props.currentTicket.salesRate || ''}
                                    fullWidth
                                    onChange={(event)=> {
                                        let salesRate = event.target.value
                                        let notes = props.handleInstructionsRateChanges(props.currentTicket, { ...props.currentTicket, salesRate: event.target.value });
                                        let cartageSaleRate = Number(props.salesRates.find(x=> x.salesRateId === event.target.value)?.salesRate || 0.00).toFixed(2)
                                        let payrollAdjustedBase = props.currentTicket.payrollAdjustedBase || 0.00;
                                        let payroll = props.currentTicket.payroll || 0.00;
                                        let cartageSalesTotal = props.currentTicket.cartageSalesTotal || 0.00;
                                        let dollarFuelSurcharge = props.currentTicket.dollarFuelSurcharge || 0.00;

                                        if(!_.isUndefined(props.currentTicket.linkOperatorToSalesRate) && props.currentTicket.linkOperatorToSalesRate){
                                            payrollAdjustedBase = Number((Number(cartageSaleRate)) * (Number((props.currentTicket.payrollMultiplier || 100.00) / 100))).toFixed(2)
                                            payroll = Number( payrollAdjustedBase * (props.currentTicket.payrollUnits || 0) * ((props.currentTicket.payrollRate || 0))).toFixed(2)
                                        }

                                        dollarFuelSurcharge = Number(Number((props.currentTicket.percentageFuelSurcharge || 0.000) / 100)  * Number(Number(cartageSaleRate) * Number((props.currentTicket.cartageSaleUnits || 0)))).toFixed(2)
                                        cartageSalesTotal = Number((Number(cartageSaleRate) * Number((props.currentTicket.cartageSaleUnits || 0)))).toFixed(2)

                                        props.setCurrentTicket({...props.currentTicket, notes: notes, cartageSaleRate: Number(cartageSaleRate).toFixed(2), salesRate: salesRate,
                                            cartageSalesTotal: cartageSalesTotal, payrollAdjustedBase: payrollAdjustedBase, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                    }}
                                >
                                    <MenuItem key={'noValue'} value={''}>None</MenuItem>
                                    {props.salesRates.map((rate) => {
                                        return (
                                            <MenuItem key={rate.salesRateId} value={rate.salesRateId}>{`${rate.salesRate} - ${rate.description}`}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </Grid>
                        </Grid> */}

                        <Grid container>
                            <Grid item xs={6} style={{paddingRight: '5px'}}>
                                <Grid container justifyContent='flex-end'>
                                    <Typography className={classes.textRowTypography}>Cartage Rate:</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    fullWidth 
                                    InputProps={{ inputProps: { tabIndex: 10 } }}
                                    className={classes.textRow}
                                    value={props.currentTicket.cartageSaleRate || ''}
                                    disabled={!_.isEmpty(props.currentTicket.salesRate)}
                                    onChange={(e) => {
                                        props.setCurrentTicket({ ...props.currentTicket, cartageSaleRate: e.target.value }) 
                                    }}
                                    onBlur={(event)=> {
                                        let payrollAdjustedBase = props.currentTicket.payrollAdjustedBase || 0.00;
                                        let payroll = props.currentTicket.payroll || 0.00;
                                        let cartageSalesTotal = props.currentTicket.cartageSalesTotal || 0.00;
                                        let dollarFuelSurcharge = props.currentTicket.dollarFuelSurcharge || 0.00;

                                        if(!_.isUndefined(props.currentTicket.linkOperatorToSalesRate) && props.currentTicket.linkOperatorToSalesRate){
                                            payrollAdjustedBase = Number((Number(event.target.value)) * (Number((props.currentTicket.payrollMultiplier || 100.00) / 100))).toFixed(2)
                                            payroll = Number( payrollAdjustedBase * (props.currentTicket.payrollUnits || 0) * ((props.currentTicket.payrollRate || 0))).toFixed(2)
                                        }

                                        dollarFuelSurcharge = Number(Number((props.currentTicket.percentageFuelSurcharge || 0.000) / 100)  * Number(Number(event.target.value) * Number((props.currentTicket.cartageSaleUnits || 0)))).toFixed(2)
                                        cartageSalesTotal = Number((Number(event.target.value) * Number((props.currentTicket.cartageSaleUnits || 0)))).toFixed(2)

                                        props.setCurrentTicket({...props.currentTicket, cartagerate: Number(event.target.value).toFixed(2),
                                            cartageSalesTotal: cartageSalesTotal, payrollAdjustedBase: payrollAdjustedBase, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                    }}
                                />

                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid item xs={6} style={{paddingRight: '5px'}}>
                                <Grid container justifyContent='flex-end'>
                                    <Typography className={classes.textRowTypography}>Cartage Per:</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    open={props.autoCompletes['cartageSalesPer'].open}
                                    onOpen={() => { handleAutoCompleteOpenClose('cartageSalesPer', true); }}
                                    onClose={() => { handleAutoCompleteOpenClose('cartageSalesPer', false); }}
                                    label="Search"
                                    id="ownerPerAutocomplete"
                                    //className={classes.selectRow}
                                    autoComplete
                                    autoHighlight
                                    fullWidth
                                    freeSolo={true}
                                    size='small'
                                    value={props.autoCompletes['cartageSalesPer'].value || ''}
                                    onInputChange={(event, newValue)=> {    //onInput changes any null value to empty strings
                                        //props.setCurrentTicket({ ...props.currentTicket, cartageSalesPer: newValue === "" ? null : newValue }) 
                                        props.setCurrentTicket((previousValues) => { return ({...previousValues, cartageSalesPer: newValue === "" ? null : newValue  }) })
                                        //props.setAutoCompletes({...props.autoCompletes, cartageSalesPer: {...props.autoCompletes.cartageSalesPer, value: newValue === "" ? null : newValue}})
                                        props.setAutoCompletes((previousValues) => { return ({...previousValues, cartageSalesPer: {...previousValues.cartageSalesPer, value: newValue === "" ? null : newValue}  }) })                              
                                    }}
                                    onChange={(event, newValue) => { 
                                        props.setCurrentTicket({ ...props.currentTicket, cartageSalesPer: newValue }) 
                                        props.setAutoCompletes({...props.autoCompletes, cartageSalesPer: {...props.autoCompletes.cartageSalesPer, value: newValue}})
                                    }}
                                    options={props.per.map((per) => per ).sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                    getOptionSelected={(option, value) => option === value}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            //label="Destination (To)"
                                            //variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                inputProps: { ...params.inputProps, tabIndex: 22 },
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>


                        <Grid container>
                            <Grid item xs={6} style={{paddingRight: '5px'}}>
                                <Grid container justifyContent='flex-end'>
                                    <Typography className={classes.textRowTypography}>Cartage Sales Units:</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField 
                                    fullWidth 
                                    InputProps={{ inputProps: { tabIndex: 11 } }}
                                    className={classes.textRow}
                                    value={props.currentTicket.cartageSaleUnits || ''}
                                    onChange={(e) => {
                                        props.setCurrentTicket({ ...props.currentTicket, cartageSaleUnits: e.target.value }) 
                                    }}
                                    onBlur={(event) => {
                                        let payrollUnits = props.currentTicket.payrollUnits || 0;
                                        let payroll = props.currentTicket.payroll || 0.00;
                                        let dollarFuelSurcharge = props.currentTicket.dollarFuelSurcharge || 0.00;

                                        if(!_.isUndefined(props.currentTicket.linkOperatorToSalesRate) && props.currentTicket.linkOperatorToSalesRate){
                                            payrollUnits = Number(event.target.value)
                                            payroll = Number((props.currentTicket.payrollAdjustedBase || 0.00) * Number(event.target.value) * ((props.currentTicket.payrollRate || 0))).toFixed(2)
                                        }

                                        dollarFuelSurcharge = Number(Number((props.currentTicket.percentageFuelSurcharge || 0.000) / 100)  * Number(Number((props.currentTicket.cartageSaleRate || 0.00)) * Number(event.target.value))).toFixed(2)

                                        props.setCurrentTicket({...props.currentTicket, cartageSalesTotal: Number(Number(event.target.value) * Number((props.currentTicket.cartageSaleRate|| 0.00))).toFixed(2), 
                                            payrollUnits: payrollUnits, payroll: payroll, dollarFuelSurcharge: dollarFuelSurcharge}) 
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={smallScreen ? null :{position: 'absolute', bottom: 0,}}>
                            <Grid container justifyContent='center'>
                                <Typography >
                                    {`Cartage Sales:   $${props.currentTicket.cartageSalesTotal || Number(0.00).toFixed(2)}`}
                                </Typography>
                            </Grid>
                                    
                            <Grid container justifyContent='center'>
                                {(!_.isUndefined(props.currentTicket.invoicePayDate) && props.currentTicket.invoicePayDate !== null)
                                    ?
                                        <Typography><small>{`Invoice Date: ${moment(props.currentTicket.invoicePayDate).format('YYYY-MM-DD h:mm a')}`}</small></Typography>
                                    :
                                        <Typography><small>{`Invoice Date: Not Invoiced`}</small></Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} style={{padding: '10px'}}>
                        <Grid container justifyContent='center'> 
                            <Typography style={{fontWeight: 600}} className={classes.textRow}>Operator</Typography>
                        </Grid>
                        <Divider className={classes.textRow} style={{marginBottom: '8px'}}/>

                        <Grid container>
                            <Grid item xs={8}>
                                {(!_.isUndefined(props.currentTicket.linkOperatorToSalesRate) && props.currentTicket.linkOperatorToSalesRate) &&
                                <Grid container>
                                    <Grid container xs={6} justifyContent='flex-end' style={{paddingRight: '5px'}}>
                                        <Typography className={classes.textRowTypography}>Operator Base Multiplier:</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            InputProps={{
                                                inputProps: { tabIndex: 24 },
                                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                            value={props.currentTicket.payrollMultiplier || ''}
                                            onChange={(e) => {
                                                props.setCurrentTicket({ ...props.currentTicket, payrollMultiplier: e.target.value })  
                                            }}
                                            onBlur={(event) => {
                                                let payrollAdjustedBase = Number((Number((props.currentTicket.cartageSaleRate || 0.00))) * (Number(event.target.value / 100))).toFixed(2)

                                                let payroll = Number((Number(event.target.value / 100) * Number((props.currentTicket.cartageSaleRate|| 0.00)) * Number((props.currentTicket.payrollUnits || 0))) * ((props.currentTicket.payrollRate || 0))).toFixed(2)

                                                props.setCurrentTicket({...props.currentTicket, payrollMultiplier: Number(event.target.value).toFixed(2),
                                                    payrollAdjustedBase: payrollAdjustedBase, payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                }

                                <Grid container>
                                    <Grid item xs={6} style={{paddingRight: '5px'}}>
                                        <Grid container justifyContent='flex-end'>
                                            <Typography className={classes.textRowTypography}>Payroll Rate:</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            className={classes.textRow}
                                            value={props.currentTicket.payrollRate || ''}
                                            InputProps={{
                                                inputProps: { tabIndex: 25 },
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            onChange={(e) => {
                                                props.setCurrentTicket({ ...props.currentTicket, payrollRate: e.target.value })  
                                            }}
                                            onBlur={(event) => {
                                                let payroll = Number(Number((props.currentTicket.payrollAdjustedBase || 0.00)) * Number((props.currentTicket.payrollUnits || 0)) * (Number(event.target.value))).toFixed(2)
                                                //console.log('payroll', payroll)

                                                props.setCurrentTicket({...props.currentTicket, payrollRate: Number(event.target.value).toFixed(2),
                                                    payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6} style={{paddingRight: '5px'}}>
                                        <Grid container justifyContent='flex-end'>
                                            <Typography className={classes.textRowTypography}>Adjusted Base:</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            InputProps={{ inputProps: { tabIndex: 26 } }}
                                            className={classes.textRow}
                                            value={props.currentTicket.payrollAdjustedBase || ''}
                                            onChange={(e) => {
                                                props.setCurrentTicket({ ...props.currentTicket, payrollAdjustedBase: e.target.value }) 
                                            }}
                                            onBlur={(event) => {
                                                let payroll = props.currentTicket.payroll || 0.00;
                                                let payrollAdjustedBase = props.currentTicket.payrollAdjustedBase || 0.00;

                                                if(!_.isUndefined(props.currentTicket.linkOperatorToSalesRate) && props.currentTicket.linkOperatorToSalesRate){
                                                    payrollAdjustedBase = Number((Number(props.currentTicket.cartageSaleRate || 0.00)) * (Number((props.currentTicket.payrollMultiplier || 100.00)) / 100)).toFixed(2)
                                                    payroll = Number(Number(payrollAdjustedBase) * Number((props.currentTicket.payrollUnits || 0)) * (Number((props.currentTicket.payrollRate || 0)))).toFixed(2)
                                                }else{
                                                    payrollAdjustedBase = Number(event.target.value).toFixed(2)
                                                    payroll = Number((Number(payrollAdjustedBase) * Number((props.currentTicket.payrollUnits || 0))) * Number((props.currentTicket.payrollRate || 0))).toFixed(2)
                                                }

                                                props.setCurrentTicket({...props.currentTicket, payrollAdjustedBase: payrollAdjustedBase, payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid container>
                                    <Grid item xs={6} style={{paddingRight: '5px'}}>
                                        <Grid container justifyContent='flex-end'>
                                            <Typography className={classes.textRowTypography}>Payroll Per:</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            open={props.autoCompletes['payrollPer'].open}
                                            onOpen={() => { handleAutoCompleteOpenClose('payrollPer', true); }}
                                            onClose={() => { handleAutoCompleteOpenClose('payrollPer', false); }}
                                            label="Search"
                                            id="payrollPerAutocomplete"
                                            //className={classes.selectRow}
                                            autoComplete
                                            autoHighlight
                                            fullWidth
                                            freeSolo={true}
                                            size='small'
                                            value={props.autoCompletes['payrollPer'].value || ''}
                                            onInputChange={(event, newValue)=> {    //onInput changes any null value to empty strings
                                                // props.setCurrentTicket({ ...props.currentTicket, payrollPer: newValue === "" ? null : newValue }) 
                                                props.setCurrentTicket((previousValues) => { return ({...previousValues, payrollPer: newValue === "" ? null : newValue  }) })
                                                // props.setAutoCompletes({...props.autoCompletes, payrollPer: {...props.autoCompletes.payrollPer, value: newValue === "" ? null : newValue}})
                                                props.setAutoCompletes((previousValues) => { return ({...previousValues, payrollPer: {...previousValues.payrollPer, value: newValue === "" ? null : newValue}  }) })
                                            }}
                                            onChange={(event, newValue) => {
                                                props.setCurrentTicket({ ...props.currentTicket, payrollPer: newValue })
                                                //holds object (and is value)
                                                props.setAutoCompletes({...props.autoCompletes, payrollPer: {...props.autoCompletes.payrollPer, value: newValue}})
                                            }}
                                            options={props.per.map((per) => per ?? '').sort((a, b) => a.toLowerCase() > b.toLowerCase())}
                                            getOptionSelected={(option, value) => option === value}
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    //label="Destination (To)"
                                                    //variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: { ...params.inputProps, tabIndex: 27 },
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6} style={{paddingRight: '5px'}}>
                                        <Grid container justifyContent='flex-end' >
                                            <Typography className={classes.textRowTypography}>Payroll Units:</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField 
                                            fullWidth 
                                            InputProps={{ inputProps: { tabIndex: 16 } }}
                                            className={classes.textRow}
                                            value={props.currentTicket.payrollUnits || ''}
                                            onChange={(e) => {
                                                props.setCurrentTicket({ ...props.currentTicket, payrollUnits: e.target.value })
                                            }}
                                            onBlur={(event) => {
                                                let payroll = props.currentTicket.payroll || 0.00;
                                                let payrollUnits = props.currentTicket.payrollAdjustedBase || 0.00;

                                                if(!_.isUndefined(props.currentTicket.linkOperatorToSalesRate) && props.currentTicket.linkOperatorToSalesRate){
                                                    payrollUnits = Number((props.currentTicket.cartageSaleUnits || 0))
                                                    payroll = Number((Number((props.currentTicket.payrollAdjustedBase || 0.00)) * Number(payrollUnits)) * (Number((props.currentTicket.payrollRate || 0)))).toFixed(2)
                                                }else{
                                                    payrollUnits = Number(event.target.value)
                                                    payroll = Number((Number((props.currentTicket.payrollAdjustedBase|| 0.00)) * Number(payrollUnits)) * (Number((props.currentTicket.payrollRate || 0)))).toFixed(2)
                                                }

                                                props.setCurrentTicket({...props.currentTicket, payrollUnits: payrollUnits, payroll: payroll})
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={6}></Grid>
                                    <Grid item xs={3}>
                                        <KeyboardTimePicker
                                            InputProps={{ inputProps: { tabIndex: 14 } }}
                                            value={props.currentTicket.timeIn}
                                            onChange={(value) => {
                                                let container = _.cloneDeep(props.currentTicket)
                                                container.timeIn = moment(value).valueOf()

                                                if(props.currentTicket.timeOut !== null){
                                                    container.payrollUnits = handleCalcPayrollHours( moment(value).valueOf());
                                                    container.payroll = Number(handleCalcPayrollHours( moment(value).valueOf()) * container.payrollRate).toFixed(2)
                                                }
                                                props.setCurrentTicket(container)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <KeyboardTimePicker
                                            InputProps={{ inputProps: { tabIndex: 15 } }}
                                            value={props.currentTicket.timeOut}
                                            onChange={(value) => {
                                                let container = _.cloneDeep(props.currentTicket)
                                                container.timeOut = moment(value).valueOf()

                                                if(props.currentTicket.timeIn !== null){
                                                    container.payrollUnits = handleCalcPayrollHours(undefined,  moment(value).valueOf());
                                                    container.payroll = Number(handleCalcPayrollHours(undefined,  moment(value).valueOf()) * container.payrollRate).toFixed(2)
                                                }
                                                props.setCurrentTicket(container)
                                            }}
                                        />
                                    </Grid>
                                </Grid>


                            </Grid>

                            <Grid item xs={3}>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <Grid container justifyContent='flex-start'>  
                                            <input 
                                                tabIndex={19}
                                                type='checkbox'
                                                className={classes.textRow}
                                                style={{margin: '10px', padding: '10px'}}
                                                //checked={props.currentTicket.linkOperatorToSalesRate}
                                                onChange={(e) => {
                                                    //props.setCurrentTicket({ ...props.currentTicket, linkOperatorToSalesRate: e.target.checked })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={classes.textRowTypography}><small>Labour Distribution</small></Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={2}>
                                        <Grid container justifyContent='flex-start'>
                                            <input 
                                                tabIndex={20}
                                                type='checkbox'
                                                className={classes.textRow}
                                                style={{margin: '10px', padding: '10px'}}
                                                checked={props.currentTicket.linkOperatorToSalesRate || false}
                                                onChange={(e) => {
                                                    props.setCurrentTicket({ ...props.currentTicket, linkOperatorToSalesRate: e.target.checked })
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={classes.textRowTypography}><small>Link Operator Rate to the Sales Rate</small></Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={2}/>
                                    <Grid item xs={10}>
                                        <Typography className={classes.textRowTypography}>
                                            <small>{`Total Minutes: ${isNaN(handleCalcPayrollHours()) ? '' : handleCalcPayrollHours() * 60}`}</small>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={2}/>
                                    <Grid item xs={10}>
                                        <Typography className={classes.textRowTypography}>
                                            <small>{`Payroll Hours: ${isNaN(handleCalcPayrollHours()) ? '' : handleCalcPayrollHours()}`}</small>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={2}>
                                        <Grid container justifyContent='flex-start'>
                                            <input 
                                                tabIndex={21}
                                                type='checkbox'
                                                style={{margin: '10px', padding: '10px'}}
                                                className={classes.textRow}
                                                checked={props.currentTicket.nextDay || false}
                                                onChange={(e) => {
                                                    let container = _.cloneDeep(props.currentTicket)
                                                    container.nextDay = e.target.checked

                                                    if(props.currentTicket.timeIn !== null && props.currentTicket.timeOut !== null){
                                                        container.payrollUnits = handleCalcPayrollHours(undefined, undefined, e.target.checked);
                                                        container.payroll = Number(handleCalcPayrollHours(undefined, undefined, e.target.checked) * container.payrollRate).toFixed(2)
                                                    }
                                                    
                                                    props.setCurrentTicket(container)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography className={classes.textRowTypography}><small>Next Day</small></Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container >
                            <Grid container justifyContent='center'>
                                <Typography style={{marginTop: '20px'}}>{`Payroll:   $${props.currentTicket.payroll || Number(0.00).toFixed(2)}`}</Typography>
                            </Grid>

                            <Grid container justifyContent='center'>
                                {(!_.isUndefined(props.currentTicket.operatorPayDate) && props.currentTicket.operatorPayDate !== null)
                                    ?
                                        <Typography><small>{`Payroll Date: ${moment(props.currentTicket.operatorPayDate).format('YYYY-MM-DD h:mm a')}`}</small></Typography>
                                    :
                                        <Typography><small>{`Payroll Date: Not Payrolled`}</small></Typography>
                                }
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Paper>

        <Grid container style={{marginTop: '20px'}}>
            <Grid item xs={6}>
                <Grid container >

                    <Badge 
                        overlap='circular' 
                        badgeContent={props.currentTicket.photoURLs?.length || 0} 
                        color={"secondary"} 
                        max={999} 
                        children={
                            <Button 
                            variant="contained" 
                            onClick={() => { setPhotoViewerOpen(true) }} 
                            style={{marginRight: '10px'}}
                            disabled={(props.currentTicket.photoURLs?.length || 0) === 0}
                        >
                            View Photos
                        </Button>
                        }
                    />

                    <Button 
                        variant="contained" 
                        onClick={() => {props.setQuickRateModal({...props.quickRateModal, open: true})}} 
                        disabled={(props.currentTicket.invoicePayDate !== null && props.currentTicket.invoicePayDate !== undefined) 
                            || (props.currentTicket.operatorPayDate !== null && props.currentTicket.operatorPayDate !== undefined)}
                        style={{marginRight: '10px'}}
                    >
                        Add Quick Rate
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={() => props.handleCopyTicket() }
                        style={{marginRight: '10px'}}
                    >
                        Copy Ticket
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={() => { props.handleNewTicket() }}
                        style={{marginRight: '10px'}}
                    >
                        New Ticket
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={6} >
                <Grid container justifyContent='flex-end'>
                    <Button 
                        variant="contained" 
                        onClick={(e) => {  props.setCurrentTicket({ ...props.currentTicket, payrollVerified: !props.currentTicket.payrollVerified })  }}
                        style={props.currentTicket.payrollVerified ? {marginRight: '10px', backgroundColor: 'green', color: 'white'} : {marginRight: '10px'}}
                    >
                        {props.currentTicket.payrollVerified ? "Unverify for Payroll" : "Verify for Payroll"}
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={(e) => {  props.setCurrentTicket({ ...props.currentTicket, invoiceVerified: !props.currentTicket.invoiceVerified })  }}
                        style={props.currentTicket.invoiceVerified ? {marginRight: '10px', backgroundColor: 'green', color: 'white'} : {marginRight: '10px'}}
                    >
                        {props.currentTicket.invoiceVerified ? "Unverify for Invoice" : "Verify for Invoice"}
                    </Button>
                    <Button 
                        variant="contained" 
                        onClick={() => props.confirmSave() }
                        style={props.saving === 2 ? {backgroundColor: 'green', color: 'white'} : null}
                        disabled={props.saving !== 0}
                    >
                        {props.saving === 1 ? "Saving..." : props.saving === 0 ? "Save Ticket" : "Saved!"}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
 
      </Grid>
    )
  }
  
  export default withRouter(TicketCard);