import React, { useState, useRef } from 'react'
import { Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Modal, Paper, makeStyles, Typography, IconButton, TextField, Divider } from '@material-ui/core';
import DeliveryModal from './Modals/DeliveryModal';
import { TableIcons } from '../../../../constants/tableIcons';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MaterialTable from 'material-table';
import _ from 'lodash';
import DeliveryNotes from '../../../containers/Notes/DeliveryNotes';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '500px',
        padding: '16px',
        margin: '0 auto',
        marginTop: '50vh',
    },
}));

const DeliveryTable = (props) => {
    const classes = useStyles();

    //const [customers] = useState(props.customers)
    const [open, setOpen] = useState(false);
    const [modalEditData, setModalNoteData] = useState({note: '', jobIndex: '', noteIndex: '', useCase: ''})

    const [deliveryModal, setDeliveryModal] = useState(false);
    const [deliveryModalOpenInEdit, setDeliveryModalOpenInEdit] = useState(false);

    const tableRef = useRef()
    const openDeliveries = useRef([])

    //opens detail panel of previously opened (openDeliveries) when rendered
    useEffect(() => {
        const { detailPanel } = tableRef.current.props
        openDeliveries.current.forEach(index => { 
            props.deliveryData[index].tableData = {
                ...props.deliveryData[index].tableData,
                // 'tableData' object is injected by MaterialTable into every row of your data
                // if Detail Panel is an array, the 'render' function is located in one of the array's elements. 
                // e.g. 'detailPanel[0].render' for your first Detail Panels 
                showDetailPanel: typeof detailPanel === 'function' ? detailPanel : detailPanel[0].render,
            }
        })
    }, [props.deliveryData])

    const closeNoteModal = () => {
        setModalNoteData({note: '', jobIndex: '', noteIndex: '', useCase: ''})
        setOpen(false)
    }

    const openDeliveryModal = (docId, openInEdit) => {
        if(openInEdit){
            setDeliveryModalOpenInEdit(true)
            let deliveryContainer = _.cloneDeep(props.deliveryData.filter(delivery => delivery.docId === docId)[0]);
            props.setCurrentDelivery({...deliveryContainer});
            props.setDeliveryCustomerAutocomplete(deliveryContainer.customer.toString())
            props.setCurrentCustomer({})
            props.setNewCustomer(false)
        }
        else{
            setDeliveryModalOpenInEdit(false)
            props.setCurrentDelivery({})
            props.setCurrentCustomer({})
            props.setDeliveryCustomerAutocomplete("")
            props.setNewCustomer(false)
        }
        setDeliveryModal(true);
    }

    const closeDeliveryModal = () => {
        setDeliveryModal(false)
    }

    return (
        <Grid>
            {/* note modal */}
            <Modal
                open={open}
                onClose={() => closeNoteModal()}>
                <Paper className={classes.modal}>
                    <Grid style={{ padding: '16px'}}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography style={{ padding: '16px'}}><b>{modalEditData.useCase === 'create' ? 'Create Note:' : modalEditData.useCase === 'edit' ? 'Edit Note:' : 'Delete Note?'} </b></Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <IconButton onClick={() => {if(modalEditData.useCase === 'create'){ 
                                                                props.handleAdminNotes('delivery', modalEditData.note, modalEditData.jobIndex, 0, modalEditData.useCase)
                                                                closeNoteModal();
                                                            }
                                                            else if(modalEditData.useCase === 'edit'){
                                                                props.handleAdminNotes('delivery', modalEditData.note, modalEditData.jobIndex, modalEditData.noteIndex, modalEditData.useCase) 
                                                                closeNoteModal();
                                                            }
                                                            else if(modalEditData.useCase === 'delete'){
                                                                props.handleAdminNotes('delivery', modalEditData.note, modalEditData.jobIndex, modalEditData.noteIndex, modalEditData.useCase) 
                                                                closeNoteModal();
                                                            }  
                                                            }} ><CheckCircleIcon></CheckCircleIcon></IconButton>
                                <IconButton onClick={() => closeNoteModal()}><CancelIcon></CancelIcon></IconButton>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                {modalEditData.useCase === 'create' || modalEditData.useCase === 'edit'
                                ? <TextField fullWidth multiline={true} defaultValue={modalEditData.note} onChange={(event) => setModalNoteData({...modalEditData, note: event.target.value}) }></TextField>
                                : <Typography>{modalEditData.note}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>

            {/* Delivery Edit Modal */}
            <Dialog fullWidth
                keepMounted
                open={deliveryModal}
                onClose={closeDeliveryModal}
            >
                <DialogTitle id="alert-dialog-title">{deliveryModalOpenInEdit ? "Edit" : "Create"} Delivery</DialogTitle>

                <DialogContent>
                    <DeliveryModal
                        deliveryData={props.deliveryData}
                        currentDelivery={props.currentDelivery}
                        setCurrentDelivery={props.setCurrentDelivery}
                        customers={props.customers}
                        currentCustomer={props.currentCustomer}
                        setCurrentCustomer={props.setCurrentCustomer}
                        newCustomer={props.newCustomer}
                        setNewCustomer={props.setNewCustomer}
                        unitData={props.unitData}
                        deliveryCustomerAutocomplete={props.deliveryCustomerAutocomplete}
                        setDeliveryCustomerAutocomplete={props.setDeliveryCustomerAutocomplete}
                    />
                </DialogContent>

                <DialogActions>
                    <Button 
                        variant="contained" 
                        onClick={() => {
                            let deliveryContainer = {};
                            if(deliveryModalOpenInEdit){
                                if(props.newCustomer){
                                    deliveryContainer = {...props.currentDelivery, customer: props.currentCustomer.name};
                                     props.createCustomer(props.currentCustomer); 
                                }
                                else{
                                    deliveryContainer = {...props.currentDelivery};     
                                }
                                props.updateDelivery({...deliveryContainer}); 
                            }
                            else{
                                if(props.newCustomer){
                                    deliveryContainer = {...props.currentDelivery, customer: props.currentCustomer.name };
                                     props.createCustomer(props.currentCustomer); 
                                }
                                else{
                                    deliveryContainer = {...props.currentDelivery};     
                                }
                                props.createDelivery({...deliveryContainer});
                            }
                            closeDeliveryModal();
                        }}
                    >
                        Save
                    </Button>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        onClick={() => closeDeliveryModal()}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <MaterialTable
                columns={[
                    { title: 'Ticket #', field: 'ticket' },
                    { title: 'Customer', field: 'customer'},
                    { title: props.timesheet.timesheetType === 'Grain' ? 'Pickup Location' : 'From', field: 'from' },
                    { title:  props.timesheet.timesheetType === 'Grain' ? 'Delivery Location' : 'To', field: 'to' },
                    { title: 'Material', field: 'material' },
                    { title: props.timesheet.timesheetType === 'Grain' ? 'Net Weight' : 'Quantity', field: 'quantity' },
                    {
                        title: 'Units', field: 'units',
                        render: rowData => //split rowData.unitNums then display each in a typography
                        props.unitData.filter(unit => rowData.units.includes(unit.docId)).map((unit, index) => {
                            
                            return (<React.Fragment>{unit.unitNumber}<br/></React.Fragment>)
                        }),
                    },
                    { title: 'Invoice Status', render: rowData => {
                        let container = !_.isUndefined(rowData.invoicePayDate) ? "Invoiced" : 'Not Invoiced'
                        return <React.Fragment>{container}</React.Fragment>
                    }},
                    { title: 'Payroll Status', render: rowData => {
                        let container = !_.isUndefined(rowData.operatorPayDate) ? "Payrolled" : 'Not Payrolled'
                        return <React.Fragment>{container}</React.Fragment>
                    }},
                ]}
                icons={TableIcons}
                tableRef={tableRef}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [],
                    toolbar: true,
                    paging: true,
                    actionsColumnIndex: -1,
                    search: false,
                    emptyRowsWhenPaging: false,
                    thirdSortClick: false,
                    rowStyle: {
                        whiteSpace: 'pre-wrap',
                    },
                }}
                data={props.deliveryData}
                isLoading={props.deliveriesLoading}
                title={"Delivery Details"}
                actions={[
                    {
                        icon: TableIcons.OpenInNew,
                        tooltip: 'Show Job',
                        onClick: (event, rowData) => props.history.push({
                            pathname: '/dashboard/reporting/timesheets/delivery',
                            search: "?" + new URLSearchParams({ docId: rowData.docId }).toString(),
                        })
                    },
                    {
                        icon: TableIcons.Edit,
                        tooltip: 'Edit Delivery',
                        onClick: (event, rowData) => {
                            openDeliveryModal(rowData.docId, true)
                        }
                    },
                    {
                        icon: TableIcons.Add,
                        tooltip: 'Add Unit',
                        isFreeAction: true,
                        onClick: (event, rowData) => {
                            openDeliveryModal(rowData.docId, false)
                        }
                    },
                ]}
                
                detailPanel={[
                    {
                        tooltip: 'Show Details',
                        icon: () => <ChevronRightIcon />,
                        render: (rowData) => {
                            return (<React.Fragment>
                                <Paper style={{ padding: '10px' , margin:'10px', backgroundColor:'#ffeebd'}}>
                                    <Grid style={{backgroundColor: '#FFBF00', color: 'black', }}>
                                        <Grid container >
                                            <Grid item xs={12}>
                                                <IconButton variant={'contained'} style={{ padding: '16px',}} onClick={(event) => {setModalNoteData({...modalEditData, jobIndex: rowData.tableData.id, useCase: 'create'}); setOpen(true)}} ><AddCircleIcon /></IconButton>
                                                <Typography display="inline" style={{ padding: '16px'}}><b>Admin Notes: </b></Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider></Divider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {rowData.adminNotes?.map((note, index) => {
                                         return(
                                            <Grid container key={index}>                 
                                                <Grid item xs={11}>    
                                                    <Typography style={{ padding: '16px'}}>{note}</Typography>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <IconButton onClick={(event) => {setModalNoteData({note: note, jobIndex: rowData.tableData.id, noteIndex: index, useCase: 'edit'}); setOpen(true); }}><EditIcon></EditIcon></IconButton>
                                                    <IconButton onClick={(event) => {setModalNoteData({note: note, jobIndex: rowData.tableData.id, noteIndex: index, useCase: 'delete'}); setOpen(true); }}><DeleteIcon></DeleteIcon></IconButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                            </Grid>
                                         )
                                     })}
                                    
                                    
                                </Paper>
                                 <div style={{ padding: '10px' }}>
                                    <DeliveryNotes
                                        openDeliveries={openDeliveries}
                                        rowData={rowData}
                                        updateDelivery={props.updateDelivery}
                                    />
                                </div>
                                </React.Fragment>
                            )
                        }
                    }
                ]}
                pathname='/dashboard/reporting/timesheets/delivery'
                editable={{
                    //onRowAdd: (newData) => props.createDelivery(newData),
                    //onRowUpdate: (newData, oldData) => props.updateDelivery(newData, oldData),
                    onRowDelete: (oldData) => props.deleteDelivery(oldData),
                }}
            />
        </Grid>
    )
}
export default DeliveryTable