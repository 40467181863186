import React, { useRef, useMemo, memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const DayViewCard = ({passedDefaultValue, passedOnChange, passedCurrentDoc}) => {

    const quill = useRef()

    var Size = ReactQuill.Quill.import('attributors/style/size');
    var icons = ReactQuill.Quill.import("ui/icons");
        icons["undo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
    </svg>`;
        icons["redo"] = `<svg viewbox="0 0 18 18">
        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
    </svg>`;

    Size.whitelist = ['12px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px'];
    ReactQuill.Quill.register(Size, true);

    const handleRedo = () => {  quill.current?.getEditor()?.history?.redo(); };
    const handleUndo = () => {  quill.current?.getEditor()?.history?.undo(); };

    const modules = useMemo(()=>({
        toolbar: {
            container: [
                {header: [1, 2, 3, 4, 5, 6, false]},
                {bold: "bold"},
                {italic: "italic"},
                {underline: "underline"},
                {strike: "strike"},
                { size:  ['12px', '14px', '18px', '24px', '30px', '36px', '48px', '60px', '72px']},
                //[{ font: [] }],
                { align: ["right", "center", "justify"] },
                { list: "ordered" }, 
                { list: "bullet" },
                {link: "link" },
                { color: ["#000000", "#434343", "#666666", "#999999", "#b7b7b7", "#cccccc", "#d9d9d9", "#efefef ", "#f3f3f3", "#ffffff", "#980000", "#ff0000", "#ff9900",
                    "#ffff00", "#00ff00", "#00ffff", "#4a86e8", "#0000ff", "#9900ff", "#ff00ff", "#e6b8af", "#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#c9daf8",
                    "#cfe2f3", "#d9d2e9", "#ead1dc", "#dd7e6b", "#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#a4c2f4", "#9fc5e8", "#b4a7d6", "#d5a6bd", "#cc4125",
                    "#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6d9eeb", "#6fa8dc", "#8e7cc3", "#c27ba0", "#a61c00", "#cc0000", "#e69138", "#f1c232", "#6aa84f",
                    "#45818e", "#3c78d8", "#3d85c6", "#674ea7","#a64d79", "#85200c", "#990000", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#1155cc", "#0b5394","#351c75", 
                    "#741b47", "#5b0f00", "#660000","#783f04", "#7f6000", "#274e13", "#0c343d", "#1c4587","#073763", "#20124d", "#4c1130", ] },
                { background: ["#000000", "#434343", "#666666", "#999999", "#b7b7b7", "#cccccc", "#d9d9d9", "#efefef ", "#f3f3f3", "#ffffff", "#980000", "#ff0000", "#ff9900",
                    "#ffff00", "#00ff00", "#00ffff", "#4a86e8", "#0000ff", "#9900ff", "#ff00ff", "#e6b8af", "#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#c9daf8",
                    "#cfe2f3", "#d9d2e9", "#ead1dc", "#dd7e6b", "#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#a4c2f4", "#9fc5e8", "#b4a7d6", "#d5a6bd", "#cc4125",
                    "#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6d9eeb", "#6fa8dc", "#8e7cc3", "#c27ba0", "#a61c00", "#cc0000", "#e69138", "#f1c232", "#6aa84f",
                    "#45818e", "#3c78d8", "#3d85c6", "#674ea7","#a64d79", "#85200c", "#990000", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#1155cc", "#0b5394","#351c75", 
                    "#741b47", "#5b0f00", "#660000","#783f04", "#7f6000", "#274e13", "#0c343d", "#1c4587","#073763", "#20124d", "#4c1130", ] },
                { undo: 'undo'},
                { redo: 'redo'},
            ],
            handlers: {
                'undo': handleUndo,
                'redo': handleRedo,
            }
        },
        history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true
        },
    }),[])


    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        //"blockquote",
        "list",
        "bullet",
        "link",
        "color",
        //"image",
        "background",
        "align",
        "size",
        //"font"
        // "undo",
        // "redo",
    ];

    //console.log('quill text rendered')

    return (
        <>
            <ReactQuill 
                ref={quill}
                style={{ height: '75vh'}}
                theme="snow" 
                defaultValue={passedDefaultValue} 
                preserveWhitespace={false}
                // set up this way as quill does some wonky stuff making onChange call at unexpected times
                onChange={(html, delta, source) => { if (source === 'user') { passedOnChange(html, passedCurrentDoc) } }}
                modules={modules}
                formats={formats}
            />
        </>
    )
}

export default memo(DayViewCard)