import React, { useState, useEffect, useRef } from 'react';
import { firestore } from '../../../../firebase/firebase'
import OrderTable from '../../../components/Orders/OrderTable/OrderTable'
import OrderSummary from '../../../components/Orders/OrderTable/OrderSummary'
import OrderOptions from '../../../components/Reports/Orders/OrderOptions'
import { useMediaQuery, makeStyles, Paper, Grid, IconButton, Collapse, Typography, Divider } from '@material-ui/core';
import moment from 'moment'
import OrdersMap from '../../../components/Orders/OrderTable/OrdersMap';
import { useTheme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import OrderOptionsMobile from '../../../components/Reports/Orders/OrderOptionsMobile'

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        paddingTop: '32px',
        paddingLeft: '32px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: '5px',
            paddingTop: '3px',
            width: '100%',
        }
    },
    sidebar: {
        position: 'fixed',
        right: 0,
        top: '64px',
        maxWidth: '256px',
        width: '100%',
        padding: '16px',
        zIndex: '1000',
        overflowY: 'auto',
        minHeight: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
    },
    sidebarSpacer: {
        marginLeft: '32px',
        maxWidth: '256px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0px',
            maxWidth: '0px',
            width: '0%',
        }

    },
    bottombar: {
        padding: '10px',
        paddingRight: '20px',
        paddingBottom: '20px',
        bottom: '0%',
        position: 'fixed',
        zIndex: '200',
        left: '0%',
        width: '100%',
        boxShadow: '0px 0px 3px 2px rgba(0,0,0,0.3)',
    },
    expand: {
        transform: 'rotate(180deg)',
        padding: '0px',
        margin: '0px',
        left: '45%',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(0deg)',
    },
    modal: {
        display: 'flex',
        justifyContent: 'center',
    }
}));

const Orders = () => {
    const tableRef = useRef();
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [expanded, setExpanded] = useState(JSON.parse(sessionStorage.getItem("orderExpanded")) || true);
    const [orderData, setOrderData] = useState(JSON.parse(sessionStorage.getItem("ordersOrderData")) || []);
    const [dateFilteredOrderData, setDateFilteredOrderData] = useState(JSON.parse(sessionStorage.getItem("ordersDateFilteredOrderData")) || []);
    const [filteredOrderData, setFilteredOrderData] = useState(JSON.parse(sessionStorage.getItem("ordersFilteredOrderData")) || []);
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [mapModal, setMapModal] = useState(false)
    const [dateType, setDateType] = useState(sessionStorage.getItem("orderDateType") ? (JSON.parse(sessionStorage.getItem("orderDateType"))) : ("submit"));
    const [showComplete, setShowComplete] = useState(JSON.parse(sessionStorage.getItem("orderShowComplete")) || false)
    const [showCancelled, setShowCancelled] = useState(JSON.parse(sessionStorage.getItem("orderShowCancelled")) || false)
    const [startDate, setStartDate] = useState(sessionStorage.getItem('startDate') ? moment(JSON.parse(sessionStorage.getItem("startDate"))) : moment());
    const [endDate, setEndDate] = useState(sessionStorage.getItem('endDate') ? moment(JSON.parse(sessionStorage.getItem("endDate"))) : moment());
    const [customerSearch, setCustomerSearch] = useState(JSON.parse(sessionStorage.getItem("orderCustomerSearch")) || '')
    const [orderNumSearch, setOrderNumSearch] = useState(JSON.parse(sessionStorage.getItem("orderOrderNumSearch")) || '')
    const [unitNumSearch, setUnitNumSearch] = useState(JSON.parse(sessionStorage.getItem("orderUnitNumSearch")) || '')
    const [deliveryLocationSearch, setDeliveryLocationSearch] = useState(JSON.parse(sessionStorage.getItem("orderDeliveryLocationSearch")) || '')

    useEffect(() => {
        // eslint-disable-next-line
        if(smallScreen){
            setExpanded(false)
            sessionStorage.setItem("orderExpanded", JSON.stringify(false))
        }
    }, [smallScreen])

    const handleExpandClick = () => {
        if(expanded)
            setExpanded(false);
        else
            setExpanded(true);    
        sessionStorage.setItem("orderExpanded", JSON.stringify(expanded))
    };

    useEffect(() => {
        setFilteredOrderData([...handleFilters(dateFilteredOrderData)])
        // eslint-disable-next-line
    }, [customerSearch, orderNumSearch, deliveryLocationSearch, unitNumSearch])

    const dateTypeHandler = type => {
        setDateType(type);
        sessionStorage.setItem("orderDateType", JSON.stringify(type))
    }
    const startDateHandler = date => {
        setStartDate(date)
        sessionStorage.setItem('startDate', JSON.stringify(date))
    }
    const endDateHandler = date => {
        setEndDate(date)
        sessionStorage.setItem('endDate', JSON.stringify(date))
    }
    const handleShowComplete = () => {
        tableRef.current.onPageChange({}, 0);
        sessionStorage.removeItem("orderReportPageValue");

        sessionStorage.setItem('orderShowComplete', !showComplete)
        setShowComplete(!showComplete);
    }
    const handleShowCancelled = () => {
        tableRef.current.onPageChange({}, 0);
        sessionStorage.removeItem("orderReportPageValue");
        sessionStorage.setItem('orderShowCancelled', !showCancelled)
        setShowCancelled(!showCancelled);
    }

    const openMapModal = () => {
        setMapModal(true);
    }
    const closeMapModal = () => {
        setMapModal(false);
    }

    async function dateCompat() {
        //let query = firestore.collection('orders')
        //query.orderBy('payment.created_at')
        //const oldDate = query.where( "payment.created_at" , ">=", (moment(startDate.startOf('day')).format('YYYY-MM-DDTHH:mm:s.SSS')+"Z")).where("payment.created_at", "<=", (moment(endDate.endOf('day')).format('YYYY-MM-DDTHH:mm:s.SSS')+"Z")).get()
        //const newDate = query.where("payment.created_at", ">=", (moment(startDate.startOf('day')).format('YYYY-MM-DDTHH:mm:s.SSS')+"Z")).where("payment.created_at", "<=", (moment(endDate.endOf('day')).format('YYYY-MM-DDTHH:mm:s.SSS')+"Z")).get()
        //TODO: will need to check payment handler and do a different one for CONVERGE
        let oldDate = orderData
        
        const withinPreferred = (order) => {
            return moment(order?.order?.deliverDate).isSameOrAfter(moment(startDate.startOf('day'))) &&
                moment(order?.order?.deliverDate).isSameOrBefore(moment(endDate.endOf('day')))
        }

        const withinSubmitted = (order) => {

            if ('payment' in order && 'paymentHandler' in order?.payment) {
                return order?.payment?.created_at &&
                    moment(order?.payment.created_at).isSameOrAfter(moment(startDate.startOf('day'))) &&
                    moment(order?.payment.created_at).isSameOrBefore(moment(endDate.endOf('day')))
            }
            else {
                return order?.payment?.created_at &&
                    moment(order?.payment.created_at).isSameOrAfter(moment(startDate.startOf('day'))) &&
                    moment(order?.payment.created_at).isSameOrBefore(moment(endDate.endOf('day')))
            }
        }

        switch (dateType) {
            case 'prefer':
                oldDate = orderData.filter(order => { return order?.order?.deliverDate === "" ? withinSubmitted(order) : withinPreferred(order); })
                break;
            case 'submit':
                oldDate = orderData.filter(order => { return withinSubmitted(order) })
                break;
            default:
                break;
        }
        return oldDate
    }

    //deconstructs strings into several sub strings inside array using , as start and end points
    const deconstructString = (string) => {
        let container = [];
        let count = [...string].filter(x => x === ',').length + 1
        let startPoint = 0
        let endPoint = string.indexOf(",", startPoint);
        for(let i = 0; i < count; i++){
            container.push(string.substring(startPoint, endPoint).trim())
            startPoint = endPoint + 1
            endPoint = string.indexOf(",", startPoint) !== -1 ? string.indexOf(",", startPoint) : string.length;
        }
        return container
    }

    const handleFilters = (orders) => {
        orders = orders.filter((doc) => !('hidden' in doc) || ('hidden' in doc && !doc.hidden))
        if(customerSearch.length > 0){
            orders = orders.filter(order => order?.customer.familyName.includes(customerSearch) || order?.customer.givenName.includes(customerSearch))
            }
        if(orderNumSearch.length > 0){
            orders = orders.filter(order => order?.order.orderID === (parseInt(orderNumSearch)))
            }
        if(unitNumSearch.length > 0){
            orders = orders.filter(order => order?.truckNumber?.unitNumber === (unitNumSearch))
        }

        if(deliveryLocationSearch.length > 0){
            // eslint-disable-next-line
            orders = orders.filter(order => {
                let container = deconstructString(order.customer?.destination?.location.toString())
                for(let i = 0; i < container.length; i++){
                    if( container[i].includes(deliveryLocationSearch)){
                        return order
                    }
                }
            })
        }
        return orders;
    }

    const generateHandler = () => {
        sessionStorage.removeItem("timesheetData");
        sessionStorage.removeItem("timesheetUnitData");
        sessionStorage.removeItem("jobData");
        sessionStorage.removeItem("defectData");
	    sessionStorage.removeItem("unitData");
        sessionStorage.removeItem("defectData");
        sessionStorage.removeItem("deliveryData");
      	sessionStorage.removeItem("deliveryUnitData");
        sessionStorage.removeItem("covidData");
       	sessionStorage.removeItem("checklistData");
        sessionStorage.removeItem("photoData");
        sessionStorage.removeItem("filteredPhotoData");
        sessionStorage.removeItem("REACT_COOL_IMG");
        setErrorMessage(null);
        if (endDate.isBefore(startDate)) {
            setErrorMessage('Start Date later than End Date!')
            return
        }
        if(endDate.diff(startDate, 'd') > 365){
            setErrorMessage('Query cannot be more than a one year period.')
            return
        }
        setLoading(true);
        dateCompat().then(querySnapshot => {
            let orderResponse = [...querySnapshot]
            setDateFilteredOrderData(orderResponse)
            sessionStorage.setItem("ordersDateFilteredOrderData", JSON.stringify(orderResponse))
            orderResponse = handleFilters(orderResponse)
            setFilteredOrderData(orderResponse)
            sessionStorage.setItem("ordersFilteredOrderData", JSON.stringify(orderResponse))
            setLoading(false)
         })
    }

    
    useEffect(() => {
        var userAgent = window.navigator.userAgent;
        if (!userAgent.match(/iPhone/i)){
            generateHandler();
        }
        // eslint-disable-next-line
    }, [orderData])

    useEffect(() => {
        var userAgent = window.navigator.userAgent;
        if (!userAgent.match(/iPhone/i)){
            let unsubscribe = firestore.collection('orders').onSnapshot(querySnapshot => {
            let docs = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                'docId': doc.id
            }));

            console.log("Orders.js: Orders updated")
            console.log(docs)

            setOrderData(docs.filter((doc) => !('hidden' in doc) || ('hidden' in doc && !doc.hidden)))
            console.log(orderData)
            setLoading(false)
        })
        return () => { unsubscribe(); }
        
        }
        // eslint-disable-next-line
    }, [])


    return (
        <React.Fragment>
            {/* map modal */}
            <OrdersMap loading={loading} orderData={filteredOrderData.filter(order => order.customer.destination.coordinates.lat !== '')} 
            showComplete={showComplete} showCancelled={showCancelled} mapModal={mapModal} closeMapModal={closeMapModal} setMapModal={setMapModal}/>
            <div className={classes.content}>
                <Grid container spacing={2} style={{ justifyContent: 'center' }}>
                    {smallScreen?null:
                    <Grid item lg={7} md={12}>
                        <OrderSummary 
                            orders={orderData} 
                            smallScreen={smallScreen} 
                        />
                    </Grid>}
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', width: '100%', paddingRight: "16px" }}>
                            <OrderTable
                                tableRef={tableRef}
                                checked={showComplete}
                                showCancelled={showCancelled}
                                smallScreen={smallScreen}
                                onChange={() => handleShowComplete()}
                                onChangeComplete={() => handleShowCancelled()}
                                orders={filteredOrderData}
                                showComplete={showComplete}
                                loading={loading}
                            ></OrderTable>
                        </div>
                    </Grid>
                </Grid>
                <div className={classes.sidebarSpacer} />
                {!smallScreen ?
                        <Paper className={classes.sidebar}>
                            <OrderOptions
                                orderNumSearch={orderNumSearch} setOrderNumSearch={setOrderNumSearch}
                                unitNumSearch={unitNumSearch} setUnitNumSearch={setUnitNumSearch}
                                deliveryLocationSearch={deliveryLocationSearch} setDeliveryLocationSearch={setDeliveryLocationSearch}
                                customerSearch={customerSearch} setCustomerSearch={setCustomerSearch}
                                openMapModal={openMapModal}
                                closeMapModal={closeMapModal}
                                //searchState={searchState} searchHandler={searchHandler}
                                //clearSearch={clearSearch}
                                startDate={startDate} startDateHandler={startDateHandler}
                                endDate={endDate} endDateHandler={endDateHandler}
                                generateHandler={generateHandler}
                                errorMsg={errorMessage}
                                loading={loading}
                                dateType={dateType} dateTypeHandler={dateTypeHandler}

                            />
                        </Paper>
                    :
                    null
                }</div>

                <div className={classes.content}>
                
                {smallScreen ?
                    <Grid container spacing={2} style={{ justifyContent: 'center' }} >
                        <Grid item lg={7} md={12}>

                            <Paper className={classes.bottombar}>
                                <Typography variant="h6" style={{ display: 'inline-block' }}>Filters</Typography>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded,
                                    })}
                                    size="small"
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon style={{ fontSize: '30px', padding: '0px', margin: '5px' }} />
                                    <Divider />
                                </IconButton>
                                <Collapse orientation="vertical" in={expanded} timeout="auto">
                                    <OrderOptionsMobile
                                        orderNumSearch={orderNumSearch} setOrderNumSearch={setOrderNumSearch}
                                        unitNumSearch={unitNumSearch} setUnitNumSearch={setUnitNumSearch}
                                        deliveryLocationSearch={deliveryLocationSearch} setDeliveryLocationSearch={setDeliveryLocationSearch}
                                        customerSearch={customerSearch} setCustomerSearch={setCustomerSearch}
                                        startDate={startDate} startDateHandler={startDateHandler}
                                        endDate={endDate} endDateHandler={endDateHandler}
                                        generateHandler={generateHandler}
                                        errorMsg={errorMessage}
                                        loading={loading}
                                        dateType={dateType} dateTypeHandler={dateTypeHandler}

                                    />
                                </Collapse>
                            </Paper>
                        </Grid>
                    </Grid>
                    : null}
            </div>
        </React.Fragment>
    );
}
export default Orders;