import React, {useState, useEffect, useCallback} from 'react';
import { Grid, makeStyles, Paper, TableCell, TableBody, TableContainer, Table, TableHead, TableRow, Snackbar,
    Button, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Typography} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { firestore } from '../../../../firebase/firebase'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({

    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'auto'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    paper: {
        padding: '20px',
        borderRadius: '0px',
        margin: '10px',
        width: '100%',
        maxWidth: '1000px',
        backgroundColor: 'rgb(252, 252, 249)',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const CommunicationsTab = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [editing, setEditing] = React.useState(false);
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const [customerData, setCustomerData] = useState(props.customerData);
    const [contactInfo, setContactInfo] = React.useState({})
    const [oldContact, setOldContact] = React.useState({})
    const [saveState, setSaveState] = useState(props.saveState);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);


    
    useEffect(() => {
        setCustomerData(props.customerData)
        //eslint-disable-next-line
    }, [props.customerData])


    const handleClickOpen = () => {
        setEditing(false);
        setContactInfo({name: '', phone: '', description: '', email: ''})
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    const handleEditOpen = (contact) => {
        setContactInfo(contact)
        setOldContact(contact)
        setOpen(true);
        setEditing(true);

    };

    const handleDeleteOpen = (contact) => {
        setDeleteOpen(true);
        setContactInfo(contact)
    };
    
    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const handleSubmit = (event) => {
        const oldContacts = props.customerData.contacts
        let newContacts = oldContacts
        setContactInfo({ ...contactInfo, 'id': oldContacts.length })
        newContacts.push(contactInfo)

        props.updateCustomerData({ ...props.customerData, contacts: newContacts })
        firestore.collection('customers').doc(props.customerData.docId)
            .update(props.customerData)
        setContactInfo({name: '', phone: '', description: '', email: ''})
    }

    const handleDelete = (event) => {
        const oldContacts = props.customerData.contacts
        const index = oldContacts.indexOf(contactInfo)
        if (index > -1) {
            oldContacts.splice(index, 1);
          }
        let newContacts = oldContacts
        
        props.updateCustomerData({ ...props.customerData, contacts: newContacts })
        firestore.collection('customers').doc(props.customerData.docId)
            .update(props.customerData)
        setContactInfo({name: '', phone: '', description: '', email: ''})
    }

    const handleEdit = (event) => {
        const oldContacts = props.customerData.contacts
        const index = oldContacts.indexOf(oldContact)
        if (index > -1) {
            oldContacts.splice(index, 1);
          }
        let newContacts = oldContacts
        newContacts.push(contactInfo)
        props.updateCustomerData({ ...props.customerData, contacts: newContacts })
        firestore.collection('customers').doc(props.customerData.docId)
            .update(props.customerData)
        setContactInfo({name: '', phone: '', description: '', email: ''})
    }
    
    const updateCustomerData = useCallback((e) => {
        setCustomerData(e)
    },
    [],
);
const changeHandler = (event) => {
    if (event.target.name.includes('.')) {
        //substring to get the first part of the name
        let option1 = event.target.name.substring(0, event.target.name.indexOf('.'))
        //substring to get the second part of the name
        let option2 = event.target.name.substring(event.target.name.indexOf('.') + 1)
        
        //updateCustomerData using the spread operator to keep the other values
        updateCustomerData({ ...customerData, [option1]: { ...customerData[option1], [option2]: event.target.value } })


    }
    else{
        updateCustomerData({ ...customerData, [event.target.name]: event.target.value })
    }
    
}
    const confirmSave = () => {
        props.setCustomerData(customerData);
        props.setSaveState(customerData);
        setSaving(true);
        firestore.collection('customers').doc(props.customerData.docId)
            .update(customerData)
            .then(() => {
                setSaveState(customerData);
                setSaving(false)
            })
            .catch((e) => {setError(e); console.log(error);});
    };
    const cancelSave = () => {
        setCustomerData(saveState);
    };

    return (
        <Grid container spacing={4} >
            <Dialog open={deleteOpen} onClose={handleClose}>
                <DialogTitle>Delete Contact</DialogTitle>
                <DialogContent>
                <Typography>Are you sure you want to delete contact: {contactInfo.name}?</Typography>
                    <DialogContentText>
                        This is NOT reversable!
                    </DialogContentText>
                    
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="small" onClick={handleDeleteClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" size="small" onClick={handleDelete}>
                            Delete
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{!editing ? "Add" : "Edit"} Contact</DialogTitle>
                <DialogContent>
                <DialogContentText>
                {!editing ? "Please enter the details of the new contact." : "Edit the current contact."}
                </DialogContentText>
                <TextField
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    value={contactInfo.name}
                    error={contactInfo.name === ''}
                    helperText={contactInfo.name === '' ? "Contact requires a name": ''}
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'name': event.target.value }) }}
                />
                <Grid container spacing={2}> 
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phone"
                            label="Phone #"
                            value={contactInfo.phone}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'phone': event.target.value }) }}
                        />
                    </Grid>
                    <Grid item xs={6}> 
                        <TextField
                            margin="dense"
                            id="phonedesc"
                            label="Phone Description"
                            value={contactInfo.description}
                            type="tel"
                            onChange={(event) => { setContactInfo({ ...contactInfo, 'description': event.target.value }) }}
                        />
                    </Grid>       
                </Grid>
                <TextField
                    margin="dense"
                    id="email"
                    label="Email Address"
                    type="email"
                    value={contactInfo.email}
                    fullWidth
                    onChange={(event) => { setContactInfo({ ...contactInfo, 'email': event.target.value }) }}
                />
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="secondary" size="small" onClick={handleClose}>
                    Cancel
                </Button>
                <Button disabled={contactInfo.name === ''} variant="contained" color="primary" size="small" onClick={!editing ? handleSubmit : handleEdit}>
                    Submit
                </Button>
                </DialogActions>
            </Dialog>

            <Grid container item justifyContent="center" spacing={2}>
                {props.edit ? 
                <TextField
                    style={{ marginBottom: '8px', width:'400px' }}
                    label='Web Site'
                    value={customerData.website ? customerData.website : ''}
                    variant='outlined'
                    name='website'
                    size="small"
                    onChange={(event) => { changeHandler(event) }}
                    inputProps={{ style: { fontSize: '1rem' } }} />
                :<Button variant="contained" target="_blank" href={props.customerData.website ? props.customerData.website : "#"} disabled={!props.customerData.website} startIcon={<OpenInBrowserIcon/>}>Visit {props.customerData.name} Web Site</Button>}
                
            </Grid>
            <Grid container item spacing={1} direction="row" wrap='nowrap'>
                    <Grid container justifyContent="flex-start">
                    <TextField
                        disabled={!props.edit}
                        label="GST Registration #"
                        name='gstRegNum'
                        value={customerData.gstRegNum}
                        onChange={(event) => { changeHandler(event) }}
                        variant="outlined"
                        style={{width:'400px'}}
                    />
                    </Grid>
                    <Grid container justifyContent="flex-end">
                    <TextField
                        disabled={!props.edit}
                        label="Retail Sales Tax #"
                        name='retailSalesTaxNum'
                        value={customerData.retailSalesTaxNum}
                        onChange={(event) => { changeHandler(event) }}
                        variant="outlined"
                        fullWidth
                    />
                    </Grid>
            </Grid>
            <Grid container style={{margin: '15px'}}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Contact</TableCell>
                            <TableCell align="right">Phone Number</TableCell>
                            <TableCell align="right">Phone Description</TableCell>
                            <TableCell align="right">Email Address</TableCell>
                            {props.edit ? <React.Fragment><TableCell align="center">Delete</TableCell><TableCell align="center">Edit</TableCell></React.Fragment>
                            : null}
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {props.customerData.contacts.map((contact, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {contact.name}
                                </TableCell>
                                <TableCell align="right">{contact.phone}</TableCell>
                                <TableCell align="right">{contact.description}</TableCell>
                                <TableCell align="right">{contact.email}</TableCell>
                                {props.edit ?<React.Fragment>
                                <TableCell align="center"><IconButton onClick={() => handleDeleteOpen(contact)} color="secondary"><DeleteForeverIcon/></IconButton></TableCell>
                                <TableCell align="center"><IconButton onClick={() => handleEditOpen(contact)} color="secondary"><EditIcon/></IconButton></TableCell></React.Fragment>  : null}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            {props.edit ?
                <Grid item xs={6} style={{margin:'16px'}}>
                    <Button disabled={!props.edit} variant="contained" onClick={handleClickOpen} startIcon={<AddIcon/>}>Add Contact</Button>
                </Grid> : null}

                 <Snackbar
                open={!_.isEqual(customerData, saveState)}
                message={saving ? 'Saving...' : 'Save Document?'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                action={
                    <React.Fragment>
                        {props.saving
                            ? null
                            : <React.Fragment>
                                <Button variant='text' color='primary' onClick={cancelSave} style={{ marginLeft: '32px', marginRight: '8px' }}>Cancel</Button>
                                <Button variant='contained' onClick={confirmSave}>Confirm</Button>
                            </React.Fragment>}
                    </React.Fragment>
                } /> 
            </Grid>
            
            
        </Grid>
    )
}
export default withRouter(CommunicationsTab)