import React, { useState, useEffect } from 'react';
import {Grid, makeStyles, Typography, Paper, TextField, CircularProgress, Select, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio, Button,
  Modal, List, ListItem, ListSubheader, ListItemText,  
 } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from '../UI/DatePicker/DatePicker';

const useStyles = makeStyles((theme) => ({
  content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      maxWidth: '90vw',
      margin: 'auto',
      marginTop: '20px',
      marginBottom: '20px',
  },
  modal: {
    width: '500px',
    padding: '16px',
    margin: '0 auto',
    marginTop: '20vh'
  },
  list: {
    borderBottom: '1px solid grey',
    minHeight: '400px', 
    maxHeight: '50vh',
    marginBottom: '5px',
    overflow: 'auto',
  },
}));

const TicketFilter = (props) => {
    const classes = useStyles();

    //holds filtered ticket data based on radio (payrolled, invoiced, all, ect)
    const [filteredTicketEntryData, setFilteredTicketEntryData] = useState([])

    const [listModalOpen, setListModalOpen] = useState(false)
    const [rowSelected, setRowSelected] = useState(null)

    //holds open state for autocompletes
    const [autoCompletesOpen, setAutoCompletesOpen] = useState({
      findTicket: false,
      //findOrder: false,
      findDate: false,
      findContact: false,
    })


    //handles setting filteredTicketData
    useEffect(() => {
      let container = _.cloneDeep(props.ticketEntryData)

      //current radio filter
      switch(props.filterValues.currentRadio) {
        case 'notInvoiced':
          container = container.filter(x=> x.invoicePayDate === null || x.invoicePayDate === undefined)
          break;
        case 'notPayrolled':
          container = container.filter(x=> x.operatorPayDate === null || x.operatorPayDate === undefined)
          break;
        case 'invoicedAndPayrolled':
          container = container.filter(x=> (x.invoicePayDate && x.invoicePayDate !== null) && (x.operatorPayDate && x.operatorPayDate !== null))
          break;
        default:
          break;
      }

      //findByFilter
      switch(props.filterValues.currentFindBy) {
        case 'date':
          container = container.filter(x=> moment(x.date).isBetween(moment(props.filterValues.dateRange?.start), moment(props.filterValues.dateRange?.end), null, '[)') )
          break;
        case 'contact':
          container = container.filter(x=> x.customerId === props.filterValues.contactAutocompleteValue?.docId)
          break;
        default:
          break;
      }

      container = container.sort((a, b) => (a.date < b.date) ? 1 : -1)
      setFilteredTicketEntryData([...container])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filterValues, props.ticketEntryData])

    const handleAutoCompleteOpenClose = (key, bool) => { setAutoCompletesOpen({...autoCompletesOpen, [key]: bool}) }

    return (
      <Grid className={classes.content}>
        {/* LIST MODAL */}
          <Modal
            open={listModalOpen}
            onClose={() => setListModalOpen(false)}
          >
            <Paper className={classes.modal}>
              <ListSubheader component="div" style={{borderBottom: '1px solid grey',}}>Choose a Ticket</ListSubheader>

              <List component="nav" disablePadding={true} dense={true} style={props.style} className={classes.list}>
                  {filteredTicketEntryData.map((item) => {
                    return (
                      <ListItem 
                        selected={rowSelected?.docId === item?.docId} 
                        button 
                        onClick={()=> { setRowSelected(item) }}
                      >
                        <ListItemText 
                          primary={`Ticket # ${item.ticket} - ${moment(item.date).format('DD-MMM-YYYY')}`} 
                          secondary={props.customers.find(x=> x.docId === item.customerId)?.name || item.customer} 
                        /> 
                      </ListItem>
                    )
                  })}
              </List>
              

              <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '12px' }}>
                <Button variant='contained' style={{marginRight: '10px'}} onClick={() => { setListModalOpen(false) }}>Cancel</Button>

                <Button 
                  variant='contained' 
                  onClick={() => {  
                    props.handleChangeCurrentTicket(rowSelected);
                    setListModalOpen(false)
                  }}
                >
                    Open
                </Button>
              </Grid>

            </Paper>
          </Modal>
        <Paper >

          <Grid container style={{padding: '10px'}}>

            <Grid item xs={12} md={6} >
              <Grid container>
                
                  {/* <KeyboardDatePicker
                    views={["year"]}
                    style={{marginTop: '10px', marginRight: '20px'}}
                    onChange={(newValue) => { props.setFilterValues({...props.filterValues, dateValue: moment(newValue).format('YYYY').valueOf()}) }}
                  />  */}

                  <Select 
                    style={{minWidth: '125px', marginRight: '20px'}}
                    value={props.filterValues.currentFindBy}
                    onChange={(e)=> {
                      props.setFilterValues({
                        ...props.filterValues,
                        ticketAutocompleteValue: null,
                        //orderAutocompleteValue: null,
                        //contactAutocompleteValue: null,
                        //dateRange: null,
                        currentFindBy: e.target.value,
                      })

                      sessionStorage.setItem("ticketFindByFilter", JSON.stringify(e.target.value)) 
                      //sessionStorage.removeItem("ticketFindByTicketFilter");
                      //sessionStorage.removeItem("ticketFindByOrderFilter");
                      //sessionStorage.removeItem("ticketFindByCustomerFilter");
                    }}
                  >
                    <MenuItem value={'ticket'}>Find By Ticket:</MenuItem>
                    {/* <MenuItem value={'order'}>Find Order:</MenuItem> */}
                    <MenuItem value={'contact'}>Find By Contact:</MenuItem>
                    <MenuItem value={'date'}>Find By Date:</MenuItem>
                  </Select>

                  {props.filterValues.currentFindBy === 'ticket' ?
                    <Autocomplete
                      open={autoCompletesOpen['findTicket']}
                      onOpen={() => { handleAutoCompleteOpenClose('findTicket', true); }}
                      onClose={() => { handleAutoCompleteOpenClose('findTicket', false); }}
                      loading={props.internalLoading}
                      label="Ticket"
                      size='small'
                      id="autocomplete"
                      autoComplete
                      autoHighlight
                      disableClearable
                      style={{minWidth: '200px',}}
                      value={props.filterValues.ticketAutocompleteValue}
                      onChange={(event, newValue) => {
                        props.handleChangeCurrentTicket(newValue)
                        props.setFilterValues({...props.filterValues, ticketAutocompleteValue: newValue})
                        //props.setTicketAutocompleteValue(newValue)
                        sessionStorage.setItem("ticketFindByTicketFilter", JSON.stringify(newValue)) 
                      }}
                      options={filteredTicketEntryData?.map((ticket) => ticket ?? {})}
                      getOptionSelected={(option, value) => option?.ticket?.toString() === value?.ticket?.toString() || {}}
                      getOptionLabel={(option) => option.ticket}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              label="Ticket"
                              variant="outlined"
                              size='small'
                              InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                      <React.Fragment>
                                          {props.internalLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                      </React.Fragment>
                                  ),
                              }}
                          />
                      )}
                    />
                  // : props.filterValues.currentFindBy === 'order' ?
                  //   <Autocomplete
                  //     open={autoCompletesOpen['findOrder']}
                  //     onOpen={() => { handleAutoCompleteOpenClose('findOrder', true); }}
                  //     onClose={() => { handleAutoCompleteOpenClose('findOrder', false); }}
                  //     loading={props.internalLoading}
                  //     label="Order"
                  //     size='small'
                  //     id="autocomplete"
                  //     autoComplete
                  //     autoHighlight
                  //     disableClearable
                  //     style={{minWidth: '200px'}}
                  //     value={props.filterValues.orderAutocompleteValue}
                  //     onChange={(event, newValue) => {
                  //       //get BOL and attempt to find a ticket with that BOL, 
                  //       //if that exists get take info from ticket or order ? 
                  //       //create new delivery if no bol ticket exists ?

                  //       props.handleChangeCurrentTicket(newValue)
                  //       props.setFilterValues({...props.filterValues, orderAutocompleteValue: newValue})
                  //       //props.setOrderAutocompleteValue(newValue)
                  //       sessionStorage.setItem("ticketFindByOrderFilter", JSON.stringify(newValue)) 
                  //     }}
                  //     options={props.orderData?.map((order) => order ?? {})}
                  //     getOptionSelected={(option, value) => option?.order?.orderID?.toString() === value?.order?.orderID?.toString() || {}}
                  //     getOptionLabel={(option) => option?.order?.orderID?.toString() || ''}
                  //     renderInput={(params) => (
                  //         <TextField
                  //             {...params}
                  //             label="Order"
                  //             variant="outlined"
                  //             size='small'
                  //             InputProps={{
                  //                 ...params.InputProps,
                  //                 endAdornment: (
                  //                     <React.Fragment>
                  //                         {props.internalLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  //                         {params.InputProps.endAdornment}
                  //                     </React.Fragment>
                  //                 ),
                  //             }}
                  //         />
                  //     )}
                  //   />
                    :
                    props.filterValues.currentFindBy === 'contact' 
                    ?
                      <Autocomplete
                        open={autoCompletesOpen['findContact']}
                        onOpen={() => { handleAutoCompleteOpenClose('findContact', true); }}
                        onClose={() => { handleAutoCompleteOpenClose('findContact', false); }}
                        loading={props.internalLoading}
                        label="Contact"
                        size='small'
                        id="autocomplete"
                        autoComplete
                        autoHighlight
                        disableClearable
                        style={{minWidth: '200px'}}
                        value={props.filterValues.contactAutocompleteValue}
                        onChange={(event, newValue) => { 
                          props.setFilterValues({...props.filterValues, contactAutocompleteValue: newValue})

                          //props.setContactAutocompleteValue(newValue)
                          //let ticketContainer = props.ticketEntryData?.filter(x=> x.customerId === newValue?.docId)?.pop()
                          //!_.isEmpty(ticketContainer) && props.handleChangeCurrentTicket(ticketContainer)

                          sessionStorage.setItem("ticketFindByCustomerFilter", JSON.stringify(newValue)) 
                        }}
                        options={props.customers.sort((a, b) => a.name.localeCompare(b.name)) || {}}
                        getOptionLabel={(option) => option.name ? option.name + ' - ' + option.streetNumber + ' ' + option.streetName : ''  }
                        getOptionSelected={(option, value) =>  option?.name === value?.name}
                        renderOption={(option) =>( 
                            props.customers.filter(customer => customer.docId === option.docId).map((customerObj) => {
                                return(
                                    <Grid key={option.docId} >
                                        <Typography>{customerObj.name}</Typography>
                                        <small>{ customerObj.streetNumber + ' ' + customerObj.streetName}</small>
                                    </Grid>
                                )
                            })
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Contact"
                                variant="outlined"
                                size='small'
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                      />
                      :
                      <Grid>
                        <DatePicker 
                          value={props.filterValues.dateRange?.start || moment().valueOf()} 
                          onChange={(date) => { 
                            props.setFilterValues({...props.filterValues, dateRange: {...props.filterValues.dateRange, start: date}}) 
                            sessionStorage.setItem("ticketFilterDateRange", JSON.stringify({...props.filterValues.dateRange, start: date})) 
                          }}
                        />

                        <DatePicker 
                          value={props.filterValues.dateRange?.end || moment().valueOf()} 
                          onChange={(date) => { 
                            props.setFilterValues({...props.filterValues, dateRange: {...props.filterValues.dateRange, end: date}}) 
                            sessionStorage.setItem("ticketFilterDateRange", JSON.stringify({...props.filterValues.dateRange, end: date})) 
                          }}
                        />
                      </Grid>
                  }


                { props.filterValues.currentFindBy !== 'ticket' &&
                  <Button 
                    onClick={() => { setListModalOpen(true) }}
                    style={{marginLeft: '10px'}}
                    variant='contained'
                  >
                    {props.filterValues.currentFindBy !== 'ticket' ? "Search" : "Open"}
                  </Button>
                }

              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container justifyContent='flex-end'>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={props.filterValues.currentRadio}
                    onChange={(e)=> {
                      props.setFilterValues({...props.filterValues, currentRadio: e.target.value})
                      //props.setCurrentRadio(e.target.value)
                      sessionStorage.setItem("ticketRadioFilter", JSON.stringify(e.target.value)) 
                    }}
                  >
                    <FormControlLabel value="all" control={<Radio />} label="All Tickets" />
                    <FormControlLabel value="notInvoiced" control={<Radio />} label="NOT INVOICED" />
                    <FormControlLabel value="notPayrolled" control={<Radio />} label="NOT PAYROLLED" />
                    <FormControlLabel value="invoicedAndPayrolled" control={<Radio />} label="INVOICED AND PAYROLLED" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    )
  }
  
  export default withRouter(TicketFilter);