import React from 'react';
import { Grid, Typography, Button, Paper, IconButton, makeStyles, Box, Tab, MenuItem, Menu} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import 'react-quill/dist/quill.snow.css';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import QuillText from './QuillText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({

    table: {
      display: 'flex',
      justifyContent: 'center',
    },

}));

const DayViewCard = (props) => {

    const classes = useStyles();

    return (
        <Grid>
            {props.viewProcessing
            ? 
                <Grid container> 
                    <Skeleton variant='rect' width={'90vw'} height={'90vh'}/>
                </Grid>
            : 
            <Paper style={{marginRight: '10px', padding: '10px',}}>

                <Grid container style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Grid container >
                        <Grid item xs={3}>
                        {props.tabSelected === 'schedule' ?
                            <Grid container>
                                
                                <IconButton
                                    variant='outlined'
                                    size={props.smallScreen ? "small" : "medium"}
                                    onClick={() => {props.handleChangeDate(false)}}>
                                    <NavigateBeforeIcon />
                                </IconButton>
                    
                                <Typography style={{fontSize: '25px', fontWeight: '600'}}>{moment(props.selectedDate).format('LL')}</Typography>
                        
                                <IconButton
                                    variant='outlined'
                                    size={props.smallScreen ? "small" : "medium"}
                                    onClick={() => {props.handleChangeDate(true)}}>
                                    <NavigateNextIcon />
                                </IconButton>
                                
                            </Grid>
                            : null}
                        </Grid>  

                        <Grid item xs={6} className={classes.table}>
                            <TabContext value={props.tabSelected}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={props.handleTableChange}>
                                    <Tab label={"Schedule"} value="schedule" />
                                    <Tab label={"Global Notes"} value="notes" />
                                </TabList>
                                </Box>
                                <TabPanel value="schedule"></TabPanel>
                                <TabPanel value="notes"></TabPanel>
                            </TabContext>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <Grid container justifyContent='flex-end'>
                                <Button
                                    style={props.smallScreen ? { marginBottom: '20px', fontSize: '12px'}  :  { marginBottom: '20px', }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {props.handleCalanderClick()}}
                                    startIcon={<ArrowBackIcon />}
                                >
                                    {window.innerWidth < 500 ? "" :"Calendar"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent='flex-end'>
                        <IconButton onClick ={(e) => {props.handleOpen(e)}} size='small'>
                            <ArrowDropDownIcon/>
                        </IconButton>

                        <Menu
                            anchorEl={props.anchorEl}
                            keepMounted
                            open={Boolean(props.anchorEl)}
                            onClose={props.handleClose}
                        >
                            {props.handleFindExistingDocByDate(props.currentDoc.date, props.plannedDeliveries)?.versions?.map((version, index) => {
                                return (
                                    <MenuItem 
                                        key={index} 
                                        onClick={() => { props.handleSwitchVersion(version)}}
                                    >
                                        {moment(version.time).format('MMMM Do YYYY, h:mm:ss a')}
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    </Grid>

                    <Grid style={{width: 'inherit', height: '80vh'}}>
                        <QuillText 
                            passedDefaultValue={props.currentDoc.value}
                            passedOnChange={props.tabSelected === 'schedule' ? props.schedulerUpdateCallback : props.notesUpdateCallback}
                            passedCurrentDoc={props.currentDoc}
                        />
                    </Grid>

                    {props.tabSelected === 'schedule' &&
                        <Grid container justifyContent='flex-end'>
                            <Button
                                variant='contained'
                                onClick={()=> { props.handleChangePublished() }}
                            >
                                {props.handleFindExistingDocByDate(props.currentDoc.date, props.plannedDeliveries)?.published ? "Unpublish" : "Publish"}
                            </Button>
                        </Grid>
                    }

                </Grid>
            </Paper>
        }
        </Grid>
    )
}

export default withRouter(DayViewCard)