import React, {useEffect, useState} from 'react';
import { Divider, Typography, makeStyles } from '@material-ui/core';
import { Grid, Paper, useMediaQuery, Button, Checkbox, TextField, CircularProgress, IconButton, Modal, Fade, Dialog, DialogTitle,
        DialogContent, DialogActions } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab'
import { withRouter } from 'react-router-dom';
import _ from 'lodash'
import { useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PaperTitle from '../Orders/OrderPage/PaperTitle';
import moment from 'moment';
import { useUser } from '../../../providers/UserContext';
import { Edit, Delete } from '@material-ui/icons';
import Backdrop from '@material-ui/core/Backdrop';
import DatePicker from '../UI/DatePicker/DatePicker';
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import DeleteIcon from '@material-ui/icons/Delete';
import SignatureCanvas from "react-signature-canvas";
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Img from 'react-cool-img';
import truck from '../../../img/geeteezoomzoom.gif';
import Dropzone from 'react-dropzone'
import PublishIcon from '@material-ui/icons/Publish';
import InlineMediaViewer from '../UI/MediaViewer/InlineMediaViewer'
import PrintIcon from '@material-ui/icons/Print';
import TimerIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles((theme) => ({
    btnGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    tabBar: {
        width: '100%',
        maxWidth: '1000px',
        zIndex: '998'
    },
    tabs:{
        [theme.breakpoints.up("md")]: {
          margin:'5px'
        }
    },
    alias: {
        display: 'flex',
        marginTop: '8px',
        marginBottom: '8px',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        maxWidth: '1000px',
    },
    content: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        maxWidth: '1000px',
        margin: 'auto',
    },
    paper: {
        borderRadius: '0px',
        margin: '10px',
        padding:'20px',
        marginBottom:'20px',
        width: '100%',
        maxWidth: '1400px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        margin: '10px',
    },
    fieldTitle: {
        fontWeight:'bold',
        lineHeight: 2,
        marginTop: '5px',
    },
    field: {
        lineHeight: 2,
        marginTop: '5px',
        margin: theme.spacing(1),
    },
    divider: {
        marginBottom: '5px'
    },
    sig: {
        border: '1px solid black',
        width: '100%',
        minHeight: '250px',
        weatherTitle: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '16px',
        },
        end: {
            justifyContent: 'flex-end',
        },
        temp: {
            justifyContent: 'center',
            border: '4px solid white',
            borderRadius: '64px',
        },
        weatherCard: {
            backgroundColor: 'rgb(59, 98, 177)',
            boxShadow: 'inset 0px 0px 2px 2px rgba(0,0,0,0.3)',
        },
    }
}));

const WorkOrderCard = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { users, userLoading, getUsers } = useUser();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [operatorAutoCompleteOpen, setOperatorAutoCompelteOpen] = React.useState(false);
    const [secondaryAutoCompleteOpen, setSecondaryAutoCompleteOpen] = useState(Array(props.workOrderData?.secondaryTechs?.length).fill(false));
    const [typedValue, setTypedValue] = useState("");
    const [secondaryTypedValues, setSecondaryTypedValues] = useState(Array(props.workOrderData?.secondaryTechs?.length).fill(''));

    const [addRepairModal, setAddRepairModal] = useState(false)
    const [imgModal, setImgModal] = useState(false)
    const [imgUrl, setImgUrl] = useState('')
    //const [edit, setEdit] = useState(false)
    //const [unitAutocompleteOpen, setUnitAutocompleteOpen] = useState(false)

    const [deleteIndex, setDeleteIndex] = useState(null)

    const [open, setOpen] = useState(false);
    const [yesDisabled, setYesDisabled] = useState(5);

    const [dialogType, setDialogType] = useState('')

    let dataCard = null;

    useEffect(() => {
    }, [smallScreen]);

    useEffect(() => {
        if(users.length <= 0){
            getUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(open) {timer();}
        //eslint-disable-next-line
    }, [yesDisabled, open])

    //function to display user displayname instead of uuid
    const getUserDisplayName = (uuid) => {
        let user = _.find(users, {uuid: uuid});
        if (!_.isUndefined(user)) {
            return user.displayName;
        } else {
            return uuid;
        }
    }

    /*const handleOpen = () => {
        setOpen(true);
        setYesDisabled(5);
    }*/

    const handleClose = () => {
        setOpen(false);
        setYesDisabled(5);
    }

   const timer = () => {
        if(!open || !open) {
            setYesDisabled(5);
            return;
        }
        if(yesDisabled > 0 && open){
            setTimeout(() => {
                if(!open) return;
                setYesDisabled(yesDisabled - 1);
            }
            , 1000);
        } else return;
    }

    const handleRepairDialogOpen = (dialogType, repair, index) => {
        if(_.isUndefined(repair) && _.isUndefined(index)){
            props.setRepairForm({...props.initialRepair})
            props.setPartForm({...props.initialPart})
            setAddRepairModal(true);
            setDialogType(dialogType)
            props.setItemIndex(null)
            setDeleteIndex(null)
        }else{
            props.setRepairForm({...repair})
            props.setPartForm({...repair})
            setAddRepairModal(true);
            setDialogType(dialogType)
            props.setItemIndex(index)
            setDeleteIndex(null)
        }
    };

    if (!_.isUndefined(props.workOrderData)) {
        dataCard = (
            <Grid className={classes.content}>

                <Grid className={classes.backButton}>
                    <Button variant="contained" onClick={() => props.history.goBack()} startIcon={<ArrowBackIcon/>}>Back</Button>
                </Grid>

                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid xs={6} item>
                            <PaperTitle title="Work Order Information" />
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container  justifyContent='flex-end'>
                                <IconButton
                                    onClick={() => { props.history.push("/dashboard/workOrders/printing/?docId=" + props.workOrderData?.docId)}}
                                >
                                    <PrintIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        props.setUnitAutocompleteValue(props.units[props.units.findIndex(unit => unit.docId === props.workOrderData.vehicle.unitDocId )])
                                        props.setEdit(!props.edit)
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    onClick={() => { setDeleteModalOpen(true) }}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider className={classes.divider}/>

                    <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}> 
                        <Grid xs={4} item style={{  marginBottom:'5px'}}>
                       
                            <Typography variant="h5" >{props.workOrderData.assignedUser === '' ? 'UNASSIGNED' : getUserDisplayName(props.workOrderData.assignedUser).toUpperCase()}</Typography>
                            
                        </Grid>

                        <Grid xs={4} item >
                            {props.edit?
                            <DatePicker
                                name="dateCreated"
                                onChange={(event) => { 
                                    props.updateWorkOrderData({ ...props.workOrderData, 'dateCreated': event.valueOf() })
                                 }}
                                value={moment(props.workOrderData.dateCreated).format('YYYY-MM-DD')}
                            />
                            :
                            <Typography variant="h5" >{moment(props.workOrderData.dateCreated).format('MMMM Do, YYYY')}</Typography>}

                        </Grid>

                        <Grid xs={4} item>
                            <Grid container justifyContent='flex-end'>
                                <Typography variant="h5" >Complete & Reviewed</Typography>
                                    <Checkbox
                                        style={{padding: 0, marginRight: '5px', marginLeft: '5px'}}
                                        //disabled={props.workOrderData.completed}
                                        checked={props.workOrderData.completed ? true : false}
                                        onClick={() => { props.workOrderData.completed ? props.handleIncompleteWorkOrder() : props.handleCompleteWorkOrder(); }}
                                    />
                            </Grid>
                        </Grid>
                    </Grid>

                    {props.workOrderData.customer.outsideWork?
                        <Grid xs={12} container style={{padding:'10px'}}>
                            <Alert variant='filled' severity={'warning'} style={{width:'100%'}}>This is an outside work order!</Alert>
                        </Grid>
                    :null}

                    <Grid container style={{padding:'10px'}}>
                        <Grid item xs={2}>
                            <Typography className={classes.fieldTitle} style={{fontWeight:'bold'}}>Date Created: </Typography>
                        </Grid>
                        <Grid item xs={10}>
                            {props.edit
                            ?
                            <DatePicker
                                name="dateCreated"
                                onChange={(event) => { props.updateWorkOrderData({ ...props.workOrderData, 'dateCreated': event.valueOf() }) }}
                                value={moment(props.workOrderData.dateCreated).format('YYYY-MM-DD')}
                            />
                            :
                            <Typography className={classes.field}>{moment(props.workOrderData.dateCreated).format('MMMM Do, YYYY')}</Typography>}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={classes.fieldTitle} style={{fontWeight:'bold'}}>Date Completed: </Typography>
                        </Grid>
                        <Grid item xs={10}>
                        {props.edit
                            ?
                            <KeyboardDateTimePicker
                            format='MMMM Do, YYYY, h:mm a'
                            value={props.workOrderData.dateCompleted}
                            onChange={(date) => { props.updateWorkOrderData({ ...props.workOrderData, 'dateCompleted': date.valueOf() }) }}
                            fullWidth />
                            :
                            <Typography className={classes.field}>{props.workOrderData.dateCompleted ? moment(props.workOrderData.dateCompleted).format('MMMM Do, YYYY, h:mm a') : ''}</Typography>
                        }
                        </Grid>
                        <Grid item xs={2} className={classes.fieldTitle} ><Typography><b>Assigned Tech(s):</b></Typography></Grid>
                    </Grid>

                    <Grid container xs={12}  style={{padding:'10px'}}>
                            <Grid item xs={4}>
                                <Autocomplete
                                    open={operatorAutoCompleteOpen}
                                    onOpen={() => {
                                        setOperatorAutoCompelteOpen(true);
                                    }}
                                    onClose={() => {
                                        setOperatorAutoCompelteOpen(false);
                                    }}
                                    loading={userLoading}
                                    label="Search"
                                    id="autocomplete"
                                    autoComplete
                                    autoHighlight
                                    //className={classes.field}
                                    //disabled={props.workOrderData.customer.outsideWork && !props.workOrderData.customer.signedOff}
                                    disableClearable
                                    style={{ maxWidth: '300px', width: '100%' }}
                                    inputValue={typedValue}
                                    onInputChange={(event, newValue) => { setTypedValue(newValue) }}
                                    value={getUserDisplayName(props.workOrderData.assignedUser)}
                                    onChange={(event, newValue) => { props.updateWorkOrderData({ ...props.workOrderData, 'assignedUser': newValue }) }}
                                    options={users.filter(x=> x.employment?.active).map((user) => user.uuid ?? '').sort((a, b) => getUserDisplayName(a).toLowerCase() > getUserDisplayName(b).toLowerCase())}
                                    getOptionSelected={(option, value) => option?.uuid === value?.uuid}
                                    getOptionLabel={(option) => getUserDisplayName(option)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ maxWidth: '300px', width: '100%' }}
                                            //label="Shop Tech"
                                            variant="outlined"
                                            size='small'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                        />
                                    )}
                                />

                            </Grid>
                            <Grid item>
                                <Button 
                                size="small" 
                                variant="contained"
                                
                                disabled={props.workOrderData.assignedUser === ''}
                                className={classes.field}
                                onClick={() => {
                                    props.updateWorkOrderData({ ...props.workOrderData, 'assignedUser': '' });
                                }}
                                >
                                Unassign
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                variant="contained"
                                size="small"
                                className={classes.field}
                                onClick={() => {
                                    props.history.push(`/dashboard/reporting/timesheets?docId=${props.workOrderData.timesheetID}`);
                                }}
                                disabled={props.workOrderData.timesheetID === ''}
                                startIcon={<TimerIcon />}
                                >
                                Timesheet
                                </Button>
                            </Grid>
                        </Grid>
                        
                                {props.workOrderData?.secondaryTechs &&
                                    props.workOrderData?.secondaryTechs?.map((tech, index) => {
                                        return (
                                            <Grid container xs={12} style={{padding:'10px'}} key={index}>
                                                <Grid item xs={4}>
                                                    <Autocomplete
                                                        open={secondaryAutoCompleteOpen[index]}
                                                        onOpen={() => {
                                                        const newOpen = [...secondaryAutoCompleteOpen];
                                                        newOpen[index] = true;
                                                        setSecondaryAutoCompleteOpen(newOpen);
                                                        }}
                                                        onClose={() => {
                                                        const newOpen = [...secondaryAutoCompleteOpen];
                                                        newOpen[index] = false;
                                                        setSecondaryAutoCompleteOpen(newOpen);
                                                        }}
                                                        loading={userLoading}
                                                        label="Search"
                                                        id={`autocomplete-secondary-${index}`}
                                                        autoComplete
                                                        autoHighlight
                                                        //className={classes.field}
                                                        disableClearable
                                                        style={{ maxWidth: '300px', width: '100%' }}
                                                        inputValue={secondaryTypedValues[index]}
                                                        onInputChange={(event, newValue) => {
                                                        const newValues = [...secondaryTypedValues];
                                                        newValues[index] = newValue;
                                                        setSecondaryTypedValues(newValues);
                                                        }}
                                                        value={getUserDisplayName(tech)}
                                                        onChange={(event, newValue) => {
                                                        let temp = [...props.workOrderData.secondaryTechs];
                                                        temp[index] = newValue;
                                                        props.updateWorkOrderData({ ...props.workOrderData, 'secondaryTechs': temp });
                                                        }}
                                                        options={users.filter(x => x.employment?.active).map((user) => user.uuid ?? '').sort((a, b) => getUserDisplayName(a).toLowerCase() > getUserDisplayName(b).toLowerCase())}
                                                        getOptionSelected={(option, value) => option?.uuid === value?.uuid}
                                                        getOptionLabel={(option) => getUserDisplayName(option)}
                                                        renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            style={{ maxWidth: '300px', width: '100%' }}
                                                            variant="outlined"
                                                            size='small'
                                                            InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                {userLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                            }}
                                                        />
                                                        )}
                                                    />
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            size="small"
                                                            className={classes.field}
                                                            onClick={() => {
                                                            let temp = props.workOrderData.secondaryTechAmount ?? 0;
                                                            let tempArray = props.workOrderData.secondaryTechs ?? [];
                                                            //remove tech from array
                                                            tempArray.splice(index, 1);
                                                            props.updateWorkOrderData({ ...props.workOrderData, 'secondaryTechAmount': temp - 1, 'secondaryTechs': tempArray })
                                                            }}
                                                        >- Remove</Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button 
                                                            variant="contained"
                                                            size="small"
                                                            className={classes.field}
                                                            onClick={() => {
                                                                props.history.push(`/dashboard/reporting/timesheets?docId=${props.workOrderData?.secondaryTimesheets.find(timesheet => timesheet.includes(tech)).split('__')[1]}`);
                                                            }}
                                                            disabled={
                                                                !props.workOrderData?.secondaryTimesheets.some(string => string.includes(tech))
                                                            }
                                                            startIcon={<TimerIcon />}
                                                            >
                                                            Timesheet
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            
                                        )
                                    })
                                } 
                                <Grid item xs={12}>
                                    <Button variant="contained" style={{marginTop:'5px'}}
                                    size="small" disabled={props.workOrderData.assignedUser === ''}
                                    onClick={() => {
                                        let temp = props.workOrderData.secondaryTechAmount ?? 0;
                                        let tempArray = props.workOrderData.secondaryTechs ?? [];
                                        tempArray.push('');
                                        props.updateWorkOrderData({ ...props.workOrderData, 'secondaryTechAmount': temp + 1, 'secondaryTechs': tempArray })
                                    }}
                                    >
                                        + Add Tech
                                    </Button>
                                </Grid>
                            
                       


                        { props.edit ?
                        <Grid container style={{height:'100%'}}>
                            <Grid xs={6} item style={{padding:'10px', height:'100%'}}>
                                <Paper style={{padding:'10px', height:'100%'}}>
                                    <Grid xs={12} item>
                                        <Typography variant='h5'>Vehicle Information</Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                    <Grid xs={12} item container>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Unit #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            {/*}
                                           <Autocomplete
                                                open={unitAutocompleteOpen}
                                                onOpen={() => {
                                                    setUnitAutocompleteOpen(true);
                                                }}
                                                onClose={() => {
                                                    setUnitAutocompleteOpen(false);
                                                }}
                                                loading={props.unitLoading}
                                                freeSolo
                                                autoSelect
                                                label="Search"
                                                id="autocomplete"
                                                autoComplete
                                                autoHighlight
                                                value={props.unitAutocompleteValue}
                                                onChange={(event, newValue) => {
                                                    props.handleUnitUpdate(newValue)
                                                }}
                                                options={props.units}
                                                renderOption={(unit) =>(
                                                    <Grid >
                                                        <Typography>{'#' + unit.unitNumber + ' ' + unit.description}</Typography>
                                                    </Grid>
                                                )}
                                                getOptionSelected={(option, value) => option.unitNumber === value.unitNumber }


                                                getOptionLabel={(option) => option.unitNumber ? option.unitNumber : option}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        //label="Select Unit"
                                                        variant="outlined"
                                                        //required
                                                        size="small"
                                                        name="unitName"
                                                        fullWidth
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {props.unitLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                    />*/}
                                            <TextField value={props.workOrderData.vehicle.unitNumber} onChange={(event) => { props.handleUpdate('vehicle', 'unitNumber', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Year: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.year} onChange={(event) => { props.handleUpdate('vehicle', 'year', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Make: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.make} onChange={(event) => { props.handleUpdate('vehicle', 'make', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Mileage: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.mileage} onChange={(event) => { props.handleUpdate('vehicle', 'mileage', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>VIN #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.vin} onChange={(event) => { props.handleUpdate('vehicle', 'vin', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>License Plate #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.licensePlate} onChange={(event) => { props.handleUpdate('vehicle', 'licensePlate', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Cab Card #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.cabCardNumber} onChange={(event) => { props.handleUpdate('vehicle', 'cabCardNumber', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>PO #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.vehicle.PONumber} onChange={(event) => { props.handleUpdate('vehicle', 'PONumber', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Safety Due: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                        <DatePicker
                                            name="vehicle.safetyDueDate"
                                            onChange={(event) => { props.handleSafetyDueDateUpdate(event) }}
                                            value={props.workOrderData.vehicle.safetyDueDate}
                                        />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid xs={6} item style={{padding:'10px'}}>
                                <Paper style={{padding:'10px'}}>
                                    <Grid xs={12} item>
                                        <Typography variant='h5'>Customer Information</Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                    <Grid xs={12} item container>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Company: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.customer.companyName} onChange={(event) => { props.handleUpdate('customer', 'companyName', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Contact Name: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.customer.contactName} onChange={(event) => { props.handleUpdate('customer', 'contactName', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Contact Phone: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <TextField value={props.workOrderData.customer.contactPhone} onChange={(event) => { props.handleUpdate('customer', 'contactPhone', event.target.value) }} fullWidth align={'right'}/>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Customer Signed Off: </Typography>
                                        </Grid>

                                        {props.workOrderData.customer.outsideWork?
                                            <Grid xs={6} container justifyContent={'flex-end'}>
                                                <Checkbox
                                                    checked={props.workOrderData.customer.signedOff}
                                                    onChange={()=>{
                                                        props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, signedOff: !props.workOrderData.customer.signedOff}})
                                                    }}
                                                />
                                            </Grid>
                                        :null }

                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid xs={6} item style={{padding:'10px'}}>
                                <Paper style={{padding:'10px'}}>
                                    <Grid xs={12} item>

                                        <Typography variant='h5'>Vehicle Information</Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>

                                    <Grid xs={12} item container>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Unit #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.unitNumber}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Year: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.year}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Make: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.make}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Mileage: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.mileage}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>VIN #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.vin}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>License Plate #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.licensePlate}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Cab Card #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.cabCardNumber}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>PO #: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.vehicle.PONumber}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Safety Due: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography
                                                className={classes.field}
                                                align={'right'}
                                            >
                                                {props.workOrderData.vehicle.safetyDueDate !== null ? moment(props.workOrderData.vehicle.safetyDueDate).format('YYYY-MM-DD') : '' }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>

                            <Grid xs={6} item style={{padding:'10px'}}>
                                <Paper style={{padding:'10px'}}>
                                    <Grid xs={12} item>
                                        <Typography variant='h5'>Customer Information</Typography>
                                        <Divider className={classes.divider}/>
                                    </Grid>
                                    <Grid xs={12} item container>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Company: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.customer.companyName}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Contact Name: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.customer.contactName}</Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.fieldTitle}>Contact Phone: </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography className={classes.field} align={'right'}>{props.workOrderData.customer.contactPhone}</Typography>
                                        </Grid>

                                        {props.workOrderData.customer.outsideWork ?
                                        <React.Fragment>
                                            <Grid xs={6} item>
                                                <Typography className={classes.fieldTitle}>Customer Signed Off: </Typography>
                                            </Grid>
                                            <Grid xs={6} container justifyContent={'flex-end'}>
                                                <Checkbox
                                                    checked={props.workOrderData.customer.signedOff}
                                                    onChange={()=>{
                                                        props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, signedOff: !props.workOrderData.customer.signedOff}})
                                                    }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                        : null }
                                </Grid>
                            
                            </Paper>

                        </Grid>
                    </Grid>
                    }

                    
                    <Grid container style={{marginTop: '20px'}}>
                        {/* customer signature */}
                        {props.workOrderData.customer.sigURL || props.edit ?
                        <Grid item xs={6}>
                            <Typography variant='h5'>Customer Signature</Typography>

                            {props.workOrderData.customer.sigURL === "" || (props.workOrderData.customer.sigURL !== props.saveState.customer.sigURL)
                            ?
                                <SignatureCanvas
                                    ref={props.customerSig}
                                    canvasProps={{className: classes.sig}}
                                    clearOnResize={false}
                                    onEnd={()=> {
                                        //adds dataURL as stand in to trigger save toast
                                        props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, sigURL: props.customerSig.current.getTrimmedCanvas().toDataURL()}})
                                    }}
                                />
                            :
                                <img className={classes.sig} alt={'customerSignature'} src={props.workOrderData.customer.sigURL}/>
                            }

                            <Grid container>
                                {props.edit ?
                                <React.Fragment>
                                    <Grid container justifyContent='center' xs={6}>
                                        {props.workOrderData.customer.sigURL === "" && <Typography><small>Sign in box above...</small></Typography> }
                                    </Grid>

                                    <Grid container xs={6} justifyContent='flex-end'>
                                        <Button
                                            variant='contained'
                                            style={{marginTop: '5px'}}
                                            onClick={()=> {
                                                if(props.workOrderData.customer.sigURL === "" || (props.workOrderData.customer.sigURL !== props.saveState.customer.sigURL)){
                                                    props.customerSig.current.clear()
                                                    props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, sigURL: ""}})
                                                }
                                                else{
                                                    props.updateWorkOrderData({ ...props.workOrderData, customer: {...props.workOrderData.customer, sigURL: ""}})
                                                }
                                            }}
                                        >
                                            {props.workOrderData.customer.sigURL === "" || (props.workOrderData.customer.sigURL !== props.saveState.customer.sigURL) ? 'Clear' : 'Remove'}
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                                : null
                                }
                            </Grid>
                        </Grid>
                        : null } 

                        {/* user signature */}
                        {props.workOrderData.userSignature || props.edit ?
                        <Grid item xs={6}>
                            <Typography variant='h5'>Employee Signature</Typography>

                            {props.workOrderData.userSignature === "" || (props.workOrderData.userSignature !== props.saveState.userSignature)
                            ?
                                <SignatureCanvas
                                    ref={props.userSig}
                                    canvasProps={{className: classes.sig}}
                                    clearOnResize={false}
                                    onEnd={()=> {
                                        //adds dataURL as stand in to trigger save toast
                                        props.updateWorkOrderData({ ...props.workOrderData, userSignature: props.userSig.current.getTrimmedCanvas().toDataURL()})
                                    }}
                                />
    
                            :
                                <img className={classes.sig} alt={'userSignature'} src={props.workOrderData.userSignature}/>
                            }

                            <Grid container>
                                {props.edit ?
                                <React.Fragment>
                                    <Grid container justifyContent='center' xs={6}>
                                        {props.workOrderData.userSignature === "" && <Typography><small>Sign in box above...</small></Typography> }
                                    </Grid>

                                    <Grid container xs={6} justifyContent='flex-end'>
                                        <Button
                                            variant='contained'
                                            style={{marginTop: '5px'}}
                                            onClick={()=> {
                                                if(props.workOrderData.userSignature === "" || (props.workOrderData.userSignature !== props.saveState.userSignature)){
                                                    props.userSig.current.clear()
                                                    props.updateWorkOrderData({ ...props.workOrderData, userSignature: ""})
                                                }
                                                else{
                                                    props.updateWorkOrderData({ ...props.workOrderData, userSignature: ""})
                                                }
                                            }}
                                        >
                                            {props.workOrderData.userSignature === "" || (props.workOrderData.userSignature !== props.saveState.userSignature) ? 'Clear' : 'Remove'}
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                                  : null
                                }
                            </Grid>
                        </Grid>
                        : null }
                    </Grid>

                    <Divider style={{marginTop:'30px', marginBottom:'45px'}}/>

                    <Grid container>
                        <Grid item xs={10}>
                            <PaperTitle title="Repairs" />
                        </Grid>
                        { props.edit ?
                            <Grid container xs={2} justifyContent='flex-end'>
                                <Button
                                    size='small'
                                    variant='contained'
                                    onClick={()=> { 
                                        handleRepairDialogOpen('New Repair')
                                    }}
                                >
                                    Add Repair
                                </Button>
                            </Grid>
                        : null
                        }
                    </Grid>

                    <Grid item sm={12} style={{marginTop: '5px'}}>
                        <Grid container spacing={2}>
                            <Grid item sm={1}>
                                <Typography><small><b>Complete</b></small></Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography><small><b>Hours</b></small></Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography><small><b>Description</b></small></Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <Typography><small><b>Tech</b></small></Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography><small><b>Edit</b></small></Typography>
                            </Grid>
                            <Grid item sm={1}>
                                <Typography><small><b>Delete</b></small></Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={12}>
                            {
                            props.workOrderData.repairsNeeded.map((repair, index) => {
                                return (
                                    <Grid container spacing={2} key={index} >
                                        <Grid item sm={1}>
                                            <Checkbox
                                                style={{margin: 0, padding: 0}}
                                                checked={ repair.completed }
                                                onChange={(e)=> {
                                                    let repairContainer = _.cloneDeep(repair)
                                                    repairContainer.completed = e.target.checked;

                                                    let repairsNeededContainer = _.cloneDeep(props.workOrderData.repairsNeeded)
                                                    repairsNeededContainer.splice(index, 1, repairContainer)

                                                    props.updateWorkOrderData({ ...props.workOrderData, repairsNeeded:  [...repairsNeededContainer] }) 
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Typography >{repair.timeSpentRepairing}</Typography>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Typography >{repair.description}</Typography>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Typography >{users.find(user => user.uuid === repair?.uuid)?.displayName}</Typography>
                                        </Grid>
                                        <Grid item sm={1}>
                                            <Typography></Typography>
                                            <IconButton 
                                                size="small"
                                                onClick={() => { handleRepairDialogOpen('Edit Repair', repair, index) }}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                        </Grid>

                                        <Grid item sm={1}>
                                        <Typography></Typography>
                                            <IconButton 
                                                size="small"
                                                onClick={() => props.handleRemoveAdditionalInfo('Repair', index)}
                                            >   
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Grid>
                                         
                                        {repair.notes && repair.notes !== '' ?
                                            <Grid item sm={12}>
                                                <b>Notes: </b> <small>{repair.notes}</small>
                                            </Grid>
                                        : null
                                        } 
                                        
                                         {repair.techNotes && repair.techNotes !== '' ?
                                            <Grid item sm={12}>
                                                <b>Tech Notes: </b> <small>{repair.techNotes}</small>
                                                
                                            </Grid>
                                        : null
                                        } 
                                        <Grid item sm={12}>
                                            <Divider/>
                                        </Grid>
                                       
                                    </Grid>
                                )
                            }
                        )}
                    </Grid>
   
                    <Divider style={{marginTop:'30px', marginBottom:'30px', opacity:'0%'}}/>
                    <Grid container>
                        <Grid item xs={10}>
                            <PaperTitle title="Parts" />
                        </Grid>
                        { props.edit ?
                            <Grid container xs={2} justifyContent='flex-end'>
                                <Button
                                    size='small'
                                    variant='contained'
                                    onClick={()=> { 
                                        handleRepairDialogOpen('New Part')
                                    }}
                                >
                                    Add Part
                                </Button>
                            </Grid>
                        : null
                        }
                    </Grid>
                        <Grid item sm={12} style={{marginTop: '5px'}}>
                            <Grid container spacing={2}>
                                <Grid item sm={2}>
                                    <Typography><small><b>Part Number</b></small></Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography noWrap style={{textOverflow: 'ellipsis'}}><small><b>Part Description</b></small></Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Typography><small><b>QTY</b></small></Typography>
                                </Grid>
                                <Grid item sm={1}>
                                    <Typography><small><b>Edit</b></small></Typography>
                                </Grid>
                                <Grid item sm={1}>
                                    <Typography><small><b>Delete</b></small></Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={12}>
                                {props.workOrderData?.partsNeeded &&
                                props.workOrderData.partsNeeded.map((part, index) => {
                                    return (
                                        <Grid key={index} container spacing={2}>
                                            <Grid item sm={2}>
                                                <Typography>{part.partNumber}</Typography>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Typography>{part.partDescription}</Typography>
                                            </Grid>
                                            <Grid item sm={2}>
                                                <Typography>{part.quantity}</Typography>
                                            </Grid>                     
                                            <Grid item sm={1}>
                                                <IconButton 
                                                    size="small"
                                                    onClick={() => { handleRepairDialogOpen('Edit Part', part, index) }}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item sm={1}>
                                                <IconButton 
                                                    size="small"
                                                    onClick={() => props.handleRemoveAdditionalInfo('Part', index)}
                                                >   
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Grid>
                                            {part.notes && part.notes !== '' ?
                                            <Grid item sm={12}>
                                                <b>Notes: </b> <small>{part.notes}</small>
                                                
                                            </Grid>
                                        : null
                                        } 
                                        {part?.photoUrls && part?.photoUrls.length > 0 ?

                                        <Grid container item sm={12}>
                                            <b>Photos: </b>&nbsp;
                                            {part.photoUrls.map((photoUrl, index) => {
                                                return (
                                                    <Grid key={photoUrl} item sm={1} style={{marginRight:'22px'}}>
                                                        <Img 
                                                            onClick={()=> {setImgUrl(photoUrl); setImgModal(true);}} 
                                                            style={{width:'100px', height:'100px'}} 
                                                            className={classes.img} 
                                                            placeholder={truck} 
                                                            src={typeof photoUrl === "string" ? photoUrl : URL.createObjectURL(photoUrl)} 
                                                            alt={index+ ' Part Photo'}
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                            
                                        </Grid>
                                        
                                    :null}
                                        <Grid item sm={12}>
                                            <Divider/>
                                        </Grid>
                                       
                                         
                                        </Grid>
                                    )
                                }
                            )}
                        </Grid>

                        <Divider style={{marginTop:'30px', marginBottom:'30px', opacity:'0%'}}/>
                        {props.workOrderData?.notes !== '' ?
                    <React.Fragment>
                    <PaperTitle title="Notes" />
                    <Grid item sm={12} style={{marginTop: '5px'}}>
                        <Grid container spacing={2}>
                            <Grid item sm={12}>
                                <Typography><small><i>{props.workOrderData.notes}</i></small></Typography>
                            </Grid>
                        </Grid>
                    </Grid></React.Fragment>:null}

                    <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end'}}>
                        <InlineMediaViewer photoURLs={props.workOrderData.photoURLs} />
                    </Grid>
                </Paper>
            </Grid>
        )
    }

    return (
        <div>
            <div>
                {/* complete work order modal */}
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: 0,
                    }}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 200,
                    }}>
                    <Fade in={open}>
                        <Paper style={{ padding: '16px' }}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <Typography align='center' style={{fontWeight: 600}}>Do you authorize that all repairs are complete and accurate?</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align='center'>Please allow a few seconds for Yes to become available.</Typography>
                                </Grid>

                                <Grid item>
                                    <Button 
                                        startIcon={<CheckIcon/>} 
                                        style={{marginRight:'10px'}} 
                                        disabled={yesDisabled > 0} 
                                        variant='contained' 
                                        onClick={() => {
                                            props.handleCompleteWorkOrder();
                                            handleClose();
                                        }}
                                    >
                                        Yes {yesDisabled > 0 ? '('+yesDisabled+')' : null}
                                    </Button>

                                    <Button 
                                        startIcon={<CloseIcon/>} 
                                        style={{color:'white',backgroundColor:'red'}} 
                                        variant='contained' 
                                        onClick={handleClose}
                                    >
                                        No
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Fade>
                </Modal>

                {/* delete modal */}
                <Modal
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        outline: 0,
                    }}
                    open={deleteModalOpen}
                    onClose={()=> {setDeleteModalOpen(false)}}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}>
                    <Fade in={deleteModalOpen}>
                        <Paper style={{ padding: '16px' }}>
                            <Grid container direction='column' spacing={2}>
                                <Grid item>
                                    <Typography>Are you sure you want to delete this work order?</Typography>
                                </Grid>

                                <Grid container justifyContent='flex-end'>
                                    <Grid item >
                                        <Button variant='contained' style={{margin: '5px'}} color="secondary" onClick={() => { setDeleteModalOpen(false) }} > Cancel </Button>
                                        <Button variant='contained' style={{margin: '5px'}} onClick={() => props.handleDeleteWorkOrder()}> Confirm </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Paper>
                    </Fade>
                </Modal>

                {/* add repair/parts modal */}
                <Dialog
                    open={addRepairModal}
                    onClose={ () => {setAddRepairModal(false)}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{dialogType}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                
                            {(dialogType === 'New Repair' || dialogType === 'Edit Repair')
                            ?
                            <React.Fragment>
                                <Grid item sm={12}>
                                    <Typography>Completed</Typography>
                                    <Checkbox
                                        name="completed"
                                        checked={props.repairForm.completed}
                                        onChange={(event)=> { 
                                            props.setRepairForm({ ...props.repairForm, completed: event.target.checked });
                                        }}
                                    />
                            
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography>Hours</Typography>
                                    <TextField
                                        name="timeSpentRepairing"
                                        value={props.repairForm.timeSpentRepairing}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography>Description</Typography>
                                    <TextField
                                        name="description"
                                        multiline
                                        minRows={4}
                                        value={props.repairForm.description}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography>General Notes</Typography>
                                    <TextField
                                        name="notes"
                                        multiline
                                        minRows={4}
                                        value={props.repairForm.notes}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography>Tech Notes</Typography>
                                    <TextField
                                        name="techNotes"
                                        multiline
                                        minRows={4}
                                        value={props.repairForm.techNotes}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Repair', event) }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item sm={6}>
                                    <Typography>Part #</Typography>
                                    <TextField
                                        name="partNumber"
                                        value={props.partForm.partNumber}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Part', event) }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography>Quantity</Typography>
                                    <TextField
                                        name="quantity"
                                        value={props.partForm.quantity}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Part', event) }}
                                        fullWidth
                                        type="number"
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography>Part Description</Typography>
                                    <TextField
                                        name="partDescription"
                                        multiline
                                        minRows={4}
                                        value={props.partForm.partDescription}
                                        onChange={(event)=> { props.handleChangeAdditionalInfo('Part', event) }}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                    />
                                </Grid>

                                <Grid item sm={12}>
                                    <Typography>Part Photos</Typography>

                                    <Dropzone 
                                        accept={{'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png', '.PNG'], 'application/pdf': ['.pdf'],}}
                                        onDrop={(fileArray) => {
                                            let photoArrayContainer = _.cloneDeep(props.partForm);
                                            fileArray.forEach((file) => { photoArrayContainer.photoUrls.push(file) })
                                            props.setPartForm(photoArrayContainer)
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <Grid container item xs={12}justifyContent='center' {...getRootProps()}  style={{border:'2px dashed lightGrey', minHeight:'100px'}}>
                                                <input {...getInputProps()} style={{ display: 'none' }} />
                        
                                                <PublishIcon style={{color:'grey', marginRight:'20px'}}/>

                                                <Typography variant='h6'>
                                                    {"Upload Part Photos"}
                                                </Typography>
                                            
                                            </Grid> 
                                        )}
                                    </Dropzone> 

                                    {props.partForm?.photoUrls?.length > 0 ? 
                                    <React.Fragment>
                                        <Typography >Photos Included</Typography>

                                        <Grid>   
                                            {props.partForm?.photoUrls?.length > 0 ?
                                                <Grid container spacing={2}>

                                                    {props.partForm?.photoUrls?.map((photo, index) => (
                                                        <Grid item key={photo + index}>
                                                            { deleteIndex === index ?
                                                            <Grid container style={{position: 'relative', border: '1px solid grey', height: '100px', width: '100px'}}>
                                                                <Grid > 
                                                                    <Typography>Are you sure?</Typography>
                                                                </Grid>

                                                                <Grid container>
                                                                    <Grid item xs={6}>
                                                                        <IconButton>
                                                                            <CheckIcon 
                                                                                style={{color: 'green'}}
                                                                                onClick={() => { props.handleDeletePhoto(index); setDeleteIndex(null) }} 
                                                                            />
                                                                        </IconButton>
                                                                    </Grid>

                                                                    <Grid item xs={6} style={{position: 'absolute', right: 0}}>                             
                                                                        <IconButton>
                                                                            <CloseIcon 
                                                                                style={{color: 'red'}}
                                                                                onClick={() => { setDeleteIndex(null) }} 
                                                                            />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            :
                                                            <Grid container style={{position: 'relative'}}>
                                                                <img 
                                                                    src={typeof photo === "string" ? photo : URL.createObjectURL(photo)} 
                                                                    alt={photo.name} 
                                                                    style={{ height: "100px", width:'100px' }} 
                                                                />

                                                                <IconButton size='small' style={{position: 'absolute', top: 0, right: 0}}>
                                                                    <CloseIcon 
                                                                        style={{color: 'red'}}
                                                                        onClick={() => { setDeleteIndex(index) }} 
                                                                    />
                                                                </IconButton>
                                                            </Grid>
                                                            }
                                                        </Grid>
                                                    ))}
                                                </Grid> : null}
                                                

                                        </Grid>
                                    </React.Fragment> 
                                    : null}

                                </Grid>

                            </React.Fragment>
                            }
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            // disabled={
                            //     (dialogType === 'Edit Repair' || dialogType === 'New Repair')
                            //     ? (props.repairForm.description === '')
                            //     : (props.partForm.partNumber === '' || props.partForm.partDescription === '')
                            // }
                            onClick={() => {
                                props.handleAddAdditionalInfo(dialogType);
                                setAddRepairModal(false);
                            }}
                            color="primary"
                        >
                            Save
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => { setAddRepairModal(false) }}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>


                 {/* img modal */}
                 <Dialog
                    
                    open={imgModal}
                    onClose={ () => {setImgModal(false)}}
                >
                    <DialogContent>
                        <Grid container spacing={2} style={{minWidth:'100%', height:'100%'}}>
                            <Grid item sm={12}>
                                <Img placeholder={truck} src={imgUrl} alt={'Part Photo'} style={{width:'500px'}}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            onClick={() => { setImgModal(false) }}
                            color="secondary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>

            <div>
                {dataCard}
            </div>
        </div>
    )
}

export default withRouter(WorkOrderCard)