export const REPORT_TYPES = {
    TIMESHEET: "timesheet",
    JOB: "job",
    CUSTOMER: "customer",
    COVID: "covid",
    LOG: "log",
    DEFECT: "defect",
    UNIT: "unit",
    DELIVERY: "delivery",
    PHOTOS: "photos",
    DOCUMENTS: "documents",
}