import * as actionTypes from '../actions/actionTypes';
import { REPORT_TYPES } from '../../constants/reportTypes';
import { updateObject } from '../utility'

const initialState = {
    timesheets: [],
    jobs: [],
    deliveries: [],
    loading: false,
    startDate: new Date(),
    reportType: REPORT_TYPES.TIMESHEET,
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.BEGIN:
            return updateObject(state, { loading: action.loading });
        case actionTypes.GET_TIMESHEETS:
            return updateObject(state, { timesheets: action.timesheets, loading: action.loading });
        case actionTypes.GET_JOBS:
            return updateObject(state, { jobs: action.jobs, loading: action.loading });
        case actionTypes.GET_DEFECTS:
            return updateObject(state, { defects: action.defects, loading: action.loading });    
        case actionTypes.GET_DELIVERIES:
            return updateObject(state, { deliveries: action.deliveries, loading: action.loading });
        case actionTypes.UPDATE_DATE:
            return updateObject(state, { loading: action.loading, startDate: action.startDate });
        case actionTypes.SET_REPORT_TYPE:
            return updateObject(state, { reportType: action.reportType });
        default:
            return state;
    }
};

export default reportReducer;